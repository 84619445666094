<div style="width: 96%; margin: auto;" align="center">
  <app-solutions-header></app-solutions-header>
  <h1 *ngIf="translate.defaultLang === 'ru'">Самоорганизация</h1>
  <h1 *ngIf="translate.defaultLang === 'en'">Self-organizer</h1>
  <br/>
  <img
    [@nettyos]
    style="width: 300px;"
    src="./assets/images/landing/step2/in-one-all.svg"
  />
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'ru'">
    <p class="text">
      Nettyos можно использовать для какой-то конкретной, ограниченной цели, а можно для всего сразу.
      Это зависит от настроек и тарифного плана.
      <br/>
      <br/>
      Можно, записывать все идеи, а потом планировать как и когда их выполнить с комментариями, ссылками и датами. А
      можно оставлять просьбы членам семьи, друзьям, работать с команадми по SCRUM, KANBAN, Waterfall и вести еще свой
      личный бизнес. Все это максимально легко и удобно.
      <br/>
      <br/>
      <strong>Если использовать Nettyos для самоорганизации, то на тарифе <a [routerLink]="'/pricing'">"Free Plan"</a> практически нет ограничений. Вот
        краткий список что доступно:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>напоминания</li>
        <li>сегодняшний todo лист</li>
        <li>списки тикетов: будущий, идеи, готово</li>
        <li>поиск по всем тикетам</li>
        <li>чек-листы без ограничений</li>
        <li>комментарии без ограничений</li>
        <li>места</li>
        <li>описание тикета</li>
        <li>ссылки тикета</li>
        <li>Nettyos Коуч</li>
        <li>Анализ Работы</li>
        <li>Ассистент Nettyos</li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class="text">
      <strong>Жизненные ситуации, в которых это решение может пригодиться:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>Не забыть принять лекарства. Если несколько, то в чек-лист.</li>
        <li>Забрать готовые документы. Дата, адрес, номер брони в описание. Если что-то поменятеся, то комментарии.</li>
        <li>Подать документы на что-то. Часто нужны пачки документов из разных мест. Тут без чек-листа не обойтись.</li>
        <li>Встреча или митинг на определенное время. Оповещение можно поставить за 5 минут, час, день...</li>
        <li>Записать идеи чтобы не забыть. Когда придет время, эту идею можно будет превратить в проект прямо в Nettyos.
          И добавить команду к проекту.
        </li>
        <li>Записать конкретные мечты. Примерно, 20 мечт из 100 у взрослых людей сбываются за год. С остальными поможет
          Nettyos.
        </li>
        <li>Начать выбирать подарок любимому человеку заранее и записывать все идеи в Nettyos. Проработку точно
          заметит.
        </li>
        <li>Написать список инструментов и материалов для ремонта.</li>
        <li>И очень много чего еще...</li>
      </ul>
    </div>

  </div>
  <div *ngIf = "translate.defaultLang === 'en'">
    <p class = "text">
      Nettyos can be used for a specific, limited purpose, or it can be used for everything at once.
      It depends on the settings and the tariff plan.
      <br/>
      <br/>
      You can write down all the ideas, and then plan how and when to complete them with comments, links and dates. Or
      you can leave requests to family members, friends, work with teams on SCRUM, KANBAN, Waterfall and keep your own
      personal business. All this is as easy and convenient as possible.
      <br/>
      <br/>
      <strong>If you use Nettyos for self-organization, then there are practically no restrictions on the <a [routerLink]="'/pricing'">"Free Plan"</a> tariff. Here
        short list of what's available: </strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> reminders </li>
        <li> today's todo list </li>
        <li> ticket lists: future, ideas, done </li>
        <li> search all tickets </li>
        <li> unlimited checklists </li>
        <li> unlimited comments </li>
        <li> places </li>
        <li> ticket description </li>
        <li> ticket links </li>
        <li> Nettyos Coach </li>
        <li> Job Analysis </li>
        <li> Nettyos Assistant </li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class = "text">
      <strong> Life cases in which this solution might be useful: </strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> Don't forget to take your medicine. If there are several, then put into the checklist. </li>
        <li> Pick up ready-made documents. Date, address, booking number set in the description. If something changes, then use the comments. </li>
        <li> Submit documents for something. A lot of documents from different places are often needed. You can't do without a checklist here. </li>
        <li> Meeting for a specific time. An alert can be set in 5 minutes, an hour, a day ... </li>
        <li> Write down ideas so as not to forget. When the time comes, this idea can be turned into a project right in Nettyos.
          And add a team to the project.
        </li>
        <li> Write down specific dreams. Approximately 20 dreams out of 100 in adults come true in a year. Nettyos will help with the rest.
        </li>
        <li> Start choosing a gift for your loved one in advance and write down all ideas in Nettyos. Working out exactly will be noticed.
        </li>
        <li> Write a list of tools and materials for repair. </li>
        <li> And a lot more ... </li>
      </ul>
    </div>

  </div>
  <br/>
  <br/>
  <br/>
  <br/>
</div>
