<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Netty menu">
  <mat-icon>menu</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="me">
    {{ 'MENU.ME' | translate}}
<!--    <mat-icon style="float: right; width: 5px !important; height: 5px !important;">notifications_active</mat-icon>-->
    <span *ngIf="personService.unread" class="alerts-are">&nbsp;&nbsp;&nbsp;</span>
  </button>
  <button [routerLink]="'/today'" mat-menu-item>{{ 'MENU.TODAY' | translate}}</button>
  <button [routerLink]="'/people'" mat-menu-item>{{ 'MENU.PEOPLE_BOOK' | translate}}</button>
  <button [routerLink]="'/places'" mat-menu-item>{{ 'MENU.PLACES' | translate}}</button>
  <button [routerLink]="'/projects'" mat-menu-item>{{ 'MENU.PROJECTS' | translate}}</button>
  <button [routerLink]="'/teams'" mat-menu-item>{{ 'MENU.TEAMS' | translate}}</button>
  <button mat-menu-item [matMenuTriggerFor]="nettyos">nettyos</button>
  <button [routerLink]="'/'" (click)="exit()" mat-menu-item>{{ 'MENU.EXIT' | translate}}</button>
</mat-menu>

<mat-menu #nettyos="matMenu">
  <button [routerLink]="'/about'" mat-menu-item>{{ 'MENU.ABOUT' | translate}}</button>
  <button [routerLink]="'/guide'" mat-menu-item>{{ 'MENU.GUIDE' | translate}}</button>
  <button [routerLink]="'/payment-plan'" mat-menu-item>{{ 'MENU.PAYMENT' | translate}}</button>
  <button *ngIf="personService.profile.email === 'niki.danilov@gmail.com'" [routerLink]="'/control'" mat-menu-item>CONTROL</button>
</mat-menu>

<mat-menu #me="matMenu">
  <button [routerLink]="'/profile'" mat-menu-item>{{ 'MENU.PROFILE' | translate}}</button>
  <button [routerLink]="'/alerts'" mat-menu-item>
    {{ 'MENU.ALERTS' | translate}}
    <span *ngIf="personService.unread" class="alerts-are">&nbsp;&nbsp;&nbsp;</span>
  </button>
  <button [routerLink]="'/skills'" *ngIf="personService.alpha || gatewayService.localhost" mat-menu-item>{{ 'MENU.SKILLS' | translate}}</button>
  <button [routerLink]="'/cost'" mat-menu-item>{{ 'MENU.COST' | translate}}</button>
  <button [routerLink]="'/coach'" mat-menu-item>
    <img style="width: 38px; vertical-align: middle; margin-right: 10px;" src="./assets/images/components/coach/coach.svg">
    <span >{{ 'MENU.COACH' | translate}}</span></button>
</mat-menu>
