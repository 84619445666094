import {Component, DoCheck, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {PersonService} from "../../../services/person.service";
import {Md5} from 'ts-md5/dist/md5';
import {GatewayService, Profile} from "../../../services/gateway.service";
import {AuthService} from "../../../services/auth.service";
import {transition, trigger, useAnimation} from "@angular/animations";
import {bounceInRight, bounceInUp, bounceOutUp, pulse, rotateIn, tada, wobble} from "ng-animate";

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
  animations: [
    trigger('free', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 2}
      }))
    ]),
    trigger('free1', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 2.2}
      }))
    ]),
    trigger('free2', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 2.4}
      }))
    ]),
    trigger('free3', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 2.6}
      }))
    ]),
    trigger('free4', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 2.8}
      }))
    ]),
    trigger('free5', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 3}
      }))
    ]),
    trigger('free6', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 3.2}
      }))
    ]),
    trigger('free7', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 3.4}
      }))
    ]),
    trigger('free8', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 3.6}
      }))
    ]),
    trigger('free9', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 3.8}
      }))
    ]),
    trigger('free10', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 4}
      }))
    ]),
    trigger('free11', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 4.2}
      }))
    ]),
    trigger('free12', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 4.4}
      }))
    ]),
    trigger('free13', [
      transition('void => *', useAnimation(rotateIn, {
        params: { timing: 4.6}
      }))
    ]),
    trigger('free14', [
      transition('void => *', useAnimation(bounceInRight, {
        params: { timing: 5}
      }))
    ])
  ]
})
export class PayComponent implements OnInit/*, DoCheck*/ {

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  choosenAccount = 0;
  currency = 'USD';
  totalAmount = 0;
  roboRubAmount = 0;
  description = '';
  signatureValue = '';
  invId = 0;
  noAuth = true;
  freeFeatures1 = 'init';
  paymentUKassaLink = '';

  constructor(private _formBuilder: FormBuilder,
              public translate: TranslateService,
              private personService: PersonService,
              public gateway: GatewayService,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.noAuth = this.auth.token == null || this.auth.token === '' || this.auth.token === 'no';
    this.firstFormGroup = this._formBuilder.group({
      // firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      totalAmountCtrl: ['', Validators.required],
      paymentPlanCtrl: ['', Validators.required],
      periodCtrl: ['', Validators.required]
    });

    // this.startLoadingAnimation();
  }

  // ngDoCheck(): void {
  //   this.noAuth = this.personService.profile.auth == null || this.personService.profile.auth === '' || this.personService.profile.auth === 'no';
  // }

  choosePaymentPlan(number: number, language: string, plan: string, period: string) {
    // by default all in USD, multiplier = 1;
    let multiplier = 1;
    if (language === 'ru') {
      this.currency = 'RUB';
      multiplier = 75;
    } else {
      this.currency = 'USD';
      multiplier = 1;
    }
    this.totalAmount = number * multiplier;
    this.choosenAccount = number;
    this.secondFormGroup.get('totalAmountCtrl').setValue((number * multiplier) + ' ' + this.currency);
    this.secondFormGroup.get('paymentPlanCtrl').setValue(plan);
    this.secondFormGroup.get('periodCtrl').setValue(period);

    this.description = this.personService.profile.email + ' ' + plan + ' ' + period;

    const md5 = new Md5();
    // console.log(md5.appendStr('hello').end());
    this.roboRubAmount = number * 75;

      // $mrh_login:$out_summ:$inv_id:$mrh_pass1

    // create order
    this.gateway.userServiceCreateOrder(this.personService.profile.email, number, plan, period).subscribe(
      (data) => {
        // console.log('invId', data);
        this.invId = data.id;
        this.personService.invId = this.invId;
        const str = 'nettyos:' + (number * 75) + ':' + data.id + ':ZvvKzGp6u5M7Ot6izB0w';
        this.signatureValue = md5.appendStr(str).end().toString();
      });


  }

 // private startLoadingAnimation() {
    //setTimeout(() => {this.freeFeatures1 = 'go'}, 1000);
  //}
  payUkassa() {
    this.gateway.userServicePayUkassaGetFinalLink(this.totalAmount, "RUB", this.description).subscribe(
      (data) => {
        if (data.result === 'okay') {
          this.paymentUKassaLink = data.message;
        }
        // console.log(data);
      });
  }
}
