import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {transition, trigger, useAnimation} from "@angular/animations";
import {flip, tada} from "ng-animate";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-family-hub',
  templateUrl: './family-hub.component.html',
  styleUrls: ['./family-hub.component.scss'],
  animations: [
    trigger('nettyos', [
      transition('void => *', useAnimation(tada))
    ]),
  ]
})
export class FamilyHubComponent implements OnInit {

  constructor(public translate: TranslateService, private router: Router) { }

  ngOnInit(): void {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0)
    // });
  }

}
