<div style="width: 96%; margin: auto;">
  <br/>
  <br/>
  <h1 *ngIf="translate.defaultLang === 'en'">Netty Guide</h1>
  <h1 *ngIf="translate.defaultLang === 'ru'">Нетти Гайд</h1>
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'en'">
    <table>
      <tr>
        <td style="vertical-align: top;">
          <img
            [@apps]
            style="width: 50px;"
            src="./assets/images/logos/appstore.png"
          /></td>
        <td style="vertical-align: top; margin-left: 10px;">
          <button *ngIf="!showAppStore" (click)="showAppStore = true;" mat-icon-button color="primary"><mat-icon>expand_more</mat-icon></button>
          <button *ngIf="showAppStore" (click)="showAppStore = false;" mat-icon-button color="primary"><mat-icon>expand_less</mat-icon></button>
        </td>
        <td style="vertical-align: top; margin-left: 10px;">
          <h3>How to use Web-App Nettyos as native iOS-App?</h3>
          <div *ngIf="showAppStore">
            <ul>
              <li>Open a browser on your iOS device - <span style="color: red;">‼️</span>Safari.
              <li>Go to the website https://nettyos.com.</li>
              <li>Tap the Share button (if you can't see the bottom menu, tap at the bottom of the screen).</li>
              <li>From the menu that appears, select - Home Screen.</li>
            </ul>
            <br/>
            <i>P.S. The Nettyos iOS app is already available to alpha-testers. Check out the App Store soon.</i>
          </div>
        </td>
      </tr>
    </table>
    <br/>
    <table>
      <tr>
        <td style="vertical-align: top;">
          <img
            [@apps]
            style="width: 50px;"
            src="./assets/images/logos/googleplay.png"
          /></td>
        <td style="vertical-align: top; margin-left: 10px;">
          <button *ngIf="!showGooglePlay" (click)="showGooglePlay = true;" mat-icon-button color="primary"><mat-icon>expand_more</mat-icon></button>
          <button *ngIf="showGooglePlay" (click)="showGooglePlay = false;" mat-icon-button color="primary"><mat-icon>expand_less</mat-icon></button>
        </td>
        <td style="vertical-align: top; margin-left: 10px;">
          <h3>How to use Web-App Nettyos as native Android-App?</h3>
          <div *ngIf="showGooglePlay">
            <ul>
              <li>Open a browser on your Android device - <span style="color: red;">‼️</span>Chrome.
              <li>Go to the website https://nettyos.com.</li>
              <li>Open the menu and select "Add to Home Screen".</li>
            </ul>
            <br/>
            <i>P.S. The Nettyos Android app is at the Prototype stage. Nettyos web application works under all modern Android devices in all respects comparable the native one.</i>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="translate.defaultLang === 'ru'">
    <table>
      <tr>
        <td style="vertical-align: top;">
          <img
            [@apps]
            style="width: 50px;"
            src="./assets/images/logos/appstore.png"
          /></td>
        <td style="vertical-align: top; margin-left: 10px;">
          <button *ngIf="!showAppStore" (click)="showAppStore = true;" mat-icon-button color="primary"><mat-icon>expand_more</mat-icon></button>
          <button *ngIf="showAppStore" (click)="showAppStore = false;" mat-icon-button color="primary"><mat-icon>expand_less</mat-icon></button>
        </td>
        <td style="vertical-align: top; margin-left: 10px;">
          <h3>Использовать Веб-Приложение Nettyos как нативное iOS-Приложение?</h3>
          <div *ngIf="showAppStore">
            <ul>
              <li>Откройте на своем iOS-устройстве браузер <span style="color: red;">‼️</span>Safari.
              <li>Зайдите на сайт https://nettyos.com.</li>
              <li>Нажмите на кнопку Поделиться (если не видно нижнее меню, нажмите внизу экрана).</li>
              <li>В появившемся меню выберите - На экран «Домой».</li>
            </ul>
            <br/>
            <i>P.S. Приложение Nettyos под iOS уже достуно альфа-тестерам. Ожидайте скоро в App Store.</i>
          </div>
        </td>
      </tr>
    </table>
    <br/>
    <table>
      <tr>
        <td style="vertical-align: top;">
          <img
            [@apps]
            style="width: 50px;"
            src="./assets/images/logos/googleplay.png"
          /></td>
        <td style="vertical-align: top; margin-left: 10px;">
          <button *ngIf="!showGooglePlay" (click)="showGooglePlay = true;" mat-icon-button color="primary"><mat-icon>expand_more</mat-icon></button>
          <button *ngIf="showGooglePlay" (click)="showGooglePlay = false;" mat-icon-button color="primary"><mat-icon>expand_less</mat-icon></button>
        </td>
        <td style="vertical-align: top; margin-left: 10px;">
          <h3>Использовать Веб-Приложение Nettyos как нативное Android-Приложение?</h3>
          <div *ngIf="showGooglePlay">
            <ul>
              <li>Откройте на своем Android-устройстве браузер <span style="color: red;">‼️</span>Chrome.
              <li>Зайдите на сайт https://nettyos.com.</li>
              <li>Откройте меню и выберите «Добавить на главный экран».</li>
            </ul>
            <br/>
            <i>P.S. Приложение Nettyos под Android находится на стадии Prototype. Веб приложение Nettyos работает под всеми современными устройстами с Android сравнительно по всем параметрам с нативным.</i>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <hr/>
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'en'">
    <span style="font-size: 200%;">Getting Started :: <a href="https://youtu.be/6hLE7EDqGd4" style="color: lightblue;"
                                                         target="_blank">Video v4.2.1</a></span>
  </div>
  <div *ngIf="translate.defaultLang === 'ru'">
    <span style="font-size: 200%;">Как начать :: <a href="https://youtu.be/u7887l0cd2c" style="color: lightblue;"
                                                         target="_blank">Видео v4.2.1</a></span>,
    <span style="font-size: 200%;"><a href="https://youtu.be/UD2O__n1S44" style="color: lightblue;"
                                                         target="_blank">Первое Знакомство (Урок №1) v4.4.9</a></span>
  </div>
  <hr/>
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'en'">
    <mat-icon [@tooltip]="tooltipAnimation" [ngStyle]="{color: tooltipAnimation}">forum</mat-icon>
    Move the cursor to any element and wait for a second. You will get a tooltip. Using a mobile device - hold your
    finger on the element for a while to get a tooltip.
    <br/>
    <br/>
    <mat-icon [@help]="helpAnimation" [ngStyle]="{color: helpAnimation}">live_help</mat-icon>
    Press the button with this icon on the top right of the screen. Nettyos assistant will be activated. Later press any
    element or button to see the prompt.
  </div>
  <div *ngIf="translate.defaultLang === 'ru'">
    <mat-icon [@tooltip]="tooltipAnimation" [ngStyle]="{color: tooltipAnimation}">forum</mat-icon>
    Подведите курсор к любому элементу и подождите секунду. Вы получите всплывающую подсказку. Используя мобильное устройство - удерживайте палец на элементе некоторое время, чтобы получить всплывающую подсказку.
    <br/>
    <br/>
    <mat-icon [@help]="helpAnimation" [ngStyle]="{color: helpAnimation}">live_help</mat-icon>
    Нажмите кнопку с этим значком в правом верхнем углу экрана. Ассистент Nettyos будет активирован. Позже нажмите любой элемент или кнопку, чтобы увидеть подсказку.
  </div>
  <br/>
  <br/>
  <h2 *ngIf="translate.defaultLang === 'en'">I use Netty for personal and family cases</h2>
  <h2 *ngIf="translate.defaultLang === 'ru'">Я использую Нетти для личных и семейных дел</h2>
  <div style="margin: auto; width: fit-content;">
    <img [@smallPicSlideInLeft]="inOneAll"
         inViewport
         (inViewportAction)="visibleInViewport($event, 'inOneAll')"
         style="width: 150px;"
         src="./assets/images/landing/step2/in-one-all.svg"
    />&nbsp;&nbsp;
    <img [@smallPicSlideInRight]="withAllFamilyPlan"
         inViewport
         (inViewportAction)="visibleInViewport($event, 'withAllFamilyPlan')"
         style="width: 150px;"
         src="./assets/images/landing/step2/with-all-family-plan.svg"
    />
  </div>
<!--  open-->
  <ul *ngIf="translate.defaultLang === 'en'">
    <li>
      <strong>What does Ticket2Success mean?</strong>
      <br/><br/>
      <i>This is extended task. Not just a task anymore and not so boring. Ticket also may be: a deal, an affair, a plan, an idea, a meeting, an event, an epic, a goal, a project, a skill, a micro-task, a big deal... All of these at the same time or combine types depends on the context.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to add ticket to my list?</strong>
      <br/><br/>
      <i>Go 'today' option in menu. This is main list of tickets. Write ticket name at the top of the page and press 'Add' button. Simplified goal for all Netty users. To category all tickets from your list and see message 'Get some rest - all done.'</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to add an event for particular date?</strong>
      <br/><br/>
      <i>Add ticket to today list. How to. Look above. Assign date to the ticket and time if necessary. Change 'today' status in left top of the tickets card. Choose 'date'. Set date and press 'okay' button.</i>
      <br/><br/>
    </li>
    <li>
      <strong>What colors in Netty means?</strong>
      <br/><br/>
      <i>
        <span style="background-color: #a8ef80;">&nbsp;&nbsp;&nbsp;</span> - ticket done.
        <span style="background-color: #D26927;">&nbsp;&nbsp;&nbsp;</span> - ticket cancelled.
        <span style="background-color: rgb(188, 216, 244);">&nbsp;&nbsp;&nbsp;</span> - ticket is idea.
        <span style="background-color: #424242;">&nbsp;&nbsp;&nbsp;</span> - ticket in future.
        <span style="background-color: red">&nbsp;&nbsp;&nbsp;</span> - ticket removed.
        <span style="background-color: darkviolet">&nbsp;&nbsp;&nbsp;</span> - Premium/Projects.
        <span style="background-color: darkorange">&nbsp;&nbsp;&nbsp;</span> - Pro/Teams.
      </i>
      <br/><br/>
    </li>
    <li>
      <strong>How to share ticket with friend?</strong>
      <br/><br/>
      <i>Open 'People Book' section. Add friend and wait confirmation. After you may change ticket type from @personal to @friend shared. Right in the ticket card. @friend will see ticket in the 'today' list and get alert via email.</i>
      <br/><br/>
    </li>
  </ul>
  <ul *ngIf="translate.defaultLang === 'ru'">
    <li>
      <strong>Что значит ТикетНаУспех?</strong>&nbsp;<a href="https://youtu.be/-sgeWklFcfg" style="color: lightblue;"
                                                   target="_blank">Бесплатный курс по nettyos.com v4.5.9 Урок №2.1 (видео)</a>
      <br/><br/>
      <i>Это расширенная задача. Больше не просто задача и не такая скучная. Тикет также может быть сделкой, делом, планом, идеей, встречей, событием, эпиком, целью, проектом, навыком, микро-задачей... Все это по раздельности и одновременно, в зависимости от контекста.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как добавить новый тикет?</strong>&nbsp;<a href="https://youtu.be/1kyj0jjFSXI" style="color: lightblue;"
                                                         target="_blank">Бесплатный курс по nettyos.com v4.5.9 Урок №2.2 (видео)</a>
      <br/><br/>
      <i>Необходимо открыть опцию 'Сегодня' в меню. Это главный список тикетов - называется 'Сегодня'. Напишите название тикета и нажмите кнопку "Добавить". Упрощенная цель для всех пользователей Нетти - стремиться к тому чтобы список "Сегодня" был пустой и увидеть сообщение: "Все сделано - можно немного отдохнуть". Тогда базовые алгоритмы "личного успеха" Нетти будут работать правильно. Не обязательно все выплолнять сегодня. Можно перенести, на завтра, потом и много других опций.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как добавить событие на конкретную дату?</strong>&nbsp;<a href="https://youtu.be/LUsUTSC21js" style="color: lightblue;"
                                                                        target="_blank">Бесплатный курс по nettyos.com v4.5.9 Урок №2.3 (видео)</a>
      <br/><br/>
      <i>Добавить тикет (всегда имеется ввиду ТикетНаУспех) в список "Сегодня". Как? Описано выше. При необходимости можно настроить тикет и добавить дату / время. Измените статус «сегодня» в левом верхнем углу карточки тикета. Выберите «дата». Установите дату и нажмите кнопку «Хорошо».</i>
      <br/><br/>
    </li>
    <li>
      <strong>Что означают цвета в Нетти?</strong>&nbsp;<a href="https://youtu.be/LUsUTSC21js" style="color: lightblue;"
                                                           target="_blank">Бесплатный курс по nettyos.com v4.5.9 Урок №2.3 (видео)</a>
      <br/><br/>
      <i>
        <span style="background-color: #a8ef80;">&nbsp;&nbsp;&nbsp;</span> - тикет сделан.
        <span style="background-color: #D26927;">&nbsp;&nbsp;&nbsp;</span> - тикет отменен.
        <span style="background-color: rgb(188, 216, 244);">&nbsp;&nbsp;&nbsp;</span> - тикет - это идея.
        <span style="background-color: #424242;">&nbsp;&nbsp;&nbsp;</span> - тикет в будущем.
        <span style="background-color: red">&nbsp;&nbsp;&nbsp;</span> - тикет удален.
        <span style="background-color: darkviolet">&nbsp;&nbsp;&nbsp;</span> - Премиум/Проекты.
        <span style="background-color: darkorange">&nbsp;&nbsp;&nbsp;</span> - Pro/Команды.
      </i>
      <br/><br/>
    </li>
    <li>
      <strong>Как поделиться тикетом с другом?</strong>&nbsp;<a href="https://youtu.be/LUsUTSC21js" style="color: lightblue;"
                                                                target="_blank">Бесплатный курс по nettyos.com v4.5.9 Урок №2.3 (видео)</a>
      <br/><br/>
      <i>Откройте секцию "Люди" в меню. Пригласите друга по email и ожидайте подтверждение. После этого можно поменять тип тикета с @личный на @друг. Прямо в карточке тикета. @друг тут же увидит тикет в своем списке и получит оповещение по почте.</i>
      <br/><br/>
    </li>
  </ul>
  <hr/>
  <br/>
  <br/>
  <h2 *ngIf="translate.defaultLang === 'en'">I use Nettyos for personal growth and private personal projects</h2>
  <h2 *ngIf="translate.defaultLang === 'ru'">Я использую Нетти для личностного роста и персональных проектов</h2>
  <div style="margin: auto; width: fit-content;">
    <img [@smallPicSlideInLeft]="lifeProProcesses"
         inViewport
         (inViewportAction)="visibleInViewport($event, 'lifeProProcesses')"
         style="width: 150px;"
         src="./assets/images/landing/step2/life-pro-processes.svg"
    />&nbsp;&nbsp;
    <img [@smallPicSlideInRight]="topPsy"
         inViewport
         (inViewportAction)="visibleInViewport($event, 'topPsy')"
         style="width: 150px;"
         src="./assets/images/landing/step2/top-psy.svg"
    />
  </div>
  <ul *ngIf="translate.defaultLang === 'en'">
    <li>
      <strong>How to not forget small things may become important?</strong>
      <br/><br/>
      <i>Use Netty Places. Open section 'Places' in menu. Add any place you want. Home, office, car, virtual kingdom map where you are the king. Later in 'Today' add the place to the ticket. Click place you go and see what you planned there. <a href="https://youtu.be/tX3BT34kUXA" class="link" target="_blank">About Places Video 1</a>&nbsp;&nbsp;<a href="https://youtu.be/20_AayQzSp8" class="link" target="_blank">About Places Video 2</a></i>
      <br/><br/>
    </li>
    <li>
      <strong>How to create new personal project?</strong>
      <br/><br/>
      <i>Go 'Personal Projects' option in menu. On the top of the screen write full project name. Short name will be generated automatically. Or you can change it. Click 'Add' button.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to add a ticket into the personal project?</strong>
      <br/><br/>
      <i>In the section 'Today'. Change attribute @personal to project name.</i>
      <br/><br/>
    </li>
    <li>
      <strong>I am an SMM manager. How to manage multiple clients?</strong>
      <br/><br/>
      <i>SMM Manager is short for Social Media Manager. That is, the manager of social networks, such as Instagram, YouTube, FaceBook, TikTok, Vkontakte ... It is on the competent and high-quality work of an SMM-specialist that the promotion of a company, brand, personage, start. With personal projects and business processes from nettyos, you can increase the quantity and quality of this type of income. By creating a project for each customer, you delegate a very important part, planning, to the best planning system that has no analogs in the world. Improve the quality and volume of work. For example, instead of 3 customers, you can process up to 10 customers. And due to an increase in the quality of services, the check can grow multiple times from each Client. The client will be happy with the result and ready to raise the check. This also applies to other businesses in the role of self-employed.</i>
      <br/><br/>
    </li>
    <li>
      <strong>I am TikTok or Instagram coach. How to promote bloggers with nettyos?</strong>
      <br/><br/>
      <i>Blogging today is a high-income profession. Most often, it is bloggers-experts in a certain area that is of interest. And coaches are experts in promotion, video editing, content planning. Hiring a good expert coach is more logical than gaining expertise in several other areas. Hence the demand for good coaches is very high. Instead of one content plan for all, you can create a project for each blogger-customer with a unique content plan. And right in nettyos to throw to the client an already worked out ticket with personalized business processes to each customer. The income of such a coach could be comparable to the income of a blogger. And, thanks to the unique nettyos planning system, the customer will always receive only the highest quality results and only on time. Nettyos personal projects will replace any CRM for self-employed and small businesses with an increase in efficiency at times. Without having to go through complex training. Medium and large business - the next section "Teams".</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to obtain netty gold coins?</strong>
      <br/><br/>
      <i>Complete shared ticket from your people book. You will get amount of gold coins. Amount of day coins limited.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to obtain netty silver coins?</strong>
      <br/><br/>
      <i>Complete your own ticket. More job/thinking/planning were done with ticket - more coins. Amount of day tickets limited.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to add business-processes to the personal project?</strong>
      <br/><br/>
      <i>Open Menu -> Personal Projects. Select the tab with name 'Business-processes'. Now you can add/remove steps of linear business-process. And look at the result process in the real time.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to use business-processes with tickets?</strong>
      <br/><br/>
      <i>Go to the Today list of tickets. Only tickets assigned to the project have this option. Click 'add business-process' button in the footer area of the ticket. And select the step of the process. Available only if business-process exists int the project and more then 1 step.</i>
      <br/><br/>
    </li>
  </ul>
  <ul *ngIf="translate.defaultLang === 'ru'">
    <li>
      <strong>Как не забыть незначительные вещи, которые могут оказаться очень важными?</strong>&nbsp;<a href="https://youtu.be/h6LI_pmZSCY" style="color: lightblue;"
                                                                                                   target="_blank">Бесплатный курс по nettyos.com v4.6.13 Урок №3.1 (видео)</a>
      <br/><br/>
      <i>Используйте Места Нетти. Откройте 'Места' в меню. Добавьте любое место. Дом, оффис, машину, виртуальную карту королевства, на которой Вы король. Позднее в списке 'Сегодня' прикрепите место к тикету. Перед тем как поехать, нажмите место и посмотрите, что надо было там сделать. <a href="https://youtu.be/tX3BT34kUXA" class="link" target="_blank">О Местах Видео 1 (английский)</a>&nbsp;&nbsp;<a href="https://youtu.be/20_AayQzSp8" class="link" target="_blank">О Местах Видео 2 (английский)</a></i>
      <br/><br/>
    </li>
    <li>
      <strong>Как создать новый персональный проект?</strong>&nbsp;<a href="https://youtu.be/wraF3o8RzD8" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.6.14 Урок №3.2 (видео)</a>
      <br/><br/>
      <i>Открыть пункт главного меню 'Личные Проекты'. Необходимо выбрать опцию "новый проект..." Ввести полное название проекта в соответсвующем поле. Короткое название проекта будет сгенерировано автоматически. Короткое название можно также изменить. Нажать кнопку 'Добавить'.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как добавить тикет в личный проект?</strong>&nbsp;<a href="https://youtu.be/wraF3o8RzD8" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.6.14 Урок №3.2 (видео)</a>
      <br/><br/>
      <i>В секции 'Сегодня' в карточке тикета. Поменять атрибут @личный на имя проекта.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Я SMM-менеджер. Как вести нескольких клиентов?</strong>&nbsp;<a href="https://youtu.be/4XHyKXCkaD0" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.6.14 Урок №3.3 (видео)</a>
      <br/><br/>
      <i>SMM-менеджер это сокращение из английского языка. Social Media Manager. В переводе на русский "Менеджер Социальных Медиа". То  есть менеджер социальных сетей, таких как: Instagram, YouTube, FaceBook, TikTok, Vkontakte ...
        Именно от грамотной и качественной работы SMM-щика зависит продвижение компании, бренда, личности. С личными проектами и бизнес-процессами от nettyos Вы можете увеличить количество и качество этого вида заработка. Создавая
        проект для каждого отдельного заказчика Вы делегируете очень важную часть, планирование, лучшей системе планирования, которая не имеет аналогов в мире. Повысите качество и объем работы. К примеру, вместо 3 заказчиков по 10 тыс рублей в месяц
        сможете обрабатывать до 10 заказчиков. А за счет увеличения качества услуг чек может вырасти к 30 тыс руб (это примерно $400 на начало 2021) в месяц и более с каждого Клиента. Клиент же будет счастлив от результата и готов повышать чек.
        Это относится и к другим видам бизнеса в роли самозанятых.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Я ТикТок или Инстаграм -коуч. Как продвигать блогеров с nettyos?</strong>&nbsp;<a href="https://youtu.be/RoQIpgdfimg" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.7.15 Урок №3.4 (видео)</a>
      <br/><br/>
      <i>Блогер сегодня - это профессия с высоким доходом. Чаще всего интересуют именно блогеры-эксперты в какой-то области. А коучи являются экспертами в продвижении, монтаже видео, контент-плане.
      Логичнее всего нанять хорошего экспертного коуча, чем получать экспертность в еще нескольких областях. Отсюда очень высокий
      спрос на хороших коучей. Вместо одного контент-плана для всех можно создать проект для каждого блогера-заказчика с уникальным
      контент-планом. И прямо в nettyos скидывать уже проработанный тикет по персонализированным бизнес-процессам каждому заказчику.
        Доходы такого коуча будут сравнимы с прибылью блогера. А, благодаря уникальной системе планирования nettyos, заказчик будет получать
      всегда только результат высшего качества и только вовремя. Личные проекты nettyos заменят любую CRM для самозанятых и малого бизнеса с
        увеличением эффективности в разы. Без необходимости проходить сложное обучение. Средний и большой бизнес - следующая секция "Команды".</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как получить золотые монеты Нетти?</strong>&nbsp;<a href="https://youtu.be/4hIJB2h7jDQ" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.8.26 Урок №3.5 (видео)</a>
      <br/><br/>
      <i>Закончить тикет, которым поделились друзья или контакты из секции "Люди". За это получаете золотые монеты. Количество золотых монет в день ограничено.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как получить себебряные моменты Нетти?</strong>&nbsp;<a href="https://youtu.be/4hIJB2h7jDQ" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.8.26 Урок №3.5 (видео)</a>
      <br/><br/>
      <i>Завершить свой собственный тикет. Не надо бояться отменять. Иногда, это лучшее решение. И монет будет начислено столько же, сколько и за статус "сделано". Больше работы/обдумывания/планирования было с определенным тикетом - больше монет. Количество тикетов за день ограничено.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как добавить бизнес-процесс в персональный проект?</strong>&nbsp;<a href="https://youtu.be/ykiZiZhVO2w" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.8.42 Урок №3.6 (видео)</a>
      <br/><br/>
      <i>Необходимо открыть Меню -> Личные Проекты. Выбрать вкладку 'Бизнес-процессы'. Теперь можно добавлять/удалять шаги бизнес-процесса. И видеть результат в реальном времени.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как использовать бизнес-процессы с тикетами?</strong>&nbsp;<a href="https://youtu.be/ykiZiZhVO2w" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.8.42 Урок №3.6 (видео)</a>
      <br/><br/>
      <i>Необходимо перейти в список тикетов Сегодня. Только тикеты прикрепленные к проекту имеют эту опцию. При этом в проекте должен быть бизнес-процесс с более, чем одним шагом. Нажать кнопку 'добавить бизнес-процесс' в нижней области тикета. И выбрать шаг бизнес-процесса.</i>
      <br/><br/>
    </li>
  </ul>
  <br/>
  <p *ngIf="translate.defaultLang === 'en'">
    Netty Growth Center not implemented yet in Netty 4 version. Still available in Netty 3. Coming soon here.
  </p>
  <p *ngIf="translate.defaultLang === 'ru'">
    Нетти Центр Роста еще не имплементирован в Нетти 4. Все еще доступен в Нетти 3. Скоро будет.
  </p>
  <hr/>
  <br/>
  <br/>
  <h2 *ngIf="translate.defaultLang === 'en'">I use Nettyos with my team</h2>
  <h2 *ngIf="translate.defaultLang === 'ru'">Я использую Нетти с моей командой</h2>

  <div style="margin: auto; width: fit-content;">
    <img [@smallPicSlideInLeft]="plannerOfTeam"
         inViewport
         (inViewportAction)="visibleInViewport($event, 'plannerOfTeam')"
         style="width: 150px;"
         src="./assets/images/landing/step2/planner-of-team.svg"
    />&nbsp;&nbsp;
    <img [@smallPicSlideInRight]="topDevices"
         inViewport
         (inViewportAction)="visibleInViewport($event, 'topDevices')"
         style="width: 150px;"
         src="./assets/images/landing/step2/top-devices.svg"
    />
  </div>
  <ul *ngIf="translate.defaultLang === 'en'">
    <li>
      <strong>How to create my own team?</strong>
      <br/><br/>
      <i>Open menu and click 'Teams' section.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to add members into the team?</strong>
      <br/><br/>
      <i>Open the team. Menu -> Teams -> Select the team. And add members by email (id).</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to create project in the team?</strong>
      <br/><br/>
      <i>Open the team. Menu -> Teams -> Select the team. And add project in the bottom of the page.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to create ticket in the team project?</strong>
      <br/><br/>
      <i>Open the team. Menu -> Teams -> Select the team -> Find project and tap 'Go'. Create ticket with in the section you want with "+" button.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How i can find ticket from team in my today list?</strong>
      <br/><br/>
      <i>Only tickets in the section 'In Progress' are visible in today list. And ticket must be assigned to you.</i>
      <br/><br/>
    </li>
    <li>
      <strong>How to assign the team ticket?</strong>
      <br/><br/>
      <i>On the teams project dashboard click the ticket and choose in the dialog box the member of the team to give this ticket.</i>
      <br/><br/>
    </li>
    <li>
      <strong> How and why to add an epic to a team project? </strong>
      <br/> <br/>
      <i> An epic is in other words a subproject or part of a project, a projects chapter. For example, you decide to add to
        food delivery app Siri support. In order not to confuse Siri tickets with tickets of the main project, it is most convenient
        add epic: "Support for voice control (Siri)".
        <br/>
        <br/>
        Or for the construction of a three-stages house in the country side. Each floor can be divided into a separate epic. And sometimes a room, depending on the volume: "Kitchen", "Living room", "Bedroom" ...
        <br/>
        <br/>
        To add an epic, select the "Teams" menu item -> create or select a team -> select a team project (or create) -> go to the Settings tab and add an epic.
      </i>
      <br/> <br/>
    </li>
    <li>
      <strong> How and why to add a sprint to a team project? </strong>
      <br/> <br/>
      <i>
        Sprints are a kind of epics, the same subprojects, but only scheduled for a specific duration. In fact, by combining epics and sprints (races), you can implement any team model.
        <br/>
        <br/>
        To add a sprint, select the "Teams" menu item -> create or select a team -> select a team project (or create) -> go to the Settings tab and add a sprint on a specific date.
      </i>
      <br/> <br/>
    </li>
    <li>
      <strong> How to set up Scrum, Kanban, Agile in nettyos? </strong>
      <br/> <br/>
      <i>
        Use the required combinations of sprints and epics. If the development methodology involves only two-week sprints, then create as many as you need.
      </i>
      <br/> <br/>
    </li>

    <li>
      <strong> How to set up Waterfall processes and other types (pre-Agile)? </strong>
      <br/> <br/>
      <i>
        Use epics that are part of the methodologies. Add epic type.
      </i>
      <br/> <br/>
    </li>
  </ul>
  <ul *ngIf="translate.defaultLang === 'ru'">
    <li>
      <strong>Как создать себе команду?</strong> <a href="https://youtu.be/mlnvN994-ss" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.13.24 Урок №4.1 (видео)</a>
      <br/><br/>
      <i>Открыть главное меню и выбрать пункт 'Команды'.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как добавить участников в команду?</strong> <a href="https://youtu.be/mlnvN994-ss" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.13.24 Урок №4.1 (видео)</a>
      <br/><br/>
      <i>Открыть команду. Меню -> Команды -> Выбрать команду. И добавить участика по email-id. То есть по тому email, с которым пользователь регистрировался в Нетти.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как создать проект для команды?</strong> <a href="https://youtu.be/mlnvN994-ss" style="color: lightblue;" target="_blank">Бесплатный курс по nettyos.com v4.13.24 Урок №4.1 (видео)</a>
      <br/><br/>
      <i>Открыть команду. Меню -> Команды -> Выбрать команду. Добавить новый проект внизу страницы. Ввести полное название, краткое и нажать кнопку "Добавить".</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как создать тикет в проекте команды?</strong>
      <br/><br/>
      <i>Открыть команду. Меню -> Команды -> Выбрать команду -> Найти проект и нажать 'Перейти'. Создать тикет в выбранной секции кнопкой "+".</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как мне найти командный тикет в моем списке дел "Сегодня"</strong>
      <br/><br/>
      <i>Только тикеты в секции "В Процессе" видны в списке "Сегодня". Этот тикет должен быть назначен Вам.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как назначить командный тикет?</strong>
      <br/><br/>
      <i>На панели проекта выберите тикет, кликните. В открывшемся диалоговом окне необходимо выбрать участника команды, который будет исполнять этот тикет.</i>
      <br/><br/>
    </li>
    <li>
      <strong>Как и зачем добавлять эпик в командный проект?</strong>
      <br/><br/>
      <i>Эпик - это другими словами подпроект или часть проекта, глава проекта. Например, Вы решили добавить к
        приложению по доставке еды поддержку Siri. Чтобы не путать тикеты с тикетами основного проекта удобнее всего
        добавить эпик: "Поддержка голосового управления (Siri)".
        <br/>
        <br/>
        Или для строительства трехэтажного дома на даче. Каждый этаж можно разделить на отдельный эпик. А иногда и помещение, в зависимости от объема: "Кухня", "Гостинная", "Спальня"...
        <br/>
        <br/>
        Чтобы добавить эпик необходимо выбрать пункт меню "Команды" -> создать или выбрать команду -> выбрать командный проект (или создать) -> перейти во вкладку Настройки и добавить эпик.
      </i>
      <br/><br/>
    </li>
    <li>
      <strong>Как и зачем добавлять спринт в командный проект?</strong>
      <br/><br/>
      <i>
        Спринты - это вид эпиков, тех же самых подпроектов, но только запланированных на определенную длительность. По сути, комбинируя эпики и спринты (забеги) можно реализовать любую командную модель.
        <br/>
        <br/>
        Чтобы добавить спринт необходимо выбрать пункт меню "Команды" -> создать или выбрать команду -> выбрать командный проект (или создать) -> перейти во вкладку Настройки и добавить спринт на определенную дату.
      </i>
      <br/><br/>
    </li>
    <li>
      <strong>Как настроить Scrum, Kanban, Agile в nettyos?</strong>
      <br/><br/>
      <i>
        Используйте необходимые комбинации спринтов и эпиков. Если методика разработки предполагает только двухнедельные спринты, то создавайте сколько потребуется.
      </i>
      <br/><br/>
    </li>

    <li>
      <strong>Как настроить Waterfall процессы и остальные виды (до Agile)?</strong>
      <br/><br/>
      <i>
        Используйте эпики, которые являются составными частями методологий. Добавляйте тип эпика.
      </i>
      <br/><br/>
    </li>
  </ul>
  <br/>
  <br/>
</div>
