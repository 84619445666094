import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(cables: any[], ...search: string []): any[] {

    let resultArray = [];

    for (let i = 0; i < search.length; i++) {
      if (!search[i].trim()) {
      } else {
        let tmpArray =  cables.filter(cable => {
          if (null != cable.status) {
            return cable.status.toLowerCase().includes(search[i].toLowerCase() || search[i].toLowerCase());
          }
        });
        resultArray = resultArray.concat(tmpArray);
      }
    }


    return resultArray;
  }

}
