<div [class]="pclass">
  <br/>
  <br/>
  <p>
    Full functionality was available in Netty Online Scheduler for free since 2009.
    <br/><br/>
    The main functionality of Netty planning to remain for free.
    <br/><br/>
    Old netty users finished thousands of private and team projects with success. And uncountable tickets.
    <br/><br/>
    Old and new Netty users saying that Netty the most comfortable and light project manager, day list, and area of
    personal growth.
    <br/><br/>
    All of you may power up Netty O.S. transferring new platforms faster.
    <br/><br/>
  </p>

  <mat-checkbox (click)="resetAll()" [(ngModel)]="agree" color="primary" style="width: 90%;">
    I am agree,
  </mat-checkbox>
  <br/>
  that all funds will be transferred in the form of a gratuitous gift to the authors of nettyos.com service without any
  guarantees and promises.
  <br/>
  <br/><br/>

  <!--    https://www.paypal.com/paypalme/nikitadanilov/21USD -->
  <div *ngIf="agree else ifNotAgree" style="margin: auto; width: fit-content;" align="center">
    Power up Netty with any amount: <br/><br/>
    <div style="color: lightblue; margin: 10px;">
      <a target="_blank"
         href="https://www.paypal.com/paypalme/nikitadanilov/10USD"
      >
        <img
          [@money]="USD10" (mouseover)="USD10 = animationType;" (click)="USD10 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/10USD.jpeg">
      </a>
    </div>

    <br/>
    <div style="color: lightblue; margin: 10px;">
      <a target="_blank"
         href="https://www.paypal.com/paypalme/nikitadanilov/20USD">
        <img
          [@money]="USD20" (mouseover)="USD20 = animationType;" (click)="USD20 = animationType;"
          style="width: 150px;"
          src="./assets/images/payments/20USD.jpg">
      </a>
    </div>
    <br/>
    <div style="color: lightblue; margin: 10px;">
      <a target="_blank"
         href="https://www.paypal.com/paypalme/nikitadanilov/50USD">
        <img
          [@money]="USD50" (mouseover)="USD50 = animationType;" (click)="USD50 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/50USD.jpg">
      </a>
    </div>
    <br/>
    <div style="color: lightblue; margin: 10px;">
      <a target="_blank"
         href="https://www.paypal.com/paypalme/nikitadanilov/100USD">
        <img
          [@money]="USD100" (mouseover)="USD100 = animationType;" (click)="USD100 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/100USD.jpg">
      </a>
    </div>
    <br/>
    <div style="color: lightblue; margin: 10px;">
      <a target="_blank"
         href="https://www.paypal.com/paypalme/nikitadanilov/1000USD">
        <img
          [@money]="USD1000" (mouseover)="USD1000 = animationType;" (click)="USD1000 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/1000USD.jpg">
      </a>
    </div>
    <br/>
    <div style="color: lightblue; margin: 10px;">
      <a target="_blank"
         href="https://www.paypal.com/paypalme/nikitadanilov/10000USD">
        <img
          [@money]="USD10K" (mouseover)="USD10K = animationType" (click)="USD10K = animationType"
          style="width: 150px;"
          src="./assets/images/payments/10000USD.png">
      </a>
    </div>
  </div>

  <ng-template #ifNotAgree>
    <div style="margin: auto; width: fit-content;" align="center">
      <div [@agree] *ngIf="alert.length > 0">
        <span style="color: #03a9f4; font-size: 200%;">{{alert}}</span>
        <br/>
        <br/>
      </div>
      Power up Netty with any amount: <br/><br/>
      <div style="color: lightblue; margin: 10px;"
           href="https://www.paypal.com/paypalme/nikitadanilov/10USD"
      >
        <img
          [@moneyprev]="USD10" (click)="checkAgree()" (mouseover)="USD10 = animationType" (click)="USD10 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/10USD.jpeg">
      </div>
      <br/>
      <div style="color: lightblue; margin: 10px;"
           href="https://www.paypal.com/paypalme/nikitadanilov/20USD">
        <img
          [@moneyprev]="USD20" (click)="checkAgree()" (mouseover)="USD20 = animationType" (click)="USD20 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/20USD.jpg">
      </div>
      <br/>
      <div style="color: lightblue; margin: 10px;"
           href="https://www.paypal.com/paypalme/nikitadanilov/50USD">
        <img
          [@moneyprev]="USD50" (click)="checkAgree()" (mouseover)="USD50 = animationType" (click)="USD50 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/50USD.jpg">
      </div>
      <br/>
      <div style="color: lightblue; margin: 10px;"
           href="https://www.paypal.com/paypalme/nikitadanilov/100USD">
        <img
          [@moneyprev]="USD100" (click)="checkAgree()" (mouseover)="USD100 = animationType" (click)="USD100 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/100USD.jpg">
      </div>
      <br/>
      <div style="color: lightblue; margin: 10px;"
           href="https://www.paypal.com/paypalme/nikitadanilov/1000USD">
        <img
          [@moneyprev]="USD1000" (click)="checkAgree()" (mouseover)="USD1000 = animationType" (click)="USD1000 = animationType"
          style="width: 150px;"
          src="./assets/images/payments/1000USD.jpg">
      </div>
      <br/>
      <div style="color: lightblue; margin: 10px;"
           href="https://www.paypal.com/paypalme/nikitadanilov/10000USD">
        <img
          [@moneyprev]="USD10K" (click)="checkAgree()" (mouseover)="USD10K = animationType" (click)="USD10K = animationType"
          style="width: 150px;"
          src="./assets/images/payments/10000USD.png">
      </div>
    </div>
  </ng-template>

  <br/><br/>

  <p>Write contact email and transaction credentials to payment@nettyos.com We will make a lot of conquests with amazing rewards. Such as forever corporate subscription with unlimited team players, great options and other. Your chance to win will multiply with milliseconds of period between your transaction and conquest with amount of money was sent moved into cents amount and other parameters. Also this is a chance to appear in the hall of glory.</p>

  <br/><br/>

</div>
