import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PersonService} from "../../../services/person.service";
import {Alert} from "../../../dto/Alerts";
import {GatewayService} from "../../../services/gateway.service";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  constructor(public translate: TranslateService, public personService: PersonService, private gateway: GatewayService) { }

  ngOnInit(): void {
    this.loadAllAlerts();
  }

  loadAllAlerts() {
    this.personService.loading = true;
    this.gateway.userServiceGetUserAlerts().subscribe(
      (alerts: Alert []) => {
        this.personService.alerts = alerts;
        this.personService.unread = false;
        for (let alert of alerts) {
          if (!alert.readFlag) {
            this.personService.unread = true;
          }
        }
        // console.log('alerts', this.personService.alerts);
        this.personService.loading = false;
      });
  }

  alertWasRead(alert: Alert) {
    this.gateway.userServiceUnreadUserAlert(alert).subscribe(
      (answer: ExtendedStatus) => {
        this.personService.loading = false;
        this.loadAllAlerts();
      });
  }


}
