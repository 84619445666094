import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PersonService} from "../../../services/person.service";
import {Alert} from "../../../dto/Alerts";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {GatewayService} from "../../../services/gateway.service";

export var periods = [
  // {
  //   "name": "Germany",
  //   "value": 8940000
  // },
  // {
  //   "name": "USA",
  //   "value": 5000000
  // },
  // {
  //   "name": "France",
  //   "value": 7200000
  // }
];

@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss']
})
export class CostComponent implements OnInit {

  workingChart: any[];

  view: any[] = [700, 400];

  // lastWorkingPoint = 0;
  // lastInterruptionPoint = 0;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Periods Of Work';
  showYAxisLabel = true;
  yAxisLabel = 'Time In Minutes';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  onSelect(event) {
    console.log(event);
  }

  reason = '';
  dayLine = '';
  chart: any;
  description = false;

  constructor(public translate: TranslateService, public personService: PersonService, private gateway: GatewayService) {
    periods = Object.assign([], personService.workingChart);
    Object.assign(this, { workingChart: periods });
  }

  ngOnInit(): void {
    this.gateway.userServicePullWorkingChart().subscribe(
      (answer: any) => {
        this.personService.loading = false;
        if (null != answer) {
          this.workingChart = answer;
          if (null == this.workingChart)
            this.workingChart = [];
          this.personService.workingChart = this.workingChart;
        }
      });
  }

  reset() {
    this.workingChart = [];
    this.personService.workingChart = [];
    this.personService.lastWorkingPoint = 0;
    this.personService.lastInterruptionPoint = 0;
    this.personService.lastBreakPoint = 0;
    // push to sycn changes on the server
    this.gateway.userServicePushWorkingChart(JSON.stringify(this.workingChart)).subscribe(
      (answer: ExtendedStatus) => {
        this.personService.loading = false;
      });
  }

  working() {

    this.gateway.userServicePullWorkingChart().subscribe(
      (answer: any) => {
        this.personService.loading = false;
        this.workingChart = answer;
        if (null == this.workingChart)
          this.workingChart = [];
        this.personService.workingChart = this.workingChart;
        // after working chart updated change and push

        let INTERRUPTED = 'Interrupted';
        let BREAK = 'Break';
        if (this.translate.defaultLang === 'ru') {
          INTERRUPTED = 'Отвлекся';
          BREAK = 'Перерыв';
        }

        if (this.personService.lastWorkingPoint === 0) {
          // milliseconds
          this.personService.lastWorkingPoint = new Date().getTime();
        }

        if (this.personService.lastInterruptionPoint !== 0) {
          let nowTs = new Date().getTime();
          let totalTimeInSec = (nowTs - this.personService.lastInterruptionPoint) / (1000 * 60);
          if (this.reason === '') {
            this.workingChart.push({
              "name": INTERRUPTED + " " + this.randomString(5),
              "value": totalTimeInSec
            });
          } else {
            this.workingChart.push({
              "name": INTERRUPTED + " " + this.randomString(5) + ' - ' + this.reason,
              "value": totalTimeInSec
            });
          }
          this.workingChart = [...this.workingChart];
          this.personService.workingChart = this.workingChart;
          this.personService.interruptedCounter++;
          this.personService.lastInterruptionPoint = 0;
        } else if (this.personService.lastBreakPoint !== 0) {
          let nowTs = new Date().getTime();
          let totalTimeInSec = (nowTs - this.personService.lastBreakPoint) / (1000 * 60);
          this.workingChart.push({
            "name": BREAK + " " + this.randomString(5),
            "value": totalTimeInSec
          });
          this.workingChart = [...this.workingChart];
          this.personService.workingChart = this.workingChart;
          this.personService.breakCounter++;
          this.personService.lastBreakPoint = 0;
        }
        // reset interrupted field
        this.reason = '';

        // let nowTs = new Date().getTime();
        // this.dayLine += 'w' + nowTs + ' ';
        // this.single.push({
        //   "name": nowTs,
        //   "value": nowTs
        // });
        // this.single = [...this.single];


        // push to sycn changes on the server
        this.gateway.userServicePushWorkingChart(JSON.stringify(this.workingChart)).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          });
      });

  }

  interrupting() {

    this.gateway.userServicePullWorkingChart().subscribe(

        (answer: any) => {
          this.personService.loading = false;
          this.workingChart = answer;
          if (null == this.workingChart)
            this.workingChart = [];
          this.personService.workingChart = this.workingChart;
          // after working chart updated change and push


          let WORKING = 'Working';
          if (this.translate.defaultLang === 'ru') {
            WORKING = 'Работаю';
          }

          this.personService.lastInterruptionPoint = new Date().getTime();
          if (this.personService.lastWorkingPoint !== 0) {
            let nowTs = new Date().getTime();
            let totalTimeInSec = (nowTs - this.personService.lastWorkingPoint) / (1000 * 60);
            this.workingChart.push({
              "name": WORKING + " " + this.randomString(5),
              "value": totalTimeInSec
            });
            this.workingChart = [...this.workingChart];
            this.personService.workingChart = this.workingChart;
            this.personService.workCounter++;
            this.personService.lastWorkingPoint = 0;
          }

          // push to sycn changes on the server
          this.gateway.userServicePushWorkingChart(JSON.stringify(this.workingChart)).subscribe(
            (answer: ExtendedStatus) => {
              this.personService.loading = false;
            });

      });

  }

  finished() {

    this.gateway.userServicePullWorkingChart().subscribe(
      (answer: any) => {
        this.personService.loading = false;
        this.workingChart = answer;
        if (null == this.workingChart)
          this.workingChart = [];
        this.personService.workingChart = this.workingChart;
        // after working chart updated change and push


        let WORKING = 'Working';
        if (this.translate.defaultLang === 'ru') {
          WORKING = 'Работаю';
        }
        this.personService.lastInterruptionPoint = 0;
        if (this.personService.lastWorkingPoint !== 0) {
          let nowTs = new Date().getTime();
          let totalTimeInSec = (nowTs - this.personService.lastWorkingPoint) / (1000 * 60);
          this.workingChart.push({
            "name": WORKING + " " + this.randomString(5),
            "value": totalTimeInSec
          });
          this.workingChart = [...this.workingChart];
          this.personService.workingChart = this.workingChart;
          this.personService.workCounter++;
          this.personService.lastWorkingPoint = 0;
        }
        // push to sycn changes on the server
        this.gateway.userServicePushWorkingChart(JSON.stringify(this.workingChart)).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          });
      });

  }

  break() {
    this.gateway.userServicePullWorkingChart().subscribe(
      (answer: any) => {
        this.personService.loading = false;
        this.workingChart = answer;
        if (null == this.workingChart)
          this.workingChart = [];
        this.personService.workingChart = this.workingChart;
        // after working chart updated change and push

        let WORKING = 'Working';
        if (this.translate.defaultLang === 'ru') {
          WORKING = 'Работаю';
        }

        this.personService.lastBreakPoint = new Date().getTime();
        if (this.personService.lastWorkingPoint !== 0) {
          let nowTs = new Date().getTime();
          let totalTimeInSec = (nowTs - this.personService.lastWorkingPoint) / (1000 * 60);
          this.workingChart.push({
            "name": WORKING + " " + this.randomString(5),
            "value": totalTimeInSec
          });
          this.workingChart = [...this.workingChart];
          this.personService.workingChart = this.workingChart;
          this.personService.workCounter ++;
          this.personService.lastWorkingPoint = 0;
          // push to sycn changes on the server
          this.gateway.userServicePushWorkingChart(JSON.stringify(this.workingChart)).subscribe(
            (answer: ExtendedStatus) => {
              this.personService.loading = false;
            });
        }
      });

  }

  randomString(length) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

}
