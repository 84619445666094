import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpEventType} from "@angular/common/http";
import {AuthService} from "../../../services/auth.service";
import {GatewayService, Profile} from "../../../services/gateway.service";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {PersonService} from "../../../services/person.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  selectedFile: File = null;

  constructor(
    private http: HttpClient,
    public gateway: GatewayService,
    private auth: AuthService,
    public personService: PersonService,
    private _formBuilder: FormBuilder
  ) {
  }


  // loader
  changePasswordForm: FormGroup;
  answer = '';
  progress = 0;
  ngOnInit(): void {
    // let url = '/api/usr/v1/user/ava/';
    // if (this.gateway.localhost) {
    //   url = this.gateway.USER_SERVICE + url;
    // }
    // this.http.get<ExtendedStatus>(url)
    //   .subscribe((data) => {
    //     //console.log('ava', data);
    //     this.ava = data.result;
    //   });
    // let url = '/api/usr/v1/user/ava/';
    // if (this.gateway.localhost) {
    //     url = this.gateway.USER_SERVICE + url;
    // }
    // this.http.put<ExtendedStatus>(url, {auth: this.auth.token})
    //   .subscribe((data) => {
    //     //console.log('ava', data);
    //     this.ava = data.result;
    //   });
    this.personService.loading = true;
    this.gateway.userServiceGetUserProfile().subscribe(
      (profile: Profile) => {
       //console.log('profile', profile);
        this.personService.profile = profile;
        this.personService.addCoins(profile.silverCoin, profile.goldCoin);
        // this.personService.silver = profile.silverCoin;
        // this.personService.gold = profile.goldCoin;
        this.personService.loading = false;
      });
    this.changePasswordForm = this._formBuilder.group({
      currentPasswordCtrl: ['', Validators.required],
      newPasswordCtrl: ['', Validators.required],
      confirmationPasswordCtrl: ['', Validators.required]
    });
  }

  changePasswordOnSubmit() {
    this.personService.loading = true;
    this.gateway.loginRegisterServiceChangePassword(this.changePasswordForm.value).subscribe(
      (status: ExtendedStatus) => {
        // todo: alert yes
        //console.log(status);
        this.answer = status.message;
        this.personService.loading = false;
      });
  }

  onFileSelected(event) {
    this.selectedFile = <File> event.target.files[0];
  }

  onUpload() {
    const fd = new FormData();
    fd.append('image', this.selectedFile, this.selectedFile.name);
    // this.http.post('', fd)
    //   .subscribe(res => {
    //     //console.log(res);
    //   }
    // );

    this.personService.loading = true;
    this.gateway.uploadProfileImage(fd).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          //console.log(event, Math.round(event.loaded / event.total * 100));
          this.progress = Math.round(event.loaded / event.total * 100);
        } else if (event.type === HttpEventType.Response) {
          this.gateway.userServiceGetUserProfile().subscribe(
            (profile: Profile) => {
              //console.log('profile', profile);
              this.personService.profile = profile;
              this.personService.addCoins(profile.silverCoin, profile.goldCoin);
              // this.personService.silver = profile.silverCoin;
              // this.personService.gold = profile.goldCoin;
              this.personService.loading = false;
              this.selectedFile = null;
            });
        }
      });
  }
}
