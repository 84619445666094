import {Component, OnInit} from '@angular/core';
import {GatewayService} from "../../../services/gateway.service";
import {PersonService} from "../../../services/person.service";

export interface Person {
  ava: string;
  firstName: string;
  lastName: string;
  personId: string;
  status: string;
  type: string;
  tmpType: string;
}

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  explanationText = '';
  relationsType = 'friend';
  emailOfPerson = '';
  // persons: Person [] = [];

  constructor(private gateway: GatewayService, public personService: PersonService) {
  }

  ngOnInit(): void {
    this.loadPeople();
  }

  // loading
  private loadPeople() {
    this.personService.loading = true;
    this.gateway.outPersons()
      .subscribe(data => {
        this.personService.persons = data;
        this.personService.loading = false;
        //console.log('outPersons', data);
      });
  }

  explain() {
    if (this.explanationText.length === 0) {
      this.explanationText = 'You can add person not from Netty to your people section.';
    } else {
      this.explanationText = '';
    }
  }

  typeOfRelations() {
    //console.log('relations type', this.relationsType);
  }

  // loading
  addPersonByEmail() {
    this.personService.loading = true;
    //console.log('emailOfPerson', this.relationsType, this.emailOfPerson);
    this.gateway.userAddPerson(this.emailOfPerson, this.relationsType)
      .subscribe(data => {
        //console.log('addPersonByEmail', data);
        this.personService.loading = false;
        this.loadPeople();
      });
  }

  // loading
  approve(person: Person) {
    this.personService.loading = true;
    this.gateway.approvePerson(person.personId, person.tmpType)
      .subscribe(data => {
        // console.log('addPersonByEmail', data);
        this.personService.loading = false;
        this.loadPeople();
      });
  }


  // loading
  remove(person: Person) {
    this.personService.loading = true;
    this.gateway.removePerson(person.personId)
      .subscribe(data => {
        // console.log('addPersonByEmail', data);
        this.personService.loading = false;
        this.loadPeople();
      });
  }
}
