import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PersonService} from "../../../services/person.service";

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  @Input() language;
  @Input() size = '33px';

  constructor(private translate: TranslateService, public personService: PersonService) { }

  ngOnInit(): void {
  }

  changeLanguage() {

    // chain of language -> personService.language
    setTimeout(() => {
      this.translate.setDefaultLang(this.personService.language);
      // alert(this.language)
    }, 0);
  }

}
