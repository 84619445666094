import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {bounce, slideInLeft, slideInRight, zoomIn} from "ng-animate";

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
  animations: [
    trigger('smallPicSlideInLeft', [
      transition('* => *', useAnimation(slideInLeft))
    ]),
    trigger('smallPicSlideInRight', [
      transition('* => *', useAnimation(slideInRight))
    ]),
    trigger('apps', [
      transition('* => *', useAnimation(zoomIn))
    ]),
    trigger('tooltip', [
      state('green', style({
        color: 'green'
      })),
      state('yellow', style({
        color: 'yellow'
      })),
      state('blue', style({
        color: 'blue'
      })),
      state('red', style({
        color: 'red'
      })),
      state('violet', style({
        color: 'violet'
      })),
      state('grey', style({
        color: 'grey'
      })),
      state('orange', style({
        color: 'orange'
      })),
      transition('* => *', [
        animate('1000ms ease-in')/*,
        style({ color: 'green' })*/
      ])
    ]),
    trigger('help', [
      state('green', style({
        color: 'green'
      })),
      state('yellow', style({
        color: 'yellow'
      })),
      state('blue', style({
        color: 'blue'
      })),
      state('red', style({
        color: 'red'
      })),
      state('violet', style({
        color: 'violet'
      })),
      state('grey', style({
        color: 'grey'
      })),
      state('orange', style({
        color: 'orange'
      })),
      transition('* => *', [
        animate('1000ms ease-in')/*,
        style({ color: 'green' })*/
      ])
    ])
  ]
})
export class GuideComponent implements OnInit {
  plannerOfTeam = false;
  topDevices = false;
  lifeProProcesses = false;
  topPsy = false;
  inOneAll = false;
  withAllFamilyPlan = false;
  showAppStore = false;
  showGooglePlay = false;
  tooltipAnimation = 'green';
  helpAnimation = 'blue';

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    setInterval(() => {
      this.randomColor();
      this.randomColor2();
    }, 1010);
  }


  action($event: any) {
    console.log($event)
  }

  visibleInViewport($event: any, flag: any) {
    switch (flag) {
      case 'plannerOfTeam': {
        if (this.plannerOfTeam && !$event.visible) {
          this.plannerOfTeam = false;
        } else if (!this.plannerOfTeam && $event.visible) {
          this.plannerOfTeam = true;
        }
        break;
      }
      case 'topDevices': {
        if (this.topDevices && !$event.visible) {
          this.topDevices = false;
        } else if (!this.topDevices && $event.visible) {
          this.topDevices = true;
        }
        break;
      }
      case 'lifeProProcesses': {
        if (this.lifeProProcesses && !$event.visible) {
          this.lifeProProcesses = false;
        } else if (!this.lifeProProcesses && $event.visible) {
          this.lifeProProcesses = true;
        }
        break;
      }
      case 'topPsy': {
        if (this.topPsy && !$event.visible) {
          this.topPsy = false;
        } else if (!this.topPsy && $event.visible) {
          this.topPsy = true;
        }
        break;
      }
      case 'inOneAll': {
        if (this.inOneAll && !$event.visible) {
          this.inOneAll = false;
        } else if (!this.inOneAll && $event.visible) {
          this.inOneAll = true;
        }
        break;
      }
      case 'withAllFamilyPlan': {
        if (this.withAllFamilyPlan && !$event.visible) {
          this.withAllFamilyPlan = false;
        } else if (!this.withAllFamilyPlan && $event.visible) {
          this.withAllFamilyPlan = true;
        }
        break;
      }
    }
  }

  randomColor() {
    const colors = [
      "yellow",
      "green",
      "blue",
      "red",
      "violet",
      "grey",
      "orange"
    ];
    const i = Math.floor((Math.random() * colors.length) + 0);
    this.tooltipAnimation = colors[i];
    return colors[i];
  }

  randomColor2() {
    const colors = [
      "yellow",
      "green",
      "blue",
      "red",
      "violet",
      "grey",
      "orange"
    ];
    const i = Math.floor((Math.random() * colors.length) + 0);
    this.helpAnimation = colors[i];
    return colors[i];
  }
}
