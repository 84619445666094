import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PersonService} from "../../../services/person.service";
import {CommentType, UserComment} from "../../../dto/Comment";
import {FormControl, Validators} from "@angular/forms";
import {MyErrorStateMatcher} from "../../today/projects/projects.component";
import {GatewayService, Ticket} from "../../../services/gateway.service";

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  @Input() ticket: Ticket;
  @Output() commentsNumber =  new EventEmitter<UserComment []>();

  comments: UserComment [] = [];

  addNewCommentFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(200)
  ]);
  matcher = new MyErrorStateMatcher();

  constructor(public personService: PersonService, private gateway: GatewayService) { }

  ngOnInit(): void {
    this.getCommentsByTicket();
  }

  addNewComment() {
    const commentText = this.addNewCommentFormControl.value;
    let comment: UserComment = {
      userAva: this.personService.profile.ava,
      userName: this.personService.profile.fname,
      userSerName: this.personService.profile.lname,
      commentText: commentText,
      commentTs: new Date().getTime(),
      id: "",
      mainUserId: "",
      ticketId: this.ticket.uuid,
      type: (this.ticket.teamProjectId != null && this.ticket.teamProjectId.length > 0) ? CommentType.TEAM: CommentType.PERSONAL,
      auth: ""
    };
    this.personService.loading = true;
    this.gateway.userServiceAddNewComment(comment).subscribe(data => {
      this.personService.loading = false;

      // reset form
      this.addNewCommentFormControl.reset();
      this.addNewCommentFormControl.markAsPristine();
      this.addNewCommentFormControl.markAllAsTouched();
      this.addNewCommentFormControl.updateValueAndValidity();
      this.addNewCommentFormControl.setErrors(null);

      this.getCommentsByTicket();
      this.ticket.commentsNumberThis ++;

    });
  }

  getCommentsByTicket() {
    // comments/by/ticket/{auth}/{ticketId}
    this.personService.loading = true;
    this.gateway.userServiceGetCommentsByTicket(this.ticket).subscribe(data => {
      // console.log(data);
      this.comments = data;
      this.commentsNumber.emit(this.comments);
      this.personService.loading = false;
    });
  }

  deleteComment(cmm: UserComment) {
    this.personService.loading = true;
    this.gateway.userServiceDeleteComment(cmm, this.ticket).subscribe(data => {
      this.personService.loading = false;
      this.getCommentsByTicket();
      this.ticket.commentsNumberThis --;
    });
  }

}
