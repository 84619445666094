import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GatewayService, Ticket} from "../../../services/gateway.service";
import {PersonService} from "../../../services/person.service";
import {transition, trigger, useAnimation} from "@angular/animations";
import {bounceInUp, bounceOutUp} from "ng-animate";

@Component({
  selector: 'app-ticket-description',
  templateUrl: './ticket-description.component.html',
  styleUrls: ['./ticket-description.component.scss'],
  animations: [
    trigger('openClose', [
      transition('void => *', useAnimation(bounceInUp)),
      transition('* => void', useAnimation(bounceOutUp))
    ])
  ]
})
export class TicketDescriptionComponent implements OnInit {

  @Input() ticket: Ticket;
  @Input() autoReloadAvailableFlag;
  @Output() autoReload = new EventEmitter<boolean>();
  @Output() loadAllTicketsFromDescription = new EventEmitter<boolean>();

  constructor(public gateway: GatewayService,
              public personService: PersonService) { }

  ngOnInit(): void {
  }


  getTextWithLinks(actualDescription: string, fullDescription) {
    // the goal to add symbol charCode=10 with spaces
    let newDescription = '';
    let counter = 0;
    for (let i=0; i < fullDescription.length; i++) {
      if (fullDescription.charCodeAt(i) !== 10) {
        newDescription += fullDescription.charAt(i);
      } else {
        newDescription += ' ' + fullDescription.charAt(i) + ' ';
        counter-=2;
      }
    }
    let wordsArray = newDescription.split(' ');
    let finalWords = [];
    // only non cut words and links
    for (let word of wordsArray) {
      if (counter <= actualDescription.length) {
        finalWords.push(word);
        counter+= word.length + 1;
      }
    }
    return finalWords;
  }

  pureLink(wordOrLink: string) {
    while (wordOrLink.charAt(wordOrLink.length - 1) === ',' ||
      wordOrLink.charAt(wordOrLink.length - 1) === ';' ||
      wordOrLink.charAt(wordOrLink.length - 1) === ':' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '-' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '—' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '.'
      ) {
      if (wordOrLink.length > 1) {
        wordOrLink = wordOrLink.substring(0, wordOrLink.length - 2)
      }
    }
    return wordOrLink;
  }

  saveDescription(ticket: Ticket) {
    this.autoReload.emit(true);
    //console.log('saveDescription', this.autoReloadTicketsOpenFlag);
    // if (ticket.description.length > 5) {
    this.personService.loading = true;
    this.gateway.planningServiceSaveTicketDescription(ticket).subscribe(data => {
      // this.tickets = data;
      this.personService.loading = false;
      this.loadAllTicketsFromDescription.emit(true);
      setTimeout(() => {
        this.ticket.descriptionAddFlag = false;
      }, 1);
    });
    // } else {
    //   this.loadAllTickets();
    // }
  }


  loadAllTickets(number: number) {

  }

  closeDescriptionEditing() {
    this.autoReload.emit(true);
    setTimeout(() => {
      this.ticket.descriptionAddFlag = false;
    }, 1);
  }
}
