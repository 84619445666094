import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GatewayService, Ticket} from "../../../services/gateway.service";
import {PersonService} from "../../../services/person.service";
import {transition, trigger, useAnimation} from "@angular/animations";
import {bounceInUp, bounceOutUp} from "ng-animate";

@Component({
  selector: 'app-ticket-check-list',
  templateUrl: './ticket-check-list.component.html',
  styleUrls: ['./ticket-check-list.component.scss'],
  animations: [
    trigger('openClose', [
      transition('void => *', useAnimation(bounceInUp))/*,
      transition('* => void', useAnimation(bounceOutUp))*/
    ])
  ]
})
export class TicketCheckListComponent implements OnInit {

  @Input() ticket: Ticket;
  @Input() checkListAddingFlag;
  @Output() autoReload = new EventEmitter<boolean>();
  @Output() loadAllTicketsFromCheckList = new EventEmitter<boolean>();

  constructor(public gateway: GatewayService,
              public personService: PersonService) { }

  ngOnInit(): void {
  }

  setOptionUnchecked(ticket: Ticket, optionDone: string) {
    this.personService.loading = true;
    this.gateway.planningServiceSetCheckListOptionUncheckedToTicket(ticket, optionDone).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListDone.indexOf(optionDone, 0);
        if (index > -1) {
          ticket.checkListDone.splice(index, 1);
        }
        ticket.checkListActual.push(optionDone);
      }
      // console.log('newCheckListOption', data);
    });
  }

  deleteOptionCheckList(ticket: Ticket, optionToDelete: string) {
    this.personService.loading = true;
    this.gateway.planningServiceDeleteCheckListOptionInTicket(ticket, optionToDelete).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListDone.indexOf(optionToDelete, 0);
        if (index > -1) {
          ticket.checkListDone.splice(index, 1);
        }
        const index1 = ticket.checkListActual.indexOf(optionToDelete, 0);
        if (index1 > -1) {
          ticket.checkListActual.splice(index1, 1);
        }
      }
      // console.log('newCheckListOption', data);
    });
  }

  newCheckListOption(ticket: Ticket) {
    this.personService.loading = true;
    this.gateway.planningServiceAddCheckListOptionToTicket(ticket).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        ticket.checkListActual.push(ticket.checkListOption);
        ticket.checkListOption = '';
      }
      // console.log('newCheckListOption', data);
    });
  }

  setOptionDone(ticket: Ticket, optionActual: string) {
    this.personService.loading = true;
    this.gateway.planningServiceSetCheckListOptionCheckedToTicket(ticket, optionActual).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListActual.indexOf(optionActual, 0);
        if (index > -1) {
          ticket.checkListActual.splice(index, 1);
        }
        ticket.checkListDone.push(optionActual);
      }
      // console.log('newCheckListOption', data);
    });
  }


  closeCheckListEditing() {
    this.loadAllTicketsFromCheckList.emit(true);
    this.autoReload.emit(true);
    setTimeout(() => {
      this.checkListAddingFlag = false;
      this.ticket.checkListAddFlag = false;
    }, 10);
  }
}
