<div [class]="'center'">
  <br/>
  <br/>

  <h1 *ngIf="translate.defaultLang === 'en'">Cost Of Interruptions</h1>
  <h1 *ngIf="translate.defaultLang === 'ru'">Цена Прерываний (Процесса)</h1>

  <button mat-stroked-button color="primary" (click)="description = ! description">...</button>

  <div *ngIf="description">

    <i *ngIf="translate.defaultLang === 'ru'">Безобидное предложение коллеги выпить кофе может выбить из эффективного рабочего процесса на час и даже на целый рабочий день.</i>
    <i *ngIf="translate.defaultLang === 'en'">A co-worker's inoffensive coffee offer can knock you out of an efficient workflow for an hour or even a whole day.</i>
    <br/>
    <br/>
    <i *ngIf="translate.defaultLang === 'en'">How to find the edge between household chores and requests from relatives with a business workflow? A very relevant question after the start of the pandemic. Was I working all day in the job or fixing the tap in the kitchen?!</i>
    <i *ngIf="translate.defaultLang === 'ru'">Где найти грань между домашними делами и просьбами родственников с рабочим процессом? Очень актуальноый вопрос после начала пандемии. Работал целый день или чинил кран на кухне?!</i>

    <br/>
    <br/>

    <i *ngIf="translate.defaultLang === 'en'">Mark your points of working and interruptions.</i>
    <i *ngIf="translate.defaultLang === 'ru'">Отмечайте моменты когда Вы начинаете работать и когда Вас отвлекают/сами отвлекаетесь и почему.</i>

  </div>

  <br/>
  <br/>

  <button (click)="working()" mat-raised-button color="accent">{{ 'COST.WORKING' | translate }}</button>
  &nbsp;
  <button (click)="reset()" mat-icon-button color="warn"><mat-icon>autorenew</mat-icon></button>
  <br/>
  <br/>
  <mat-progress-bar *ngIf="personService.lastWorkingPoint !== 0" mode="buffer"></mat-progress-bar>

  <div *ngIf="personService.lastInterruptionPoint !== 0" class="center" align="center">
    <mat-spinner color="warn"></mat-spinner>
    <br/>
    {{ 'COST.INTERRUPTED' | translate }} {{reason}}
  </div>

  <div *ngIf="personService.lastBreakPoint !== 0" class="center" align="center">
    <mat-spinner color="primary"></mat-spinner>
    <br/>
    {{ 'COST.BREAK' | translate }} {{reason}}
  </div>

  <br/>
  <br/>
  <form class="cost-form">
    <button (click)="finished()" [disabled]="personService.lastWorkingPoint === 0" mat-raised-button color="primary">{{ 'COST.FINISHED' | translate }}</button>
    &nbsp;
    <button (click)="break()" [disabled]="personService.lastWorkingPoint === 0" mat-raised-button color="primary">{{ 'COST.BREAK' | translate }}</button>
    &nbsp;
    <button (click)="interrupting()" [disabled]="personService.lastWorkingPoint === 0" mat-raised-button color="warn">{{ 'COST.INTERRUPTED' | translate }}</button>
    &nbsp;
    <mat-form-field *ngIf="personService.lastWorkingPoint !== 0" class="cost-full-width">
      <mat-label>{{ 'COST.REASON' | translate }}</mat-label>
      <input [disabled]="reason.length > 20" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="reason" [placeholder]="'COST.COFFEE' | translate" >
    </mat-form-field>
    <button *ngIf="reason.length > 20" mat-icon-button (click)="reason = ''"><mat-icon color="warn">delete</mat-icon></button>
    <br/>
    <br/>
<!--    <mat-form-field class="example-full-width">-->
<!--      <mat-label>Reason</mat-label>-->
<!--      <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="reason" placeholder="Coffee with co-worker..." >-->
<!--    </mat-form-field>-->
  </form>
  <br/>
  <br/>
<!--  <div [innerHTML]="dayLine"></div>-->
  <br/>
  <br/>
  <ngx-charts-bar-vertical
    [view]="view"
    [scheme]="colorScheme"
    [results]="workingChart"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="'COST.PERIODS_OF_WORK' | translate"
    [yAxisLabel]="'COST.TIME_IN_MINUTES' | translate"
    (select)="onSelect($event)">
  </ngx-charts-bar-vertical>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>

</div>
