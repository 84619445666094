<div style="width: 96%; margin: auto;" align="center">
  <app-solutions-header></app-solutions-header>
  <h1 *ngIf="translate.defaultLang === 'ru'">Персональные Проекты</h1>
  <h1 *ngIf="translate.defaultLang === 'en'">Personal Projects</h1>
  <br/>
  <img
    [@nettyos]
    style="width: 300px;"
    src="./assets/images/landing/step2/life-pro-processes.svg"
  />
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'ru'">
    <p class="text">
      Каждый пользователь, который начнет пользоваться Nettyos правильно и фиксировать все мысли идеи, планы и задачи здесь сталкнется с проблемой.
      <br/>
      <br/>
      В какой-то момент появляется слишком много тикетов (идеи, дела, мечты, задачи...) Могут быть тысячи тикетов в списке "Сегодня",
      если не использовать возможности Nettyos. В списке "Сегодня" должны быть только то, что имеет шанс на завершение именно сегодня.
      В тот день, который пользователь использует Nettyos.
      <br/>
      <br/>
      Остальное необходимо отправлять в категории: "потом", "завтра", "идея", "завершено" и т.д. Но и после этого появляется новая проблема. В каждой из этих
      категорий количество тикетов начинает превышать тысячи. Тогда на помощь приходят персональные проекты, которые связяны со статусами тикетов.
      <br/>
      <br/>
      Необходимо создать проект и все тикеты прикрепить к этому проекту. А для пользователей, которые с 2009 года с Nettyos, это объяснять не нужно. Личный проект создается
      и доступ туда есть только у создателя. На тарифе <a [routerLink]="'/pricing'">"Free Plan"</a> дается три проекта бесплатно. Это очень много, даже для коммерческого использования.
      <br/>
      <br/>
      На тарифе <a [routerLink]="'/pricing'">"Premium"</a> количество проектов не ограничено. Это требуется, чаще всего, когда у самозанятых или маленького бизнеса есть
      серьезный доход, много клиентов... Подписка около $10 долларов в месяц - это не много для важнейшего инструмента в бизнесе. А начать использовать Nettyos в коммерческих
      целях можно бесплатно. Nettyos на стороне Твоего успеха.
      <br/>
      <br/>
      <strong>Краткий список того, что Nettyos умеет для роста самозанятых, малого бизнеса (с использованием решения "Персовнальные Проекты"):</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>создать до 3 персональных проектов включительно</li>
        <li>создать более 3 персональных проектов <a [routerLink]="'/pricing'"> "Premium+" </a></li>
        <li>содавать/использовать бизнес-процессы в персональных проектах</li>
        <li>прикреплять/откреплять тикеты к/от проекту(а)</li>
        <li>синхронизировать свои тикеты с категориями проекта (автоматически)</li>
        <li>использовать доску проекта для удобной визуализации</li>
        <li>настраивать проект</li>
        <li>изменять имена проектов, архивировать, удалять (безопасно)</li>
        <li>и другие фичи...</li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class="text">
      <strong>Жизненные/бизнес ситуации, в которых это решение может пригодиться:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>Пишете крутой проект как Nettyos (Nettyos настолько крут именно из-за того, что создавался с использованием самого Nettyos)</li>
        <li>Пишете серию книг на нескольких языках в качестве "Свободного Автора"</li>
        <li>Занимаетесь фотографией профессионально. Добавить бизнес-процессы для фотосессии. "Подбор образов" -> "Найти Студию" -> "Аренда Pro Оборудования" -> "Дорога" -> "Съемка" -> "Отбор Фотографий" -> "Цветокоррекция" -> "Ретушь" -> "Финальный просмотр". Именно за профессиональные этапы, в каждом из которых рост безграничен, Клиент готов платить хорошие деньги. А не просто за час щелканий на фотокамере.</li>
        <li>Для SMM менеджеров. Вести каждый бренд индивидуально и качественно помогут персональные проекты.</li>
        <li>Для стартапов, которые могут поменять завтрашнюю IT реальность.</li>
        <li>Для ремонта, который хочется сделать самостоятельно. Сэкономить, но не на качестве. Вот бизнес-процесс простой наклейки гипсокартона на ровные стены из пазагребня, которые делали Джамшут и Равшан. "Очистить щели от пыли" -> "Загрунтовать щели" -> "Подождать не менее 5 часов" -> "Зашпаклевать Кнауф Фуген" -> "Подождать до высыхания" -> "Зачистить поверхность стены шпателем от всех цементных наростов" -> "Пропылесосить" -> "Загрунтовать" -> "Подождать пока высохнет" -> "Вырезать гипсокартон со всеми отверстиями (розетки, углы , выходы)" -> "Развести клей и сразу ненести" -> "Приклеить" -> "Простучать специальным молотком" -> "Зафиксировать по нивелиру". Это каждый раз когда надо наклеить гиспокартон в реальной ситуации. И это еще до обработки швов сеткой, углов и финальной шпаклевки. Сколько денег надо заплатить чтобы ребята запарились так как надо!? Можно использовать команды Nettyos или контролировать full time.</li>
        <li>Вы работаете над крутым проектом, но проджект менеджер без IT образования, бывший доставщик суши. Это очень популярная ситуация для компаний, которые продают стажеров по цене синьоров девелоперов, а иногда одного стажера сразу нескольким заказчиками в качестве синьора. В этой ситуации "доставщик суши" будет беситься от любой технической информации. Кроме политической войны лучше использовать персональный проект где будут все технические детали, а в командном только названия тикетов.</li>
        <li>Любое хобби или даже категория может быть очень удобно инкапсулирована в персональный проект.</li>
        <li>Если проект имеет шанс вырасти и потребуется команда, удобнее сразу сделать командный проект, который потом расширить. Решение: <a [routerLink]="'/all-teams'">Команды (любые)</a></li>
        <li>И очень много других кейсов...</li>
      </ul>
    </div>
  </div>
  <div *ngIf = "translate.defaultLang === 'en'">
    <p class = "text">
      Every user who starts using Nettyos correctly and fixing all thoughts, ideas, plans and tasks will face a problem here.
      <br/>
      <br/>
      At some point, too many tickets appear (ideas, plans, dreams, tasks...) There may be thousands of tickets in the "Today" list,
      if you do not use the capabilities of Nettyos. The "Today" list should contain only that which has a chance to be completed today.
      The day the user uses Nettyos.
      <br/>
      <br/>
      The rest should be submitted in categories: "later", "tomorrow", "idea", "completed", etc. But even after that, a new problem appears. In each of these
      categories, the number of tickets starts to exceed a thousand. Then personal projects come to the rescue, which are associated with the statuses of tickets.
      <br/>
      <br/>
      You need to create a project and attach all related tickets to this project. And for users who have been with Nettyos since 2009, there is no need to explain this. Personal project is being created
      and only the creator has access to it. At the rate <a [routerLink]="'/pricing'"> "Free Plan" </a> three projects are given free of charge. That's a lot, even for commercial use.
      <br/>
      <br/>
      There is no limit to the number of projects at <a [routerLink]="'/pricing'"> "Premium" </a> tariff. This is most often required when self-employed or small businesses have
      serious income, many clients ... A subscription of about $10 dollars a month is not a lot for the most important tool in business. And start using Nettyos in commercial
      purposes are free. Nettyos is on the side of Your success.
      <br/>
      <br/>
      <strong> Here's a short list of what Nettyos can do for self-employed, small business growth (using the Personal Projects solution): </strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> create up to 3 personal projects inclusive </li>
        <li> create more than 3 personal projects <a [routerLink]="'/pricing'"> "Premium +" </a> </li>
        <li> create/use business processes in personal projects </li>
        <li> attach/detach tickets to/from project </li>
        <li> sync your projects with project categories (automatically) </li>
        <li> use the project board for easy visualization </li>
        <li> customize the project </li>
        <li> change project names, archive, delete (safe) </li>
        <li> and other features ... </li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class = "text">
      <strong> Life/business situations in which this solution can be useful: </strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> You write a cool project as Nettyos (Nettyos is so cool precisely because it was created using Nettyos itself) </li>
        <li> Writing a series of books in several languages ​​as a "Free Author" </li>
        <li> Do photography professionally. Add business processes for a photo shoot. "Image Selection" -> "Find a Studio" -> "Rent Pro Equipment" -> "Road" -> "Shooting" -> "Select Photos" -> "Color Correction" -> "Retouch" -> "Final View". It is for the professional stages, in each of which the growth is unlimited, that the Client is ready to pay good money. Not just an hour of camera clicks. </li>
        <li> For SMM managers. Personal projects will help to lead each brand individually and with high quality. </li>
        <li> For startups that can change tomorrow's IT reality. </li>
        <li> For repairs that you want to do yourself. Save money, but not quality. Here is the business process of simply gluing drywall onto smooth groove walls that Jamshut and Ravshan did. "Clean the cracks from dust" -> "Prime the cracks" -> "Wait at least 5 hours" -> "Putty Knauf Fugen" -> "Wait until dry" -> "Clean the wall surface with a spatula from all cement build-up" -> " Vacuum "->" Prime "->" Wait until it is dry "->" Cut drywall with all holes (sockets, corners, exits) "->" Dilute the glue and apply immediately "->" Glue "->" Tap with a special hammer "->" Fix to level ". This is every time you need to glue the gypsum board in a real situation. And this is even before processing the seams with a mesh, corners and final putty. How much money do you have to pay to make the guys get tired as they should!? You can use Nettyos teams or control the full time.</li>
        <li> You are working on a cool project, but a project manager without IT education, a former sushi delivery man. This is a very popular situation for companies that sell juniors at the price of senior developers, and sometimes one junior to several customers at once as a senior (a few ppl). In this situation, the "sushi delivery man" will freak out from any technical information. In addition to a political war, it is better to use a personal project where to put all technical details, but in the team project only ticket name and statuses. </li>
        <li> Any hobby or even a category can be very conveniently encapsulated into a personal project. </li>
        <li> If the project has a chance to grow and a team is needed, it is more convenient to immediately make a team project, which then expand. Solution: <a [routerLink]="'/all-teams'"> Teams (any) </a> </li>
        <li> And a lot of other cases ... </li>
      </ul>
    </div>
  </div>


  <br/>
  <br/>
  <br/>
  <br/>
</div>
