import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotFound404Component} from "./components/system/not-found404/not-found404.component";
import {LoginComponent} from "./components/user/login/login.component";
import {RegisterComponent} from "./components/user/register/register.component";
import {LandingComponent} from "./components/layout/landing/landing.component";
import {TodayComponent} from "./components/user/today/today.component";
import {ProfileComponent} from "./components/today/profile/profile.component";
import {DevelopmentComponent} from "./components/alerts/development/development.component";
import {ResetComponent} from "./components/user/reset/reset.component";
import {PaymentComponent} from "./components/today/payment/payment.component";
import {PrivacyPolicyComponent} from "./components/law/privacy-policy/privacy-policy.component";
import {TermsOfUseComponent} from "./components/law/terms-of-use/terms-of-use.component";
import {PeopleComponent} from "./components/today/people/people.component";
import {GuideComponent} from "./components/help/guide/guide.component";
import {PlacesComponent} from "./components/today/places/places.component";
import {ProjectsComponent} from "./components/today/projects/projects.component";
import {TeamsComponent} from "./components/today/teams/teams.component";
import {TeamProjectComponent} from "./components/today/team-projects/team-project.component";
import {AboutComponent} from "./components/help/about/about.component";
import {PayComponent} from "./components/today/pay/pay.component";
import {ControlComponent} from "./components/administration/control/control.component";
import {CostComponent} from "./components/today/cost/cost.component";
import {CoachComponent} from "./components/today/coach/coach.component";
import {UsersComponent} from "./components/layout/pages/users/users.component";
import {SkillsComponent} from "./components/today/skills/skills.component";
import {SelfOrganizerComponent} from "./components/layout/pages/self-organizer/self-organizer.component";
import {FamilyHubComponent} from "./components/layout/pages/family-hub/family-hub.component";
import {DreamsMakerComponent} from "./components/layout/pages/dreams-maker/dreams-maker.component";
import {PersonalProjectsComponent} from "./components/layout/pages/personal-projects/personal-projects.component";
import {AllTeamsComponent} from "./components/layout/pages/all-teams/all-teams.component";
import {TourComponent} from "./components/layout/pages/tour/tour.component";
import {ContactsComponent} from "./components/layout/pages/contacts/contacts.component";
import {AlertsComponent} from "./components/today/alerts/alerts.component";


const routes: Routes = [
  { path: '', component: LandingComponent},
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'reset', component: ResetComponent},
  { path: 'today', component: TodayComponent},
  { path: 'profile', component: ProfileComponent},
  { path: 'today', component: TodayComponent},
  { path: 'payment', component: PaymentComponent},
  { path: 'payment-plan', component: PayComponent},
  { path: 'privacy', component: PrivacyPolicyComponent},
  { path: 'tos', component: TermsOfUseComponent},
  { path: 'development', component: DevelopmentComponent},
  { path: 'people', component: PeopleComponent},
  { path: 'guide', component: GuideComponent},
  { path: 'about', component: AboutComponent},
  { path: 'places', component: PlacesComponent},
  { path: 'projects', component: ProjectsComponent},
  { path: 'teams', component: TeamsComponent},
  { path: 'team-project', component: TeamProjectComponent},
  { path: 'control', component: ControlComponent},
  { path: 'cost', component: CostComponent},
  { path: 'coach', component: CoachComponent},
  { path: 'alerts', component: AlertsComponent},
  { path: 'users', component: UsersComponent},
  { path: 'pricing', component: PayComponent},
  { path: 'skills', component: SkillsComponent},
  { path: 'self-organizer', component: SelfOrganizerComponent},
  { path: 'family-hub', component: FamilyHubComponent},
  { path: 'dreams-maker', component: DreamsMakerComponent},
  { path: 'personal-projects', component: PersonalProjectsComponent},
  { path: 'all-teams', component: AllTeamsComponent},
  { path: 'tour', component: TourComponent},
  { path: 'contacts', component: ContactsComponent},
  { path: 'public-guide', component: GuideComponent},
  { path: '**', component: NotFound404Component}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
