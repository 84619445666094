<table>
  <tr>
    <td class="full-display">
      <button mat-icon-button
              (click)="openLink('https://vk.com/nettyos')"
      >
          <img style="height: 25px;" src="/assets/images/social/vk.png">
      </button>
    </td>
    <td class="full-display">
      <button
        mat-icon-button
        (click)="openLink('https://ok.ru/nettyos')"
      >
        <img style="height: 25px;" src="/assets/images/social/ok.png">
      </button>
    </td>
    <td>
      <div style="height: 20px;">
        <p class="center" style="margin-top: 3px;">
          Using this application you apply <a [routerLink]="'/privacy'" style="color: lightblue">Privacy Policy</a> and
          <a
            [routerLink]="'/tos'" style="color: lightblue">Terms Of Use</a>
        </p>
      </div>
      <div style="height: 20px;">
        <p class="center" style="font-size: 100%;">
          ©NettyOS.COM℠ by <a href="http://ahm-pro.com" target="_blank" style="color: lightblue">AHM</a>
        </p>
      </div>
      <div style="height: 10px;">
      </div>
    </td>
    <td class="full-display">
      <button
        mat-icon-button
        (click)="openLink('https://www.youtube.com/channel/UCRbi5fPwF2IZIh7Ho5nzVDA')"
      >
        <img style="height: 25px;" src="/assets/images/social/yt.png">
      </button>
    </td>
    <td class="full-display">
      <button
        mat-icon-button
        (click)="openLink('https://www.facebook.com/groups/nettyos')"
      >
        <img style="height: 25px;" src="/assets/images/social/fb.png">
      </button>
    </td>
  </tr>
</table>
