<br/>
<br/>
<br/>
<div class="wrapper" align="center">
  <br/>
  <br/>

  <form [formGroup]="loginGroup" (ngSubmit)="loginOnSubmit(loginGroup.value)">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'SIGN.ENTER_EMAIL' | translate}}</mat-label>
      <input matInput type="email" placeholder="u.name@example.com" formControlName="emailCtrl" required>
<!--      <mat-error>valid email</mat-error>-->
    </mat-form-field>
    <br/>
    <p style="width: 200px;" appearance="fill">
      <mat-icon matSuffix style="float: right; cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </p>
    <br/>
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'SIGN.ENTER_PASSWORD' | translate}}
      </mat-label>
      <input #pwd matInput
             [type]="(pwd.value.length === 0) || !hide ? 'text': 'password'"
             formControlName="pwdCtrl" required>
    </mat-form-field>
    <br/>
    <span [ngStyle]="{color: loginColor}">{{loginMessage}}</span>
    &nbsp;
    <a [routerLink]="'/today'" *ngIf="loginMessage === 'Done.'">
      <button class="btn" mat-stroked-button color="primary" class="lr-btn" alert="1">{{ 'SIGN.GO' | translate}}</button>
    </a>
    <br/>
    <br/>
    <div *ngIf="loginMessage !== 'Done.'">
      <div *ngIf="loadingFlag">
        <mat-progress-bar style="width: 200px;" mode="indeterminate"></mat-progress-bar>
        <br/>
      </div>
      <button class="btn" mat-raised-button color="primary" type="submit">
        {{ 'SIGN.ENTER' | translate}}
      </button>

      <a [routerLink]="'/register'" *ngIf="loginMessage !== 'Password wrong.' else reset">
        <button mat-stroked-button class="btn" color="primary" class="lr-btn" alert="1">{{ 'SIGN.REGISTER_' | translate}}</button>
      </a>
      <ng-template #reset>
        <a [routerLink]="'/reset'" >
          <button mat-stroked-button class="btn" color="primary">{{ 'SIGN.RESET_' | translate}}</button>
        </a>
      </ng-template>
    </div>
  </form>

</div>
