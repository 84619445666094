import {Component, OnInit, ViewChild} from '@angular/core';
import {MatAccordion} from "@angular/material/expansion";
import {AuthService} from "../../../services/auth.service";
import {GatewayService, Profile} from "../../../services/gateway.service";
import {HttpClient} from "@angular/common/http";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {CookieService} from "ngx-cookie-service";
import {PersonService} from "../../../services/person.service";
import {OverlayContainer} from "@angular/cdk/overlay";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  // @ViewChild(MatAccordion) accordion: MatAccordion;
  ava = '';

  constructor(private http: HttpClient,
              public auth: AuthService,
              public gateway: GatewayService,
              private cookieService: CookieService,
              public personService: PersonService,
              private overlayContainer: OverlayContainer) { }

  ngOnInit(): void {
    // let url = '/api/usr/v1/user/ava/';
    // if (this.gateway.localhost) {
    //   url = this.gateway.USER_SERVICE + url;
    // }
    // this.http.get<ExtendedStatus>(url)
    //   .subscribe((data) => {
    //     //console.log('ava', data);
    //     this.ava = data.result;
    //   });
    this.gateway.userServiceGetUserProfile().subscribe(
      (profile: Profile) => {
        this.personService.profile = profile;
        // console.log('ava', profile);
        if (null != profile.conf) {
          if (-1 !== profile.conf.indexOf('w')) {
            this.auth.theme = '';
          }
          if (-1 !== profile.conf.indexOf('b')) {
            this.auth.theme = 'dark-theme';
              this.overlayContainer.getContainerElement().classList.add('dark-theme');
          }
        }
      });
  }

  exit() {
    this.auth.token = '';
    this.cookieService.delete('auth');
    this.cookieService.deleteAll();
    this.auth.homeButtonLink = '/';
  }

}
