
<div class="comment-section">
  <br/>
  <form (ngSubmit)="addNewComment()">
    <mat-form-field style="width: 80%;">
      <mat-label>{{ 'COMMENTS.NEW_COMMENT' | translate}}</mat-label>
      <textarea
        style="height: 33px;"
        matInput
        [formControl]="addNewCommentFormControl" [errorStateMatcher]="matcher"
      ></textarea>
    </mat-form-field>
    &nbsp;
    <button
      type="submit"
      mat-icon-button
      [disabled]="!addNewCommentFormControl.valid"
      color="primary"><mat-icon>play_arrow</mat-icon>
    </button>
  </form>
  <div *ngIf="comments.length > 0" style="min-height: 70px; max-height: 200px; overflow: auto;">
    <table>
      <tr *ngFor="let cmm of comments" style="width: 100%;">
        <td style="width: 38px;">
          <img style="width: 30px; height: 30px; border-radius: 50%; top: 0px; margin-right: 10px;"
               [src]="cmm.userAva"/>
        </td>
        <td style="width: 80%;">
          <a style="color: #03a9f4;" [routerLink]="'/people'" mat-dialog-close>{{cmm.userName}} {{cmm.userSerName}}</a>

          <p style="word-break: break-all; width: fit-content; margin: 0px;">{{cmm.commentText}}</p>

          <div style="color: lightgrey;">{{cmm.commentTs | date: "dd-MM-yyyy HH:mm"}}</div>
          <br/>
        </td>
        <td>
          <button color="warn" [disabled]="personService.profile.userId !== cmm.mainUserId" (click)="deleteComment(cmm)" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
