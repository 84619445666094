import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {AuthService} from "../../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {bounce, bounceInDown, bounceInRight, fadeIn, flash, flipInX, rotateIn, swing, tada} from "ng-animate";
import {VersionService} from "../../../services/version.service";

export interface LandingChips {
  chipText: string;
  chipPressed: boolean;
  color: string;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    trigger('welcome', [
      transition('void => *', [
        style({ opacity: 0}),
        animate(1500)
      ])
    ]),
    trigger('step2', [
      transition('stay => act', useAnimation(fadeIn, {
        params: { timing: 10}
      }))
    ]),
    trigger('step2Down', [
      transition('stay => act', useAnimation(fadeIn, {
        params: { timing: 10}
      }))
    ]),
    trigger('step2Text', [
      transition('stay => act', [
        style({ opacity: 1}),
        animate(5000)
      ])
    ]),
    trigger('yeah', [
      transition('* => *', useAnimation(bounce))
    ]),
    trigger('avaGreeting', [
      transition('* => *', useAnimation(fadeIn))
    ]),
    trigger('textGreeting', [
      transition('* => *', useAnimation(fadeIn), {
        params: {
          timing: 1
        }
      })
    ]),
    trigger('ios', [
      transition('* => *', useAnimation(swing))
    ]),
    trigger('android', [
      transition('* => *', useAnimation(swing))
    ]),
    trigger('coach', [
      transition('* => *', useAnimation(tada))
    ]),
    trigger('hellocoach', [
      transition('* => *', useAnimation(tada))
    ]),
    trigger('business', [
      transition('* => *', [
        style({ opacity: 0}),
        animate(1000)
      ])
    ]),
    trigger('solutions', [
      transition('* => *', useAnimation(tada))
    ])
  ]
})
// trigger('bounce', [transition('* => *', useAnimation(bounce))])
export class LandingComponent implements OnInit {
  welcome = false;
  allInOne = 'stay';
  topPsy = 'stay';
  lifeProcessesPro = 'stay';
  allFamilyPlanning = 'stay';
  teamPlanner = 'stay';
  topDevices = 'stay';
  free = 'stay';
  showYeah = true;
  allInOneLastTs = 0;
  topPsyLastTs = 0;
  lifeProcessesProLastTs = 0;
  allFamilyPlanningLastTs = 0;
  teamPlannerLastTs = 0;
  topDevicesLastTs = 0;
  freeLastTs = 0;
  solutionsGalleryAnimations = ['', '', '', '', ''];

  chipsList1: LandingChips [] = [
    {chipText: "Делегировать процесс планирования", chipPressed: false, color: ""},
    {chipText: "Не изучать сложную систему", chipPressed: false, color: ""},
    {chipText: "Нет низкой эффективности", chipPressed: false, color: ""},
    {chipText: "Без пробного периода", chipPressed: false, color: ""},
    {chipText: "Полезные фичи бесплатно", chipPressed: false, color: ""},
    {chipText: "Есть бизнес-процессы", chipPressed: false, color: ""},
    {chipText: "Прост как лист бумаги", chipPressed: false, color: ""},
    {chipText: "Экспериментальные модели команд", chipPressed: false, color: ""},
    {chipText: "Канбан, скрум, что хочешь", chipPressed: false, color: ""},
    {chipText: "Есть личные проекты", chipPressed: false, color: ""},
    {chipText: "Сразу монетизировать себя", chipPressed: false, color: ""},
    {chipText: "Нет темного леса колонок", chipPressed: false, color: ""},
    {chipText: "Поднять средний чек", chipPressed: false, color: ""},
    {chipText: "Интегрированна Pro психология", chipPressed: false, color: ""},
    {chipText: "Сложные фичи - легче простого", chipPressed: false, color: ""}
  ];

  chipsList1Eng: LandingChips [] = [
    {chipText: "Delegate the planning process", chipPressed: false, color: ""},
    {chipText: "Do not study a complex system", chipPressed: false, color: ""},
    {chipText: "No low efficiency", chipPressed: false, color: ""},
    {chipText: "No trial period", chipPressed: false, color: ""},
    {chipText: "Useful features for free", chipPressed: false, color: ""},
    {chipText: "There are business processes", chipPressed: false, color: ""},
    {chipText: "Simple as a sheet of paper", chipPressed: false, color: ""},
    {chipText: "Experimental Team Models", chipPressed: false, color: ""},
    {chipText: "Kanban, scrum whatever you want", chipPressed: false, color: ""},
    {chipText: "There are personal projects", chipPressed: false, color: ""},
    {chipText: "Immediately monetize yourself", chipPressed: false, color: ""},
    {chipText: "No dark forest of columns", chipPressed: false, color: ""},
    {chipText: "Raise the average bill", chipPressed: false, color: ""},
    {chipText: "Integrated Pro Psychology", chipPressed: false, color: ""},
    {chipText: "Complex features are easier than ever", chipPressed: false, color: ""}
  ];
  private monitor = 0;

  chipsList2: LandingChips [] = [
    {chipText: "Не бойся переоценить планирование", chipPressed: false, color: ""},
    {chipText: "Дневной список личных дел", chipPressed: false, color: ""},
    {chipText: "Распорядок дня", chipPressed: false, color: ""},
    {chipText: "Добиться лучших результатов", chipPressed: false, color: ""},
    {chipText: "Топ1 в офисе", chipPressed: false, color: ""},
    {chipText: "Проекты с друзьями", chipPressed: false, color: ""},
    {chipText: "Задачи коллегам", chipPressed: false, color: ""},
    {chipText: "Scrum, agile, kanban...", chipPressed: false, color: ""},
    {chipText: "Семейный scrum", chipPressed: false, color: ""},
    {chipText: "Honey do list", chipPressed: false, color: ""},
    {chipText: "Личный scrum бывает", chipPressed: false, color: ""},
    {chipText: "Планировать митинги", chipPressed: false, color: ""},
    {chipText: "Планирование мечты - попробуй", chipPressed: false, color: ""},
    {chipText: "Записал в nettyos значит будет", chipPressed: false, color: ""}
  ];

  chipsList2Eng: LandingChips [] = [
    {chipText: "Don't be afraid to overestimate the planning", chipPressed: false, color: ""},
    {chipText: "Personal to-do list", chipPressed: false, color: ""},
    {chipText: "Schedule", chipPressed: false, color: ""},
    {chipText: "Get the best results", chipPressed: false, color: ""},
    {chipText: "Top1 in the office", chipPressed: false, color: ""},
    {chipText: "Projects with friends", chipPressed: false, color: ""},
    {chipText: "Tasks for colleagues", chipPressed: false, color: ""},
    {chipText: "Scrum, agile, kanban ...", chipPressed: false, color: ""},
    {chipText: "Family scrum", chipPressed: false, color: ""},
    {chipText: "Honey do list", chipPressed: false, color: ""},
    {chipText: "Personal scrum happens", chipPressed: false, color: ""},
    {chipText: "Plan meetings", chipPressed: false, color: ""},
    {chipText: "Dream Planning - Try it", chipPressed: false, color: ""},
    {chipText: "Written in nettyos means it will be", chipPressed: false, color: ""}
  ];
  yeahState = true;
  greetingState = '';
  iosState = '';
  androidState = '';
  private wasVisible = false;
  private iosWasVisible = false;
  private androidWasVisible = false;
  coachHelloRus = false;
  private coachWasVisible = false;
  coachState = '';
  private helloCoachWasVisible = false;
  helloCoachState = '';
  readFullAuthorRus = false;
  businessProcess = 'it';
  businessStep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  generatedChipsList1: LandingChips[];
  generatedChipsList1Eng: LandingChips[];
  generatedChipsList2: LandingChips[];
  generatedChipsList2Eng: LandingChips[];

  constructor(public auth: AuthService,
              public translate: TranslateService,
              public versionService: VersionService) {
    this.generatedChipsList1 = this.getRandomNumberOfItems(this.chipsList1, 5);
    this.generatedChipsList1Eng = this.getRandomNumberOfItems(this.chipsList1Eng, 5);
    this.generatedChipsList2 = this.getRandomNumberOfItems(this.chipsList2, 5);
    this.generatedChipsList2Eng = this.getRandomNumberOfItems(this.chipsList2Eng, 5);

  }

  ngOnInit(): void {

    // Solutions gallery period

    let autoSaveInterval: number = setInterval(() => {
      this.solutionsGalleryAnimations[this.getRandomInt(this.solutionsGalleryAnimations.length)] = this.getRandomInt(1000000) + '';
    }, 1000);

    setTimeout(() => {
      this.welcome = true;
    }, 0);

    // init colors
    for (let chip of this.chipsList1) {
      chip.color = this.randomColor();
    }
    for (let chip of this.chipsList1Eng) {
      chip.color = this.randomColor();
    }
    for (let chip of this.chipsList2) {
      chip.color = this.randomColor();
    }
    for (let chip of this.chipsList2Eng) {
      chip.color = this.randomColor();
    }
  }

  onAppear() {
    // alert(1)
    //console.log('Nice...');
  }

  animateStep2(option: string) {
    switch (option) {
      case 'allInOne':
        if ( (new Date().getTime() - this.allInOneLastTs) < 3000) break;
        this.allInOneLastTs = new Date().getTime();
        this.allInOne = 'stay';
        setTimeout(() => {
          this.allInOne = 'act';
        }, 0);
        break;
      case 'topPsy':
        if ( (new Date().getTime() - this.topPsyLastTs) < 3000) break;
        this.topPsyLastTs = new Date().getTime();
        this.topPsy = 'stay';
        setTimeout(() => {
          this.topPsy = 'act';
        }, 0);
        break;
      case 'lifeProcessesPro':
        if ( (new Date().getTime() - this.lifeProcessesProLastTs) < 3000) break;
        this.lifeProcessesProLastTs = new Date().getTime();
        this.lifeProcessesPro = 'stay';
        setTimeout(() => {
          this.lifeProcessesPro = 'act';
        }, 0);
        break;
      case 'allFamilyPlanning':
        if ( (new Date().getTime() - this.allFamilyPlanningLastTs) < 3000) break;
        this.allFamilyPlanningLastTs = new Date().getTime();
        this.allFamilyPlanning = 'stay';
        setTimeout(() => {
          this.allFamilyPlanning = 'act';
        }, 0);
        break;
      case 'teamPlanner':
        if ( (new Date().getTime() - this.teamPlannerLastTs) < 3000) break;
        this.teamPlannerLastTs = new Date().getTime();
        this.teamPlanner = 'stay';
        setTimeout(() => {
          this.teamPlanner = 'act';
        }, 0);
        break;
      case 'topDevices':
        if ( (new Date().getTime() - this.topDevicesLastTs) < 3000) break;
        this.topDevicesLastTs = new Date().getTime();
        this.topDevices = 'stay';
        setTimeout(() => {
          this.topDevices = 'act';
        }, 0);
        break;
      case 'free':
        if ( (new Date().getTime() - this.freeLastTs) < 3000) break;
        this.freeLastTs = new Date().getTime();
        this.free = 'stay';
        setTimeout(() => {
          this.free = 'act';
        }, 0);
        break;
    }
  }

  showYeahFn(chip: LandingChips) {
    if (!chip.chipPressed)
      this.yeahState = !this.yeahState;
    // this.showYeah = false;
    // setTimeout( () => {
    //   //this.monitor = new Date().getTime() + 1499;
    //   this.showYeah = true;
    // }, 0);
    // setTimeout(() => {
    //   if (new Date().getTime() > this.monitor)
    //     this.showYeah = false;
    // }, 1500);
  }

  randomColor() {
    const colors = [
      "#FFA500",
      "#00FF00",
      "#FFD700",
      "#FFDAB9",
      "#FF00FF",
      "#EE82EE",
      "#58a09e",
      "#00FF7F",
      "#ffe9bc",
      "#b7ff76",
      "#ffb0d4",
      "#9b91ff",
      "#E6E6FA"
    ];
    const i = Math.floor((Math.random() * colors.length) + 0);
    return colors[i];
  }

  avaGreetingInViewport(event: any) {
    if (event.visible && !this.wasVisible) {
      this.greetingState = this.randomString();
      this.wasVisible = true;
    } else if (!event.visible) {
      this.wasVisible = false;
    }
  }

  iosInViewport(event: any) {
    if (event.visible && !this.iosWasVisible) {
      this.iosState = this.randomString();
      this.iosWasVisible = true;
    } else if (!event.visible) {
      this.iosWasVisible = false;
    }
  }

  androidInViewport(event: any) {
    if (event.visible && !this.androidWasVisible) {
      this.androidState = this.randomString();
      this.androidWasVisible = true;
    } else if (!event.visible) {
      this.androidWasVisible = false;
    }
  }

  coachInViewport(event: any) {
    if (event.visible && !this.coachWasVisible) {
      this.coachState = this.randomString();
      this.coachWasVisible = true;
    } else if (!event.visible) {
      this.coachWasVisible = false;
    }
  }

  helloCoachInViewport(event: any) {
    if (event.visible && !this.helloCoachWasVisible) {
      this.helloCoachState = this.randomString();
      this.helloCoachWasVisible = true;
    } else if (!event.visible) {
      this.helloCoachWasVisible = false;
    }
  }

  randomString(length = 5) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  changeBusinessStepIfPreviousDone(number: number) {
    let counter = 0;
    for (let i=0; i <number; i++) {
      counter += this.businessStep[i];
    }
    if (number === counter) {
      this.businessStep[number] = 1;
    }
  }

  public getRandomNumberOfItems(fullItemsList: LandingChips[], number: number) {

    //region MAKING LIST OF RANDOM INDEXES
    let showingItems: number[] = [];
    for (let i=0; i<number; i++) {
      // contract: number of item shouldn't become more than size of input array
      while (showingItems.length === i) {
        let randomIndex = this.getRandomInt(fullItemsList.length);
        if (!showingItems.find(p => p === randomIndex)) {
          showingItems.push(randomIndex);
        }
      }
    }
    //endregion

    //region: GENERATING NEW ARRAY
    let resultArray: LandingChips[] = [];
    for (let item of showingItems) {
      resultArray.push(fullItemsList[item]);
    }
    //endregion

    return resultArray;
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

}
