import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../services/auth.service";
import {GatewayService} from "../../../services/gateway.service";
import {CookieService} from "ngx-cookie-service";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // email = new FormControl('', [Validators.required, Validators.email]);
  // password = new FormControl('', [Validators.required]);
  loginGroup: FormGroup;

  authToken = '';
  loginMessage = '';
  loginColor = 'black';
  loadingFlag = false;
  hide = true;

  constructor(private _formBuilder: FormBuilder,
              private http: HttpClient,
              private auth: AuthService,
              private api: GatewayService,
              private cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.loginGroup = this._formBuilder.group({
      emailCtrl: ['', Validators.required/*, Validators.email*/],
      pwdCtrl: ['', Validators.required]
    });
  }

  loginOnSubmit(value: any) {
    // todo: GateWayService
    try {
      this.loadingFlag = true;
      let url = '/api/lrs/v1/login/email/';
      if (this.api.localhost) {
        url = this.api.LOGIN_REGISTER_SERVICE + url;
      }
      this.auth.email = value.emailCtrl;
      this.http.post<ExtendedStatus>(url, value)
        .subscribe((data) => {
          if (data.result === 'not') {
            this.loginMessage = data.message;
            this.loginColor = 'orange';
          } else if (data.result === 'okay') {
            this.authToken = data.message;
            this.cookieService.deleteAll();
            this.auth.token = this.authToken;
            // set cookies
            this.cookieService.set('auth', this.authToken, 365);
            // say user - all good
            this.loginMessage = 'Done.';
            this.loginColor = 'green';
            this.auth.homeButtonLink = '/today';
          }
          this.loadingFlag = false;
        }, (error) => this.handleError(error));
    } catch (e) {
      this.loadingFlag = false;
    }
  }

  handleError(error: any) {
    this.loadingFlag = false;
    this.loginMessage = 'User not found. Register.';
    this.loginColor = 'red';
    //console.log('error', error);
  }
}
