<br/>
<br/>
<table>
  <tr>
    <td>
      <img
        [@nettyos]
        class="logo"
        src="./assets/images/logos/logo.jpg"
      />
    </td>
    <td style="padding-left: 20px;">
      <div *ngIf="translate.defaultLang === 'ru'">
        <h1>
          <span style="font-family: 'IBM Plex Serif';" class="netty-name">Nettyos <i
            style="font-size: 1rem">{{versionService.version}}</i></span> - это полная экосистема планирования
        </h1>
        <div>Перейти от списка ежедневных дел (прост как лист бумаги)<br/> к спринту команды, которая использует
          SCRUM,<br/> легко, - в один клик!
        </div>
        <br/>
        <br/>
      </div>
      <div *ngIf="translate.defaultLang === 'en'">
        <h1>
          <span style="font-family: 'IBM Plex Serif';" class="netty-name">Nettyos <i
            style="font-size: 1rem">{{versionService.version}}</i></span> - it's a complete planning ecosystem
        </h1>
        <div>
          Jump from to-do list (as simple as a piece of paper)<br/>
          to the sprint of a team using SCRUM,<br/>
          easy - in one click!
        </div>
        <br/>
        <br/>
      </div>
    </td>
  </tr>
</table>
<hr/>
