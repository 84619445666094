<br/>
<br/>
<br/>
<div class="wrapper">
  <a [routerLink]="'/login'">
    <button mat-stroked-button color="primary" class="lr-btn" alert="1">{{ 'SIGN.LOGIN_' | translate}}</button>
  </a>
  <br/>
  <br/>
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="dataFormGroup">
      <form [formGroup]="dataFormGroup" (ngSubmit)="registerOnSubmitStep1(dataFormGroup.value)">
        <ng-template matStepLabel>{{ 'SIGN.FILL_DATA' | translate}}</ng-template>
        <mat-form-field>
          <mat-label>{{ 'SIGN.EMAIL' | translate}}</mat-label>
          <input matInput type="email" placeholder="Email" formControlName="emailCtrl" required>
        </mat-form-field>
        <br/>
        <mat-form-field>
          <mat-label>{{ 'SIGN.FIRST_NAME' | translate}}</mat-label>
          <input matInput placeholder="First name" formControlName="nameCtrl" required>
        </mat-form-field>
        <br/>
        <mat-form-field>
          <mat-label>{{ 'SIGN.LAST_NAME' | translate}}</mat-label>
          <input matInput placeholder="Last name" formControlName="sNameCtrl" required>
        </mat-form-field>
        <br/>
        <mat-form-field>
          <mat-label>{{ 'SIGN.PASSWORD' | translate}}</mat-label>
          <input matInput type="password" formControlName="pwdCtrl" placeholder=""
                 required>
        </mat-form-field>
        <br/>
        <mat-form-field>
          <mat-label>{{ 'SIGN.CONFIRMATION' | translate}}</mat-label>
          <input matInput type="password" formControlName="cPwdCtrl" placeholder=""
                 required>
        </mat-form-field>
        <div>
          <br/>
          <br/>
          <div *ngIf="translate.defaultLang === 'en'">
            <input mat-checkbox type="checkbox" formControlName="agreementCtrl" [(ngModel)]="agreement"> I accept <a [routerLink]="'/privacy'" class="link" target="_blank">Privacy Policy</a> and <a [routerLink]="'/tos'" target="_blank" class="link">Terms Of Use</a>
          </div>
          <div *ngIf="translate.defaultLang === 'ru'">
            <input mat-checkbox type="checkbox" formControlName="agreementCtrl" [(ngModel)]="agreement"> Принимаю <a [routerLink]="'/privacy'" class="link" target="_blank">Политику Конфиденциальности</a> и <a [routerLink]="'/tos'" target="_blank" class="link">Пользовательское Соглашение</a>
          </div>
          <br/>
          <br/>
          <div *ngIf="translate.defaultLang === 'en'">
            <input mat-checkbox type="checkbox" formControlName="systemInformationCtrl" [(ngModel)]="systemInformation"> Agree to receive system messages from nettyos.com
          </div>
          <div *ngIf="translate.defaultLang === 'ru'">
            <input mat-checkbox type="checkbox" formControlName="systemInformationCtrl" [(ngModel)]="systemInformation"> Согласен получать системные сообщения от nettyos.com
          </div>
          <br/>
          <br/>
          <!--          <button mat-stroked-button color="primary" [disabled]="!agreement" (click)="register()">Register</button>-->
          <button
            mat-stroked-button
            [disabled]="!agreement  || !systemInformation"
            matStepperNext
            type="submit"
          >{{ 'SIGN.CONTINUE' | translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="codeFormGroup">
      <form [formGroup]="codeFormGroup" (ngSubmit)="registerOnSubmitStep2(codeFormGroup.value)">
        <ng-template matStepLabel>Code from email</ng-template>
        <mat-form-field>
          <mat-label>Confirmation code</mat-label>
          <input matInput formControlName="codeCtrl" placeholder=""
                 required>
        </mat-form-field>
        <div>
          <p [innerHTML]="suchUserCreated" style="color: orange"></p>
          <button mat-button matStepperPrevious>{{ 'SIGN.BACK' | translate}}</button>
          <button mat-button matStepperNext type="submit"t>{{ 'SIGN.CONFIRM' | translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'SIGN.DONE' | translate}}</ng-template>
      <br/>
      <p [innerHTML]="registerResult" [style.color]="registerColor"></p>
      <div *ngIf="!goAuth">
        <button mat-stroked-button color="primary" matStepperPrevious>{{ 'SIGN.BACK' | translate}}</button>
        &nbsp;
        <button mat-stroked-button color="primary" (click)="stepper.reset()">{{ 'SIGN.RESET' | translate}}</button>
        &nbsp;
        <a [routerLink]="'/login'"><button mat-stroked-button color="primary">{{ 'SIGN.LOGIN' | translate}}</button></a>
      </div>
      <div *ngIf="goAuth">
        <a [routerLink]="'/today'"><button mat-stroked-button color="primary">{{ 'SIGN.GO' | translate}}</button></a>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
