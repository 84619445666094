import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {AuthService} from "./services/auth.service";
import {GatewayService, Profile} from "./services/gateway.service";
import {PersonService} from "./services/person.service";
import {animate, style, transition, trigger, useAnimation} from "@angular/animations";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {afterMethod} from "kaop-ts";
import {AOPDialogTooltipAnswer} from "./components/user/today/today.component";
import {GuideDialogComponent} from "./components/help/guide-dialog/guide-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {OverlayContainer} from "@angular/cdk/overlay";
import {slideInLeft, slideInRight, slideOutLeft, slideOutRight, zoomIn} from "ng-animate";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('gold', [
      transition('void => *', [
        style({ transform: 'translateY(-100%)' }),
        animate(200)
      ])
    ]),
    trigger('silver', [
      transition('void => *', [
        style({ transform: 'translateY(-100%)' }),
        animate(200)
      ])
    ]),
    trigger('sidenav', [
      transition('* => void', useAnimation(slideOutLeft)),
      transition('void => *', useAnimation(slideInLeft))
    ])
  ]
})
export class AppComponent {
  title = 'Netty Online Scheduler';
  opened = true;

  language = 'en';

  constructor(private translate: TranslateService,
              public router: Router,
              private cookieService: CookieService,
              public auth: AuthService,
              private gatewayService: GatewayService,
              public personService: PersonService,
              private _snackBar: MatSnackBar,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              private _overlayContainer: OverlayContainer) {

    // automatically set local language
    // @ts-ignore
    let userLang = navigator.language || navigator.userLanguage;
    let defaultLanguage = 'en';
    //console.log('language', userLang);
    if (userLang === 'ru-RU') {
      defaultLanguage = 'ru';
      this.personService.language = 'ru';
    }
    translate.setDefaultLang(defaultLanguage);
  }

  ngOnInit() {
    const authCookieFlag = this.cookieService.check('auth');
    const authCookieValue = this.cookieService.get('auth');
    if (authCookieFlag) {
      this.auth.token = authCookieValue;
      this.gatewayService.loginRegisterServiceCheckAuth().subscribe(answer => {
        //console.log('check answer', answer);
        // @ts-ignore
        if ('yes' === answer.result) {
          this.router.navigate(['/today']);
          this.auth.homeButtonLink = '/today';
          this.gatewayService.userServiceGetUserProfile().subscribe(
            (profile: Profile) => {
              //console.log('profile', profile);
              // this.profile = profile;
              this.personService.addCoins(profile.silverCoin, profile.goldCoin);
              // this.personService.silver = profile.silverCoin;
              // this.personService.gold = profile.goldCoin;
            });
        }
      });
    }
  }

  openSnackBar() {
    if (!this.personService.giveDialogTooltipAnswer) {
      let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
      let verticalPosition: MatSnackBarVerticalPosition = 'bottom';
      // console.log('_snackBar', this._snackBar);
      this.translate.get('GUIDE.NETTYOS_GUIDE_MESSAGE').subscribe(data => {
        this._snackBar.open(data, 'Okay', {
          duration: 5000,
          // panelClass: ['dark-theme'],
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition,
        });
      });
    }
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  changeTheme() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    // if ('' === this.auth.token || ) {
      this.auth.theme === '' ? this.auth.theme = 'dark-theme': this.auth.theme = '';

      this.auth.theme !== '' ? this.changeThemeGlobally('dark-theme'): this.changeThemeGlobally('mat-light-theme');

    // }
    this.gatewayService.userServiceChangeUserTheme().subscribe(
      (status: any) => {
        //console.log(status);
        if ('okay' === status.result) {
          // this.auth.theme === '' ? this.auth.theme = 'dark-theme': this.auth.theme = ''
        }
      });
  }

  changeThemeGlobally(theme: 'dark-theme' | 'mat-light-theme'): void {
    // remove old theme class and add new theme class
    const overlayContainerClasses = this._overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses)
      .filter((item: string) => item.includes('theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
  }

  // in use by AOP functions
  public openDialog(data): void {

    //console.log(ticket);

    const dialogRef = this.dialog.open(GuideDialogComponent, {
      width: '250px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
    });
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  aboutCoins() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
  }

  // new page scroll on top
  onActivate(event) {
    document.body.scrollTop = 0;
  }

}
