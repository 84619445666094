<div class="center">
  <br/>
  <br/>
  <br/>

  <div *ngIf="translate.defaultLang === 'en'">
    <h1>Notifications</h1>
  </div>

  <div *ngIf="translate.defaultLang === 'ru'">
    <h1>Оповещения</h1>
  </div>

  <table>
    <tr *ngFor="let alert of personService.alerts">
      <td class="holder" >
        <mat-card [ngStyle]="{
          fontWeight: alert.readFlag ? 'normal': 'bold',
          backgroundColor: alert.readFlag ? 'transparent': 'grey',
          cursor: alert.readFlag ? 'default': 'pointer'}"
                  (click)="alertWasRead(alert)"
                  class="notification-card"
                  style="vertical-align:middle"
        >
          <mat-icon *ngIf="!alert.readFlag" style="vertical-align:middle; color: lightgreen">fiber_manual_record</mat-icon>
          {{"ALERTS." + alert.code | translate}}:&nbsp;{{alert.ticketText}}.
        </mat-card>
      </td>
    </tr>
  </table>

  <div *ngIf="personService.alerts.length === 0">
    <div *ngIf="translate.defaultLang === 'en'">
      <strong>No notifications</strong>
    </div>

    <div *ngIf="translate.defaultLang === 'ru'">
      <strong>Нет оповещений</strong>
    </div>
  </div>


</div>
