<br/>
<!--<br/>-->
<!--<hr/>-->
<div align="center" style="overflow: scroll;">

  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="projectBusinessProcessTabIndex">
    <!--    Project Dashboard     -->
    <mat-tab>

      <ng-template mat-tab-label>
        <u  [routerLink]="'../teams'"
               [queryParams]="{id: team.id}"
               style="color:darkorange; text-underline-color: darkorange;"
        >{{team.name}}</u>
        &nbsp;->&nbsp;
        <span>{{teamProject.name}}</span>
        <span *ngIf="selectedEpic != null">
          &nbsp;
          :
          &nbsp;
          {{selectedEpic}}
        </span>
        <span *ngIf="selectedSprint != null">
          &nbsp;
          :
          &nbsp;
          {{selectedSprint}}
        </span>
      </ng-template>

      <div cdkDropListGroup class="drag-n-drop-wrapper">
        <div class="tickets-container">
          <h2>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.BACKLOG' | translate }}
            <button *ngIf="addTicketInput" [@addNewIconAnimation] mat-icon-button (click)="addTicketMode()">
              <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="closeTicketInput" [@closeNewIconAnimation] mat-icon-button (click)="closeTicketMode()">
              <mat-icon>close</mat-icon>
            </button>
          </h2>
          <!--      {{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate }}-->
          <div class="binding-statuses">
            <mat-chip-list>
              <mat-chip class="small-chip" color="accent"
                        selected>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate}}</mat-chip>
            </mat-chip-list>
          </div>
          <form *ngIf="newTicketInput" [@formToAddTicketAnimation]>
            <mat-form-field style="width: 140px;">
              <mat-label>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ADD' | translate }}</mat-label>
              <textarea [(ngModel)]="backlogTextArea"
                        matInput
                        [ngModelOptions]="{standalone: true}"
                        [placeholder]="'TEAMS.TEAM.PROJECTS.DASHBOARD.DISCUSS' | translate"></textarea>
            </mat-form-field>
            <button
              [disabled]="backlogTextArea.length === 0"
              (click)="addToHere(this.backlogTextArea, 'backlog')"
              style="width: 40px; margin-left: 10px;" mat-mini-fab color="primary">+
            </button>
          </form>
          <div
            cdkDropList
            [cdkDropListData]="projectTicketsBacklog"
            class="tickets-list"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event, 'backlog')">
            <div
              align="left"
              (click)="openDialog(item)"
              class="ticket-box grey-important dark-text-important"
              *ngFor="let item of projectTicketsBacklog" cdkDrag>
              <!--          <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>-->
              {{item.ttext}}
              <br/>
              <i [ngStyle]="{color: (item.userHasGotId !== personService.profile.userId) ? 'lightgrey': 'lightgreen'}">{{getFullName(item)}}</i>
            </div>
          </div>
        </div>

        <div class="tickets-container">
          <h2>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.TODO' | translate }}
            <button *ngIf="addTicketInput" [@addNewIconAnimation] mat-icon-button (click)="addTicketMode()">
              <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="closeTicketInput" [@closeNewIconAnimation] mat-icon-button (click)="closeTicketMode()">
              <mat-icon>close</mat-icon>
            </button>
          </h2>
          <!--      {{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate }}-->
          <div class="binding-statuses">
            <mat-chip-list>
              <mat-chip class="small-chip" color="accent"
                        selected>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate}}</mat-chip>
            </mat-chip-list>
          </div>
          <form *ngIf="newTicketInput" [@formToAddTicketAnimation]>
            <mat-form-field style="width: 140px;">
              <mat-label>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ADD' | translate }}</mat-label>
              <textarea [(ngModel)]="todoTextArea"
                        matInput
                        [ngModelOptions]="{standalone: true}"
                        [placeholder]="'TEAMS.TEAM.PROJECTS.DASHBOARD.PLANS' | translate"></textarea>
            </mat-form-field>
            <button [disabled]="todoTextArea.length === 0"
                    (click)="addToHere(this.todoTextArea, 'todo')"
                    style="width: 40px; margin-left: 10px;" mat-mini-fab color="primary">+
            </button>
          </form>
          <div
            cdkDropList
            [cdkDropListData]="projectTicketsTodo"
            class="tickets-list"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event, 'todo')">
            <div
              align="left"
              (click)="openDialog(item)"
              class="ticket-box grey-important dark-text-important"
              *ngFor="let item of projectTicketsTodo" cdkDrag>
              <!--          <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>-->
              {{item.ttext}}
              <br/>
              <i [ngStyle]="{color: (item.userHasGotId !== personService.profile.userId) ? 'lightgrey': 'lightgreen'}">{{getFullName(item)}}</i>
            </div>
          </div>
        </div>

        <div class="tickets-container">
          <h2 style="color: #03a9f4">{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.IN_PROGRESS' | translate }}
            <button *ngIf="addTicketInput" [@addNewIconAnimation] mat-icon-button (click)="addTicketMode()">
              <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="closeTicketInput" [@closeNewIconAnimation] mat-icon-button (click)="closeTicketMode()">
              <mat-icon>close</mat-icon>
            </button>
          </h2>
          <!--      {{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.CAN_SEE' | translate }}-->
          <div class="binding-statuses">
            <mat-chip-list>
              <mat-chip class="small-chip" color="primary"
                        selected>{{ 'TODAY.TICKET.STATUS.TODAY' | translate}}</mat-chip>
            </mat-chip-list>
          </div>
          <form *ngIf="newTicketInput" [@formToAddTicketAnimation]>
            <mat-form-field style="width: 140px;">
              <mat-label>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ADD' | translate }}</mat-label>
              <textarea [(ngModel)]="progressTextArea"
                        matInput
                        [ngModelOptions]="{standalone: true}"
                        [placeholder]="'TEAMS.TEAM.PROJECTS.DASHBOARD.DO_NOW' | translate"></textarea>
            </mat-form-field>
            <button [disabled]="progressTextArea.length === 0"
                    (click)="addToHere(this.progressTextArea, 'progress')"
                    style="width: 40px; margin-left: 10px;" mat-mini-fab color="primary">+
            </button>
          </form>
          <div
            cdkDropList
            [cdkDropListData]="projectTicketsToday"
            class="tickets-list"
            (cdkDropListDropped)="drop($event, 'progress')">
            <div
              align="left"
              (click)="openDialog(item)"
              class="ticket-box grey-important dark-text-important"
              *ngFor="let item of projectTicketsToday"
              cdkDrag>
              <!--          <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>-->
              {{item.ttext}}
              <br/>
              <i [ngStyle]="{color: (item.userHasGotId !== personService.profile.userId) ? 'lightgrey': 'lightgreen'}">{{getFullName(item)}}</i>
            </div>
          </div>
        </div>

        <div class="tickets-container">
          <h2>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.COMPLETED' | translate }}
            <button *ngIf="addTicketInput" [@addNewIconAnimation] mat-icon-button (click)="addTicketMode()">
              <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="closeTicketInput" [@closeNewIconAnimation] mat-icon-button (click)="closeTicketMode()">
              <mat-icon>close</mat-icon>
            </button>
          </h2>
          <!--      {{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate }}-->
          <div class="binding-statuses">
            <mat-chip-list>
              <mat-chip class="small-chip" color="accent"
                        selected>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate}}</mat-chip>
            </mat-chip-list>
          </div>
          <form *ngIf="newTicketInput" [@formToAddTicketAnimation]>
            <mat-form-field style="width: 140px;">
              <mat-label>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ADD' | translate }}</mat-label>
              <textarea [(ngModel)]="completedTextArea"
                        matInput
                        [ngModelOptions]="{standalone: true}"
                        [placeholder]="'TEAMS.TEAM.PROJECTS.DASHBOARD.FINISHED' | translate"></textarea>
            </mat-form-field>
            <button [disabled]="completedTextArea.length === 0"
                    (click)="addToHere(this.completedTextArea, 'completed')"
                    style="width: 40px; margin-left: 10px;" mat-mini-fab color="primary">+
            </button>
          </form>
          <div
            cdkDropList
            [cdkDropListData]="projectTicketsCompleted"
            class="tickets-list"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event, 'completed')">
            <div
              align="left"
              (click)="openDialog(item)"
              class="ticket-box grey-important dark-text-important"
              *ngFor="let item of projectTicketsCompleted"
              cdkDrag>
              <!--          <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>-->
              <button mat-icon-button>
                <mat-icon>lock_open</mat-icon>
              </button>{{item.ttext}}
              <br/>
              <i [ngStyle]="{color: (item.userHasGotId !== personService.profile.userId) ? 'lightgrey': 'lightgreen'}">{{getFullName(item)}}</i>
            </div>
          </div>
        </div>

        <div class="tickets-container">
          <h2>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.CLOSED' | translate }}
            <button *ngIf="addTicketInput" [@addNewIconAnimation] mat-icon-button (click)="addTicketMode()">
              <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="closeTicketInput" [@closeNewIconAnimation] mat-icon-button (click)="closeTicketMode()">
              <mat-icon>close</mat-icon>
            </button>
          </h2>
          <!--      {{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate }}-->
          <div class="binding-statuses">
            <mat-chip-list>
              <mat-chip class="small-chip" color="accent"
                        selected>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ONLY_HERE' | translate}}</mat-chip>
            </mat-chip-list>
          </div>
          <form *ngIf="newTicketInput" [@formToAddTicketAnimation]>
            <mat-form-field style="width: 140px;">
              <mat-label>{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.ADD' | translate }}</mat-label>
              <textarea [(ngModel)]="closedTextArea"
                        matInput
                        [ngModelOptions]="{standalone: true}"
                        [placeholder]="'TEAMS.TEAM.PROJECTS.DASHBOARD.HAS_CLOSED' | translate"></textarea>
            </mat-form-field>
            <button [disabled]="closedTextArea.length === 0"
                    (click)="addToHere(this.closedTextArea, 'closed')" style="width: 40px; margin-left: 10px;"
                    mat-mini-fab color="primary">+
            </button>
          </form>
          <div
            cdkDropList
            [cdkDropListData]="projectTicketsClosed"
            class="tickets-list"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event, 'closed')">
            <div
              align="left"
              (click)="openDialog(item)"
              class="ticket-box grey-important dark-text-important"
              *ngFor="let item of projectTicketsClosed"
              cdkDrag>
              <!--          <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>-->
              <button mat-icon-button>
                <mat-icon>lock</mat-icon>
              </button>{{item.ttext}}
              <br/>
              <i [ngStyle]="{color: (item.userHasGotId !== personService.profile.userId) ? 'lightgrey': 'lightgreen'}">{{getFullName(item)}}</i>
            </div>
          </div>
        </div>

      </div>

    </mat-tab>
    <!--  Settings  -->
    <mat-tab [label]="'TEAMS.TEAM.PROJECTS.DASHBOARD.SETTINGS' | translate ">
      <br/>
      <div *ngIf="teamProject != null">&nbsp;&nbsp;&nbsp;{{ 'TEAMS.TEAM.PROJECTS.DASHBOARD.PROJECT' | translate }}:
        [{{teamProject.shortName}}] {{teamProject.name}} &nbsp;<button mat-icon-button color="primary">
          <mat-icon *ngIf="settingsOpenBtn" (click)="settingsOpened = true; settingsOpenBtn = false;">settings
          </mat-icon>
        </button>
      </div>
      <br/>
<!--      epics    -->
      <mat-form-field appearance="fill">
        <mat-label>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.EPIC' | translate }}</mat-label>
        <mat-select
          [formControl]="epics"
          (valueChange)="epicOptionSelected($event)"
        >
          <mat-select-trigger>
            {{ (epics.value === '111111100110101100101000000000') ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.MAIN-PROJECT' | translate) : epics.value === '111011100110101100101000000000' ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.NEW-EPIC-CREATING' | translate) : epics.value}}
          </mat-select-trigger>
          <mat-option
            [value]="'111111100110101100101000000000'">{{'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.MAIN-PROJECT' | translate}}</mat-option>
          <mat-option
            [value]="'111011100110101100101000000000'">{{'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.ADD-NEW-EPIC' | translate}}</mat-option>
          <mat-option *ngFor="let epic of teamProject.epicsList" [value]="epic">{{epic}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
<!--      sprints  -->
      <mat-form-field appearance="fill">
        <mat-label>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.SPRINT' | translate }}</mat-label>
        <mat-select
          [formControl]="sprints"
          (valueChange)="sprintOptionSelected($event)"
        >
          <mat-select-trigger>
            {{ (sprints.value === '111111100110101100101000000000') ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.SELECT-SPRINT' | translate) : sprints.value === '111011100110101100101000000000' ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.NEW-SPRINT-CREATING' | translate) : sprints.value}}
          </mat-select-trigger>
          <mat-option
            [value]="'111111100110101100101000000000'">{{'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.SELECT-SPRINT' | translate}}</mat-option>
          <mat-option
            [value]="'111011100110101100101000000000'">{{'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.NEW-SPRINT' | translate}}</mat-option>
          <mat-option *ngFor="let sprint of teamProject.sprintsList" [value]="sprint">{{sprint}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="settingsOpened" [@settings]>
        &nbsp;&nbsp;
        <button mat-icon-button>
          <mat-icon color="accent" (click)="closeSettings();">settings</mat-icon>
        </button>
        &nbsp;
        <br/>
        <form class="epic-name-form" (ngSubmit)="updateName()">
          <mat-form-field class="epic-name-full-width">
            <mat-label>{{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.SHORT_NAME' | translate }}</mat-label>
            <input type="text" matInput [formControl]="updateShortNameFormControl" [errorStateMatcher]="matcher"
                   [placeholder]="">
            <mat-hint></mat-hint>
            <mat-error
              *ngIf=" (updateShortNameFormControl.hasError('minlength')) && !updateShortNameFormControl.hasError('required')">
              {{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.SYMBOLS' | translate }}
            </mat-error>
            <mat-error *ngIf="updateShortNameFormControl.hasError('required')">
              <strong>
                {{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.SHORT_NAME_REQ' | translate }}
              </strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="epic-name-full-width">
            <mat-label>{{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.FULL_NAME' | translate }}</mat-label>
            <input type="text" matInput [formControl]="updateFullNameFormControl" [errorStateMatcher]="matcher"
                   [placeholder]="">
            <mat-hint></mat-hint>
            <mat-error
              *ngIf=" (updateFullNameFormControl.hasError('minlength')) && !updateFullNameFormControl.hasError('required')">
              {{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.SYMBOLS' | translate }}
            </mat-error>
            <mat-error *ngIf="updateFullNameFormControl.hasError('required')">
              <strong>
                {{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.FULL_NAME_REQ' | translate }}
              </strong>
            </mat-error>
          </mat-form-field>
          <button mat-button color="primary" type="submit" [disabled]="updateShortNameFormControl.errors != null && updateFullNameFormControl.errors != null">
            {{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.UPDATE_IT' | translate }}
          </button>
          &nbsp;
          <button mat-button color="warn" type="reset">
            {{ 'TEAMS.TEAM.PROJECTS.SETTINGS.UPDATE.RESET' | translate }}
          </button>
        </form>

        <br/>
        <br/>
      </div>
      <div *ngIf="addEpic" style="margin: 20px; padding: 10px; border: 3px solid silver; max-width: 250px;">

        &nbsp;
        &nbsp;
        <form class="epic-name-form" (ngSubmit)="addNewEpic()">
          <mat-form-field class="epic-name-full-width">
            <mat-label>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.EPIC-NAME' | translate }}</mat-label>
            <input type="text" matInput [formControl]="epicNameFormControl" [errorStateMatcher]="matcher"
                   [placeholder]="'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.EPIC-HOLDER' | translate">
            <mat-hint></mat-hint>
            <mat-error
              *ngIf=" (epicNameFormControl.hasError('minlength')) && !epicNameFormControl.hasError('required')">
              {{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.MIN5' | translate }}
            </mat-error>
            <mat-error *ngIf="epicNameFormControl.hasError('required')">
              <strong>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.REQ' | translate }}</strong>
            </mat-error>
          </mat-form-field>
          <button mat-button color="primary" type="submit" [disabled]="epicNameFormControl.errors != null">
            {{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.ADD' | translate }}
          </button>
          &nbsp;
          <button mat-button color="warn" type="reset">
            {{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.RESET' | translate }}
          </button>
        </form>
      </div>
      <div *ngIf="addSprint" style="margin: 20px; padding: 10px; border: 3px solid silver; max-width: 250px;">
        &nbsp;
        &nbsp;
        <form class="epic-name-form" (ngSubmit)="addNewSprint()">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.SPRINT-LAST-DAY' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="sprintDatePickerFormControl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
<!--          <mat-form-field class="epic-name-full-width">-->
<!--            <mat-label>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.SPRINT-WEEKS' | translate }}</mat-label>-->
<!--            <input type="number" matInput [formControl]="sprintWeeksNumberFormControl" [errorStateMatcher]="matcher"-->
<!--                   [placeholder]="'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.SPRINT-HOLDER' | translate">-->
<!--            <mat-hint></mat-hint>-->
<!--            <mat-error *ngIf=" (epicNameFormControl.hasError('pattern')) && !epicNameFormControl.hasError('required')">-->
<!--              {{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.WEEKS' | translate }}-->
<!--            </mat-error>-->
<!--            <mat-error *ngIf="epicNameFormControl.hasError('required')">-->
<!--              <strong>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.NUM-REQ' | translate }}</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--          <button mat-button color="primary" type="submit" [disabled]="sprintWeeksNumberFormControl.errors != null">-->
          <button mat-button color="primary" type="submit">
            {{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.ADD' | translate }}
          </button>
          &nbsp;
          <button mat-button color="warn" type="reset">
            {{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.RESET' | translate }}
          </button>
        </form>
      </div>

    </mat-tab>
    <mat-tab [label]=" 'PERSONAL_PROJECTS.BP.TITLE' | translate ">
      <br/>
      <i>{{'PERSONAL_PROJECTS.BP.INSTRUCTION' | translate}}</i>
      <br/>
      <br/>
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>{{'PERSONAL_PROJECTS.BP.STEP' | translate}}</mat-label>
          <input type="text" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                 placeholder="scenario step">
          <mat-hint>{{'PERSONAL_PROJECTS.BP.ERRORS' | translate}}</mat-hint>
          <!--            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">-->
          <!--              Please enter a valid email address-->
          <!--            </mat-error>-->
          <mat-error *ngIf="emailFormControl.hasError('required')">
            {{'PERSONAL_PROJECTS.BP.STEP_IS' | translate}} <strong>{{'PERSONAL_PROJECTS.BP.REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        &nbsp;
        <button mat-raised-button color="primary" (click)="addStep()">{{'PERSONAL_PROJECTS.BP.ADD_STEP' | translate}}</button>
      </form>
      <br/>
      <button mat-raised-button color="warn" (click)="removeStep()">{{'PERSONAL_PROJECTS.BP.REMOVE_STEP' | translate}}</button>

      <br/>
      <br/>
      <br/>

      <p>
          <span class="step" *ngFor="let step of steps; let i = index">
            <span class="business-process">
            {{step}}
            </span>
            <br/>
            {{i !== steps.length - 1 ? '↓' : ''}}
            <br/>
          </span>
      </p>

      <div *ngIf="steps.length === 0">{{'PERSONAL_PROJECTS.BP.NO_BP' | translate}}</div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

    </mat-tab>
<!--    todo: move settings here-->
<!--    <mat-tab [label]="'Settings'">-->
<!--    </mat-tab>-->

<!--    <mat-tab [label]="'Business processes'">-->
<!--      <br/>-->
<!--      <i>{{'PERSONAL_PROJECTS.BP.INSTRUCTION' | translate}}</i>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <form class="example-form">-->
<!--        <mat-form-field class="example-full-width">-->
<!--          <mat-label>{{'PERSONAL_PROJECTS.BP.STEP' | translate}}</mat-label>-->
<!--          <input type="text" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"-->
<!--                 placeholder="scenario step">-->
<!--          <mat-hint>{{'PERSONAL_PROJECTS.BP.ERRORS' | translate}}</mat-hint>-->
<!--          &lt;!&ndash;            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">&ndash;&gt;-->
<!--          &lt;!&ndash;              Please enter a valid email address&ndash;&gt;-->
<!--          &lt;!&ndash;            </mat-error>&ndash;&gt;-->
<!--          <mat-error *ngIf="emailFormControl.hasError('required')">-->
<!--            {{'PERSONAL_PROJECTS.BP.STEP_IS' | translate}} <strong>{{'PERSONAL_PROJECTS.BP.REQUIRED' | translate}}</strong>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
<!--        &nbsp;-->
<!--        <button mat-raised-button color="primary" (click)="addStep()">{{'PERSONAL_PROJECTS.BP.ADD_STEP' | translate}}</button>-->
<!--      </form>-->
<!--      <br/>-->
<!--      <button mat-raised-button color="warn" (click)="removeStep()">{{'PERSONAL_PROJECTS.BP.REMOVE_STEP' | translate}}</button>-->

<!--      <br/>-->
<!--      <br/>-->
<!--      <br/>-->

<!--      <p>-->
<!--          <span class="step" *ngFor="let step of steps; let i = index">-->
<!--            <span class="business-process">-->
<!--            {{step}}-->
<!--            </span>-->
<!--            <br/>-->
<!--            {{i !== steps.length - 1 ? '↓' : ''}}-->
<!--            <br/>-->
<!--          </span>-->
<!--      </p>-->

<!--      <div *ngIf="steps.length === 0">{{'PERSONAL_PROJECTS.BP.NO_BP' | translate}}</div>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <br/>-->

<!--    </mat-tab>-->

  </mat-tab-group>


</div>

