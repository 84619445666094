<div class="ticket-text" *ngIf="ticket.edit !== 'yes'">
  {{ticket.ttext}}
  <button
    [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
    mat-icon-button
    color="primary"
    (click)="startTicketEditing(ticket)"
  >
    <mat-icon>edit</mat-icon>
  </button>
</div>
<div class="ticket-text" *ngIf="ticket.edit === 'yes'">
  <form (ngSubmit)="editTicketText(ticket)">
    <mat-form-field style="width: 100%;">
      <mat-label>{{ 'TODAY.TICKET.TEXT.EDIT' | translate}}</mat-label>
      <input
        matInput
        [formControl]="editTicketTextFormControl" [errorStateMatcher]="matcher"
      >
    </mat-form-field>
    <br/>
    <button
      type="submit"
      mat-button
      color="primary">{{ 'TODAY.TICKET.TEXT.SAVE' | translate}}</button>
    &nbsp;
    <button
      (click)="ticket.edit = 'no'"
      mat-button
      color="accent">{{ 'TODAY.TICKET.TEXT.CANCEL' | translate}}</button>
    <br/>
    <br/>
  </form>
</div>
