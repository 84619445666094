export enum CommentType {
  PERSONAL, TEAM
}

export interface UserComment {
  id: string;
  auth: string;
  mainUserId: string;
  ticketId: string;
  commentText: string;
  commentTs: number;
  userName: string;
  userSerName: string;
  userAva: string;
  type: CommentType;
}
