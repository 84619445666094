<div style="width: 96%; margin: auto;">
  <br/>
  <br/>
  <h1>Terms of Use</h1>
  <br/>
  <br/>
  last update: 2020/07/20
  <br/>
  <br/>
  <hr/>
  <br/>
  <br/>
  <h2>License</h2>
  <p>
    To make it easier and more clear all content created with nettyos.com automatically belongs to service nettyos.com.
    And we automatically provide the author of the generated content unlimited license to use this content without
    breaking regional laws.
  </p>
  <br/>
  <br/>
  <h2>Modification</h2>
  <p>
    We may modify these terms to reflect changes to laws or this service. When we make a modification, we will publish
    here and notification of update will be available when you access this website after the update. Changes will become
    effective no sooner than 14 days after publishing, unless those changes addressing new functions of this service, or
    changes for legal reasons. Additional terms ( if available ) will control for the conflict if there is a conflict
    between these terms and the additional terms. If it turns out that a particular term is not enforceable, this will
    not affect any other terms. If you do not comply with these terms, and we don't take action right away, this doesn't
    mean that we are giving up any rights that we may have (such as taking action in the future). You have to leave
    nettyos.com service and all related resources if you do not comply with these terms.
  </p>
  <br/>
  <br/>
  <h2>Warranty & Disclaimer</h2>
  <p>
    OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER NETTYOS.COM NOR ITS SUPPLIERS OR
    DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON'T MAKE ANY COMMITMENTS ABOUT THE
    CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY
    TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES "AS IS". TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.
  </p>
  <br/>
  <br/>
  <h2>Liability</h2>
  <p>
    WHEN PERMITTED BY LAW, NETTYOS.COM AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS,
    REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. TO THE
    EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF NETTYOS.COM, AND ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIMS
    UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES
    (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN). IN ALL CASES, NETTYOS.COM, AND ITS SUPPLIERS AND
    DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE.
  </p>
  <br/>
  <br/>
  <h2>Applicable Law</h2>
  <p>
    These terms shall be governed by and construed under the laws of Russia and subject to the exclusive jurisdiction of
    the courts located in the Russian Federation.
  </p>
  <hr/>
  <br/>
  <br/>
  <div style="margin: 0 auto; width: fit-content;">end of terms</div>
  <br/>
  <br/>
</div>
