<table class="lr-btn">
  <tr>
    <td *ngIf="router.url != '/login'
    && router.url != '/reset'
    && router.url != '/register'
    && router.url != '/today'
    && router.url != '/profile'
    && !router.url.startsWith('/payment')
    && router.url != '/privacy'
    && router.url != '/tos'
    && router.url != '/people'
    && router.url != '/guide'
    && !router.url.startsWith('/places')
    && !router.url.startsWith('/projects')
    && !router.url.startsWith('/team')
    && !router.url.startsWith('/about')
    && !router.url.startsWith('/control')
    && !router.url.startsWith('/cost')
    && !router.url.startsWith('/coach')
    && !router.url.startsWith('/alerts')
    && !router.url.startsWith('/users')
    && !router.url.startsWith('/skills')
    && !router.url.startsWith('/public-guide')
    && !router.url.startsWith('/self-organizer')
    && !router.url.startsWith('/family-hub')
    && !router.url.startsWith('/dreams-maker')
    && !router.url.startsWith('/personal-projects')
    && !router.url.startsWith('/all-teams')
    && !router.url.startsWith('/tour')
    && !router.url.startsWith('/contacts')
    && !router.url.startsWith('/pricing')
    && router.url != '/development' ">
      <button mat-button color="primary" alert="1" [routerLink]="'/login'">
        {{ 'SIGN.LOGIN' | translate}}
      </button>
    </td>
    <td *ngIf="(auth.token.length === 0 || auth.token === 'no') &&
    (router.url === '/login' ||
    router.url === '/register'||
    router.url === '/reset' ||
    router.url === '/users'||
    router.url === '/pricing'||
    router.url === '/public-guide' ||
    router.url === '/self-organizer' ||
    router.url === '/family-hub' ||
    router.url === '/dreams-maker' ||
    router.url === '/personal-projects'
    || router.url === '/all-teams'
    || router.url === '/tour'
    || router.url === '/contacts'
    || router.url === '/tos'
    || router.url === '/privacy'
    )">
      <button mat-button color="primary" alert="1" [routerLink]="'/'">
        {{ 'SIGN.HOME' | translate}}
      </button>
    </td>
    <td *ngIf="router.url === '/'">
      <!--<button mat-button color="primary" class="lr-btn1" alert="1" [routerLink]="'/users'"-->
      <!--   *ngIf="router.url === '/'">-->
      <!-- todo: кейсы -->
      <!--  {{ 'SIGN.WHOM' | translate}}-->
      <!--</button>-->
      <button mat-button color="primary" alert="1" [routerLink]="'/pricing'">
        {{ 'SIGN.PRICING' | translate}}
      </button>
    </td>
    <td *ngIf="router.url === '/'">
      <button mat-button color="primary" alert="1" [routerLink]="'/public-guide'">
        {{ 'SIGN.GUIDE' | translate}}
      </button>
    </td>
  </tr>
</table>

<img class="round-loader" *ngIf="personService.loading" src="./assets/loading.gif"/>

<a *ngIf="(auth.token.length > 0 && auth.token !== 'no') else space"
   [matTooltip]="'TOP_MENU.TODAY_TOOLTIP' | translate"
>
  <button
    *ngIf="!personService.giveDialogTooltipAnswer"
    mat-stroked-button
    color="primary"
    class="title"
    (click)="personService.searchFlag = false; personService.reloadTicketsOnDemand = true;"
    [routerLink]="auth.homeButtonLink">
    <span class="fullBtn">
      {{ 'TOP_MENU.TODAY' | translate}}
<!--      <span class="word1">{{ 'HEADER.TITLE.WORD1' | translate}}</span>-->
<!--      &nbsp;-->
<!--      <span class="word2">{{ 'HEADER.TITLE.WORD2' | translate}}</span>-->
<!--      &nbsp;-->
<!--      <span class="word3">{{ 'HEADER.TITLE.WORD3' | translate}}</span>-->
<!--      &nbsp;4:&nbsp;-->
<!--      <i class="motto">{{ 'HEADER.TITLE.MOTTO' | translate}}</i>-->
    </span>
    <span *ngIf="!personService.loading" class="shortBtn">
      {{ 'TOP_MENU.TODAY' | translate}}
    </span>
    <div *ngIf="personService.loading" class="shortBtn progress-size" >
        <mat-progress-bar class="line-loader" mode="buffer"></mat-progress-bar>
    </div>
  </button>
  <button
    *ngIf="personService.giveDialogTooltipAnswer"
    mat-stroked-button
    color="primary"
    class="title"
    [ngStyle]="{border: '3px solid yellow'}"
    (click)="mainTodayClicked()"
  >
    <span class="fullBtn">
      {{ 'TOP_MENU.TODAY' | translate}}
    </span>
    <span *ngIf="!personService.loading" class="shortBtn">{{ 'TOP_MENU.TODAY' | translate}}</span>
    <div *ngIf="personService.loading" class="shortBtn progress-size" >
        <mat-progress-bar class="line-loader" mode="buffer"></mat-progress-bar>
    </div>
  </button>
</a>
<ng-template #space>&nbsp;</ng-template>
<!--<br *ngIf="personService.loading"/>-->
<!--<mat-progress-bar class="line-loader" *ngIf="personService.loading" mode="buffer"></mat-progress-bar>-->
