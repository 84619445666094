import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GatewayService, Ticket} from "../../../services/gateway.service";
import {Project} from "../../today/projects/projects.component";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {PersonService} from "../../../services/person.service";
import {UserComment} from "../../../dto/Comment";
import {TeamProject} from "../../today/teams/teams.component";

@Component({
  selector: 'app-business-process',
  templateUrl: './business-process.component.html',
  styleUrls: ['./business-process.component.scss']
})
export class BusinessProcessComponent implements OnInit {

  @Input() ticket: Ticket;
  @Input() dialogFlag = false;
  @Output() closeDialog =  new EventEmitter<boolean>();
  assigned = false;
  type = 'personal';
  personalProject: Project;
  teamProject: TeamProject;

  constructor(private personService: PersonService, private gateway: GatewayService) { }

  ngOnInit(): void {
    //console.log('ticket ', this.ticket);
    if (this.ticket.teamProjectId != null && this.ticket.teamProjectId.length > 0) {
      //console.log(this.personService.teams);
      for (let team of this.personService.teams) {
        for (let teamProject of team.projects) {
          if (teamProject.id === this.ticket.teamProjectId && teamProject.businessProcess != null && teamProject.businessProcess.indexOf(',') !== -1) {
            this.assigned = true;
            this.type = 'team';
            this.teamProject = teamProject;
          }
        }
        // return false;
      }
    } else {
      let project: Project = this.personService.projects.find(p => p.id === this.ticket.projectId);
      this.personalProject = project;
      if (null != project && project.businessProcess != null && project.businessProcess.indexOf(',') !== -1) {
        this.assigned = true;
      }
      else
        this.assigned = false;
    }
  }

  assignFirstBusinessStep(ticket: Ticket) {
    if (this.type === 'personal') {
      let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
      if (null != project && project.businessProcess != null && project.businessProcess.indexOf(',') !== -1) {
        ticket.businessProcessStep = project.businessProcess.split(',')[0];
        this.personService.loading = true;
        this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          }
        );
      }
    } else {
      if (null != this.teamProject && this.teamProject.businessProcess != null && this.teamProject.businessProcess.indexOf(',') !== -1) {
        ticket.businessProcessStep = this.teamProject.businessProcess.split(',')[0];
        this.personService.loading = true;
        this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          }
        );
      }
    }
  }

  isPreviousStep(ticket: Ticket) {
    if (this.type === 'personal') {
      let isTheStepExist = false;
      let existsFlag = false;
      let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
      let stepsArray = project.businessProcess.split(',');
      for (let i = 0; i < stepsArray.length; i++) {
        if (stepsArray[i] === ticket.businessProcessStep && i > 0) {
          existsFlag = true;
        }
        if (stepsArray[i] === ticket.businessProcessStep) {
          isTheStepExist = true;
        }
      }
      if (!isTheStepExist) {
        // если этого бизнес процесса больше нет - тогда вначало
        this.assignFirstBusinessStep(ticket);
      }
      return existsFlag;
    } else {
      let isTheStepExist = false;
      let existsFlag = false;
      let stepsArray = this.teamProject.businessProcess.split(',');
      for (let i = 0; i < stepsArray.length; i++) {
        if (stepsArray[i] === ticket.businessProcessStep && i > 0) {
          existsFlag = true;
        }
        if (stepsArray[i] === ticket.businessProcessStep) {
          isTheStepExist = true;
        }
      }
      if (!isTheStepExist) {
        // если этого бизнес процесса больше нет - тогда вначало
        this.assignFirstBusinessStep(ticket);
      }
      return existsFlag;
    }
  }

  movePreviousStep(ticket: Ticket) {
    if (this.type === 'personal') {
      let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
      if (null != project && project.businessProcess != null) {
        let stepsArray = project.businessProcess.split(',');
        for (let i = 0; i < stepsArray.length; i++) {
          if (stepsArray[i] === ticket.businessProcessStep && (i - 1) >= 0) {
            ticket.businessProcessStep = stepsArray[i - 1];
            break;
          }
        }
        this.personService.loading = true;
        this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          }
        );
      }
    } else {
      if (null != this.teamProject && this.teamProject.businessProcess != null) {
        let stepsArray = this.teamProject.businessProcess.split(',');
        for (let i = 0; i < stepsArray.length; i++) {
          if (stepsArray[i] === ticket.businessProcessStep && (i - 1) >= 0) {
            ticket.businessProcessStep = stepsArray[i - 1];
            break;
          }
        }
        this.personService.loading = true;
        this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          }
        );
      }
    }
  }

  isNextStep(ticket: Ticket) {
    if (this.type === 'personal') {
      let existsFlag = false;
      let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
      let stepsArray = project.businessProcess.split(',');
      for (let i = 0; i < stepsArray.length; i++) {
        if (stepsArray[i] === ticket.businessProcessStep && i + 1 < stepsArray.length) {
          existsFlag = true;
        }
      }
      return existsFlag;
    } else {
      let existsFlag = false;
      let stepsArray = this.teamProject.businessProcess.split(',');
      for (let i = 0; i < stepsArray.length; i++) {
        if (stepsArray[i] === ticket.businessProcessStep && i + 1 < stepsArray.length) {
          existsFlag = true;
        }
      }
      return existsFlag;
    }
  }

  moveNextStep(ticket: Ticket) {
    if (this.type === 'personal') {
      let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
      if (null != project && project.businessProcess != null) {
        let stepsArray = project.businessProcess.split(',');
        for (let i = 0; i < stepsArray.length; i++) {
          if (stepsArray[i] === ticket.businessProcessStep && (i + 1) < stepsArray.length) {
            ticket.businessProcessStep = stepsArray[i + 1];
            break;
          }
        }
        this.personService.loading = true;
        this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          }
        );
      }
    } else {
      if (null != this.teamProject && this.teamProject.businessProcess != null) {
        let stepsArray = this.teamProject.businessProcess.split(',');
        for (let i = 0; i < stepsArray.length; i++) {
          if (stepsArray[i] === ticket.businessProcessStep && (i + 1) < stepsArray.length) {
            ticket.businessProcessStep = stepsArray[i + 1];
            break;
          }
        }
        this.personService.loading = true;
        this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
          (answer: ExtendedStatus) => {
            this.personService.loading = false;
          }
        );
      }
    }
  }

  randomString() {
    const length = 5;
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  onNoClick() {
    this.closeDialog.emit(true);
  }
}
