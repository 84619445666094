<div [class]="auth.theme">
  <mat-toolbar class="header top-panel">
    <app-menu *ngIf="
                router.url !== '/'
                && router.url !== '/login'
                && router.url !== '/reset'
                && router.url !== '/register'
                && router.url !== '/users'
                && router.url !== '/pricing'
                && router.url !== '/public-guide'
                && router.url !== '/self-organizer'
                && router.url !== '/family-hub'
                && router.url !== '/dreams-maker'
                && router.url !== '/personal-projects'
                && router.url !== '/all-teams'
                && router.url !== '/tour'
                && router.url !== '/contacts'
                && router.url !== '/tos'
                && router.url !== '/privacy'
              "
              class="alt-netty-menu"></app-menu>
    <app-header style="margin: auto"></app-header>

    <button *ngIf="(auth.token.length > 0 && auth.token !== 'no')" (click)="openSnackBar(); personService.giveDialogTooltipAnswer = !personService.giveDialogTooltipAnswer;" mat-icon-button [color]="personService.giveDialogTooltipAnswer ? 'accent' : 'primary'" aria-label="Help button">
      <mat-icon>live_help</mat-icon>
    </button>

    <app-language *ngIf="auth.token.length === 0 || auth.token === 'no'" [language]="personService.language"></app-language>

    <span
      (click)="aboutCoins()"
      [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
    >
      <span *ngIf="(auth.token.length > 0 && auth.token !== 'no')"
            class="coins-amount" style="color: silver; margin-right: 5px;">{{personService.silver}}</span>
      <img [@silver]
           *ngIf="personService.silverCoinFlag && (auth.token.length > 0 && auth.token !== 'no')" style="height: 20px;" src="/assets/images/coins/nettysilver.png">
      &nbsp;&nbsp;
      <span *ngIf="(auth.token.length > 0 && auth.token !== 'no')"
            class="coins-amount" style="color: goldenrod; margin-right: 5px;">{{personService.gold}}</span>
      <img [@gold]
           *ngIf="personService.goldCoinFlag && (auth.token.length > 0 && auth.token !== 'no')" style="height: 20px;" src="/assets/images/coins/nettygold.png">
    </span>
    <button mat-button
            [ngStyle]="{color: auth.theme === '' ? 'black' : 'yellow', border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            (click)="changeTheme()"
    >
<!--      <mat-icon>brightness_high</mat-icon>-->
      <mat-icon matTooltip="Switch White Theme" *ngIf="auth.theme !== ''">wb_sunny</mat-icon>
      <mat-icon matTooltip="Switch Dark Theme" *ngIf="auth.theme === ''">brightness_6</mat-icon>
    </button>
<!--    <button mat-button color="primary" (click)="changeTheme()">B&W</button>-->
  </mat-toolbar>
<!--  <button-->
<!--    (click)="personService.sideNavVisible = true;"-->
<!--    style="position: relative; right: 10px;"-->
<!--    mat-icon-button>-->
<!--    <mat-icon>keyboard_arrow_right</mat-icon>-->
<!--  </button>-->
  <mat-sidenav-container class="container">
    <mat-sidenav mode="side" class="sidenav"
                 [@sidenav]
                 [fixedInViewport]="false"
                 [fixedTopGap]="0"
                 [fixedBottomGap]="0"
                 [(opened)]="opened"
                 *ngIf="
                   (router.url !== '/'
                   && router.url !== '/login'
                   && router.url !== '/reset'
                   && router.url !== '/register'
                   && router.url !== '/users'
                   && router.url !== '/pricing'
                   && router.url !== '/public-guide'
                   && router.url !== '/self-organizer'
                   && router.url !== '/family-hub'
                   && router.url !== '/dreams-maker'
                   && router.url !== '/personal-projects'
                   && router.url !== '/all-teams'
                   && router.url !== '/tour'
                   && router.url !== '/contacts'
                   && router.url !== '/tos'
                   && router.url !== '/privacy'
                   )
                   && personService.sideNavVisible
                "
    >
      <app-sidenav></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="content">
      <button
        class="device"
        *ngIf="!personService.sideNavVisible"
        (click)="personService.sideNavVisible = true;"
        style="position: relative; right: 5px; top: 25px;"
        mat-icon-button>
        <mat-icon color="accent">keyboard_arrow_right</mat-icon>
      </button>
      <!--    <app-content></app-content>-->
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      <br/>
      <div *ngIf="(auth.token.length === 0 || auth.token === 'no')">
        <br/><br/><br/>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <mat-toolbar class="footer" *ngIf="(auth.token.length === 0 || auth.token === 'no')">
    <app-footer style="margin: auto; font-size: 50%;"></app-footer>
  </mat-toolbar>
</div>
