<div style="width: 96%; margin: auto;" align="center">
  <app-solutions-header></app-solutions-header>
  <h1 *ngIf="translate.defaultLang === 'ru'">Семейный хаб</h1>
  <h1 *ngIf="translate.defaultLang === 'en'">Family hub</h1>
  <br/>
  <img
    [@nettyos]
    style="width: 300px;"
    src="./assets/images/landing/step2/with-all-family-plan.svg"
  />
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'ru'">
    <p class="text">
      Nettyos можно использовать для какой-то конкретной, ограниченной цели, а можно для всего сразу.
      Это зависит от настроек и тарифного плана.
      <br/>
      <br/>
      Nettyos будет незаменим для реализации "Семейного Хаба". Даже у небольшой семьи есть много дел, что уж говорить о больших семьях. Не все просьбы можно исполнить прямо сейчас,
      кто-то работает, другие строят бизнес. Поэтому просьбы с низким приоритетом (но важные) можно отправить другу или подруге, которые могут быть членами семьи. То же самое возможно и с друзьями.
      Поэтому "Семейный Хаб" актуален для любой компании друзей.
      <br/>
      <br/>
      Поделиться можно не только просьбой, но и идеей, мечтой. При этом просьба, идея или мечта (в Nettyos это все называется одним словом - тикет) не потеряются. Будет возможность обдумать,
      обсудить в комментариях, прикрепить необходимые ресурсы. А потом идея может перерасти в интересный проект. Такой как строительство дома или новый семейный (партнерский) бизнес.
      <br/>
      <br/>
      <strong>Если использовать Nettyos для семейного/дружеского хаба, вот краткий список того, что можно добавить к разделу <a [routerLink]="'/self-organizer'">самоорганизация</a>. По умолчанию входит в тариф <a [routerLink]="'/pricing'">"Free Plan"</a>:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>отправить просьбу, идею, мечту (тикет) другу</li>
        <li>видеть статус тикета у друга</li>
        <li>переписываться под тикетом</li>
        <li>открепить от пресонального проекта и отправить другу</li>
        <li>создать команду, не более 1 из 2х человек</li>
        <li>создать команду в необходимом составе <a [routerLink]="'/pricing'">"Pro.Light+"</a></li>
        <li>попробовать семейный скрум <a [routerLink]="'/pricing'">"Pro.Light+"</a></li>
        <li>honey do list</li>
        <li>бизнес-процессы <a [routerLink]="'/pricing'">"Free Plan+"</a></li>
        <li>и другие опции...</li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class="text">
      <strong>Жизненные ситуации, в которых это решение может пригодиться:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>Отправить тикет с настроенным напоминанием, посмотреть любимую программу (которую постоянно забывает) девушке/жене.</li>
        <li>Напомнить родителям принять лекарства и видеть статус.</li>
        <li>Попросить купить бесшумный кран на кухню, когда у мужа будет время.</li>
        <li>Создать проект для ремонта квартиры и расписать все бизнес-процессы. Это может сэкономить миллионы и получить качественный (идеальный) ремонт. Учитывая, что бригады способные делать "как для себя" добавляют очень большие цифры. И не всегда это честно. С помощью Nettyos можно контролировать частников и бригады, но это уже в другое решение.</li>
        <li>Использовать эпики для ремонта каждой комнаты: "кухня", "спальня", "детская", "коридор"... Это будет очень удобно!</li>
        <li>Использовать недельные спринты для поклейки обоев в каждой комнате семьей. Семейный SCRUM (упрощенный) или любую другую модель.</li>
        <li>Спланировать поход с детьми, отпуск и не забыть ни одной мелочи. Именно такой подход поможет сделать отдых идеальным.</li>
        <li>Придумать награду для мужа/жены, если выполнит все просьбы. Honey do list.</li>
        <li>Начать крутой бизнес вдвоем бесплатно, а когда появится доход подключить 1000 сотрудников в свою команду.</li>
        <li>И очень много других кейсов...</li>
      </ul>
    </div>

  </div>

  <div *ngIf = "translate.defaultLang === 'en'">
    <p class = "text">
      Nettyos can be used for a specific, limited purpose, or it can be used for everything at once.
      It depends on the settings and the tariff plan.
      <br/>
      <br/>
      Nettyos will be indispensable for the implementation of the "Family Hub". Even a small family has many things to do, for sure large families - a lot. Not all requests can be fulfilled at the moment,
      some are working, others are building a business. Therefore, low priority but important requests can be sent to a friend who may be family members. The same is possible with friends.
      Therefore, the "Family Hub" is relevant for any group of friends.
      <br/>
      <br/>
      You can share not only a request, but also an idea, a dream. At the same time, a request, an idea or a dream (in Nettyos it is all called in one word - a ticket) will not be lost. There will be an opportunity to ponder
      discuss in the comments, attach the necessary resources. And then the idea can grow into an interesting project. Such as building an apartment or a new family (partner) business.
      <br/>
      <br/>
      <strong>If you are using Nettyos for your family/friend hub, here is a short list of things to add to the <a [routerLink]="'/self-organizer'">self-organizing</a> section. By default included in the tariff <a [routerLink]="'/pricing'">"Free Plan"</a>: </strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li> send a request, idea, dream (ticket) to a friend </li>
        <li> see a friend's ticket status </li>
        <li> correspond under the ticket </li>
        <li> detach from personal project and send to a friend </li>
        <li> create a team, no more than 1 of 2 people </li>
        <li> create a team with the required composition <a [routerLink]="'/pricing'"> "Pro.Light +" </a> </li>
        <li> try family SCRUM <a [routerLink]="'/pricing'"> "Pro.Light +" </a> </li>
        <li> honey do list </li>
        <li> business processes <a [routerLink]="'/pricing'"> "Free Plan +" </a> </li>
        <li> and other options ... </li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class="text">
      <strong>Life situations in which this solution may be useful:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li> Send a ticket with a customized reminder, watch her favorite program (which she constantly forget) to your girlfriend/wife. </li>
        <li> Remind parents to take medication and see status. </li>
        <li> Ask to buy a silent faucet for the kitchen when my husband has time. </li>
        <li> Create a project for apartment renovation and describe all business processes. This can save millions and get a quality (perfect) renovation. Considering that the brigades are able to do "as for themselves" add very large numbers of money. And it's not always fair. With the help of Nettyos, you can control private individuals and brigades, but this is a different solution. </li>
        <li> Use epics to renovate each room: "kitchen", "bedroom", "nursery", "corridor"... It will be very handy! </li>
        <li> Use weekly sprints to wallpaper each room with the family. Family SCRUM (simplified) or any other model. </li>
        <li> Plan a trip with children, vacation and not forget a single detail. This approach will help make your vacation/holiday perfect. </li>
        <li> Come up with a reward for the husband/wife if all requests are fulfilled. Honey do list. </li>
        <li> Starting a cool business for two is free, and when the income appears, hire 1000 employees to your team. </li>
        <li> And a lot of other cases ... </li>
      </ul>
    </div>

  </div>
  <br/>
  <br/>
  <br/>
  <br/>
</div>
