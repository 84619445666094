<!--<div *ngIf="!addNewProjectFlag">-->
<!--  <br/><br/>-->
<!--  &nbsp;&nbsp;<button (click)="addNewProjectFlag = true" mat-button color="primary">Add new project >></button>-->
<!--</div>-->
<br/>
<div *ngIf="addNewProjectFlag">
  <br/><br/>
  &nbsp;&nbsp;<button (click)="addNewProjectFlag = false; projectId = 'select';" mat-button
                      color="primary">{{ 'PERSONAL_PROJECTS.CONTROLS.HIDE' | translate}}</button>
</div>
<br/>
<div class="center" align="center">
  <div style="width: fit-content;">
    <div *ngIf="addNewProjectFlag">
      <i>{{ 'PERSONAL_PROJECTS.INFORMATION' | translate}}</i>
      <br/>
      <br/>
      <form class="project-form" align="left">
        <mat-form-field class="project-full-width">
          <mat-label>{{ 'PERSONAL_PROJECTS.CONTROLS.NEW_PROJECT' | translate}}</mat-label>
          <input
            matInput
            [placeholder]="'PERSONAL_PROJECTS.CONTROLS.FULL_NAME' | translate"
            [(ngModel)]="projectFullName"
            (keyup)="generateShortName()"
            [ngModelOptions]="{standalone: true}"
          >
        </mat-form-field>
        <mat-form-field class="project-short-width">
          <!--      <mat-label>Project short name</mat-label>-->
          <input
            matInput
            (keyup)="fix3Symbols()"
            [placeholder]="'PERSONAL_PROJECTS.CONTROLS.SHORT_NAME' | translate"
            [(ngModel)]="projectShortName"
            [ngModelOptions]="{standalone: true}"
          >
        </mat-form-field>&nbsp;{{ 'PERSONAL_PROJECTS.CONTROLS.3_SYMBOLS' | translate}}
        <br/>
        <button
          [disabled]="projectFullName.length < 5 || projectShortName.length < 3|| projectShortName.length > 5"
          (click)="addProject()"
          *ngIf="personService.isPremiumAccount() || personService.isProAccount() || personService.projects.length < 3"
          mat-stroked-button
          color="primary"
        >Okay
        </button>
      </form>
    </div>
    <mat-form-field style="min-width: 200px; max-width: 400px; color: lightblue;">
      <mat-select
        class="project-select"
        (ngModelChange)="chooseProject()"
        [(ngModel)]="projectId"
        required>
        <mat-option [value]="'select'">{{ 'PERSONAL_PROJECTS.CONTROLS.CHOOSE' | translate}}</mat-option>
        <mat-option
          *ngIf="personService.isPremiumAccount() || personService.isProAccount() || personService.projects.length < 3"
          [value]="'newProject'">{{ 'PERSONAL_PROJECTS.CONTROLS.NEW' | translate}}
        </mat-option>
        <mat-option *ngIf="!personService.isPremiumAccount() && !personService.isProAccount()"
                    [routerLink]="'/payment-plan'"
                    [value]="'payment'"><span
          style="background-color: darkorchid; color: white;">Premium</span>&nbsp;{{ 'PERSONAL_PROJECTS.CONTROLS.PAY1' | translate}}&nbsp;<span
          style="background-color: darkorange; color: white;">Pro.*</span> {{ 'PERSONAL_PROJECTS.CONTROLS.PAY2' | translate}}
        </mat-option>
        <mat-option [value]="project.id" *ngFor="let project of personService.projects">
          <span *ngIf="project.archive"><b>A</b>:&nbsp;</span>[{{project.shortName}}] {{project.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    &nbsp;<button *ngIf="null != project && project.id !== ''" mat-icon-button [disabled]="true"><mat-icon style="color: silver">share</mat-icon></button>&nbsp;<button mat-icon-button *ngIf="!settingsActive" (click)="settingsActive=true"><mat-icon style="color: silver">settings</mat-icon></button>
    <br/>
  </div>
  <!-- projects controls -->
  <div *ngIf="null != project && project.id !== '' && settingsActive" [@settings]>
    <div *ngIf="project.archive"><span style="color: #D26927">{{ 'PERSONAL_PROJECTS.ARCHIVE.NOT_VISIBLE' | translate}}</span></div>
    <form align="left" [formGroup]="projectGroup" (ngSubmit)="saveProjectOnSubmit()">
      <button mat-icon-button *ngIf="settingsActive" (click)="settingsActive=false"><mat-icon style="color: silver">settings</mat-icon></button>
      &nbsp;&nbsp;
      <mat-form-field style="width: 50px;">
        <input
        matInput
        (input)="projectGroup.get('shortName').setValue(projectGroup.get('shortName').value.toString().toUpperCase())"
        formControlName="shortName" required
        >
      </mat-form-field>
      &nbsp;
      <mat-form-field>
        <input
        matInput
        formControlName="fullName" required
        >
      </mat-form-field>
      <button mat-button [disabled]="projectGroup.valid && project.shortName === projectGroup.get('shortName').value && project.name === projectGroup.get('fullName').value" type="submit" color="primary">
        {{ 'PERSONAL_PROJECTS.ARCHIVE.SAVE' | translate}}
      </button>
      <button mat-button *ngIf="!project.archive" color="accent" (click)="archiveProject(project)">
        {{ 'PERSONAL_PROJECTS.ARCHIVE.ZIP_PROJECT' | translate}}
      </button>
      <button mat-button *ngIf="project.archive" color="primary" (click)="unzipProject(project)">
        {{ 'PERSONAL_PROJECTS.ARCHIVE.UNZIP_PROJECT' | translate}}
      </button>
      <button mat-button color="warn" (click)="removeProject(project)">
        {{ 'PERSONAL_PROJECTS.ARCHIVE.REMOVE_PROJECT' | translate}}
      </button>
    </form>
  </div>

</div>

<div align="center" style="overflow: scroll;">
  <div *ngIf="null != project && project.id.length > 0 else noproject">
<!--    <hr/>-->
    <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="projectBusinessProcessTabIndex">
      <mat-tab [label]=" 'PERSONAL_PROJECTS.BP.PROJECT' | translate" >
        <div cdkDropListGroup [class]="wrapperClass">
          <div [class]="containerClass">
            <h2>{{ 'PERSONAL_PROJECTS.SECTIONS.BACKLOG' | translate}}</h2>
<!--            {{ 'PERSONAL_PROJECTS.SECTIONS.ONLY_HERE' | translate}}-->
            <div class="binding-statuses">
              <mat-chip-list aria-label="Backlog tickets">
                <mat-chip class="small-chip" style="background-color: darkviolet;" selected>{{ 'PERSONAL_PROJECTS.SECTIONS.ONLY_HERE' | translate}}</mat-chip>
              </mat-chip-list>
            </div>
            <div
              cdkDropList
              [cdkDropListData]="personService.projectTicketsBacklog"
              class="tickets-list"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event, 'backlog')">
              <div
                align="left"
                (click)="openDialog(item)"
                class="ticket-box grey-important dark-text-important"
                *ngFor="let item of personService.projectTicketsBacklog"
                cdkDrag>
                <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>
                {{item.ttext}}
                <div style="word-break: break-all;" *ngIf="item.businessProcessStep!= null && item.businessProcessStep.length > 0">
                  <br/>
                  <span style="background-color: darkorange; color: white;">↑</span>&nbsp;
                  <span style="color: darkorange;">{{item.businessProcessStep}}</span>
                </div>
              </div>
            </div>
          </div>

          <div [class]="containerClass">
            <h2>{{ 'PERSONAL_PROJECTS.SECTIONS.TODO' | translate}}</h2>
<!--            {{ 'PERSONAL_PROJECTS.SECTIONS.LATER_AUTO' | translate}}-->
            <div class="binding-statuses">
              <mat-chip-list aria-label="To-do tickets" [(ngModel)]="todoChipsList">
                <mat-chip (click)="todoChipsList = 'tomorrow'" [value]="'tomorrow'"  class="small-chip">{{ 'TODAY.TICKET.STATUS.TOMORROW' | translate}}</mat-chip>
                <mat-chip (click)="todoChipsList = 'later'" [value]="'later'" class="small-chip" selected>{{ 'TODAY.TICKET.STATUS.LATER' | translate}}</mat-chip>
                <mat-chip (click)="todoChipsList = 'idea'" [value]="'idea'" class="small-chip">{{ 'TODAY.TICKET.STATUS.IDEA' | translate}}</mat-chip>
                <mat-chip [value]="'date'" class="small-chip" disabled>{{ 'TODAY.TICKET.STATUS.DATE' | translate}}</mat-chip>
              </mat-chip-list>
            </div>
            <div
              cdkDropList
              [cdkDropListData]="personService.projectTicketsTodo"
              class="tickets-list"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event, 'todo')">
              <div
                align="left"
                (click)="openDialog(item)"
                class="ticket-box grey-important dark-text-important"
                *ngFor="let item of personService.projectTicketsTodo"
                cdkDrag>
                <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>
                {{item.ttext}}
                <div style="word-break: break-all;" *ngIf="item.businessProcessStep!= null && item.businessProcessStep.length > 0">
                  <br/>
                  <span style="background-color: darkorange; color: white;">↑</span>&nbsp;<span style="color: darkorange;">{{item.businessProcessStep}}</span>
                </div>
              </div>
            </div>
          </div>

          <div [class]="containerClass">
            <h2 style="color: #03a9f4">{{ 'PERSONAL_PROJECTS.SECTIONS.PROGRESS' | translate}}</h2>
<!--            {{ 'PERSONAL_PROJECTS.SECTIONS.TODAY_LIST' | translate}}-->
            <div class="binding-statuses">
              <mat-chip-list aria-label="Today tickets">
                <mat-chip class="small-chip" selected>{{ 'TODAY.TICKET.STATUS.TODAY' | translate}}</mat-chip>
              </mat-chip-list>
            </div>
            <div
              cdkDropList
              [cdkDropListData]="personService.projectTicketsToday"
              class="tickets-list"
              (cdkDropListDropped)="drop($event, 'progress')">
              <div
                align="left"
                (click)="openDialog(item)"
                class="ticket-box grey-important dark-text-important"
                *ngFor="let item of personService.projectTicketsToday"
                cdkDrag>
<!--                <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>-->
                {{item.ttext}}
                <div style="word-break: break-all;" *ngIf="item.businessProcessStep!= null && item.businessProcessStep.length > 0">
                  <br/>
                  <span style="background-color: darkorange; color: white;">↑</span>&nbsp;<span style="color: darkorange;">{{item.businessProcessStep}}</span>
                </div>
              </div>
            </div>
          </div>

          <div [class]="containerClass">
            <h2>{{ 'PERSONAL_PROJECTS.SECTIONS.COMPLETED' | translate}}</h2>
            <div class="binding-statuses">
              <mat-chip-list aria-label="Finished tickets" [(ngModel)]="completedChipsList">
                <mat-chip class="small-chip" (click)="completedChipsList = 'done'" [value]="'done'" >{{ 'TODAY.TICKET.STATUS.DONE' | translate}}</mat-chip>
                <mat-chip class="small-chip" (click)="completedChipsList = 'cancelled'" [value]="'cancelled'" >{{ 'TODAY.TICKET.STATUS.CANCELLED' | translate}}</mat-chip>
              </mat-chip-list>
            </div>
            <div
              cdkDropList
              [cdkDropListData]="personService.projectTicketsCompleted"
              class="tickets-list"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event, 'completed')">
              <div
                align="left"
                (click)="openDialog(item)"
                class="ticket-box grey-important dark-text-important"
                *ngFor="let item of personService.projectTicketsCompleted"
                cdkDrag>
                <app-ticket-status-marker [ticket]="item"></app-ticket-status-marker>
                {{item.ttext}}
                <div style="word-break: break-all;" *ngIf="item.businessProcessStep!= null && item.businessProcessStep.length > 0">
                  <br/>
                  <span style="background-color: darkorange; color: white;">↑</span>&nbsp;<span style="color: darkorange;">{{item.businessProcessStep}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br/>
        <br/>
        <br/>
      </mat-tab>
      <mat-tab [label]=" 'PERSONAL_PROJECTS.BP.TITLE' | translate ">
        <br/>
        <i>{{'PERSONAL_PROJECTS.BP.INSTRUCTION' | translate}}</i>
        <br/>
        <br/>
        <form class="example-form">
          <mat-form-field class="example-full-width">
            <mat-label>{{'PERSONAL_PROJECTS.BP.STEP' | translate}}</mat-label>
            <input type="text" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                   placeholder="scenario step">
            <mat-hint>{{'PERSONAL_PROJECTS.BP.ERRORS' | translate}}</mat-hint>
<!--            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">-->
<!--              Please enter a valid email address-->
<!--            </mat-error>-->
            <mat-error *ngIf="emailFormControl.hasError('required')">
              {{'PERSONAL_PROJECTS.BP.STEP_IS' | translate}} <strong>{{'PERSONAL_PROJECTS.BP.REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>
          &nbsp;
          <button mat-raised-button color="primary" (click)="addStep()">{{'PERSONAL_PROJECTS.BP.ADD_STEP' | translate}}</button>
        </form>
        <br/>
        <button mat-raised-button color="warn" (click)="removeStep()">{{'PERSONAL_PROJECTS.BP.REMOVE_STEP' | translate}}</button>

        <br/>
        <br/>
        <br/>

        <p>
          <span class="step" *ngFor="let step of steps; let i = index">
            <span class="business-process">
            {{step}}
            </span>
            <br/>
            {{i !== steps.length - 1 ? '↓' : ''}}
            <br/>
          </span>
        </p>

        <div *ngIf="steps.length === 0">{{'PERSONAL_PROJECTS.BP.NO_BP' | translate}}</div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

      </mat-tab>
    </mat-tab-group>


  </div>


  <!--  chrome fix: text-align-last:center;-->
  <ng-template #noproject>
    <br/>
    {{ 'PERSONAL_PROJECTS.NO_SELECTED' | translate}}
  </ng-template>
</div>
