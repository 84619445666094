<br/>
<div class="center" align="center">
  <form class="example-form">
    <mat-form-field class="cost-full-width">
      <mat-label>{{ 'PEOPLE.ADD' | translate }}</mat-label>
      <input
        matInput
        placeholder="By email"
        [(ngModel)]="emailOfPerson"
        [ngModelOptions]="{standalone: true}"
      >
    </mat-form-field>
    <mat-radio-group [(ngModel)]="relationsType"
                     (change)="typeOfRelations()"
                     aria-label="Relations"
                     [ngModelOptions]="{standalone: true}"
                     class="relations-radio-group"
    >
      <mat-radio-button class="relations-radio-button" color="primary" value="friend">{{ 'PEOPLE.FRIEND' | translate }}</mat-radio-button>
      <mat-radio-button class="relations-radio-button" color="accent" value="contact">{{ 'PEOPLE.CONTACT' | translate }}</mat-radio-button>
      <mat-radio-button class="relations-radio-button" color="warn" value="opponent">{{ 'PEOPLE.OPPONENT' | translate }}</mat-radio-button>
    </mat-radio-group>
    <button
      [disabled]="emailOfPerson.length === 0"
      (click)="addPersonByEmail()"
      mat-stroked-button
      color="primary"
    >{{ 'PEOPLE.OKAY' | translate }}</button>
  </form>
  <br/><br/>
<!--  todo: create person later-->
<!--  <button mat-stroked-button>Create person</button>-->
<!--  <br/>-->
<!--  <button (click)="explain()" mat-button>explanation</button>-->
<!--  <br/>-->
<!--  <p class="explain">-->
<!--    <i>{{explanationText}}</i>-->
<!--  </p>-->
  <br/>
  <p *ngIf="personService.persons.length === 0">
    <b>{{ 'PEOPLE.NO_PEOPLE' | translate }}</b>
  </p>
<!--  <div *ngFor="let person of persons">-->
<!--  </div>-->
  <table  *ngIf="personService.persons.length > 0">
    <tr class="person-row" *ngFor="let person of personService.persons" style="border: 1px solid lightblue;">
      <td>
        <img
          style="width: 30px; height: 30px; border-radius: 50%; object-fit: cover;"
          [src]="(person.ava != null && person.ava.length >0) ? person.ava: '/assets/images/logos/logo.jpg'" />
      </td>
      <td>{{person.firstName}}</td>
      <td>{{person.lastName}}</td>
      <td *ngIf="person.status === 'accepted' || person.status === 'sent'">[{{person.type}}]</td>
      <td *ngIf="person.status === 'await'">
        <select [(ngModel)]="person.tmpType">
          <option value="friend" [selected]="true">{{ 'PEOPLE.FRIEND' | translate }}</option>
          <option value="contact">{{ 'PEOPLE.CONTACT' | translate }}</option>
          <option value="opponent">{{ 'PEOPLE.OPPONENT' | translate }}</option>
        </select>
      </td>
      <td *ngIf="person.status === 'accepted'">
        <button mat-button color="primary" (click)="remove(person)">{{ 'PEOPLE.REMOVE' | translate }}</button>
      </td>
      <td *ngIf="person.status === 'sent'">
        <button mat-mini-fab style="width: fit-content;" color="primary" [disabled]="true">{{ 'PEOPLE.SENT' | translate }}</button>
      </td>
      <td *ngIf="person.status === 'await'">
        <button mat-button color="primary" (click)="approve(person)">{{ 'PEOPLE.APPROVE' | translate }}</button>
      </td>
    </tr>
  </table>
</div>
