import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.scss']
})
export class CoachComponent implements OnInit {

  allToToday = false;
  upToDate = false;
  morningPlanning = false;
  eveningPlanning = false;
  commandCenter = false;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
