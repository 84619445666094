import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {transition, trigger, useAnimation} from "@angular/animations";
import {bounceInDown, bounceInUp, flip, tada} from "ng-animate";

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
  animations: [
    trigger('nettyos', [
      transition('void => *', useAnimation(tada))
    ]),
    trigger('tour', [
      transition('void => *', useAnimation(bounceInDown), {
        params: { timing: 3}
      })
    ])
  ]
})
export class TourComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
