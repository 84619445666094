<div style="width: 96%; margin: auto;" align="center">
  <app-solutions-header></app-solutions-header>
  <h1 *ngIf="translate.defaultLang === 'ru'">Контакты</h1>
  <h1 *ngIf="translate.defaultLang === 'en'">Contacts</h1>
  <br/>
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'ru'" class="text" align="left">
    <p>
      ИНН:780160096004, Никита Михайлович Данилов
      <br/>
      <br/>
      По общим вопросам: info@nettyos.com
      <br/>
      <br/>
      По вопросам оплаты: payment@nettyos.com
      <br/>
      <br/>
      Nettyos интуитивно прост и понятен для любой цели.
      <br/>
      Перейдите в <a [routerLink]="'/public-guide'">Nettyos Гайд</a> для решения своего вопроса
      <br/>
      <br/>
    </p>
    <ul>
      <li>Nettyos оснащен подсказками (подробнее в <a [routerLink]="'/public-guide'">Nettyos Гайд</a>)</li>
      <li>Nettyos оснащен видеокурсом (подробнее в <a [routerLink]="'/public-guide'">Nettyos Гайд</a>)</li>
      <li>Nettyos оснащен Ассистентом Nettyos (подробнее в <a [routerLink]="'/public-guide'">Nettyos Гайд</a>)</li>
    </ul>
    <br/>
    <br/>
    Если проблема не решена - то обработка сообщения займет до 48 часов.
  </div>
  <div *ngIf = "translate.defaultLang === 'en'" class="text" align="left">
<!--
ИНН (Идентификационный Номер Налогоплательщика) — ITN (Indi­vid­ual Tax­pay­er Num­ber) – для физических лиц и TIN (Tax­pay­er Iden­ti­fi­ca­tion Num­ber – для юридических лиц;
Источник: https://englishfull.ru/znat/inn-po-angliyski.html
-->
    <p>
      ITN:780160096004, Nikita Mikhailovich Danilov
      <br/>
      <br/>
      For general inquiries: info@nettyos.com
      <br/>
      <br/>
      For payment questions: payment@nettyos.com
      <br/>
      <br/>
      Nettyos is intuitive and straightforward for any purpose.
      <br/>
      Go to <a [routerLink]="'/public-guide'"> Nettyos Guide </a> to resolve your question
      <br/>
      <br/>
    </p>
    <ul>
      <li> Nettyos is equipped with hints (more in <a [routerLink]="'/public-guide'"> Nettyos Guide </a>) </li>
      <li> Nettyos is equipped with a video course (more in <a [routerLink]="'/public-guide'"> Nettyos Guide </a>) </li>
      <li> Nettyos is equipped with Nettyos Assistant (for more details see <a [routerLink]="'/public-guide'"> Nettyos Guide </a>) </li>
    </ul>
    <br/>
    <br/>
    If the problem is not resolved, the message processing will take up to 48 hours.
  </div>
  <br/>
  <br/>
  <br/>
  <br/>
</div>
