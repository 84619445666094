import {Component, Input, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [
    trigger('money', [
      transition('small => big', [
        style({ width: '300px'}),
        animate(1500)
      ]),
      transition('small => side', [
        style({ width: '200px'}),
        animate(1500)
      ])
    ]),
    trigger('moneyprev', [
      transition('small => big', [
        style({ transform: 'translateX(-1000%)' }),
        animate(300)
      ]),
      transition('small => side', [
        style({ transform: 'translateX(-300%)' }),
        animate(500)
      ])
    ]),
    trigger('agree', [
      transition('void => *', [
        style({ transform: 'translateX(-300%)' }),
        animate(500)
      ])
    ])
  ]
})
export class PaymentComponent implements OnInit {

  USD10K = 'small';
  USD1000 = 'small';
  USD100 = 'small';
  USD50 = 'small';
  USD20 = 'small';
  USD10 = 'small';

  @Input() animationType = 'big';
  @Input() pclass = 'center';
  agree = false;
  alert = '';
  // if tsFlag less - okay
  tsFlagAllow = 0;

  constructor() { }

  ngOnInit(): void {
  }

  checkAgree() {
    this.alert = 'Check agree to follow the links';
    this.tsFlagAllow = new Date().getTime() + 3000;
    setTimeout(() => {
      if (new Date().getTime() > this.tsFlagAllow) {
        this.alert = '';
      }
      // else other set up will handle
    }, 3100);
  }

  resetAll() {
    this.USD10K = 'small';
    this.USD1000 = 'small';
    this.USD100 = 'small';
    this.USD50 = 'small';
    this.USD20 = 'small';
    this.USD10 = 'small';
  }

}
