import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {GatewayService, Ticket} from "../../../../services/gateway.service";
import {PersonService} from "../../../../services/person.service";
import {consoleTestResultHandler} from "tslint/lib/test";
import {DatePipe} from "@angular/common";
import {FormControl, Validators} from "@angular/forms";
import {MyErrorStateMatcher} from "../../projects/projects.component";
import {UserComment} from "../../../../dto/Comment";
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {
  bounce,
  bounceIn,
  bounceInLeft,
  bounceInRight,
  bounceInUp,
  bounceOutRight,
  bounceOutUp, jello, pulse, rollIn, rollOut, tada, zoomIn,
  zoomOut
} from "ng-animate";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {afterMethod} from "kaop-ts";
import {AOPDialogTooltipAnswer} from "../../../user/today/today.component";
import {GuideDialogComponent} from "../../../help/guide-dialog/guide-dialog.component";

export interface DialogData {
  animal: string;
  name: string;
}

export class AOPDialogTooltipAnswerTD {
  // all meta https://github.com/k1r0s/kaop-ts/blob/master/docs/api.md#as-an-alias
  static answer(meta) {
    // console.log('meta.key', meta.key);
    // console.log('Scope: ', meta.scope.personService.giveDialogTooltipAnswer);
    const flagShowAnswer = meta.scope.personService.giveDialogTooltipAnswer;
    if (flagShowAnswer) {
      switch (meta.key) {
        case 'addDescriptionInDialogT' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.DESCRIPTION.ADD').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'openCommentsDT' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.OPEN_COMMENTS').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'checkListAddStartDT' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.CHECK_LIST').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        default:
      }
    }
    // any action should set flag off
    meta.scope.personService.giveDialogTooltipAnswer = false;
  }
}


@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
  animations: [
    trigger('comments', [
      transition('void => *', useAnimation(bounceInUp)),
      transition('* => void', useAnimation(bounceOutUp))
    ]),
    trigger('settings', [
      transition('void => *', useAnimation(zoomIn)),
      transition('* => void', useAnimation(zoomOut))
    ])
  ]
})
export class TicketDialogComponent implements OnInit {

  assigned: string;
  comments: UserComment [] = [];
  commentsFlag = false;
  commentsNumber = 0;
  settingsOpened = false;

  addNewCommentFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(200)
  ]);
  matcher = new MyErrorStateMatcher();

  epics = new FormControl();
  sprints = new FormControl();


  constructor(
    public personService: PersonService,
    public dialogRef: MatDialogRef<TicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public ticket: Ticket,
    private gateway: GatewayService,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    // error in the template
    ticket.checkListDone = [];
    ticket.checkListActual = [];
  }

  ngOnInit(): void {
    // console.log(this.data);
    // console.log(this.assigned);
    this.loadTicket();
    this.getCommentsByTicket();
  }

  loadTicket() {
    this.personService.loading = true;
    this.gateway.planningServiceLoadFullTicket(this.ticket).subscribe(fullTicket => {
      this.personService.currentDialogTicket = fullTicket;
      this.ticket = fullTicket;
      this.epics.setValue(this.ticket.epic);
      this.sprints.setValue(this.ticket.sprint);
      //console.log(fullTicket);
      this.assigned = this.ticket.userHasGotId;
      this.personService.loading = false;
      this.checkListAddingFlag = false;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  includes(userId: string) {
    return this.personService.teamSelected.members.find(p => p.userId === userId)
  }

  findName(userId: string) {
    let user = this.personService.teamSelected.members.find(p => p.userId === userId);
    if (null != user)
      return user.firstName + ' ' + user.secondName;
  }

  changeMember() {
    this.personService.loading = true;
    //console.log('this.assigned', this.assigned);
    this.gateway.planningServiceAssignNewTeamMember(this.assigned, this.ticket).subscribe(data => {
      this.personService.loading = false;
      // this.data.userHasGotId = this.assigned;
    });
  }

  randomString() {
    const length = 5;
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  // copy from today component
  getTextWithLinks(actualDescription: string, fullDescription) {
    // the goal to add symbol charCode=10 with spaces
    let newDescription = '';
    let counter = 0;
    for (let i=0; i < fullDescription.length; i++) {
      if (fullDescription.charCodeAt(i) !== 10) {
        newDescription += fullDescription.charAt(i);
      } else {
        newDescription += ' ' + fullDescription.charAt(i) + ' ';
        counter-=2;
      }
    }
    let wordsArray = newDescription.split(' ');
    let finalWords = [];
    // only non cut words and links
    for (let word of wordsArray) {
      if (counter <= actualDescription.length) {
        finalWords.push(word);
        counter+= word.length + 1;
      }
    }
    return finalWords;
  }

  // copy from today component todo: make one component
  pureLink(wordOrLink: string) {
    while (wordOrLink.charAt(wordOrLink.length - 1) === ',' ||
      wordOrLink.charAt(wordOrLink.length - 1) === ';' ||
      wordOrLink.charAt(wordOrLink.length - 1) === ':' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '-' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '—' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '.'
      ) {
      if (wordOrLink.length > 1) {
        wordOrLink = wordOrLink.substring(0, wordOrLink.length - 2)
      }
    }
    return wordOrLink;
  }

  // copy from today component todo: make one component
  setOptionUnchecked(ticket: Ticket, optionDone: string) {
    this.personService.loading = true;
    this.gateway.planningServiceSetCheckListOptionUncheckedToTicket(ticket, optionDone).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListDone.indexOf(optionDone, 0);
        if (index > -1) {
          ticket.checkListDone.splice(index, 1);
        }
        ticket.checkListActual.push(optionDone);
      }
      // console.log('newCheckListOption', data);
    });
  }

  // copy from today component todo: make one component
  setOptionDone(ticket: Ticket, optionActual: string) {
    this.personService.loading = true;
    this.gateway.planningServiceSetCheckListOptionCheckedToTicket(ticket, optionActual).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListActual.indexOf(optionActual, 0);
        if (index > -1) {
          ticket.checkListActual.splice(index, 1);
        }
        ticket.checkListDone.push(optionActual);
      }
      // console.log('newCheckListOption', data);
    });
  }

  // copy from today component todo: make one component
  deleteOptionCheckList(ticket: Ticket, optionToDelete: string) {
    this.personService.loading = true;
    this.gateway.planningServiceDeleteCheckListOptionInTicket(ticket, optionToDelete).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListDone.indexOf(optionToDelete, 0);
        if (index > -1) {
          ticket.checkListDone.splice(index, 1);
        }
        const index1 = ticket.checkListActual.indexOf(optionToDelete, 0);
        if (index1 > -1) {
          ticket.checkListActual.splice(index1, 1);
        }
      }
      // console.log('newCheckListOption', data);
    });
  }

  // copy from today
  checkListAddingFlag = false;
  placeChoosen() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
  }

  @afterMethod(AOPDialogTooltipAnswerTD.answer)
  checkListAddStartDT() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    this.checkListAddingFlag = true;
    this.ticket.checkListAddFlag = true;
  }

  // no loader

  newDate(tsOfPlannedDate: number) {
    return new Date(tsOfPlannedDate);
  }

  ifNotToday(ticket: any) {
    let localDateInTicket = new Date(ticket.tsOfPlannedDate);
    // console.log(ticket, this.datePipe.transform(localDateInTicket,"yyyy-MM-dd"));
    if (this.datePipe.transform(localDateInTicket,"yyyy-MM-dd") === this.datePipe.transform(new Date(),"yyyy-MM-dd")) {
      // today date
      return false;
    } else if (ticket.tsOfPlannedDate < new Date().getTime()) {
      // expired
      return false;
    }
    else {
      return true;
    }
  }

  addNewComment() {
    const commentText = this.addNewCommentFormControl.value;
    let comment: UserComment = {
      type: undefined,
      userAva: this.personService.profile.ava,
      userName: this.personService.profile.fname,
      userSerName: this.personService.profile.lname,
      commentText: commentText,
      commentTs: new Date().getTime(),
      id: "",
      mainUserId: "",
      ticketId: this.ticket.uuid,
      auth: ""
    };
    this.personService.loading = true;
    this.gateway.userServiceAddNewComment(comment).subscribe(data => {
      this.personService.loading = false;

      // reset form
      this.addNewCommentFormControl.reset();
      this.addNewCommentFormControl.markAsPristine();
      this.addNewCommentFormControl.markAllAsTouched();
      this.addNewCommentFormControl.updateValueAndValidity();
      this.addNewCommentFormControl.setErrors(null);

      this.getCommentsByTicket();
    });
  }

  getCommentsByTicket() {
    // comments/by/ticket/{auth}/{ticketId}
    this.personService.loading = true;
    this.gateway.userServiceGetCommentsByTicket(this.ticket).subscribe(data => {
      // console.log(data);
      this.comments = data;

      this.personService.loading = false;
    });
  }

  deleteComment(cmm: UserComment) {
    this.personService.loading = true;
    this.gateway.userServiceDeleteComment(cmm, this.ticket).subscribe(data => {
      this.personService.loading = false;
      this.getCommentsByTicket();
    });
  }

  setCommentsNumber(comments: UserComment[]) {
    this.comments = comments;
  }

  epicOptionSelected($event: any) {
    // console.log($event)
    this.personService.loading = true;
    this.gateway.planningServiceAssignEpic2Ticket(this.ticket, $event).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        this.sprints.setValue('');
      }
    });

  }

  sprintOptionSelected($event: any) {
    this.personService.loading = true;
    this.gateway.planningServiceAssignSprint2Ticket(this.ticket, $event).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        this.epics.setValue('');
      }
    });
  }

  settings() {
    this.settingsOpened = !this.settingsOpened;
  }

  openSnackBar() {
    if (!this.personService.giveDialogTooltipAnswer) {
      let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
      let verticalPosition: MatSnackBarVerticalPosition = 'bottom';
      // console.log('_snackBar', this._snackBar);
      this.translate.get('GUIDE.NETTYOS_GUIDE_MESSAGE').subscribe(data => {
        this._snackBar.open(data, 'Okay', {
          duration: 5000,
          // panelClass: ['dark-theme'],
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition,
        });
      });
    }
  }

  @afterMethod(AOPDialogTooltipAnswerTD.answer)
  addDescriptionInDialogT() {
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    this.ticket.descriptionAddFlag = true;
  }

  public openDialog(data): void {

    //console.log(ticket);

    const dialogRef = this.dialog.open(GuideDialogComponent, {
      width: '250px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
    });
  }

  @afterMethod(AOPDialogTooltipAnswerTD.answer)
  openCommentsDT() {
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    this.commentsFlag = !this.commentsFlag
  }
}
