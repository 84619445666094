<br/>
<br/><br/>

<div style="width: 90%; margin: auto;" align="center">

  <mat-tab-group [(selectedIndex)]="tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{'MENU.TEAMS' | translate}}</span>
        <span *ngIf="teamName !== ''">: {{teamName}}</span>
      </ng-template>
      <br/>
      <br/>
      <br/>
      <form>
        <mat-form-field class="cost-full-width">
          <mat-label>{{ 'TEAMS.NEW' | translate}}</mat-label>
          <input
            matInput
            [placeholder]="'TEAMS.TEAM_NAME' | translate"
            style="width: 200px;"
            [formControl]="teamNameFormControl"
            [errorStateMatcher]="matcher"
          >
        </mat-form-field>
        &nbsp;
        <button mat-stroked-button (click)="createTeam()" color="primary">{{ 'TEAMS.CREATE' | translate}}</button>
      </form>
      <!--  <p>my teams</p>-->
      <br/><br/>

<!--      <select matNativeControl-->
<!--              (change)="chooseTeam()"-->
<!--              [(ngModel)]="teamId"-->
<!--              style="width: fit-content; text-align-last:center;"-->
<!--              required>-->
<!--        <option value="">{{ 'TEAMS.CHOOSE' | translate}}</option>-->
<!--        <option [value]="team.id" *ngFor="let team of personService.teams">-->
<!--          {{team.name}}-->
<!--        </option>-->
<!--      </select>-->

      <mat-form-field>
        <mat-select
          (selectionChange)="chooseTeam()"
          [(ngModel)]="teamId"
          >
          <mat-option value="">{{ 'TEAMS.CHOOSE' | translate}}</mat-option>
          <mat-option [value]="team.id" *ngFor="let team of personService.teams">
            {{team.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <br/>
<!--      <form (ngSubmit)="changeTeamName()">-->
<!--        <mat-form-field>-->
<!--          <input-->
<!--            matInput-->
<!--            [formControl]="newTeamNameCtrl"-->
<!--          />-->
<!--        </mat-form-field>-->
<!--        <mat-form-field>-->
<!--          <button type="submit" mat-stroked-button>Change name</button>-->
<!--        </mat-form-field>-->
<!--      </form>-->


      <form>
        <mat-form-field class="cost-full-width">
          <mat-label>{{ 'TEAMS.TEAM.NEW_NAME' | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'TEAMS.TEAM_NAME' | translate"
            style="width: 200px;"
            [(ngModel)]="personService.teamSelected.name"
            [formControl]="newTeamNameFormControl"
            [errorStateMatcher]="matcher"
          >
        </mat-form-field>
        &nbsp;
        <button mat-stroked-button (click)="updateTeamName()" color="primary">
          {{ 'TEAMS.TEAM.CHANGE_NAME' | translate }}
        </button>
      </form>

      <br/>
      <br/>
      <div *ngIf="teamId !== ''" align="left">
        <button (click)="tabs = 1;" mat-button color="primary">{{ 'TEAMS.TEAM.PROJECTS.TEAMS_PROJECTS' | translate }} >></button>
        <br/>
        <br/>
        <button (click)="tabs = 2;" mat-button color="primary">{{ 'TEAMS.TEAM.MEMBERS' | translate }} >></button>
      </div>
      <br/>
      <br/>
    </mat-tab>
    <mat-tab [label]="'TEAMS.TEAM.PROJECTS.TEAMS_PROJECTS' | translate">
      <br/>
      <br/>
      <br/>
      <ng-template #noTeamsProjects>
        {{ 'TEAMS.NO_CHOSEN' | translate }}
        <br/>
        <br/>
        <button (click)="tabs = 0;" mat-button color="primary">{{ 'TEAMS.TEAM.SELECT' | translate }} >></button>
      </ng-template>

      <div *ngIf="teamId !== '' else noTeamsProjects">

        <div>
          <i>"{{ 'TEAMS.TEAM.PROJECTS.ADD_PROJECT_NOTE' | translate }}"</i>
          <br/>
          <br/>
          <form align="center">
            [
            <mat-form-field style="width: 80px;">
              <!--      <mat-label>Project short name</mat-label>-->
              <input
                matInput
                (keyup)="fix3Symbols()"
                [placeholder]="'TEAMS.TEAM.PROJECTS.SHORT' | translate"
                [(ngModel)]="projectShortName"
                [ngModelOptions]="{standalone: true}"
              >
            </mat-form-field>
            ]&nbsp;
            <mat-form-field style="width: 200px;">
              <mat-label>{{ 'TEAMS.TEAM.PROJECTS.NEW_PROJECT' | translate }}</mat-label>
              <input
                matInput
                placeholder="Project full name"
                [(ngModel)]="projectFullName"
                (keyup)="generateShortName()"
                [ngModelOptions]="{standalone: true}"
              >
            </mat-form-field>
            <br/>
            <button
              [disabled]="projectFullName.length < 3 || projectShortName.length !== 3"
              (click)="addProject()"
              mat-stroked-button
              color="primary"
            >Okay
            </button>
          </form>
        </div>
        <br/>
        <br/>
        <table>
          <tr *ngFor="let project of personService.teamProjects">
            <td>[{{project.shortName}}]</td>
            <td>{{project.name}}</td>
            <td>
              <button [routerLink]="'/team-project'" [queryParams]="{id: project.id}" mat-button color="primary">
                {{ 'TEAMS.TEAM.PROJECTS.GO' | translate }} >></button>
            </td>
          </tr>
        </table>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>

    </mat-tab>
    <mat-tab [label]="'TEAMS.TEAM.MEMBERS' | translate">

      <br/>
      <br/>
      <br/>
      <div style="color: red;" [innerHTML]="alert"></div>

      <ng-template #noTeamsMembers>
        {{ 'TEAMS.NO_CHOSEN' | translate }}
        <br/>
        <br/>
        <button (click)="tabs = 0;" mat-button color="primary">{{ 'TEAMS.TEAM.SELECT' | translate }} >></button>
      </ng-template>

      <div *ngIf="teamId !== '' else noTeamsMembers">
        <mat-form-field class="cost-full-width">
          <mat-label>{{ 'TEAMS.TEAM.NEW_MEMBER' | translate }}</mat-label>
          <input matInput [placeholder]="'TEAMS.TEAM.ENTER_EMAIL' | translate" [(ngModel)]="memberEmail">
        </mat-form-field>
<!--        <br/>-->
        &nbsp;
        <button mat-stroked-button (click)="addTeamMember()" color="primary">{{ 'TEAMS.TEAM.ADD_MEMBER' | translate }}</button>
        <br/>
        <!--    Team name: {{teamSelected.name}}-->
        <br/><br/>
        <table>
          <tr>
            <th>{{ 'TEAMS.TEAM.FIRST_NAME' | translate }}</th>
            <th>{{ 'TEAMS.TEAM.SECOND_NAME' | translate }}</th>
            <th>{{ 'TEAMS.TEAM.ROLE' | translate }}</th>
            <th>{{ 'TEAMS.TEAM.ACTIONS' | translate }}</th>
          </tr>
          <tr *ngFor="let member of personService.teamSelected.members">
            <!--        <td>{{member.userId}}</td>-->
            <td>{{member.firstName}}</td>
            <td>{{member.secondName}}</td>
            <td>{{member.role}}</td>
            <td>
              <button mat-button color="warn" (click)="removeMember(member, teamId)">{{ 'TEAMS.TEAM.REMOVE' | translate }}</button>
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>


</div>
