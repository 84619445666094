import { Component, OnInit } from '@angular/core';
import {GatewayService, Profile} from "../../../services/gateway.service";

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit {

  constructor(private gatewayService: GatewayService) { }

  users: Profile [] = [
    {
      account: "acc",
      accountExpirationDate: "",
      expirationDateType: "",
      auth: "",
      ava: "",
      conf: "",
      email: "em",
      fname: "fn",
      goldCoin: 10,
      lname: "ln",
      silverCoin: 12,
      userId: "",
      tsCreated: 1
    }
  ];
  account = 'free plan';
  expirationDate = 'month';

  ngOnInit(): void {
    this.loadAll();
  }

  loadAll() {
    this.gatewayService.allAccountsControlService().subscribe(data => {
      // console.log(data);
      data.sort((a, b) => {
        return  <any>new Date(a.tsCreated) - <any>new Date(b.tsCreated);
      });
      this.users = data;
    });
  }

  changeAccount(user: Profile) {
    this.gatewayService.changeAccountControlService(user, this.expirationDate).subscribe(data => {
      console.log(data);
      this.loadAll();
      alert(data != null ? data.result: 'no');
    });
  }
}
