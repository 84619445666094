import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {VersionService} from "../../../../services/version.service";
import {transition, trigger, useAnimation} from "@angular/animations";
import {flip, tada} from "ng-animate";

@Component({
  selector: 'app-solutions-header',
  templateUrl: './solutions-header.component.html',
  styleUrls: ['./solutions-header.component.scss'],
  animations: [
    trigger('nettyos', [
      transition('void => *', useAnimation(tada))
    ]),
  ]
})
export class SolutionsHeaderComponent implements OnInit {

  constructor(public translate: TranslateService, public versionService: VersionService) { }

  ngOnInit(): void {
  }

}
