<div *ngIf="assigned && (ticket.businessProcessStep == null || ticket.businessProcessStep.length === 0)" align="center">
  <button mat-button
          (click)="assignFirstBusinessStep(ticket)"
          color="primary"
  ><< {{'PERSONAL_PROJECTS.BP.ASSIGN' | translate}} >></button>
</div>

<div *ngIf="assigned && ticket.businessProcessStep != null && ticket.businessProcessStep.length > 0" align="center">
  <span style="color: darkorange;">{{'PERSONAL_PROJECTS.BP.BP' | translate}}</span>
  <br/>
  <button mat-mini-fab color="accent" [disabled]="!isPreviousStep(ticket)" (click)="movePreviousStep(ticket)"><<</button>
  &nbsp;&nbsp;
  <a *ngIf="!dialogFlag && type === 'personal'" [routerLink]="'/projects'" [queryParams]="{id: ticket.projectId, tab: '1'}"
     class="business-process">{{ticket.businessProcessStep}}
  </a>
  <a [routerLink]="'/projects'"
     *ngIf="dialogFlag && type === 'personal'"
     (click)="onNoClick()"
     [queryParams]="{id: ticket.projectId, tab: '1', rnd: randomString()}"
     style="background-color: darkorange; color: #03a9f4;">{{ticket.businessProcessStep}}
  </a>
<!--  teams in today list-->
  <span *ngIf="!dialogFlag && type !== 'personal'" class="business-process">
   {{ticket.businessProcessStep}}
  </span>
<!--  teams in dialog ticket-->
  <span *ngIf="dialogFlag && type === 'team'" class="business-process">
   {{ticket.businessProcessStep}}
  </span>
  &nbsp;&nbsp;
  <button mat-mini-fab color="primary" [disabled]="!isNextStep(ticket)" (click)="moveNextStep(ticket)">>></button>
</div>
