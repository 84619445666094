<div *ngIf="null != ticket.description && ticket.description.length > 0 && !ticket.descriptionAddFlag" style="max-height: 140px; overflow: auto;">
  <hr/>
  <div *ngIf="null != ticket.description && ticket.description.length > 110 && !ticket.fullDescription">
    <p style="margin-top: 10px;">
                <span
                  *ngFor="let wordOrLink of getTextWithLinks(ticket.description.substring(0, 100), ticket.description); let i = index">
                  <span *ngIf="!wordOrLink.startsWith('http') || wordOrLink.length < 12">
                    {{wordOrLink}}
                  </span>
                  <a
                    *ngIf="wordOrLink.startsWith('http') && wordOrLink.length > 11"
                    [href]="pureLink(wordOrLink)"
                    style="color: #03a9f4"
                    target="_blank"
                  >{{pureLink(wordOrLink)}}</a>
                  <span
                    *ngIf="i < getTextWithLinks(ticket.description.substring(0, 100), ticket.description).length - 1">&nbsp;</span>
                  <br *ngIf="wordOrLink.length === 1 && wordOrLink.charCodeAt(0) === 10"/>
                </span>
      &nbsp;<span (click)="ticket.fullDescription = true"
                  style="color: #03a9f4; cursor: pointer; border: 1px solid #03a9f4;">...</span>
    </p>
  </div>
  <p style="margin-top: 10px;"
     *ngIf="null != ticket.description && (ticket.description.length <= 110 || ticket.fullDescription)">
              <span *ngFor="let wordOrLink of getTextWithLinks(ticket.description, ticket.description); let i = index">
                  <span *ngIf="!wordOrLink.startsWith('http') || wordOrLink.length < 12">
                    {{wordOrLink}}
                  </span>
                  <a
                    *ngIf="wordOrLink.startsWith('http') && wordOrLink.length > 11"
                    [href]="pureLink(wordOrLink)"
                    style="color: #03a9f4"
                    target="_blank"
                  >{{pureLink(wordOrLink)}}</a>
                  <span *ngIf="i < getTextWithLinks(ticket.description, ticket.description).length - 1">&nbsp;</span>
                <br *ngIf="wordOrLink.length === 1 && wordOrLink.charCodeAt(0) === 10"/>
                </span>
  </p>
</div>
<!--    Edit description area      -->
<div *ngIf="ticket.descriptionAddFlag">
  <br/>
  <form>
    <mat-form-field style="width: 100%;">
      <mat-label>{{ 'TODAY.TICKET.DESCRIPTION.LABEL' | translate}}</mat-label>
      <textarea
        matInput
        [(ngModel)]="ticket.description"
        name="description"
        style="height: 60px;"
        placeholder="Add description here"></textarea>
    </mat-form-field>
    <br/>
    <button
      [@openClose]
      mat-button
      color="primary"
      (click)="saveDescription(ticket)">{{ 'TODAY.TICKET.DESCRIPTION.SAVE' | translate}}</button>
    <button
      mat-button
      [@openClose]
      color="accent"
      (click)="closeDescriptionEditing()">{{ 'TODAY.TICKET.DESCRIPTION.CANCEL' | translate}}</button>
  </form>
</div>
