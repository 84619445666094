<div [class]="auth.theme === '' ? 'step1' : 'step1b'">
<!--  <img style="width: 100%;" src="./assets/images/landing/step1/landing_step1@2x.png" style="visibility: hidden;" />-->

  <br/>
<!--  <br class="br"/>-->
  <br class="br"/>
<!--  <br/>-->
  <div style="margin: auto; width: fit-content; color: white;" align="center">
    <img class="small-logo" src="/assets/images/logos/logo.jpg">&nbsp;<span style="font-family: 'IBM Plex Serif';" class="netty-name">Nettyos <i style="font-size: 1rem">{{versionService.version}}</i></span>
    <br/>
    <br/>
    <br class="br"/>
    <div *ngIf="welcome" [@welcome] class="main-title" style="font-family: 'IBM Plex Serif';">{{ 'LANDING.TITLE_PART1' | translate}}<br class="iphone"/><br class="iphone"/><span class="iphone">&nbsp;</span> {{ 'LANDING.TITLE_PART2' | translate}}</div>
<!--    <div *ngIf="welcome" [@welcome] class="main-title">of planning..</div>-->
    <button [routerLink]="'/register'" class="btnBig" mat-raised-button color="warn" style="font-family: 'IBM Plex Serif';">{{ 'LANDING.JOIN_CONTINUE' | translate}}</button>
    <button [routerLink]="'/register'" class="btnSmall" mat-button color="warn" style="font-family: 'IBM Plex Serif';">{{ 'LANDING.JOIN_CONTINUE' | translate}}</button>
<!--    <span>-->
<!--      delegate all planning and dreaming-->
<!--      <br/>-->
<!--      processes to nettyos totally and relax-->
<!--    </span>-->
  </div>
</div>

<br/><br/>

<!--<div style="width:fit-content; margin: auto;" align="center">-->
<!--  <button [routerLink]="'/register'" mat-raised-button color="primary">Join Free</button>-->
<!--  <br/>-->
<!--  <br/>-->
<!--  <i>almost all functions in the principal web-app will remain free also for commercial use</i>-->
<!--</div>-->

<br/><br/>

<mat-toolbar class="toolbar">
  <div class="title-spacer" align="center" style="color: #03a9f4;">
    {{ 'LANDING.NETTYOS' | translate}} 4 (Beta)
  </div>
</mat-toolbar>

<!--<carousel></carousel>-->

<div class="wrapper chip-list">
  <br/>
  <br/>
  <mat-card
    inViewport
    (inViewportAction)="avaGreetingInViewport($event)">
    <table>
      <tr class="mobile">
        <td style="display:block; box-sizing:border-box; clear:both;" align="center">
          <img [@avaGreeting]="greetingState"
                  src="./assets/images/niki.jpg" style="width: 150px; height: 150px; border-radius: 50%; margin: auto;">
        </td>
      </tr>
      <tr>
        <td class="no-mobile">
          <img [@avaGreeting]="greetingState"
               src="./assets/images/niki.jpg" style="width: 150px; height: 150px; border-radius: 50%;">
        </td>
        <td *ngIf="translate.defaultLang === 'ru'">
          <p style="margin-left: 15px;" [@textGreeting]="greetingState">
            Привет, меня зовут <a href="https://instagram.com/ahm.live" target="_blank" style="color: #03a9f4">Никита Данилов</a>. Я создатель <strong>Нетти.РФ</strong>
            <br/>
            <br/>
            Еще на первых курсах университета моей мечтой было создать сеть знакомств по нейтральным признакам: учеба, работа... У людей тогда было дикое, непреодолимое желание общаться друг с другом, но сайты знакомств не давали это сделать из-за "нравишься/не нравишься".

          </p>
            <button mat-button
                    color="primary"
                    *ngIf="!readFullAuthorRus"
                    (click)="readFullAuthorRus = true;"
                    style="margin: 0px 0px 10px 15px;"
            >Читать полностью...</button>

            <p *ngIf="readFullAuthorRus" style="margin-left: 15px;" [@textGreeting]="greetingState">
              <i>
                <br/>
                Идея была простой. Добавиться в контакты по нейтральному признаку и начать общение без выяснения кто кому и
                насколько нравится. Я подходил к лучшим студентам и предлагал реализовать проект, но у них была работа... А
                через несколько лет мир узнал о появлении Фейсбук.
                <br/>
                <br/>
                Я все-таки начал проект и первые версии <strong>Нетти.РФ</strong> были, в том числе, социальными сетями,
                работающими тогда на невиданных технологиях: как приложение, на одной веб-странице. Я столкнулся с проблемами
                первичного рынка программных продуктов в России.
                <br/>
                <br/>
                Было невозможно получить финансирование на ту систему, которая создает новую пользовательскую привычку.
                Несущественные суммы можно было получить только на то, что уже приносит доход, а как мы знаем все системы
                первичного рынка начали приносить доход не сразу.
                <br/>
                <br/>
                Вторым существенным препятствием на пути к созданию первичных программных продуктов было отсутсвие
                профессиональных инструментов. Поэтому Нетти сначала превратился в эффективный инструмент планирования для
                разработки программных продуктов и только со второй версии в <strong>Нетти.РФ</strong> появилась социальная
                сеть. В каком-то смысле это была победа, для рынка IT России невиданных масштабов.
                <br/>
                <br/>
                К сожалению, было уже поздно для социальных сетей, поэтому <strong>Нетти.РФ</strong> продолжил свое
                существование как самый эффективны инструмент планирования в мире. И это очевидно. Ведь Мне пришлось
                конкурировать водиночку с такими компаниями как Фейсбук, Гугл, Атлассиан... А без лучшего в мире
                инструмента планирования это было невозможно. Позднее выяснилось, что <strong>Нетти.РФ</strong>
                очень эффективен для команд и не только в рамках разработки программных продуктов, но и всего что можно
                разрабатывать.
                <br/>
                <br/>
              </i>
            </p>
          <p style="margin-left: 15px;" [@textGreeting]="greetingState">
            Без сомнений, большинство будет жалеть, что не нашли <strong>Нетти.РФ</strong> раньше. Процессы планирования доведены до идеала и включают в себя топовую психологию из серии книг <a href="http://ahm-pro.com" target="_blank" style="color: #03a9f4">"Азбука Человеческой Психики"</a>, тоже моего авторства. Много успешных проектов были созданы с использованием <strong>Нетти.РФ</strong>, присоединяйся!
          </p>
        </td>
        <td *ngIf = "translate.defaultLang === 'en'">
          <p style = "margin-left: 15px;" [@textGreeting]="greetingState">
            Hi, my name is <a href="https://instagram.com/ahm.live" target="_blank" style="color: #03a9f4">Nikita Danilov</a>, I am the founder of <strong>nettyos.com</strong>
            <br/>
            <br/>
            And this service helps to simplify self-organization, realize personal projects, dreams and work in any type of team: <i>existing</i> and <i>future</i>
            <br/>
            <br/>
            Our goal is to combine self-organization, growth, personal and team planning into one tool and save teams from the unnecessary complexities of choosing planning systems, with <strong>nettyos.com</strong> it is enough to say: "Just add me to the team"
            <br/>
            <br/>
            The main criteria are: <strong>simplicity</strong>, <strong>productivity</strong> and the ability to <strong>delegate</strong> the planning process at any level to the system without paying the cost of studying unnecessary complexities
            <br/>
            <br/>
            <strong>nettyos.com</strong> itself, a series of books <a href="http://ahm-pro.com" target="_blank" style="color: #03a9f4">"Alphabet of the Human Mind"</a> and many-many successful projects have been created using <strong>nettyos.com</strong>
          </p>

        </td>
      </tr>
    </table>
  </mat-card>
</div>

<div class="wrapper chip-list">
  <br/>
  <br/>
  <mat-card
    *ngIf="translate.defaultLang === 'ru'"
    style="justify-content: center;"
    inViewport
    (inViewportAction)="avaGreetingInViewport($event)">

    <div style="display: flex; justify-content: center;">
      <h1>Скачать приложение Нетти на телефон</h1>
    </div>
    <div style="display: flex; justify-content: center;">
      <button mat-stroked-button color="primary"
              (click)="iosState = randomString(10);">У меня iPhone</button>
      &nbsp;
      <button mat-stroked-button color="primary"
              (click)="androidState = randomString(10);">У меня Android</button>
    </div>
    <br/>
    <ul class="horizontal">
      <li style="margin: 10px 50px 10px 50px;" class="mobile-app">
        <br/>
        <img
          inViewport
          (inViewportAction)="iosInViewport($event)"
          [@ios]="iosState" style="width: 100px; height: 100px; margin: 5px;" src="/assets/images/logos/appstore.png"/>
        <br/>
        <img
          inViewport
          (inViewportAction)="androidInViewport($event)"
          [@android]="androidState" style="width: 100px; height: 100px; margin: 5px;" src="/assets/images/logos/googleplay.png"/>
      </li>
      <li style="margin: 10px; display: flex; justify-content: center;"><img src="/assets/images/landing/app/iosapp.gif"/></li>
    </ul>

  </mat-card>

  <mat-card
    *ngIf="translate.defaultLang === 'en'"
    style="justify-content: center;"
    inViewport
    (inViewportAction)="avaGreetingInViewport($event)">

    <div style="display: flex; justify-content: center;">
      <h1>Download Nettyos app on your phone</h1>
    </div>
    <div style="display: flex; justify-content: center;">
      <button mat-stroked-button color="primary"
              (click)="iosState = randomString(10);">I use iPhone</button>
      &nbsp;
      <button mat-stroked-button color="primary"
              (click)="androidState = randomString(10);">I use Android</button>
    </div>
    <br/>
    <ul class="horizontal">
      <li style="margin: 10px 50px 10px 50px;" class="mobile-app">
        <br/>
        <img
          inViewport
          (inViewportAction)="iosInViewport($event)"
          [@ios]="iosState" style="width: 100px; height: 100px; margin: 5px;" src="/assets/images/logos/appstore.png"/>
        <br/>
        <img
          inViewport
          (inViewportAction)="androidInViewport($event)"
          [@android]="androidState" style="width: 100px; height: 100px; margin: 5px;" src="/assets/images/logos/googleplay.png"/>
      </li>
      <li style="margin: 10px; display: flex; justify-content: center;"><img src="/assets/images/landing/app/iosapp.gif"/></li>
    </ul>

  </mat-card>

</div>

<div class="wrapper chip-list" align="center">
  <br/>
  <br/>
  <ul class="horizontal">
    <li style="margin: 10px 10px 10px 10px;" class="mobile-app">
      <mat-card>

        <img inViewport
             (inViewportAction)="coachInViewport($event)"
             [@coach]="coachState"
             style="width: 300px; height: 300px;" src="./assets/images/components/coach/coach.svg"/>

      </mat-card>
    </li>
    <li
      *ngIf="translate.defaultLang === 'ru'"
      style="margin: 10px 10px 10px 10px; justify-content: left;" class="mobile-app">
      <mat-card style="text-align: left; min-height: 305px;">
        <h2>Я твой личный коуч и помогу тебе инвестировать в твои цели мечты и планы</h2>
        <button
          inViewport
          (inViewportAction)="helloCoachInViewport($event)"
          [@hellocoach]="helloCoachState"
          mat-stroked-button
          color="primary"
          [disabled]="coachHelloRus"
          (click)="coachHelloRus = true;"
        >Познакомиться с коучем...</button>
        <br/>
        <br/>
        <div *ngIf="coachHelloRus">
          <strong>Чтобы меня найти надо:</strong>
          <ul>
            <li><i>Зарегистрироваться или войти в систему</i></li>
            <li><i>Открыть меню в левом верхнем углу экрана</i></li>
            <li><i>Выделить вкладку "Я"</i></li>
            <li><i>В открывшемся подменю выбрать "Личный Коуч"</i></li>
          </ul>
          <br/>
          "Без инвестиций в акции не будет дивидендного дохода, без инвестиций в мечты и планы не будет успеха!" ©
          <br/>
          <br/>
          <i>До встречи! Твой Личный Коуч.</i>
        </div>
      </mat-card>
    </li>
    <li
      *ngIf="translate.defaultLang === 'en'"
      style="margin: 10px 10px 10px 10px; justify-content: left;" class="mobile-app">
      <mat-card style="text-align: left; min-height: 305px;">
        <h2>I am your personal coach and will help you invest in your goals dreams and plans</h2>
        <button
          inViewport
          (inViewportAction)="helloCoachInViewport($event)"
          [@hellocoach]="helloCoachState"
          mat-stroked-button
          color="primary"
          [disabled]="coachHelloRus"
          (click)="coachHelloRus = true;"
        >Meet the coach...</button>
        <br/>
        <br/>
        <div *ngIf="coachHelloRus">
          <strong>To find me, you need to:</strong>
          <ul>
            <li><i>Register or log in</i></li>
            <li><i>Open the menu in the upper left corner of the screen</i></li>
            <li><i>Select the "Me" tab</i></li>
            <li><i>In the submenu that opens, select "Personal Coach"</i></li>
          </ul>
          <br/>
          "Without investing in stocks there will be no dividend income, without investing in dreams and plans there will be no success!" ©
          <br/>
          <br/>
          <i>See you! Your Personal Coach.</i>
        </div>
      </mat-card>
    </li>
  </ul>
  <br/>
  <br/>
</div>

<!--Business processes-->
<div class="wrapper chip-list">
  <br/>
  <br/>
  <mat-card
    style="justify-content: center;"
    inViewport
    (inViewportAction)="avaGreetingInViewport($event)">

    <div style="display: flex; justify-content: center;">
      <h1 *ngIf="translate.defaultLang === 'ru'">Бизнес-процессы любому тикету</h1>
      <h1 *ngIf="translate.defaultLang === 'en'">Business processes for any ticket</h1>
    </div>

    <div style="display: flex; justify-content: center;">
      <i *ngIf="translate.defaultLang === 'ru'">делегировать с сохранением качества, увеличить внутреннюю ценность продукта, на новый уровень</i>
      <i *ngIf="translate.defaultLang === 'en'">delegate while maintaining quality, increase the intrinsic value of the product, go to a new level</i>
    </div>

    <br/>

    <div *ngIf="translate.defaultLang === 'ru'" style="display: flex; justify-content: center;">
      <button mat-stroked-button color="primary"
              (click)="businessProcess = 'it'; businessStep = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];">Проект/Модуль в IT</button>
      &nbsp;
      <button mat-stroked-button color="primary"
              (click)="businessProcess = 'blog'; businessStep = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];">Видео для блога</button>
    </div>

    <div *ngIf="translate.defaultLang === 'en'" style="display: flex; justify-content: center;">
      <button mat-stroked-button color="primary"
              (click)="businessProcess = 'it'; businessStep = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];">IT Project/Module</button>
      &nbsp;
      <button mat-stroked-button color="primary"
              (click)="businessProcess = 'blog'; businessStep = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];">Video for a blog</button>
    </div>

    <div style="overflow-x: scroll;">
      <!--      IT Project-->
      <table *ngIf="businessProcess === 'it'" style="margin: auto;">
        <tr>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[0]"
                (click)="changeBusinessStepIfPreviousDone(0)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[0] === 0 ? 'white': 'gold'}"
                    style="font-size: 2rem;">R&D</strong>
                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div [@business]="businessStep[1]"
                   (click)="changeBusinessStepIfPreviousDone(1)"
                   style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[1] === 0 ? 'white': 'gold'}"
                    style="font-size: 2rem;">PoC</strong>
                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div [@business]="businessStep[2]"
                   (click)="changeBusinessStepIfPreviousDone(2)"
                   style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[2] === 0 ? 'white': 'gold'}"
                    style="font-size: 2rem;">MVP</strong>
                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div [@business]="businessStep[3]"
                   (click)="changeBusinessStepIfPreviousDone(3)"
                   style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[3] === 0 ? 'white': 'gold'}"
                    style="font-size: 1.5rem;">PreAlpha</strong>
                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div [@business]="businessStep[4]"
                   (click)="changeBusinessStepIfPreviousDone(4)"
                   style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[4] === 0 ? 'white': 'gold'}"
                    style="font-size: 1.5rem;">Alpha</strong>
                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div [@business]="businessStep[5]"
                   (click)="changeBusinessStepIfPreviousDone(5)"
                   style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[5] === 0 ? 'white': 'gold'}"
                    style="font-size: 1.5rem;">Beta</strong>
                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div [@business]="businessStep[6]"
                   (click)="changeBusinessStepIfPreviousDone(6)"
                   style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[6] === 0 ? 'white': 'gold'}"
                    style="font-size: 2rem;">RC</strong>
                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div [@business]="businessStep[7]"
                   (click)="changeBusinessStepIfPreviousDone(7)"
                   style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto;">
                  <strong
                    [ngStyle]="{color: businessStep[7] === 0 ? 'white': 'gold'}"
                    style="font-size: 1.5rem;">Release</strong>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <!--      Blog -->
      <table *ngIf="businessProcess === 'blog'" style="margin: auto;">
        <tr>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[0]"
                (click)="changeBusinessStepIfPreviousDone(0)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[0] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Выбор Локации</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[0] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Find Location</strong>

                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[1]"
                (click)="changeBusinessStepIfPreviousDone(1)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[1] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Pro Оборудование</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[1] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Pro Equipment</strong>

                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[2]"
                (click)="changeBusinessStepIfPreviousDone(2)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[2] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Выбор Локации</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[2] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Find Location</strong>

                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[3]"
                (click)="changeBusinessStepIfPreviousDone(3)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[3] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Написать Сюжет</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[3] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Write a Plot</strong>

                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[4]"
                (click)="changeBusinessStepIfPreviousDone(4)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[4] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Запомнить Якоря</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[4] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Remember Anchors</strong>

                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[5]"
                (click)="changeBusinessStepIfPreviousDone(5)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[5] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Снять Видео</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[5] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Film a Movie</strong>

                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[6]"
                (click)="changeBusinessStepIfPreviousDone(6)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[6] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Pro Обработка</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[6] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Pro Editing</strong>

                </div>
              </div>
            </div>
          </td>
          <td>➔</td>
          <td>
            <div style="display: flex; background-color: transparent; width:150px; height: 150px;">
              <div
                [@business]="businessStep[7]"
                (click)="changeBusinessStepIfPreviousDone(7)"
                style="cursor:pointer; display:flex; margin: auto; background-color: #03a9f4; transform: rotate(45deg); width:100px; height: 100px;">
                <div style="transform: rotate(-45deg); margin: auto; text-align: center;">
                  <strong
                    *ngIf="translate.defaultLang === 'ru'"
                    [ngStyle]="{color: businessStep[7] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Выложить</strong>

                  <strong
                    *ngIf="translate.defaultLang === 'en'"
                    [ngStyle]="{color: businessStep[7] === 0 ? 'white': 'gold'}"
                    style="font-size: 0.9rem;">Upload</strong>

                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>


      <!--      <br/>-->
      <!--      <br/>-->
      <!--      Нетти представляет фичу. Возможность добавить бизнес-процесс для любого тикета из личного или командного проекта.-->
      <!--      <br/>-->
      <!--      <br/>-->
      <!--      Именно отточенные бизнес-процессы дают бизнесу вэлью. Бизнес-процессы компании Макдональдс оттчавались годами и теперь Макдональдс продает их в виде франшизы.-->
      <!--      <br/>-->
      <!--      <br/>-->
      <!--      Это супер удобно для делегирования без потери качества. Вы можете делегировать сотруднику не просто задачу, а процесс.-->
      <!--      <br/>-->
      <!--      <br/>-->
      <!--      Именно хорошие бизнес-процессы дают вэлью вашему бизнесу. На примере видео для блога.-->
      <!--      <br/>-->
      <!--      <br/>-->
      <!--      Вот вариант бизнес-процесса для съемки видео для блога.-->
      <!--      <br/>-->
      <!--      <br/>-->
      <!--      Выбор Локации -> Оборудование -> Профессиональный Свет -> Сюжет -> Якоря -> Съемка -> Монтаж -> Выложить-->
      <!--      <br/>-->
      <!--      <br/>-->
      <!--      Ваши клиенты, заказчики, зрители сразу увидят дополнительное вэлью продукта, если он будет проходить через правильные бизнес процессы.-->

    </div>
    <br/>

  </mat-card>

</div>


<div class="wrapper chip-list" align="center">
  <br/>
  <br/>
  <h2>
  <button mat-raised-button color="accent" [routerLink]="'/tour'" >{{ 'FOOTER.PRODUCT-TOUR' | translate}} >></button>
  </h2>
  <br/>

  <mat-card
    style="justify-content: center;"
    inViewport
    (inViewportAction)="avaGreetingInViewport($event)">

<!--  <iframe class="videotour" src="https://www.youtube.com/embed/z0TyPkOVVWg" title="Видеотур по продукту" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

<!--    <h1>Решения от Нетти</h1>-->
<!--    <br/>-->

    <div style="overflow-x: scroll;">
      <table>
        <tr>
          <td style="vertical-align: top;">
            <!--      Self Organizer -->
            <mat-card [@solutions]="solutionsGalleryAnimations[0]"
                      class="mat-card-solutions-gallery">
              <mat-card-header>
                <div mat-card-avatar class="self-organizer-header-image"></div>
                <mat-card-title *ngIf="translate.defaultLang === 'ru'"
                >Самоорганизация</mat-card-title>
                <mat-card-title *ngIf="translate.defaultLang === 'en'"
                >Self-organizer</mat-card-title>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'ru'"
                >решение от нетти</mat-card-subtitle>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'en'"
                >nettyos solution</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p *ngIf="translate.defaultLang === 'ru'">
                  Можно, записывать все дела/идеи/напоминания (тикеты), а потом планировать как и когда их выполнить с комментариями, ссылками и датами. Превратить идею в командный проект и добавить команду для выполнения - супер просто!
                </p>
                <p *ngIf="translate.defaultLang === 'en'">
                  You can write down all the tasks/ideas/reminders (tickets), and then plan how and when to complete them with comments, links and dates. Turning an idea into a team project and adding a team to execute is super easy!
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button [routerLink]="'/self-organizer'" *ngIf="translate.defaultLang === 'ru'" mat-button>ПОДРОБНЕЕ</button>
                <button [routerLink]="'/self-organizer'" *ngIf="translate.defaultLang === 'en'" mat-button>MORE INFO</button>
              </mat-card-actions>
            </mat-card>
          </td>
          <td style="vertical-align: top;">
            <!--      Family Hub -->
            <mat-card [@solutions]="solutionsGalleryAnimations[1]"
                      class="mat-card-solutions-gallery">
              <mat-card-header>
                <div mat-card-avatar class="family-header-image"></div>
                <mat-card-title *ngIf="translate.defaultLang === 'ru'"
                >Семейный Хаб</mat-card-title>
                <mat-card-title *ngIf="translate.defaultLang === 'en'"
                >Family Hub</mat-card-title>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'ru'"
                >решение от нетти</mat-card-subtitle>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'en'"
                >nettyos solution</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p *ngIf="translate.defaultLang === 'ru'">
                  Nettyos будет незаменим для реализации "Семейного Хаба". Даже у небольшой семьи есть много дел, что уж говорить о больших семьях. Не все просьбы можно исполнить прямо сейчас, кто-то работает, другие строят бизнес. Поэтому просьбы с низким приоритетом (но важные) можно отправить другу или подруге, которые могут быть членами семьи. То же самое возможно и с друзьями. Поэтому "Семейный Хаб" актуален для любой компании друзей.
                </p>
                <p *ngIf="translate.defaultLang === 'en'">
                  Nettyos will be indispensable for the implementation of the "Family Hub". Even a small family has many things to do, for sure large families - a lot. Not all requests can be fulfilled at the moment, some are working, others are building a business. Therefore, low priority but important requests can be sent to a friend who may be family members. The same is possible with friends. Therefore, the "Family Hub" is relevant for any group of friends.
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button [routerLink]="'/family-hub'" *ngIf="translate.defaultLang === 'ru'" mat-button>ПОДРОБНЕЕ</button>
                <button [routerLink]="'/family-hub'" *ngIf="translate.defaultLang === 'en'" mat-button>MORE INFO</button>
              </mat-card-actions>
            </mat-card>
          </td>
          <td style="vertical-align: top;">
            <!-- Dreams Maker -->
            <mat-card [@solutions]="solutionsGalleryAnimations[2]"
                      class="mat-card-solutions-gallery">
              <mat-card-header>
                <div mat-card-avatar class="dreams-maker-header-image"></div>
                <mat-card-title *ngIf="translate.defaultLang === 'ru'"
                >Исполнитель мечт</mat-card-title>
                <mat-card-title *ngIf="translate.defaultLang === 'en'"
                >Dreams Maker</mat-card-title>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'ru'"
                >решение от нетти</mat-card-subtitle>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'en'"
                >nettyos solution</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p *ngIf="translate.defaultLang === 'ru'">
                  Много психологических инновационных решений интегрировано в Nettyos чтобы помочь реализовать мечты. В первую очередь автору и создателю Nettyos, который активно пользуется этим сервисом. И, конечно, благодаря IT эти психотехнологии открыли другим людям.
                </p>
                <p *ngIf="translate.defaultLang === 'en'">
                  Many psychological innovations are integrated into Nettyos to help make dreams come true. First of all, to the author and creator of Nettyos, who actively uses this service. And, of course, thanks to IT, these psychotechnologies were opened to other people.
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button [routerLink]="'/dreams-maker'" *ngIf="translate.defaultLang === 'ru'" mat-button>ПОДРОБНЕЕ</button>
                <button [routerLink]="'/dreams-maker'" *ngIf="translate.defaultLang === 'en'" mat-button>MORE INFO</button>
              </mat-card-actions>
            </mat-card>
          </td>
          <td style="vertical-align: top;">
            <!-- Personal Projects -->
            <mat-card [@solutions]="solutionsGalleryAnimations[3]"
                      class="mat-card-solutions-gallery">
              <mat-card-header>
                <div mat-card-avatar class="personal-projects-header-image"></div>
                <mat-card-title *ngIf="translate.defaultLang === 'ru'"
                >Персональные Проекты</mat-card-title>
                <mat-card-title *ngIf="translate.defaultLang === 'en'"
                >Personal Projects</mat-card-title>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'ru'"
                >решение от нетти</mat-card-subtitle>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'en'"
                >nettyos solution</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p *ngIf="translate.defaultLang === 'ru'">
                  Идеально подходит для самозанятых, которые хотят в разы повысить средний чек, малого бизнеса, хобби, категорий и даже для тех кто хочет конкурировать с IT гигантами в одиночку. Да именно Нетти, который разрабатывался во времена, когда инструментов планирования еще не было, а необходимость жесткой конкуренции была очень высокой.
                </p>
                <p *ngIf="translate.defaultLang === 'en'">
                  Ideal for self-employed people who want to significantly increase the average check, small businesses, hobbies, categories, and even for those who want to conquer with IT giants alone. Yes, it was Nettyos, which was developed at a time when there were no planning tools yet, and the need for tough competition was very high.
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button [routerLink]="'/personal-projects'" *ngIf="translate.defaultLang === 'ru'" mat-button>ПОДРОБНЕЕ</button>
                <button [routerLink]="'/personal-projects'" *ngIf="translate.defaultLang === 'en'" mat-button>MORE INFO</button>
              </mat-card-actions>
            </mat-card>
          </td>
          <td style="vertical-align: top;">
            <!-- Teams -->
            <mat-card [@solutions]="solutionsGalleryAnimations[4]"
                      class="mat-card-solutions-gallery">
              <mat-card-header>
                <div mat-card-avatar class="teams-header-image"></div>
                <mat-card-title *ngIf="translate.defaultLang === 'ru'"
                >Команды (любые)</mat-card-title>
                <mat-card-title *ngIf="translate.defaultLang === 'en'"
                >Teams (any)</mat-card-title>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'ru'"
                >решение от нетти</mat-card-subtitle>
                <mat-card-subtitle *ngIf="translate.defaultLang === 'en'"
                >nettyos solution</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p *ngIf="translate.defaultLang === 'ru'">
                  Переходить от одной модели команды к другой можно в "горячем" режиме. Также как и кастомизировать. Стал неэффективным SCRUM, поменяли на KANBAN, все еще нет эффекта - сделали mix: SCRUM + KANBAN + Своя Методология. Простые проекты понятные каждому - легко. Сложные проекты стали управляться просто и гибко.
                </p>
                <p *ngIf="translate.defaultLang === 'en'">
                  You can move from one team model to another in a "hot" mode. As well as customization. SCRUM became ineffective, changed to KANBAN, still no effect - made mix: SCRUM + KANBAN + Own Methodology. Simple projects that are clear to everyone - easy. Complex projects have become easy and flexible to manage.
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button [routerLink]="'/all-teams'" *ngIf="translate.defaultLang === 'ru'" mat-button>ПОДРОБНЕЕ</button>
                <button [routerLink]="'/all-teams'" *ngIf="translate.defaultLang === 'en'" mat-button>MORE INFO</button>
              </mat-card-actions>
            </mat-card>
          </td>
        </tr>
      </table>


    </div>

  </mat-card>

  <br/>
  <br/>
</div>

<div class="wrapper chip-list">
  <br/>
  <br/>
  <mat-chip-list *ngIf="translate.defaultLang === 'ru'" style="margin: auto; width: fit-content;" aria-label="Features" align="center">
    <mat-chip *ngFor="let chip of generatedChipsList1" class="chips" [ngStyle]="{backgroundColor: chip.color}">
      <mat-checkbox color="primary" [(ngModel)]="chip.chipPressed" [disabled]="chip.chipPressed"  (click)="showYeahFn(chip); chip.chipPressed = true;">{{chip.chipText}}</mat-checkbox>
    </mat-chip>
  </mat-chip-list>

  <mat-chip-list *ngIf="translate.defaultLang === 'en'" style="margin: auto; width: fit-content;" aria-label="Features" align="center">
    <mat-chip *ngFor="let chip of generatedChipsList1Eng" class="chips" [ngStyle]="{backgroundColor: chip.color}">
      <mat-checkbox color="primary" [(ngModel)]="chip.chipPressed" [disabled]="chip.chipPressed"  (click)="showYeahFn(chip); chip.chipPressed = true;">{{chip.chipText}}</mat-checkbox>
    </mat-chip>
  </mat-chip-list>
  <br/>
  <br/>
  <div class="center" align="center">
    <div [@yeah]="yeahState" style="font-size: 500%; height: 33px;" *ngIf="showYeah">👍</div>
    <br/>
    <br/>
    <button class="center" mat-stroked-button color="primary" [routerLink]="'/register'">{{ 'LANDING.CHIPS.NOW_TRY' | translate}}</button>
  </div>
  <br/>
  <br/>
  <br/>
  <br/>
</div>

<div class="wrapper">

  <div align="center" style="width: 100%; margin: auto;">
  <br/><br/>
<!--    <div *ngIf="welcome" [@welcome]><b style="font-size: 150%;">Welcome!</b></div>-->
    <br/><br/>
<!--    <ul class="horizontal"  style="margin: auto; width: fit-content;">-->
<!--      <li>-->
<!--        <p style="font-size: 200%; width: 200px;">Nettyos this is professional instrument can simply increase the value of your doing something 5-10 times and more.</p>-->
<!--        <br/>-->
<!--        <br/>-->
<!--        <p style="font-size: 200%; width: 200px;">Ordinary and good planner systems can give about 2-3 times increase level.</p>-->
<!--        <br/>-->
<!--        <br/>-->
<!--        <p style="font-size: 200%; width: 200px;">In nettyos no limits because of integrated psychology and growth center.</p>-->
<!--      </li>-->
<!--      <li>-->
<!--        <img src="./assets/images/landing/step2/macBookProNetty@1xW1000.png">-->
<!--      </li>-->
<!--    </ul>-->
  </div>

<!--
Все в одном
Календарь, блокнот, список дел, менеджер проектов, командный планировшик, центр личного роста. Не надо думать в какой блокнот или ежедневник записать свою мысль, задачу, идею. Все и всегда - в Нетти!

Интегрирована топовая психология
Nettyos даёт заряд сил и вдохновения для выполнения ваших задач! Благодаря интегрированной топовой психологии Nettyos помогает достичь успеха в любом деле!

Жизненные процессы Pro
Помогает найти баланс и гармонично совмещать все сферы жизни!

Планируйте всей семьёй!
С nettyos легко планировать дела, назначать задачи друг другу, обмениваться сообщениями, делиться идеями!

Командный планировщик
Nettyos идеальный инструмент для командной работы. Создавайте проекты, назначайте задачи, отслеживайте статус дел. Поможет справиться с любым объемом работы и быть всегда курсе. С Nettyos создаются самые невероятные проекты!

Все топовые устройства
Nettyos хорошо работает со всеми топовыми устройствами.

Основные функции бесплатны
Основные функции nettyos останутся бесплатными для веб-приложения.
-->

  <ul class="horizontal"  style="margin: auto; width: fit-content;">
    <li>
      <mat-card class="landing-card"
                (click)="animateStep2('allInOne')"
                (mouseover)="animateStep2('allInOne')"
                (mouseout)="animateStep2('allInOne')"
      >
        <mat-card-header align="left" style="margin-bottom: 5px;">
          <strong>{{ 'LANDING.ALL_IN_ONE_TITLE' | translate}}</strong>
        </mat-card-header>
        <img
          [@step2]="allInOne" src="./assets/images/landing/step2/in-one-all.svg"
        />
        <mat-card-content class="step2-text">
          <p *ngIf="translate.defaultLang === 'ru'" [@step2Text]="allInOne" style="opacity: 0">
            Календарь, блокнот, список дел, менеджер проектов, командный планировщик, центр личного роста. Не надо
            думать в какой блокнот или ежедневник записать свою мысль, задачу, идею. Все и всегда - в Нетти!
          </p>
          <p *ngIf="translate.defaultLang === 'en'" [@step2Text]="allInOne" style="opacity: 0">
            Calendar, notebook, to-do list, project manager, team planner, personal growth center. No need to
            think in what notebook or diary to write down your thought, task, idea. Everything and always - in Nettyos!
          </p>
        </mat-card-content>
      </mat-card>
    </li>
    <li>
      <mat-card class="landing-card"
                (click)="animateStep2('topPsy')"
                (mouseover)="animateStep2('topPsy')"
                (mouseout)="animateStep2('topPsy')">
        <mat-card-header align="left" style="margin-bottom: 5px;">
          <strong>{{ 'LANDING.TOP_PSY_INSIDE' | translate}}</strong>
        </mat-card-header>
        <img
          [@step2]="topPsy" src="./assets/images/landing/step2/top-psy.svg"
        />
        <mat-card-content class="step2-text">
          <p *ngIf="translate.defaultLang === 'ru'" [@step2Text]="topPsy" style="opacity: 0">
            Nettyos даёт заряд сил и вдохновения для выполнения ваших задач! Благодаря интегрированной топовой психологии Nettyos помогает достичь успеха в любом деле!
          </p>
          <p *ngIf="translate.defaultLang === 'en'" [@step2Text]="topPsy" style="opacity: 0">
            Nettyos gives you a boost of strength and inspiration to complete your tasks! Thanks to the integrated top psychology, Nettyos helps you achieve success in any business!
          </p>
        </mat-card-content>
      </mat-card>
    </li>
    <li>
      <mat-card (click)="animateStep2('lifeProcessesPro')"
                (mouseover)="animateStep2('lifeProcessesPro')"
                (mouseout)="animateStep2('lifeProcessesPro')"
                class="landing-card">
        <mat-card-header align="left" style="margin-bottom: 5px;">
          <strong>{{ 'LANDING.LIFE_PROCESSES_PRO' | translate}}</strong>
        </mat-card-header>
        <img [@step2]="lifeProcessesPro" src="./assets/images/landing/step2/life-pro-processes.svg" />
        <mat-card-content class="step2-text">
          <p *ngIf="translate.defaultLang === 'ru'" [@step2Text]="lifeProcessesPro" style="opacity: 0">
            Помогает найти баланс и гармонично совмещать все сферы жизни!
          </p>
          <p *ngIf="translate.defaultLang === 'en'" [@step2Text]="lifeProcessesPro" style="opacity: 0">
            Helps to find balance and harmoniously combine all spheres of life!
          </p>
        </mat-card-content>
      </mat-card>
    </li>
  </ul>

  <ul class="horizontal"  style="margin: auto; width: fit-content;">
    <li>
      <mat-card (click)="animateStep2('allFamilyPlanning')"
                (mouseover)="animateStep2('allFamilyPlanning')"
                (mouseout)="animateStep2('allFamilyPlanning')"
                class="landing-card">
        <mat-card-header align="left" style="margin-bottom: 5px;">
          <strong>{{ 'LANDING.PLAN_WITH_FAMILY' | translate}}</strong>
        </mat-card-header>
        <img [@step2Down]="allFamilyPlanning" src="./assets/images/landing/step2/with-all-family-plan.svg" />
        <mat-card-content class="step2-text">
          <p *ngIf="translate.defaultLang === 'ru'" [@step2Text]="allFamilyPlanning" style="opacity: 0">
            С nettyos легко планировать дела, назначать задачи друг другу, обмениваться сообщениями, делиться идеями!
          </p>
          <p *ngIf="translate.defaultLang === 'en'" [@step2Text]="allFamilyPlanning" style="opacity: 0">
            With nettyos, it's easy to plan things, assign tasks to each other, exchange messages, share ideas!
          </p>
        </mat-card-content>
      </mat-card>
    </li>
    <li>
      <mat-card (click)="animateStep2('teamPlanner')"
                (mouseover)="animateStep2('teamPlanner')"
                (mouseout)="animateStep2('teamPlanner')"
                class="landing-card">
        <mat-card-header align="left" style="margin-bottom: 5px;">
          <strong>{{ 'LANDING.TEAM_PLANNER' | translate}}</strong>
        </mat-card-header>
        <img [@step2Down]="teamPlanner" src="./assets/images/landing/step2/planner-of-team.svg" />
        <mat-card-content class="step2-text">
          <p *ngIf="translate.defaultLang === 'ru'" [@step2Text]="teamPlanner" style="opacity: 0">
            Nettyos идеальный инструмент для командной работы. Создавайте проекты, назначайте задачи, отслеживайте статус дел. Поможет справиться с любым объемом работы и быть всегда курсе. С Nettyos создаются самые невероятные проекты!
          </p>
          <p *ngIf="translate.defaultLang === 'en'" [@step2Text]="teamPlanner" style="opacity: 0">
            Nettyos is the perfect tool for teamwork. Create projects, assign tasks, track the status of cases. It will help to cope with any volume of work and be always up to date. The most incredible projects are created with Nettyos!
          </p>
        </mat-card-content>
      </mat-card>
    </li>
    <li>
      <mat-card (click)="animateStep2('topDevices')"
                (mouseover)="animateStep2('topDevices')"
                (mouseout)="animateStep2('topDevices')"
                class="landing-card">
        <mat-card-header align="left" style="margin-bottom: 5px;">
          <strong>{{ 'LANDING.ALL_TOP_DEVICES' | translate}}</strong>
        </mat-card-header>
        <img [@step2Down]="topDevices" src="./assets/images/landing/step2/top-devices.svg" />
        <mat-card-content class="step2-text">
          <p *ngIf="translate.defaultLang === 'ru'" [@step2Text]="topDevices" style="opacity: 0">
            Nettyos хорошо работает со всеми топовыми устройствами.
          </p>
          <p *ngIf="translate.defaultLang === 'en'" [@step2Text]="topDevices" style="opacity: 0">
            Nettyos works well with all top end devices.
          </p>
        </mat-card-content>
      </mat-card>
    </li>
  </ul>

  <ul class="horizontal"  style="margin: auto; width: fit-content;">
    <li>
<!--      <mat-card class="landing-card">-->
<!--        <mat-card-header align="left" style="margin-bottom: 5px;">-->
<!--          <strong>{{ 'LANDING.TEAM_TITLE' | translate}}</strong>-->
<!--        </mat-card-header>-->
<!--        <div style="-webkit-font-smoothing: antialiased; opacity: 0.7;">-->
<!--          {{ 'LANDING.TEAM_TEXT' | translate}}-->
<!--        </div>-->
<!--      </mat-card>-->
    </li>
    <li>
      <mat-card (click)="animateStep2('free')"
                (mouseover)="animateStep2('free')"
                (mouseout)="animateStep2('free')"
                class="landing-card">
        <mat-card-header align="left" style="margin-bottom: 5px;">
          <strong>{{ 'LANDING.FREE_TITLE' | translate}}</strong>
        </mat-card-header>
        <img [@step2Down]="free" src="./assets/images/landing/step2/free.svg" />
        <mat-card-content class="step2-text">
          <p *ngIf="translate.defaultLang === 'ru'" [@step2Text]="free" style="opacity: 0">
            Основные функции nettyos останутся бесплатными для веб-приложения.
          </p>
          <p *ngIf="translate.defaultLang === 'en'" [@step2Text]="free" style="opacity: 0">
            The main functions of nettyos will remain free for the web application.
          </p>
        </mat-card-content>
      </mat-card>
    </li>
    <li>
<!--      <mat-card class="landing-card">-->
<!--        <mat-card-header align="left" style="margin-bottom: 5px;">-->
<!--          <strong>{{ 'LANDING.DEVICES_TITLE' | translate}}</strong>-->
<!--        </mat-card-header>-->
<!--        <div style="-webkit-font-smoothing: antialiased; opacity: 0.7;">-->
<!--          {{ 'LANDING.DEVICES_TEXT' | translate}}-->
<!--        </div>-->
<!--      </mat-card>-->
    </li>
  </ul>

  <br/>
  <br/>

  <mat-card
    style="justify-content: center;">

    <!--  <iframe class="videotour" src="https://www.youtube.com/embed/z0TyPkOVVWg" title="Видеотур по продукту" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

    <!--    <h1>Решения от Нетти</h1>-->
    <!--    <br/>-->

    <div style="overflow-x: scroll;">
      <table>
        <tr>
        <td align="center">
          <a href="./assets/images/landing/step3/places_iphone8spacegrey_portrait.png" target="_blank">
            <img class="mocked-device" src="./assets/images/landing/step3/places_iphone8spacegrey_portrait.png"/>
          </a>
        </td>
        <td align="center">
          <a href="./assets/images/landing/step3/project_write_my_book_ipad_black_portrait.png" target="_blank">
            <img class="mocked-device" src="./assets/images/landing/step3/project_write_my_book_ipad_black_portrait.png">
          </a>
        </td>
        <td align="center">
          <a href="./assets/images/landing/step3/shared_tickets_iphonexspacegrey_portrait.png" target="_blank">
            <img class="mocked-device" src="./assets/images/landing/step3/shared_tickets_iphonexspacegrey_portrait.png">
          </a>
        </td>
        <td align="center">
          <a href="./assets/images/landing/step3/tickets_iphone8plusgold_portrait.png" target="_blank">
            <img class="mocked-device" src="./assets/images/landing/step3/tickets_iphone8plusgold_portrait.png">
          </a>
        </td>
        </tr>
      </table>
    </div>
  </mat-card>

  <div class="subheader" align="center" style="color: lightblue">
<!--    Approved devices Netty this and more-->
  </div>
  <br/><br/>
  <ul class="horizontal" align="center">
    <li align="center">
      <table style="width: fit-content; margin: auto;">
        <tr>
          <td>
            <img class="devices" src="/assets/images/landing/notebooks.jpg">
            &nbsp;
          </td>
          <td>
            <img class="devices" src="/assets/images/landing/macbook.jpg">
            &nbsp;
          </td>
        </tr>
      </table>
    </li>
    <li align="center">
      <table style="width: fit-content; margin: auto;">
        <tr>
          <td>
            <img class="devices" src="/assets/images/landing/ipad.jpg">
            &nbsp;
          </td>
          <td>
            <img class="devices" src="/assets/images/landing/iphone.jpg">
            &nbsp;
          </td>
        </tr>
      </table>
    </li>
    <li align="center">
      <table style="width: fit-content; margin: auto;">
        <tr>
          <td>
            <img class="devices" src="/assets/images/landing/alldevices.jpg">
          </td>
        </tr>
      </table>
    </li>
  </ul>

  <!--  What for Netty Online Scheduler may be used:-->

<!--  <div style="width: 100%" align="center">-->
<!--    <span style="font-size: 150%"><u>What for Netty Online Scheduler may be used:</u></span>-->
<!--    <br/>-->
<!--    <br/>-->
<!--    <br/>-->
<!--  </div>-->

  <br/>
<!--  <br/>-->
<!--  <br/>-->
<!--<div style="width: 80%; margin: auto;" align="center">-->
<!--  <mat-chip-list aria-label="What to do with nettyos">-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.DAY_LIST' | translate}}</mat-chip>-->
<!--    <mat-chip color="accent" selected>{{ 'LANDING.STEP5.OFFICE' | translate}}</mat-chip>-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.FRIENDS' | translate}}</mat-chip>-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.TEAM' | translate}}</mat-chip>-->
<!--    <mat-chip color="accent" selected>{{ 'LANDING.STEP5.ASSIGN' | translate}}</mat-chip>-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.SCRUM' | translate}}</mat-chip>-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.FAMILY_SCRUM' | translate}}</mat-chip>-->
<!--    <mat-chip color="accent" selected>{{ 'LANDING.STEP5.PERSONAL_SCRUM' | translate}}</mat-chip>-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.MEETINGS' | translate}}</mat-chip>-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.ENTERPRISE' | translate}}</mat-chip>-->
<!--    <mat-chip color="accent" selected>{{ 'LANDING.STEP5.DREAMS' | translate}}</mat-chip>-->
<!--    <mat-chip color="primary" selected>{{ 'LANDING.STEP5.NO_TIME' | translate}}</mat-chip>-->
<!--  </mat-chip-list>-->
<!--</div>-->

  <div class="wrapper chip-list">
    <br/>
    <br/>
    <mat-chip-list *ngIf="translate.defaultLang === 'ru'" style="margin: auto; width: fit-content;" aria-label="Features" align="center">
      <mat-chip *ngFor="let chip of generatedChipsList2" class="chips" [ngStyle]="{backgroundColor: chip.color}">
        <mat-checkbox color="primary" [(ngModel)]="chip.chipPressed" [disabled]="chip.chipPressed" (click)="showYeahFn(chip);chip.chipPressed = true;">{{chip.chipText}}</mat-checkbox>
      </mat-chip>
    </mat-chip-list>

    <mat-chip-list *ngIf="translate.defaultLang === 'en'" style="margin: auto; width: fit-content;" aria-label="Features" align="center">
      <mat-chip *ngFor="let chip of generatedChipsList2Eng" class="chips" [ngStyle]="{backgroundColor: chip.color}">
        <mat-checkbox color="primary" [(ngModel)]="chip.chipPressed" [disabled]="chip.chipPressed" (click)="showYeahFn(chip);chip.chipPressed = true;">{{chip.chipText}}</mat-checkbox>
      </mat-chip>
    </mat-chip-list>
    <br/>
    <br/>
    <div class="center" align="center">
      <div [@yeah]="yeahState" style="font-size: 500%; height: 33px;" *ngIf="showYeah">👍</div>
      <br/>
      <br/>
      <button class="center" mat-stroked-button color="primary" [routerLink]="'/register'">{{ 'LANDING.CHIPS.WILL_TRY' | translate}}</button>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>

<!--  <ul class="vertical">-->
<!--    <li class="li-logo"><img class="small-logo" src="/assets/images/logos/logo.jpg"> make day list of private affairs-->
<!--    </li>-->
<!--    <li><br/></li>-->
<!--    <li class="li-logo"><img class="small-logo" src="/assets/images/logos/logo.jpg"> make day schedule to get better-->
<!--      results in office work-->
<!--    </li>-->
<!--    <li><br/></li>-->
<!--    <li class="li-logo"><img class="small-logo" src="/assets/images/logos/logo.jpg"> for generating tasks for your team-->
<!--      colleagues or asks for your friends-->
<!--    </li>-->
<!--    <li><br/></li>-->
<!--    <li class="li-logo"><img class="small-logo" src="/assets/images/logos/logo.jpg"> planning common meetings</li>-->
<!--    <li><br/></li>-->
<!--    <li class="li-logo"><img class="small-logo" src="/assets/images/logos/logo.jpg"> serious projects building, that can-->
<!--      be simple site or a big ship-->
<!--    </li>-->
<!--  </ul>-->

  <br/>
  <br/>
  <br/>

  <div style="width:fit-content; margin: auto;" align="center">
    <mat-card align="center" style="width: 80%; margin: auto;">
      <br/><br/>
        <h1>
          {{ 'LANDING.STEP6.SHORT_DESCRIPTION' | translate}}
        </h1>
      <br/>
      <p style="text-align: left">
        {{ 'LANDING.STEP6.DESCRIPTION1' | translate}}
      </p>
      <br/>
      <p style="font-size: 100%; text-align: left;">
        {{ 'LANDING.STEP6.DESCRIPTION2.PART1' | translate}}
        <a href="http://ahm-pro.com" target="_blank">{{ 'LANDING.STEP6.DESCRIPTION2.PART2' | translate}}</a>
        {{ 'LANDING.STEP6.DESCRIPTION2.PART3' | translate}}
      </p>
      <br/><br/>
    </mat-card>
  </div>


  <br/><br/>

  <div style="width:fit-content; margin: auto;" align="center">
    <button [routerLink]="'/register'" mat-raised-button color="primary">{{ 'LANDING.STEP7.JOIN' | translate}}</button>
    <br/>
    <br/>
<!--    <i>{{ 'LANDING.STEP7.REMAIN' | translate}}</i>-->
  </div>

<!--  &lt;!&ndash;  STEP: FOTTER &ndash;&gt;-->
<!--  <div style="width:fit-content; margin: auto;" align="center">-->
<!--    <br/>-->
<!--    <br/>-->
<!--    -->
<!--  </div>-->

  <br/><br/>


</div>

<mat-toolbar class="toolbar" style="width: 100%; height: fit-content !important; font-size: 100% !important;" align="center">
  <table style="width: fit-content; margin: auto; text-align: left;" >
    <tr>
      <th>{{ 'FOOTER.PRODUCT' | translate}}</th>
      <th>{{ 'FOOTER.SOLUTIONS' | translate}}</th>
      <th>{{ 'FOOTER.RESOURCES' | translate}}</th>
      <th>{{ 'FOOTER.ABOUT' | translate}}</th>
<!--      <th>News and blogs</th>-->
    </tr>

    <tr>
      <!--Product-->
      <td class="footer-td"><a [routerLink]="'/tour'" >{{ 'FOOTER.PRODUCT-TOUR' | translate}}</a></td>
      <!--Solutions-->
      <td class="footer-td"><a [routerLink]="'/self-organizer'" >{{ 'FOOTER.SELF-ORGANIZER' | translate}}</a></td>
      <!--Resources-->
      <td class="footer-td"><a [routerLink]="'/public-guide'" >{{ 'FOOTER.NETTYOS-GUIDE' | translate}}</a></td>
      <!--About-->
      <td class="footer-td"><a href="http://ahm-pro.com" target="_blank">{{ 'FOOTER.VISIT-AHM' | translate}}</a></td>
      <!--News-->
      <!--<td></td>-->
    </tr>

    <tr>
      <!--Product-->
      <td class="footer-td"><a [routerLink]="'/pricing'" >{{ 'FOOTER.PRICING' | translate}}</a></td>
      <!--Solutions-->
      <td class="footer-td"><a [routerLink]="'/family-hub'" >{{ 'FOOTER.FAMILY-HUB' | translate}}</a></td>
      <!--Resources-->
      <td class="footer-td"></td>
      <!--About-->
      <td class="footer-td">
        <a [routerLink]="'/contacts'" >{{ 'FOOTER.CONTACTS' | translate}}</a>
      </td>
      <!--News-->
      <!--<td></td>-->
    </tr>

    <tr>
      <!--Product-->
      <td class="footer-td"></td>
      <!--Solutions-->
      <td class="footer-td"><a [routerLink]="'/dreams-maker'" >{{ 'FOOTER.DREAMS-MAKER' | translate}}</a></td>
      <!--Resources-->
      <td class="footer-td"></td>
      <!--About-->
      <td class="footer-td"></td>
      <!--News-->
      <!--<td></td>-->
    </tr>

    <tr>
      <!--Product-->
      <td class="footer-td"></td>
      <!--Solutions-->
      <td class="footer-td">
<!--        todo: add after grow center implemented (plus: coach, cost of interruptions) -->
<!--        <a [routerLink]="'/public-guide'" >{{ 'FOOTER.GROW-CENTER' | translate}}</a>-->
      </td>
      <!--Resources-->
      <td class="footer-td"></td>
      <!--About-->
      <td class="footer-td"></td>
      <!--News-->
      <!--<td></td>-->
    </tr>

    <tr>
      <!--Product-->
      <td class="footer-td"></td>
      <!--Solutions-->
      <td class="footer-td"><a [routerLink]="'/personal-projects'" >{{ 'FOOTER.PERSONAL-PROJECTS' | translate}}</a></td>
      <!--Resources-->
      <td class="footer-td"></td>
      <!--About-->
      <td class="footer-td"></td>
      <!--News-->
      <!--<td></td>-->
    </tr>

    <tr>
      <!--Product-->
      <td class="footer-td"></td>
      <!--Solutions-->
      <td class="footer-td"><a [routerLink]="'/all-teams'" >{{ 'FOOTER.TEAMS' | translate}}</a></td>
      <!--Resources-->
      <td class="footer-td"></td>
      <!--About-->
      <td class="footer-td"></td>
      <!--News-->
      <!--<td></td>-->
    </tr>

  </table>
  <br/>
  <br/>
</mat-toolbar>
<!--<hr/>-->
<br/>
<br/>
<span *ngIf="translate.defaultLang === 'en'" style="float: right; margin-right: 5%; margin-left: 5%;">©2009-2022 Nettyos. All rights reserved. Patented.</span>
<span *ngIf="translate.defaultLang === 'ru'" style="float: right; margin-right: 5%; margin-left: 5%;">©2009-2022 Nettyos. Все права защищены. Патенты получены.</span>
<br/>
<br/>
<br/>
<br/>
