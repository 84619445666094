import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PersonService} from "../../../services/person.service";
import {GatewayService, Ticket} from "../../../services/gateway.service";
import {tick} from "@angular/core/testing";
import {ActivatedRoute} from "@angular/router";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";

export interface Place {
  name: string;
  id: string;
  selected: boolean;
  auth: string;
}

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit, OnDestroy {
  placeName = '';
  searching = false;

  @Input() tclass = 'ticket-content';
  id = '';

  constructor(public personService: PersonService, private gateway: GatewayService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      // this.placeName = params['name'];
      // //console.log(this.id);
      if (null != this.id && this.id.length > 0) {
        this.choosePlace({id: this.id, name: '', selected: true, auth: ''});
      } else {
        this.loadAllPlaces();
      }
      //console.log('id', this.id);
    });
  }

  ngOnInit(): void {

  }

  // loading
  addPlace() {
    let place: Place = {id: '', name: this.placeName, selected: false, auth: ''};
    // this.personService.places.push(place);
    this.placeName = '';
    this.personService.loading = true;
    this.gateway.planningServiceNewPlace(place).subscribe(data => {
      // console.log('choosePlace', data)
      // todo: load all places
      this.personService.loading = false;
      this.loadAllPlaces();
    });
  }

  // loading
  choosePlace(place: Place) {
    this.searching = true;
    for (let pl of this.personService.places) {
      pl.selected = false;
      if (place.name === '' && place.id === pl.id) {
        place = pl;
      }
    }
    place.selected = true;
    this.personService.loading = true;
    this.gateway.planningServicePlaceTickets(place).subscribe(data => {
      // console.log('planningServicePlaceTickets', data);
      // this.personService.places = data;
      this.personService.placeTickets = data;
      this.personService.loading = false;
    });
  }

  // loading
  loadAllPlaces() {
    this.personService.loading = true;
    this.gateway.planningServiceAllPlaces().subscribe(data => {
      // console.log('loadAllPlaces', data);
      this.personService.places = data;
      this.personService.loading = false;
    });
  }

  // loading
  removePlace(place: Place) {
    this.personService.loading = true;
    this.gateway.planningServiceDeletePlace(place).subscribe(data => {
      // console.log('choosePlace', data)
      // todo: load all places
      this.personService.loading = false;
      this.loadAllPlaces();
    });
  }

  // no loading
  ngOnDestroy(): void {
    this.personService.placeTickets = [];
    for (let pl of this.personService.places) {
      pl.selected = false;
    }
    this.searching = false;
  }

  // loading
  moveTicketToToday(ticket: Ticket) {
    ticket.status = 'today';
    //console.log(ticket);
    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(data => {
      this.personService.loading = false;
      this.loadAllPlaces();
    });
  }

  moveTicketToDone(ticket: Ticket) {
    ticket.status = 'done';
    //console.log(ticket);
    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(
      (answer: ExtendedStatus) => {
        this.upCoins(answer);
      this.personService.loading = false;
      this.loadAllPlaces();
    });
  }

  moveTicketToCancelled(ticket: Ticket) {
    ticket.status = 'cancelled';
    //console.log(ticket);
    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(
      (answer: ExtendedStatus) => {
      this.upCoins(answer);
      this.personService.loading = false;
      this.loadAllPlaces();
    });
  }

  private upCoins(answer: ExtendedStatus) {
    if ('GOLD' === answer.message) {
      // this.personService.gold += answer.measurement;
      this.personService.addCoins(this.personService.silver, this.personService.gold + answer.measurement)
    } else if ('SILVER' === answer.message) {
      this.personService.addCoins(this.personService.silver + answer.measurement, this.personService.gold)
    }
  }
}
