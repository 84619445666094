<br/>
<br/>
<br/>
<div class="wrapper">
  <a [routerLink]="'/login'">
    <button mat-stroked-button color="primary" class="lr-btn" alert="1">{{ 'SIGN.LOGIN_' | translate}}</button>
  </a>
  <br/>
  <br/>
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="passwordsFormGroup">
      <form [formGroup]="passwordsFormGroup" (ngSubmit)="resetOnSubmitStep1(passwordsFormGroup.value)">
        <ng-template matStepLabel>{{ 'SIGN.NEW_PASSWORD_SET' | translate}}</ng-template>
        <mat-form-field>
          <mat-label>{{ 'SIGN.EMAIL' | translate}}</mat-label>
          <input matInput type="email" placeholder="Email" formControlName="emailCtrl" required>
        </mat-form-field>
        <br/>
        <mat-form-field>
          <mat-label>{{ 'SIGN.NEW_PASSWORD' | translate}}</mat-label>
          <input matInput type="password" formControlName="pwdCtrl" placeholder=""
                 required>
        </mat-form-field>
        <br/>
        <mat-form-field>
          <mat-label>{{ 'SIGN.CONFIRMATION' | translate}}</mat-label>
          <input matInput type="password" formControlName="cPwdCtrl" placeholder=""
                 required>
        </mat-form-field>
        <div>
          <br/>
          <button
            mat-stroked-button
            [disabled]="
            passwordsFormGroup.get('pwdCtrl').value.length < 6
            || passwordsFormGroup.get('pwdCtrl').value !== passwordsFormGroup.get('cPwdCtrl').value
            "
            matStepperNext
            type="submit"
          >{{ 'SIGN.CONTINUE' | translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="confirmationCodeFormGroup">
      <form [formGroup]="confirmationCodeFormGroup" (ngSubmit)="resetOnSubmitStep2(confirmationCodeFormGroup.value)">
        <ng-template matStepLabel>{{ 'SIGN.CONFIRM_CODE' | translate}}</ng-template>
        <mat-form-field>
          <mat-label>{{ 'SIGN.CONFIRMATION_CODE' | translate}}</mat-label>
          <input matInput formControlName="codeCtrl" placeholder=""
                 required>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>{{ 'SIGN.BACK' | translate}}</button>
          <button
            mat-button
            matStepperNext
            type="submit"t>{{ 'SIGN.CONFIRM' | translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'SIGN.DONE' | translate}}</ng-template>
      <br/>
      <p [innerHTML]="resetResult" [style.color]="resetColor"></p>
      <br/>
      <div *ngIf="!goAuth">
        <button mat-stroked-button color="primary" matStepperPrevious>{{ 'SIGN.BACK' | translate}}</button>
        &nbsp;
        <button mat-stroked-button color="primary" (click)="stepper.reset()">{{ 'SIGN.RESET' | translate}}</button>
        &nbsp;
        <a [routerLink]="'/login'"><button mat-stroked-button color="primary">{{ 'SIGN.LOGIN' | translate}}</button></a>
      </div>
      <div *ngIf="goAuth">
        <a [routerLink]="'/today'"><button mat-stroked-button color="primary">{{ 'SIGN.GO' | translate}}</button></a>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
