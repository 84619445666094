<div class="center">
  <br/>
  <br/>
  <br/>

  <div *ngIf="translate.defaultLang === 'en'">
    <!-- Personal Coach -->
    <mat-card class="coach-card">
      <mat-card-header>
        <div mat-card-avatar class="coach-header-image"></div>
        <mat-card-title>Personal Coach</mat-card-title>
        <mat-card-subtitle>recommendations for today</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>
          Complete these points. At least some.
        </p>
        <span
          *ngIf="allToToday && upToDate && morningPlanning && eveningPlanning && commandCenter"
          style="font-size: 300%; position: absolute; right: 3%; top: 20%;"
        >👍</span>
      </mat-card-content>
    </mat-card>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="allToToday" color="primary" class="coach-mat-checkbox">Put all into Today list</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: allToToday ? '#03A9F4': 'transparent'}">
      <li>your ideas, thoughts, dreams</li>
      <li>your tasks, deals, affairs or plans</li>
      <li>your skills, life-hacks, etc.</li>
      <li>your appointments, meetings, dating</li>
      <li>all you want but briefly</li>
    </ul>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="upToDate" color="primary" class="coach-mat-checkbox">Set Today list up to date</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: upToDate ? '#03A9F4': 'transparent'}">
      <li>move all you won't finish today to the future</li>
      <li>move all ideas you won't start today to 'idea'</li>
      <li>pin all tickets related to projects to there</li>
      <li>pin all tickets related to teams to there</li>
      <li>mark all finished tickets with 'done' or 'cancelled'</li>
      <li>remove all tickets will never do 100%</li>
    </ul>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="morningPlanning" color="primary" class="coach-mat-checkbox">Morning planning</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: morningPlanning ? '#03A9F4': 'transparent'}">
      <li>move to Today list from 'idea' section</li>
      <li>move to Today list from 'future' section</li>
      <li>move to 'In Progress' form projects</li>
      <li>move to 'In Progress' form team projects</li>
      <li>calculate all your psychic energy for Today</li>
      <li>calculate all your resources for Today list</li>
      <li>update Today list finally</li>
    </ul><br/>
    <br/>
    <mat-checkbox [(ngModel)]="eveningPlanning" color="primary" class="coach-mat-checkbox">Evening planning</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: eveningPlanning ? '#03A9F4': 'transparent'}">
      <li>put tickets from Today list to the dates</li>
      <li>figure out hard tickets: later, cancel, done...</li>
      <li>adjust tickets with check-boxes, description...</li>
      <li>try to make all Today list empty</li>
    </ul>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="commandCenter" color="primary" class="coach-mat-checkbox">Use nettyos as your Command Center</mat-checkbox>
    <br/>
    <i>
      <ul [ngStyle]="{backgroundColor: commandCenter ? '#03A9F4': 'transparent'}">
        <li>visit your projects and teams</li>
        <li>share tickets with friends and contacts</li>
        <li>delegate tickets to the team</li>
      </ul>
    </i>

  </div>

  <div *ngIf="translate.defaultLang === 'ru'">
    <!-- Personal Coach -->
    <mat-card class="coach-card">
      <mat-card-header>
        <div mat-card-avatar class="coach-header-image"></div>
        <mat-card-title>Личный Коуч</mat-card-title>
        <mat-card-subtitle>рекомендации на сегодня</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>
          Выполните эти пункты. Хотя бы некоторые.
        </p>
        <span
          *ngIf="allToToday && upToDate && morningPlanning && eveningPlanning && commandCenter"
          style="font-size: 300%; position: absolute; right: 3%; top: 20%;"
        >👍</span>
      </mat-card-content>
    </mat-card>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="allToToday" color="primary" class="coach-mat-checkbox">Поместите все в список "Сегодня"</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: allToToday ? '#03A9F4': 'transparent'}">
      <li> ваши идеи, мысли, мечты </li>
      <li> ваши задачи, дела или планы </li>
      <li> ваши навыки, лайфхаки и т. д. </li>
      <li> ваши встречи, свидания </li>
      <li> все, что вы хотите, но кратко </li>
    </ul>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="upToDate" color="primary" class="coach-mat-checkbox">Актуализировать список "Сегодня"</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: upToDate ? '#03A9F4': 'transparent'}">
      <li> перенесите все, что вы не закончите сегодня, в будущее </li>
      <li> переместите все идеи, которые вы не начнете сегодня к "идеям" </li>
      <li> прикрепите соответсвующие тикеты к проектам</li>
      <li> прикрепите соответсвующие тикеты к командам</li>
      <li> пометьте все готовые тикеты: "готово"/"отменено"</li>
      <li> удалите все тикеты которые не нужны 100% </li>
    </ul>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="morningPlanning" color="primary" class="coach-mat-checkbox">Утренний планинг</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: morningPlanning ? '#03A9F4': 'transparent'}">
      <li> отправить тикеты в список "Сегодня" из раздела "идеи" </li>
      <li> отправить тикеты в список "Сегодня" из раздела "будущее" </li>
      <li> актуализировать "Выполняется" в проектах</li>
      <li> актуализировать "Выполняется" в командах </li>
      <li> рассчитайте всю свою психическую энергию на Сегодня </li>
      <li> рассчитайте все свои ресурсы для списка "Сегодня" </li>
      <li> финально обновите список "Сегодня" </li>
    </ul><br/>
    <br/>
    <mat-checkbox [(ngModel)]="eveningPlanning" color="primary" class="coach-mat-checkbox">Вечерний планинг</mat-checkbox>
    <ul [ngStyle]="{backgroundColor: eveningPlanning ? '#03A9F4': 'transparent'}">
      <li> поместите тикеты из списка "Сегодня" на даты </li>
      <li> сложные тикеты: позже, отмена, готово ... </li>
      <li> обогатите тикеты с помощью чек-листа, описания ... </li>
      <li> попытайтесь сделать весь список "Сегодня" пустым </li>
    </ul>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="commandCenter" color="primary" class="coach-mat-checkbox">Использовать nettyos как Командный Центр</mat-checkbox>
    <br/>
    <i>
      <ul [ngStyle]="{backgroundColor: commandCenter ? '#03A9F4': 'transparent'}">
        <li>посетите проекты и команды</li>
        <li>поделитесь тикетами с друзьями и контактами</li>
        <li>делегируйте тикеты команде</li>
      </ul>
    </i>

  </div>

</div>
