<div style="width: 96%; margin: auto;">
  <br/>
  <br/>
  <h1>Privacy Policy</h1>
  <br/>
  <br/>
  last update: 2020/07/20
  <br/>
  <br/>
  <hr/>
  <br/>
  <br/>
  <h2>Data Collection</h2>
  <p>
    When you use this service, this service will automatically collect and store the data generated from your
    interaction with this service, including the term you search, your browse history in this site, the link you click,
    the device you use, and your location information, etc.
  </p>
  <h3>Personal Information</h3>
  <p>
    We also keep the information you provided when you register in this service, including your email address as your
    account, your preferred display name, and store them in your personal account. If you sign in with 3rd party
    services like Facebook or Google, we'll also retrieve basic pieces of information provided by these services,
    including your preferred avatar, which could also be collected when you manually upload it on the settings page.
    When you contact us, we will keep your call record and email contact to help you resolve any issues you encountered.
    When you make a purchase or subscription, we will keep some transaction details, including your IP address, basic
    payer information. Additionally, your password will be encrypted with enough strength automatically by this service
    before we store it in the database. Thus, we won't know your password.
  </p>
  <h3>Cookies</h3>
  <p>
    We and our partners ( including Google, Facebook, VK, and others ) use cookies to identify your browser and device.
  </p>
  <h3>Assets</h3>
  <p>
    You can save your assets, including images, configurations, settings, tickets, and designs in this service when you
    explicitly click the save button or another way. We keep these data within our database, associated with your
    account for you to access.
  </p>
  <br/>
  <br/>
  <h2>Data Usage</h2>
  <p>
    Data collected will be used to provide, maintain, and improve this service, and to develop new services or protect
    this service and its users. We will also use these data to provide you personalized content.
  </p>
  <h3>Personal Information</h3>
  <p>
    We will use your email to send notifications to you about the change or update of this service. Your email will also
    be used to identify your account and help you to reset the password when you lost it. We also use your email to send
    marketing and product promotion information, but only when you subscribe to our newsletter when signing up or in the
    settings. Your display name will be visible to others along with your public assets.
  </p>
  <h3>Cookies</h3>
  <p>
    Cookie will be used to identify your account and improve the quality of this service, such as, remember who you are
    and your settings as a default when you visit this service next time.
  </p>
  <h3>Assets</h3>
  <p>
    When saved, your assets, including customized configuration and uploaded images, will be public and used as
    showcases for other users to see how you customize your public assets. All assets are public except private lists
    of tickets.
  </p>
  <br/>
  <br/>
  <h2>Data Sharing</h2>
  <p>
    Your collected data will be stored, analyzed, or processed by this service, or the 3rd party service that we trusted
    to provide better service to our users.
  </p>
  <h3>Personal Information</h3>
  <ul>
    <li>approved by you</li>
    <li>requested due to legal reason</li>
    <li>for processing - data will be sent to trusted organizations or people to help us process, manage or analysis
      under our instruction
    </li>
  </ul>
  <p>
    Your ID in this service ( a random key which could be found in your profile page ) will be provided to any
    analytics system we trust to help us to improve this service for you by knowing more about your behavior in our
    service.
  </p>
  <h3>Assets</h3>
  <p>
    All your assets, except today list of tickets, will by default be public and can be viewed by other
    users. We provide a personal profile for each user that shows their saved assets.
  </p>
  <br/>
  <br/>
  <h2>Data Deletion</h2>
  You can contact us directly to request a data deletion, including your account, all your personal information and
  saved assets.
  <br/>
  <br/>
  <h2>Modification</h2>
  <p>
    This privacy policy is probably going to be updated frequently in the future. If an update of this privacy policy is
    made, there will be a notification for you when you access this service, and the update will also be published here
    on this page. Modification history will also be available as a link on this page. You can check each revision of our
    privacy policy at any time.
  </p>
</div>
