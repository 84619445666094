import { Injectable } from '@angular/core';
import {ExtendedStatus} from "../dto/ExtendedStatus";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {DatePipe} from "@angular/common";
import {Person} from "../components/today/people/people.component";
import {PersonService, Team, TeamMember} from "./person.service";
import {Place} from "../components/today/places/places.component";
import {Project} from "../components/today/projects/projects.component";
import {tick} from "@angular/core/testing";
import {TeamProject} from "../components/today/teams/teams.component";
import {UserComment} from "../dto/Comment";
import {Alert} from "../dto/Alerts";


export interface Order {
  id: number,
  accountType: string,
  period: string,
  email: string,
  paymentDone: string,
  auth: string
}

export interface Profile {
  fname: string,
  lname: string,
  email: string,
  ava: string,
  auth: string,
  conf: string,
  silverCoin: number,
  goldCoin: number,
  account: string,
  accountExpirationDate: string,
  expirationDateType: string,
  userId: string,
  tsCreated: number
}
// b - black theme, w - white theme

export interface Ticket {
  // local
  relatedTicketId: string;
  commentsFlag: any;

  tmpOption: string;
  // checkbox
  checkListActual: string [];
  checkListDone: string [];
  //
  checkListOption: string;
  checkListAddFlag: boolean;
  descriptionAddFlag: boolean;
  description: string;
  fullDescription: boolean;
  auth: string,
  tomorrowDateSending: string,
  ttext: string,
  uuid: string,
  deleteButtonColor: string,
  status: string,
  // teams
  teamStatus: string,
  teamId: string,
  teamProjectId: string,
  epic: string,
  sprint: string,
  //
  hh: string,
  mm: string,
  pickerDate: Date,
  tsOfPlannedDate: number,
  alertTsBefore: number,
  dateIdStr: string,
  dateAndTimeIdStr: string,
  direction: string,
  personFullName: string,
  personId: string,
  relatedStatus: string,
  addPlace: string,
  placeId: string,
  placeName: string,
  // personal projects
  projectFullName: string,
  projectShortName: string,
  projectId: string,
  businessProcessStep: string,
  //
  answer: string
  // user
  userAuthorId: string;
  userHasGotId: string;
  animationState: string;
  edit: string;
  // comments
  commentsNumberThis: number;
  commentsNumberMirror: number;
}

export interface AmountUmoney {
  value: string;
  currency: string;
}

export interface ConfirmationUmoney {
  type: string;
  return_url: string;
}

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  public localhost = false;
  public NETTY_CLIENT = 'http://localhost:7070';
  public LOGIN_REGISTER_SERVICE = 'http://localhost:7071';
  public USER_SERVICE = 'http://localhost:7072';
  public PLANNING_SERVICE = 'http://localhost:7073';
  public NETTY_CLIENT_ANGULAR = 'http://localhost:4200';

  constructor(private http: HttpClient, private auth: AuthService,
              private datePipe: DatePipe,
              private personService: PersonService) {
    if (String (document.location).indexOf('localhost') !== -1) {
      this.localhost = true;
    }
  }

  userServiceGetUserProfile<Profile>() {
    let url = '/api/usr/v1/user/profile/';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.put<Profile>(url, {auth: this.auth.token, tomorrow: ''});
  }

  userServiceChangeUserTheme() {
    // change
    let url = '/api/usr/v1/user/theme/';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.put(url, {auth: this.auth.token, tomorrow: ''});
  }

  // todo: read from cookies
  userAddPerson(email, typeOfRelations) {
    // change
    let url = '/api/usr/v1/user/person/';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.post(url,
      {
        auth: this.auth.token,
        email: email,
        type: typeOfRelations
      });
  }

  // Planning service
  // ******************************************************************
  //
  planningServiceGetTicketsList(category: string) {
    let dateToday = new Date();
    const dateStr = this.datePipe.transform(dateToday,"yyyy-MM-dd:HH:mm:ss");
    let url = '/api/pln/v1/tickets/' + category + '?date=' + dateStr;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    let date = new Date();
    date.setDate(date.getDate() + 1);
    const tomorrow = this.datePipe.transform(date,"dd-MM-yyyy");
    return this.http.put<Ticket[]>(url, {auth: this.auth.token, tomorrow: tomorrow});
  }


  planningServiceNewTicket(value: any) {
    const data = {
      auth: this.auth.token,
      form: value
    };
    // console.log('planningServiceNewTicket', data);
    // let date = new Date();
    // console.log(date.toISOString());
    let date = new Date();
    date.setDate(date.getDate());
    const dateStr = this.datePipe.transform(date,"yyyy-MM-dd:HH:mm:ss");
    let url = '/api/pln/v1/new?date=' + dateStr;//  + date.toISOString();
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.post(url, data);
  }

  planningServiceDeleteTicket(uuid: string) {
    let url = '/api/pln/v1/delete/' + uuid;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.delete(url);
  }

  loginRegisterServiceCheckAuth() {
    let url = '/api/lrs/v1/login/check';
    if (this.localhost) {
      url = this.LOGIN_REGISTER_SERVICE + url;
    }
    return this.http.put(url, {auth: this.auth.token, tomorrow: ''});
  }

  planningServiceNewTicketStatus(ticket: Ticket) {
    let dateToday = new Date();
    const dateStr = this.datePipe.transform(dateToday,"yyyy-MM-dd:HH:mm:ss");
    let url = '/api/pln/v1/status?date=' + dateStr;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    let date = new Date();
    date.setDate(date.getDate() + 1);
    ticket.tomorrowDateSending = this.datePipe.transform(date,"dd-MM-yyyy");
    // console.log('ticket.dateSending', ticket.tomorrowDateSending, this.datePipe.transform(date,"dd-MM-yyyy"));
    return this.http.put(url, ticket);
  }

  outPersons() {
    let url = '/api/usr/v1/user/people/';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.put<any []>(url,
      {
        auth: this.auth.token
      });
  }

  approvePerson(personId: string, tmpType: string) {
    let url = '/api/usr/v1/user/approve/';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.post(url,
      {
        auth: this.auth.token,
        type: tmpType,
        id: personId
      });
  }

  removePerson(personId: string) {
    let url = '/api/usr/v1/user/relation/remove/';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.put(url,
      {
        auth: this.auth.token,
        id: personId
      });
  }

  planningServiceAssignTicket(ticket: Ticket) {
    let url = '/api/pln/v1/assign';
    const personId = ticket.personId;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    ticket.direction = 'to';
    //console.log('planningServiceAssignTicket', ticket);
    let person: Person = null;
    for (let aPerson of this.personService.persons) {
      if (aPerson.personId === personId ) {
        person = aPerson;
      }
    }
    ticket.personFullName = person.firstName + ' ' + person.lastName;
    ticket.personId = person.personId;
    return this.http.post(url, ticket);
  }

  planningServiceNewPlace(place: Place) {
    let url = '/api/pln/v1/places/new';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    place.auth = this.auth.token;
    //console.log(url, place);
    return this.http.post(url, place);
  }

  planningServiceAllPlaces() {
    let url = '/api/pln/v1/places/all/' + this.auth.token;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Place []>(url);
  }

  planningServiceDeletePlace(place: Place) {
    let url = '/api/pln/v1/places/delete/' + this.auth.token + '/' + place.id;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.delete(url);
  }

  planningServicePlaceTickets(place: Place) {
    let url = '/api/pln/v1/places/tickets/' + this.auth.token + '/' + place.id;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Ticket []>(url);
  }

  planningServicePlaceToTicket(ticket: Ticket) {
    let url = '/api/pln/v1/tickets/place';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.post(url, ticket);
  }

  planningServiceNewProject<ExtendedStatus>(project: Project) {
    let url = '/api/pln/v1/projects/add';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    project.auth = this.auth.token;
    return this.http.post<ExtendedStatus>(url, project);
  }

  planningServiceAllUserProjects() {
    let url = '/api/pln/v1/projects/all/' + this.auth.token;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Project []>(url);
  }

  planningServiceSetTicketToProject(ticket: Ticket) {
    let url = '/api/pln/v1/projects/ticket';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.put(url, ticket);
  }

  planningServiceProjectTickets(project: Project, column) {
    let url = '/api/pln/v1/projects/tickets/' + this.auth.token + '/' + project.id + '/' + column;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Ticket []>(url);
  }

  planningServiceGetTicketListBySearch(search: string) {
    let dateToday = new Date();
    const dateStr = this.datePipe.transform(dateToday,"yyyy-MM-dd:HH:mm:ss");
    let url = '/api/pln/v1/tickets/search?date=' + dateStr;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.put<Ticket[]>(url, {auth: this.auth.token, search: search});
  }

  planningServiceSaveTicketDescription(ticket: Ticket) {
    let url = '/api/pln/v1/tickets/description/save';
    ticket.auth = this.auth.token;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.post<Ticket[]>(url, ticket);
  }

  planningServiceNewTeam(team: Team) {
    let url = '/api/pln/v1/teams/team/new';
    team.auth = this.auth.token;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.post<ExtendedStatus>(url, team);
  }

  planningServiceLoadAllTeams() {
    let url = '/api/pln/v1/teams/team/all/' + this.auth.token;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Team[]>(url);
  }

  planningServiceLoadAllTeamMembers(teamId: string) {
    let url = '/api/pln/v1/teams/team/members/' + this.auth.token + '/' + teamId;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Team>(url);
  }

  planningServiceAddTeamMember(team: Team, newMember: TeamMember) {
    let url = '/api/pln/v1/teams/team/members/add';
    team.auth = this.auth.token;
    team.newMember = newMember;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.post<ExtendedStatus>(url, team);
  }

  userServiceGetUserId(memberEmail: string) {
    // /member/uuid/{auth}/{email}
    let url = '/api/usr/v1/user/member/uuid/' + this.auth.token + '/' + memberEmail;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.get<TeamMember>(url);
  }

  planningServiceRemoveTeamMember(member: TeamMember, teamId) {
    let url = '/api/pln/v1/teams/team/members/delete/' + this.auth.token + '/' + member.userId + '/' + teamId;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.delete<ExtendedStatus>(url);
  }

  planningServiceNewTeamProject(teamProject: TeamProject) {
    let url = '/api/pln/v1/teams/team/projects/new';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    teamProject.auth = this.auth.token;
    return this.http.post<ExtendedStatus>(url, teamProject);
  }

  planningServicAllTeamProjects(teamId: string) {
    let url = '/api/pln/v1/teams/team/projects/all/' + this.auth.token + '/' + teamId;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<TeamProject []>(url);
  }

  // planningServicGetTeamProject(teamProjectId: any) {
  //   let url = '/api/pln/v1/teams/team/projects/get/' + this.auth.token + '/' + teamProjectId;
  //   if (this.localhost) {
  //     url = this.PLANNING_SERVICE + url;
  //   }
  //   return this.http.get<TeamProject>(url);
  // }
  planningServicNewTeamProjectTicket(ticket: Ticket) {
    let url = '/api/pln/v1/teams/team/project/tickets/new';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.post<Ticket>(url, ticket);
  }

  planningServicLoadTeamTickets(teamProjectId: any, section: string, epic: string, sprint: string) {
    const epicLocal = epic == null ? '111111100110101100101000000000' : epic;
    const sprintLocal = sprint == null ? '111111100110101100101000000000' : sprint;
    let url = '/api/pln/v1/teams/team/project/tickets/load/' + this.auth.token + '/' + teamProjectId + '/' + section + '/' + epicLocal + '/' + sprintLocal ;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Ticket []>(url);
  }

  planningServiceChangeTeamProjectTicketStatus(ticket: Ticket) {
    let url = '/api/pln/v1/teams/team/project/tickets/status';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceLoadAllTeamMembersByProjectId(teamProjectId: any) {
    let url = '/api/pln/v1/teams/team/members/tproject/' + this.auth.token + '/' + teamProjectId;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<Team>(url);
  }

  planningServicLoadTeamProject(teamProjectId: any) {
    let url = '/api/pln/v1/teams/team/project/project/' + this.auth.token + '/' + teamProjectId;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.get<TeamProject>(url);
  }

  planningServiceAssignNewTeamMember(assigned: string, ticket: Ticket) {
    let url = '/api/pln/v1/teams/team/members/assign/';
    ticket.userHasGotId = assigned;
    ticket.auth = this.auth.token;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceEditTicketText(ticket: Ticket) {
    let url = '/api/pln/v1/tickets/edit/text/';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  changeAccountControlService(user: Profile, period) {
    let url = '/api/usr/v1/user/control/account/change/' + period;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    user.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, user);
  }

  allAccountsControlService() {
    let url = '/api/usr/v1/user/control/profiles/' + this.auth.token;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.get<Profile []>(url);
  }

  userServiceCreateOrder(email: string, number: number, plan: string, period: string) {
    let url = '/api/usr/v1/payment/order/new';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    const order: Order = {
      accountType: plan,
      email: email,
      id: 0,
      paymentDone: "no",
      period: period,
      auth: this.auth.token
    };
    return this.http.post<Order>(url, order);
  }

  loginRegisterServiceChangePassword(changePasswordForm: any) {
    let url = '/api/lrs/v1/reset/password/change/' + this.auth.token;
    if (this.localhost) {
      url = this.LOGIN_REGISTER_SERVICE + url;
    }
    return this.http.put(url, changePasswordForm);
  }

  planningServiceAddCheckListOptionToTicket(ticket: Ticket) {
    let url = '/api/pln/v1/tickets/checklist/option/new';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.post<ExtendedStatus>(url, ticket);
  }

  planningServiceSetCheckListOptionCheckedToTicket(ticket: Ticket, optionActual: string) {
    let url = '/api/pln/v1/tickets/checklist/option/checked';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    ticket.tmpOption = optionActual;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceSetCheckListOptionUncheckedToTicket(ticket: Ticket, optionActual: string) {
    let url = '/api/pln/v1/tickets/checklist/option/unchecked';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    ticket.tmpOption = optionActual;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceDeleteCheckListOptionInTicket(ticket: Ticket, optionToDelete: string) {
    let url = '/api/pln/v1/tickets/checklist/option/delete';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    ticket.tmpOption = optionToDelete;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceUpdateProjectBusinessProcess(project: Project) {
    let url = '/api/pln/v1/projects/bp/update';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    project.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, project);
  }

  planningServiceUpdateTicketBusinessStep(ticket: Ticket) {
    let url = '/api/pln/v1/tickets/bp/step/update';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceLoadFullTicket(ticket: Ticket) {
    let url = '/api/pln/v1/tickets/full-ticket/'+this.auth.token + '/' + ticket.uuid;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.get<Ticket>(url);
  }

  uploadProfileImage(fd: FormData) {
    let url = '/api/usr/v1/user/new-ava/'+this.auth.token;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.post(url, fd, {
      reportProgress: true,
      observe: 'events'
    });
  }

  planningServiceResetProjectInTicket(ticket: Ticket) {
    let url = '/api/pln/v1/tickets/project-reset';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceUpdateProjectName(project: Project) {
    let url = '/api/pln/v1/project/update-name';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    project.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, project);
  }

  planningServiceArchiveUnzipProject(project: Project) {
    let url = '/api/pln/v1/project/archive-unzip';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    project.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, project);
  }

  planningServiceRemoveProject(project: Project) {
    let url = '/api/pln/v1/project/delete/' + this.auth.token + '/' + project.id;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    // project.auth = this.auth.token;
    return this.http.delete<ExtendedStatus>(url);
  }

  // /api/pln/v1/teams/team/project/tickets/new
  getAllTeamProjectsService() {
    let url = '/api/pln/v1/teams/team/all-team-projects/' + this.auth.token;
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    // project.auth = this.auth.token;
    return this.http.get<TeamProject []>(url);
  }

  userServiceAddNewComment(comment: UserComment) {
    let url = '/api/usr/v1/user/comments/new';
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    comment.auth = this.auth.token;
    return this.http.post(url, comment);
  }

  userServiceGetCommentsByTicket(ticket: Ticket) {
    // comments/by/ticket/{auth}/{ticketId}
    // console.log('*' + this.auth.token + '*', '%' + ticket.uuid + '%', '$' + ticket.relatedTicketId + '$');
    if ('' === ticket.relatedTicketId) {
      ticket.relatedTicketId = 'no';
    }
    let url = '/api/usr/v1/user/comments/by/ticket/' + this.auth.token + '/' + ticket.uuid + '/' + ticket.relatedTicketId;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.get<UserComment []>(url);
  }

  userServiceDeleteComment(cmm: UserComment, ticket: Ticket) {
    let url = '/api/usr/v1/user/comments/delete/' + this.auth.token + '/' + ticket.uuid + '/' + cmm.id;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.delete<ExtendedStatus>(url);
  }

  // planningServiceTicketCounter(ticket: Ticket, increase: string) {
  //   let url = '/api/pln/v1/teams/team/all-team-projects/' + this.auth.token;
  //   if (this.localhost) {
  //     url = this.PLANNING_SERVICE + url;
  //   }
  //   // project.auth = this.auth.token;
  //   return this.http.get<TeamProject []>(url);
  // }

  planningServiceSetTicketToTeam(ticket: Ticket) {
    let url = '/api/pln/v1/teams/team/team-project/join-ticket';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.auth = this.auth.token;
    return this.http.put(url, ticket);
  }

  planningServiceUpdateTeamProjectBusinessProcess(teamProject: TeamProject) {
    let url = '/api/pln/v1/projects/team/bp/update';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    teamProject.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, teamProject);
  }

  planningServicUpdateTeamName(team: Team) {
    let url = '/api/pln/v1/teams/team/name/update';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    team.auth = this.auth.token;
    return this.http.put(url, team);
  }

  planningServiceUpdateTeamProjectName(teamProject: TeamProject) {
    let url = '/api/pln/v1/projects/team-project/name/update';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    teamProject.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, teamProject);
  }

  planningServiceTeamProjectAddEpic(teamProject: TeamProject) {
    let url = '/api/pln/v1/projects/team-project/epic/add';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    teamProject.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, teamProject);
  }

  planningServiceTeamProjectAddSprint(teamProject: TeamProject) {
    let url = '/api/pln/v1/projects/team-project/sprint/add';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    teamProject.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, teamProject);
  }

  planningServiceAssignEpic2Ticket(ticket: Ticket, $event: any) {
    let url = '/api/pln/v1/projects/team-project/epic-sprint/assign';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.epic = $event;
    ticket.sprint = '';
    ticket.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  planningServiceAssignSprint2Ticket(ticket: Ticket, $event: any) {
    let url = '/api/pln/v1/projects/team-project/epic-sprint/assign';
    if (this.localhost) {
      url = this.PLANNING_SERVICE + url;
    }
    ticket.epic = '';
    ticket.sprint = $event;
    ticket.auth = this.auth.token;
    return this.http.put<ExtendedStatus>(url, ticket);
  }

  userServiceGetUserAlerts() {
    let url = '/api/usr/v1/user/alerts/' + this.auth.token;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.get<Alert []>(url);
  }

  userServiceUnreadUserAlert(alert: Alert) {
    let url = '/api/usr/v1/user/alerts/read';
    alert.auth = this.auth.token;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.put<ExtendedStatus>(url, alert);
  }

  userServicePayUkassaGetFinalLink(totalAmount: number, currency: string, description: string) {
    //  curl https://api.yookassa.ru/v3/payments \
    //   -X POST \
    //   -u <Идентификатор магазина>:<Секретный ключ> \
    //   -H 'Idempotence-Key: <Ключ идемпотентности>' \
    //   -H 'Content-Type: application/json' \
    //   -d '{
    //         "amount": {
    //           "value": "100.00",
    //           "currency": "RUB"
    //         },
    //         "capture": true,
    //         "confirmation": {
    //           "type": "redirect",
    //           "return_url": "https://www.merchant-website.com/return_url"
    //         },
    //         "description": "Заказ №1"
    //       }'

    let url = '/api/usr/v1/payment/umoney/1/' + this.auth.token;
    // alert.auth = this.auth.token;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }

    //const url = 'https://api.yookassa.ru/v3/payments';
    const randomIdempotenceKey = this.makeRandom();

    let umoneyPaymentRequestData = {
      "amount": {
        "value": totalAmount + ".00",
        "currency": currency
      },
      "capture": true,
      "confirmation": {
        "type": "redirect",
        "return_url": "https://nettyos.com/api/usr/v1/payment/okayukassa?i="+this.personService.invId+"&c="+randomIdempotenceKey
      },
      "description": description,
      "invId": this.personService.invId,
      "idempotence": randomIdempotenceKey
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Idempotence-Key': randomIdempotenceKey,
        'Authorization': 'Basic ' + btoa('799759:live_tk-BiPOk4cakAtRGl2MFpwffP77hwrJuoDS-7wgxRjw')
      })
    };

    return this.http.post<ExtendedStatus>(url, umoneyPaymentRequestData);

  }

  makeRandom() {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
    const lengthOfCode = 33;
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  userServicePullWorkingChart() {
    let url = '/api/usr/v1/user/chart/pull/' + this.auth.token;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.get<any []>(url);
  }


  userServicePushWorkingChart(workingChart: string) {
    let url = '/api/usr/v1/user/chart/push/' + this.auth.token;
    if (this.localhost) {
      url = this.USER_SERVICE + url;
    }
    return this.http.put<ExtendedStatus>(url, workingChart);
  }
}
