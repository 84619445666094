import {Component, Input, OnInit} from '@angular/core';
import {PersonService} from "../../../services/person.service";
import {GatewayService, Profile, Ticket} from "../../../services/gateway.service";
import {Place} from "../places/places.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {TicketDialogComponent} from "../team-projects/ticket-dialog/ticket-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {bounce, bounceInDown, bounceInUp, bounceOutUp, zoomOut} from "ng-animate";
import {DatePipe} from "@angular/common";

export interface Project {
  id: string,
  name: string,
  shortName: string,
  selected: boolean,
  auth: string,
  businessProcess: string,
  archive: boolean
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [
    trigger('settings', [
      transition('void => *', useAnimation(bounceInUp)),
      transition('* => void', useAnimation(bounceOutUp))
    ])
  ]
})
export class ProjectsComponent implements OnInit {

  steps = [];

  // 0 - project, 1 - business process
  projectBusinessProcessTabIndex = 0;

  emailFormControl = new FormControl('', [
    Validators.required,
  ]);

  projectGroup: FormGroup = null;


  matcher = new MyErrorStateMatcher();

  selectProjectId = 'select';

  drop(event, column) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    //console.log('?item', event.container.data[event.currentIndex], column)
    if ('backlog' === column) {
      this.setProjectStatus(event.container.data[event.currentIndex]);
    } else if ('progress' === column) {
      this.setTodayStatus(event.container.data[event.currentIndex]);
    } else if ('completed' === column) {
      this.setDoneStatus(event.container.data[event.currentIndex]);
    } else if ('todo' === column) {
      this.setLaterStatus(event.container.data[event.currentIndex]);
    }
  }

  // end of playground
  projectFullName = '';
  projectShortName = '';
  projectId = 'select';
  project: Project = {archive: false, auth: "", id: "", name: "", selected: false, shortName: "", businessProcess: ""};
  id = '';

  @Input() tclass = 'ticket-content';
  @Input() wrapperClass  = 'drag-n-drop-wrapper';
  @Input() containerClass  = 'tickets-container';
  addNewProjectFlag = false;

  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    public personService: PersonService,
    private gateway: GatewayService,
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      if ('1' === params['tab']) {
        this.projectBusinessProcessTabIndex = 1;
      }
      //console.log('id', this.id);
    });
  }

  // no loader
  ngOnInit(): void {

    if (null != this.project && this.project.id !== '') {
      this.projectGroup = this._formBuilder.group({
        shortName: ['', [Validators.required, Validators.minLength(3),
          Validators.maxLength(5)]],
        fullName: ['', [Validators.required, Validators.minLength(5),
          Validators.maxLength(50)]]
      });
    }

    this.loadAllProjects();
    this.personService.loading = true;
    this.gateway.userServiceGetUserProfile().subscribe(
      (profile: Profile) => {
        this.personService.profile = profile;
        this.personService.loading = false;
      });
  }

  // no loader
  generateShortName() {
    //
    let short = '';
    let name = this.projectFullName.split(' ');
    for (let word of name) {
      if (short.length < 3) {
        short += word.charAt(0).toUpperCase();
      }
    }
    if (short.length === 1) {
      short += short + short;
    } else if (short.length === 2) {
      short += short.charAt(0);
    }
    // console.log(name, short)
    this.projectShortName = short;
  }

  // no loader
  fix3Symbols() {
    this.projectShortName = this.projectShortName.toUpperCase();
    if (this.projectShortName.length > 5) {
      this.projectShortName =
        this.projectShortName.charAt(0) +
        this.projectShortName.charAt(1) +
        this.projectShortName.charAt(2) +
        this.projectShortName.charAt(3) +
        this.projectShortName.charAt(4);
    }
  }

  // loader
  addProject() {
    let project: Project = {
      archive: false,
      id: '', name: this.projectFullName, shortName: this.projectShortName, selected: false, auth: '', businessProcess: ""};
    this.projectFullName = '';
    this.projectShortName = '';
    this.personService.loading = true;
    this.gateway.planningServiceNewProject(project).subscribe((extendedStatus: ExtendedStatus) => {
      this.personService.loading = false;
      if (extendedStatus.result === 'okay') {
        this.id = extendedStatus.message;
        this.router.navigateByUrl('/projects?id=' + extendedStatus.message);
        this.loadAllProjects();
      }
      // this.loadAllProjects();
    });
  }

  // loader
  private loadAllProjects() {
    this.personService.loading = true;
    this.gateway.planningServiceAllUserProjects().subscribe(data => {
      //console.log('planningServiceAllUserProjects', data);
      this.personService.projects = data;
      if (null != this.id) {
        this.projectId = this.id;
        this.chooseProject();
      }
      this.personService.loading = false;
    });
  }

  // loader
  chooseProject() {
    setTimeout(() => {
      // console.log(this.projectId, this.selectProjectId);
      // return;
      this.project = this.personService.projects.find(p => p.id === this.projectId);
      // this.newShortName = this.project.shortName;
      // this.newFullName = this.project.name;
      if (null != this.project && this.project.id !== '') {
        this.projectGroup = this._formBuilder.group({
          shortName: ['', [Validators.required, Validators.minLength(3),
            Validators.maxLength(5)]],
          fullName: ['', [Validators.required, Validators.minLength(5),
            Validators.maxLength(50)]]
        });
        this.projectGroup.get('shortName').setValue(this.project.shortName);
        this.projectGroup.get('fullName').setValue(this.project.name);
      }

    //console.log('this.projectGroup', this.projectGroup);

      if (this.project != null && 'select' !== this.projectId && this.projectId !== '' && this.projectId !== 'newProject' && this.projectId !== 'payment') {
        this.router.navigateByUrl('/projects?id=' + this.project.id);
        // only first request will cancel the flag
        this.personService.loading = true;
        // load in progress
        this.gateway.planningServiceProjectTickets(this.project, 'today').subscribe(data => {
          // console.log('planningServiceAllUserProjects', data);
          this.personService.projectTicketsToday = data;
          this.personService.loading = false;
        });
        // load in todo
        this.gateway.planningServiceProjectTickets(this.project, 'todo').subscribe(data => {
          // console.log('planningServiceAllUserProjects', data);
          this.personService.projectTicketsTodo = data;
          this.personService.loading = false;
        });
        // load completed
        this.gateway.planningServiceProjectTickets(this.project, 'completed').subscribe(data => {
          // console.log('planningServiceAllUserProjects', data);
          this.personService.projectTicketsCompleted = data;
          this.personService.loading = false;
        });
        // load in backlog
        this.gateway.planningServiceProjectTickets(this.project, 'project').subscribe(data => {
          // console.log('planningServiceAllUserProjects', data);
          this.personService.projectTicketsBacklog = data;
          this.personService.loading = false;
        });
        this.addNewProjectFlag = false;

        //console.log('this.project', this.project.businessProcess);
        if (null != this.project.businessProcess && -1 !== this.project.businessProcess.indexOf(',')) {
          this.steps = this.project.businessProcess.split(',');
        } else {
          this.steps = [];
        }

      } else if (this.projectId === 'newProject') {
        this.addNewProjectFlag = true;
      }else if (this.projectId === 'payment') {
        // nothing
        // redirection to payment-plan
      } else {
        this.personService.projectTicketsToday = [];
        this.project = {archive: false, auth: "", id: "", name: "", selected: false, shortName: "", businessProcess: ""};
        this.addNewProjectFlag = false;
      }
      // console.log()
    }, 0);
  }

  // loader
  setProjectStatus(ticket: Ticket) {
    ticket.status = 'project';
    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(data => {
      //this.loadAllProjects();
      this.personService.loading = false;
    });
  }

  // loader
  setTodayStatus(ticket: Ticket) {
    ticket.status = 'today';
    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(data => {
      //this.loadAllProjects();
      this.personService.loading = false;
    });
  }

  // loader
  setDoneStatus(ticket: Ticket) {
    ticket.status = this.completedChipsList;
    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(
      (answer: ExtendedStatus) => {
      //this.loadAllProjects();
      if ('GOLD' === answer.message) {
        // this.personService.gold += answer.measurement;
        this.personService.addCoins(this.personService.silver, this.personService.gold + answer.measurement)
      } else if ('SILVER' === answer.message) {
        this.personService.addCoins(this.personService.silver + answer.measurement, this.personService.gold)
      }
      this.personService.loading = false;
    });
  }

  itemDropped(item: string) {
    //console.log('dropped', item)
  }

  // loader
  changes = false;
  newShortName = '';
  newFullName = '';
  completedChipsList = 'done';
  todoChipsList = 'later';
  settingsActive = false;


  private setLaterStatus(ticket: Ticket) {
    ticket.status = this.todoChipsList;
    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(data => {
      this.personService.loading = false;
      //this.loadAllProjects();
    });
  }


  addStep() {
    if (this.emailFormControl.value === '') return;

    this.steps.push(this.emailFormControl.value.toString().split(',').join('').toLowerCase());
    this.emailFormControl.setValue('');
    this.emailFormControl.setErrors(null);

    //store data
    this.project.businessProcess = '';
    for (let step of this.steps) {
      this.project.businessProcess += step;
      if (step !== this.steps[this.steps.length - 1]) {
        this.project.businessProcess += ',';
      }
    }
    // store to db
    // console.log('this.project.businessProcess', this.project.businessProcess);
    this.personService.loading = true;
    this.gateway.planningServiceUpdateProjectBusinessProcess(this.project).subscribe(data => {
      this.personService.loading = false;
    });
  }

  removeStep() {
    this.steps.splice(this.steps.length - 1, 1);
    //store data
    this.project.businessProcess = '';
    for (let step of this.steps) {
      this.project.businessProcess += step;
      if (step !== this.steps[this.steps.length - 1]) {
        this.project.businessProcess += ',';
      }
    }
    this.personService.loading = true;
    this.gateway.planningServiceUpdateProjectBusinessProcess(this.project).subscribe(data => {
      this.personService.loading = false;
    });
  }

  openDialog(ticket: Ticket): void {
    // this.personService.currentDialogTicket = ticket;
    const dialogRef = this.dialog.open(TicketDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      data: ticket
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
      ticket.businessProcessStep = this.personService.currentDialogTicket.businessProcessStep;
      ticket.ttext = this.personService.currentDialogTicket.ttext;
    });
  }

  saveProjectOnSubmit() {
    // projectGroup
    // console.log('this.projectGroup.valid', this.projectGroup.valid);
    if (this.projectGroup.valid) {
      this.personService.loading = true;
      this.project.shortName = this.projectGroup.get('shortName').value;
      this.project.name = this.projectGroup.get('fullName').value;
      this.gateway.planningServiceUpdateProjectName(this.project).subscribe(data => {
        this.personService.loading = false;
      });
    }
  }

  archiveProject(project: Project) {
    this.personService.loading = true;
    project.archive = true;
    this.gateway.planningServiceArchiveUnzipProject(project).subscribe(data => {
      this.personService.loading = false;
    });
  }

  unzipProject(project: Project) {
    this.personService.loading = true;
    project.archive = false;
    this.gateway.planningServiceArchiveUnzipProject(project).subscribe(data => {
      this.personService.loading = false;
    });
  }

  removeProject(project: Project) {
    this.personService.loading = true;
    project.archive = false;
    this.gateway.planningServiceRemoveProject(project).subscribe(data => {
      this.personService.loading = false;
      this.project = {archive: false, auth: "", id: "", name: "", selected: false, shortName: "", businessProcess: ""};
      this.projectId = 'select';
      this.id = null;
      this.loadAllProjects();
    });
  }

}
