<span style="float: right;">
    <button (click)="openSnackBar(); personService.giveDialogTooltipAnswer = !personService.giveDialogTooltipAnswer;" mat-icon-button [color]="personService.giveDialogTooltipAnswer ? 'accent' : 'primary'" aria-label="Help button">
      <mat-icon>live_help</mat-icon>
    </button>
&nbsp;
<button
  *ngIf="ticket.teamProjectId != null"
  [color]="settingsOpened ? 'accent': 'primary'"
  mat-icon-button
  (click)="settings()"
>
  <mat-icon>settings</mat-icon>
</button>
  &nbsp;
  <button
    mat-icon-button [mat-dialog-close]="ticket">
  <mat-icon>close</mat-icon>
</button>
</span>

<table><tr>
  <td valign="top">

<p [ngStyle]="{color:
            ticket.status === 'done' ? '#a8ef80'  :
            ticket.status === 'cancelled' ? '#D26927' :
            ticket.status === 'idea' ? 'rgb(188, 216, 244)' :
            ticket.status === 'project' ? 'darkviolet' :
            ticket.status === 'team' ? 'darkorange' :
            (ticket.status === 'tomorrow' ||
            ticket.status === 'later' ||
            (ticket.status === 'date')
            ) ? '#d3d7d5' : 'default'}"><{{ticket.status}}></p>

<table *ngIf="'date' === ticket.status && 0 < ticket.tsOfPlannedDate">
  <tr>
    <td>
      <mat-chip-list aria-label="Counter date" style="font-size: 70%;">
        <mat-chip color="accent" style="height: 25px;" selected>
          <app-counter [plannedTs]="ticket.tsOfPlannedDate"></app-counter>
        </mat-chip>
      </mat-chip-list>
    </td>
    <td>
                  <span [ngStyle]="{color:
                        (ticket.status === 'date' && ifNotToday(ticket)
                        ) ? '' : '#03a9f4'}"
                  >{{newDate(ticket.tsOfPlannedDate) | date: '[yyyy-MM-dd, HH:mm]'}}</span>
    </td>
  </tr>
</table>
<!--</div>-->
<!--mat-dialog-title-->
<app-ticket-text
  [ticket]="ticket"
></app-ticket-text>

<div mat-dialog-content>
  <app-ticket-description
    [ticket]="ticket"
    (loadAllTicketsFromDescription)="loadTicket()"
  ></app-ticket-description>
  <!--Check list here if exists-->
  <app-ticket-check-list
    [ticket]="ticket"
    [checkListAddingFlag]="checkListAddingFlag"
    (loadAllTicketsFromCheckList)="loadTicket()"
  ></app-ticket-check-list>

  <app-business-process (closeDialog)="onNoClick()" [ticket]="ticket" [dialogFlag]="true"></app-business-process>

<!--  <div *ngIf="null != ticket.businessProcessStep && ticket.businessProcessStep !== ''">-->
<!--    <br/>-->
<!--    <span style="color: darkorange;">{{'PERSONAL_PROJECTS.BP.BP' | translate}}:</span>-->
<!--    &nbsp;-->
<!--&lt;!&ndash;    <span style="background-color: darkorange; color: #03a9f4;">{{data.businessProcessStep}}</span>&ndash;&gt;-->
<!--    <a [routerLink]="'/projects'"-->
<!--       (click)="onNoClick()"-->
<!--       [queryParams]="{id: ticket.projectId, tab: '1', rnd: randomString()}"-->
<!--       style="background-color: darkorange; color: #03a9f4;">{{ticket.businessProcessStep}}-->
<!--    </a>-->
<!--  </div>-->

  <!-- places -->
  <div
    *ngIf=" ticket.placeName != null && '' !== ticket.placeName"
    [mat-dialog-close]="ticket"
    [matTooltip]="'GUIDE.TOOLTIPS.TICKET.PLACE.OPEN' | translate"
    style="
              color: #03a9f4;
              margin: 3px auto; width: fit-content;
              cursor: pointer;
              "
  >
    <div
         [routerLink]="'/places'"
         [queryParams]="{id: ticket.placeId, name: ticket.placeName}"
    >
      <span style="color: darkorange">-</span> {{ticket.placeName}} <span style="color: darkorange">-</span>
    </div>
<!--    <ng-template #notLinkPlaceTooltip>-->
<!--      <div (click)="placeChoosen()">-->
<!--        <span style="color: darkorange">-</span> {{ticket.placeName}} <span style="color: darkorange">-</span>-->
<!--      </div>-->
<!--    </ng-template>-->
  </div>
</div>

<div [@comments] *ngIf="commentsFlag">
  <app-comments (commentsNumber)="setCommentsNumber($event)" [ticket]="ticket"></app-comments>
</div>
<!--<div *ngIf="commentsFlag" [@comments]>-->
<!--  <br/>-->
<!--  <form (ngSubmit)="addNewComment()">-->
<!--    <mat-form-field style="width: 80%;">-->
<!--      <mat-label>{{ 'COMMENTS.NEW_COMMENT' | translate}}</mat-label>-->
<!--      <textarea-->
<!--        style="height: 33px;"-->
<!--        matInput-->
<!--        [formControl]="addNewCommentFormControl" [errorStateMatcher]="matcher"-->
<!--      ></textarea>-->
<!--    </mat-form-field>-->
<!--    &nbsp;-->
<!--    <button-->
<!--      type="submit"-->
<!--      mat-icon-button-->
<!--      [disabled]="!addNewCommentFormControl.valid"-->
<!--      color="primary"><mat-icon>play_arrow</mat-icon>-->
<!--    </button>-->
<!--  </form>-->
<!--  <div *ngIf="comments.length > 0" style="min-height: 70px; max-height: 200px; overflow: auto;">-->
<!--    <table>-->
<!--        <tr *ngFor="let cmm of comments" style="width: 100%;">-->
<!--          <td style="width: 38px;">-->
<!--            <img style="width: 30px; height: 30px; border-radius: 50%; top: 0px; margin-right: 10px;"-->
<!--                 [src]="cmm.userAva"/>-->
<!--          </td>-->
<!--          <td style="width: 80%;">-->
<!--            <a style="color: #03a9f4;" [routerLink]="'people'" mat-dialog-close>{{cmm.userName}} {{cmm.userSerName}}</a>-->

<!--            <p style="word-break: break-all; width: fit-content; margin: 0px;">{{cmm.commentText}}</p>-->

<!--            <div style="color: lightgrey;">{{cmm.commentTs | date: "dd-MM-yyyy HH:mm"}}</div>-->
<!--            <br/>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button color="warn" [disabled]="personService.profile.userId !== cmm.mainUserId" (click)="deleteComment(cmm)" mat-icon-button>-->
<!--              <mat-icon>delete</mat-icon>-->
<!--            </button>-->
<!--          </td>-->
<!--        </tr>-->
<!--    </table>-->
<!--  </div>-->
<!--</div>-->

<div mat-dialog-actions>
  <button
    mat-icon-button
    [color]="!commentsFlag ? 'primary': 'accent'"
    (click)="openCommentsDT()"
    [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
  ><mat-icon>comment</mat-icon> {{(comments.length > 0) ? comments.length: ''}}
  </button>
  <button
    mat-icon-button
    *ngIf="!ticket.descriptionAddFlag"
    color="primary"
    [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
    (click)="addDescriptionInDialogT()">
    <mat-icon>description</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    *ngIf="!checkListAddingFlag"
    [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
    (click)="checkListAddStartDT()"
  ><mat-icon>done_all</mat-icon>
  </button>
<!--  <button mat-icon-button [mat-dialog-close]="ticket" cdkFocusInitial>{{ 'TODAY.TICKET.CLOSE' | translate}}</button>-->
</div>

</td>
  <td valign="top" *ngIf="settingsOpened" [@settings]>

    <!--  team section-->
    <div *ngIf="null != ticket.teamProjectId" style="padding: 2%;">

<!--      Author/ Assigned-->
      {{ 'TEAMS.TEAM.PROJECTS.TICKET.AUTHOR' | translate}}: <span *ngIf="includes(ticket.userAuthorId)">{{findName(ticket.userAuthorId)}}</span>
      <br/>
      {{ 'TEAMS.TEAM.PROJECTS.TICKET.ASSIGNED' | translate}}:
      <mat-form-field *ngIf="includes(ticket.userHasGotId)">
        <mat-select
          (selectionChange)="changeMember()"
          [(ngModel)]="assigned"
          required>
          <mat-option *ngFor="let member of this.personService.teamSelected.members"
                      [value]="member.userId">{{member.firstName}} {{member.secondName}}</mat-option>
        </mat-select>
      </mat-form-field>

      <br/>
      <!--      epics    -->
      <mat-form-field appearance="fill">
        <mat-label>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.ASSIGN-EPIC' | translate }}</mat-label>
        <mat-select
          [formControl]="epics"
          (valueChange)="epicOptionSelected($event)"
        >
<!--          <mat-select-trigger>-->
<!--            {{ (epics.value === '111111100110101100101000000000') ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.MAIN-PROJECT' | translate) : epics.value === '111011100110101100101000000000' ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.NEW-EPIC-CREATING' | translate) : epics.value}}-->
<!--          </mat-select-trigger>-->
          <mat-option
            [value]="''">{{'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.MAIN-PROJECT' | translate}}
          </mat-option>
          <mat-option *ngFor="let epic of personService.lastTeamProject.epicsList" [value]="epic">{{epic}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <!--      sprints  -->
      <mat-form-field appearance="fill">
        <mat-label>{{ 'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.ASSIGN-SPRINT' | translate }}</mat-label>
        <mat-select
          [formControl]="sprints"
          (valueChange)="sprintOptionSelected($event)"
        >
<!--          <mat-select-trigger>-->
<!--            {{ (sprints.value === '111111100110101100101000000000') ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.SELECT-SPRINT' | translate) : sprints.value === '111011100110101100101000000000' ? ('TEAMS.TEAM.PROJECTS.EPIC-SPRINT.NEW-SPRINT-CREATING' | translate) : sprints.value}}-->
<!--          </mat-select-trigger>-->
          <mat-option
            [value]="''">{{'TEAMS.TEAM.PROJECTS.EPIC-SPRINT.MAIN-PROJECT' | translate}}</mat-option>
          <mat-option *ngFor="let sprint of personService.lastTeamProject.sprintsList" [value]="sprint">{{sprint}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </td>
</tr>
</table>
