import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {VersionService} from "../../../../services/version.service";
import {animate, style, transition, trigger, useAnimation} from "@angular/animations";
import {tada} from "ng-animate";

@Component({
  selector: 'app-self-organizer',
  templateUrl: './self-organizer.component.html',
  styleUrls: ['./self-organizer.component.scss'],
  animations: [
    trigger('nettyos', [
      transition('void => *', useAnimation(tada))
    ]),
  ]
})
export class SelfOrganizerComponent implements OnInit {

  constructor(public translate: TranslateService, public versionService: VersionService) { }

  ngOnInit(): void {
    //document.body.scrollTop = 0;
  }

}
