<!--<table>-->
<!--  <tr><td>-->
<!--    <hr [ngStyle]="{backgroundColor:-->
<!--                    ticket.status === 'done' ? '#a8ef80'  :-->
<!--                    ticket.status === 'cancelled' ? '#D26927' :-->
<!--                    ticket.status === 'idea' ? '#03a9f4' :-->
<!--                    ticket.status === 'project' ? 'darkviolet' :-->
<!--                    ticket.status === 'team' ? 'darkorange' :-->
<!--                    (ticket.status === 'tomorrow' ||-->
<!--                    ticket.status === 'later' ||-->
<!--                    (ticket.status === 'date' && ifNotToday(ticket))-->
<!--                    ) ? '#d3d7d5' : 'transparent'}" style="width: 10px; height: 10px;" />-->
<!--  </td>-->
<!--    <td></td>-->
<!--  </tr>-->
<!--</table>-->

    <span [ngStyle]="{backgroundColor:
                    ticket.status === 'done' ? '#a8ef80'  :
                    ticket.status === 'cancelled' ? '#D26927' :
                    ticket.status === 'idea' ? '#03a9f4' :
                    ticket.status === 'project' ? 'darkviolet' :
                    ticket.status === 'team' ? 'darkorange' :
                    (ticket.status === 'tomorrow' ||
                    ticket.status === 'later' ||
                    (ticket.status === 'date')
                    ) ? '#d3d7d5' : 'transparent'}" style="width: 10px; height: 10px; border-radius: 50%;">
      &nbsp;&nbsp;&nbsp;&nbsp;
    </span>&nbsp;
