import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {transition, trigger, useAnimation} from "@angular/animations";
import {flip, tada} from "ng-animate";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  animations: [
    trigger('nettyos', [
      transition('void => *', useAnimation(tada))
    ]),
  ]
})
export class ContactsComponent implements OnInit {

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
  }

}
