<div style="width: 96%; margin: auto;" align="center">
  <br/>
  <br/>
  <h1 [@tour] *ngIf="translate.defaultLang === 'ru'">Тур по продукту</h1>
  <h1 [@tour] *ngIf="translate.defaultLang === 'en'">Product tour</h1>
  <img
    [@nettyos]
    class="logo"
    src="./assets/images/logos/logo.jpg"
  />
  <br/>
  <br/>


  <p *ngIf="translate.defaultLang === 'ru'" class="text">
    <strong>Nettyos - это единая экосистема планирования, самоорганизации и личностного роста.</strong>
    <br/>
    <br/>
    Не надо выбирать платформы для командной работы. Присоединиться к любой команде можно в один клик.
    <br/>
    <br/>
    И использовать любые методологии, меняя их в "горячем режиме": SCRUM, Kanban, Custom, любые... При этом все личные дела будут здесь же. Вы же не разный человек, когда работаете над командным тикетом и хотите поставить напоминания на запись к врачу. Так зачем тогда пользоваться разными сервисами планирования!?...
  </p>
  <p *ngIf="translate.defaultLang === 'en'" class="text">
    <strong>Nettyos - it is a unified ecosystem of planning, self-organization and personal growth.</strong>
    <br/>
    <br/>
    No need to choose platforms for teamwork. You can join any team in one click.
    <br/>
    <br/>
    And use any methodology, changing them in "hot mode": SCRUM, Kanban, Custom, any ... In this case, all personal affairs will be here. You are not a different person when you are working on a team ticket and want to set reminders on your doctor's appointment. So why then use different planning services!?...
  </p>
  <br/>
  <br/>
  <table class="text">
    <tr>
      <td>
        <img
          [@nettyos]
          class="image-solution"
          src="./assets/images/landing/step2/in-one-all.svg"
        />
      </td>
      <td style="padding-left: 20px;">
        <div *ngIf="translate.defaultLang === 'ru'">
          <h1>
            Решение от Nettyos: <a [routerLink]="'/self-organizer'">Самоорганизация</a>
          </h1>
          <div>
            Можно, записывать все дела/идеи/напоминания (тикеты), а потом планировать как и когда их выполнить с комментариями, ссылками и датами. Превратить идею в командный проект и добавить команду для выполнения - супер просто!
          </div>
          <br/>
          <br/>
        </div>
        <div *ngIf="translate.defaultLang === 'en'">
          <h1>
            Nettyos solution: <a [routerLink]="'/self-organizer'">Self-organizer</a>
          </h1>
          <div>
            You can write down all the tasks/ideas/reminders (tickets), and then plan how and when to complete them with comments, links and dates. Turning an idea into a team project and adding a team to execute is super easy!
          </div>
          <br/>
          <br/>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          [@nettyos]
          class="image-solution"
          src="./assets/images/landing/step2/with-all-family-plan.svg"
        />
      </td>
      <td style="padding-left: 20px;">
        <div *ngIf="translate.defaultLang === 'ru'">
          <h1>
            Решение от Nettyos: <a [routerLink]="'/family-hub'">Семейный хаб</a>
          </h1>
          <div>
            Nettyos будет незаменим для реализации "Семейного Хаба". Даже у небольшой семьи есть много дел, что уж говорить о больших семьях. Не все просьбы можно исполнить прямо сейчас, кто-то работает, другие строят бизнес. Поэтому просьбы с низким приоритетом (но важные) можно отправить другу или подруге, которые могут быть членами семьи. То же самое возможно и с друзьями. Поэтому "Семейный Хаб" актуален для любой компании друзей.
          </div>
          <br/>
          <br/>
        </div>
        <div *ngIf="translate.defaultLang === 'en'">
          <h1>
            Nettyos solution: <a [routerLink]="'/family-hub'">Family hub</a>
          </h1>
          <div>
            Nettyos will be indispensable for the implementation of the "Family Hub". Even a small family has many things to do, for sure large families - a lot. Not all requests can be fulfilled at the moment, some are working, others are building a business. Therefore, low priority but important requests can be sent to a friend who may be family members. The same is possible with friends. Therefore, the "Family Hub" is relevant for any group of friends.
          </div>
          <br/>
          <br/>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          [@nettyos]
          class="image-solution"
          src="./assets/images/landing/step2/top-devices.svg"
        />
      </td>
      <td style="padding-left: 20px;">
        <div *ngIf="translate.defaultLang === 'ru'">
          <h1>
            Решение от Nettyos: <a [routerLink]="'/dreams-maker'">Исполнитель мечт</a>
          </h1>
          <div>
            Много психологических инновационных решений интегрировано в Nettyos чтобы помочь реализовать мечты. В первую очередь автору и создателю Nettyos, который активно пользуется этим сервисом. И, конечно, благодаря IT эти психотехнологии открыли другим людям.
          </div>
          <br/>
          <br/>
        </div>
        <div *ngIf="translate.defaultLang === 'en'">
          <h1>
            Nettyos solution: <a [routerLink]="'/dreams-maker'">Dreams maker</a>
          </h1>
          <div>
            Many psychological innovations are integrated into Nettyos to help make dreams come true. First of all, to the author and creator of Nettyos, who actively uses this service. And, of course, thanks to IT, these psychotechnologies were opened to other people.</div>
          <br/>
          <br/>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          [@nettyos]
          class="image-solution"
          src="./assets/images/landing/step2/life-pro-processes.svg"
        />
      </td>
      <td style="padding-left: 20px;">
        <div *ngIf="translate.defaultLang === 'ru'">
          <h1>
            Решение от Nettyos: <a [routerLink]="'/personal-projects'">Персональные Проекты</a>
          </h1>
          <div>
            Идеально подходит для самозанятых, которые хотят в разы повысить средний чек, малого бизнеса, хобби, категорий и даже для тех кто хочет конкурировать с IT гигантами в одиночку. Да именно Нетти, который разрабатывался во времена, когда инструментов планирования еще не было, а необходимость жесткой конкуренции была очень высокой.
          </div>
          <br/>
          <br/>
        </div>
        <div *ngIf="translate.defaultLang === 'en'">
          <h1>
            Nettyos solution: <a [routerLink]="'/personal-projects'">Personal Projects</a>
          </h1>
          <div>
            Ideal for self-employed people who want to significantly increase the average check, small businesses, hobbies, categories, and even for those who want to conquer with IT giants alone. Yes, it was Nettyos, which was developed at a time when there were no planning tools yet, and the need for tough competition was very high.
          </div>
          <br/>
          <br/>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          [@nettyos]
          class="image-solution"
          src="./assets/images/landing/step2/planner-of-team.svg"
        />
      </td>
      <td style="padding-left: 20px;">
        <div *ngIf="translate.defaultLang === 'ru'">
          <h1>
            Решение от Nettyos: <a [routerLink]="'/all-teams'">Команды (любые)</a>
          </h1>
          <div>
            Переходить от одной модели команды к другой можно в "горячем" режиме. Также как и кастомизировать. Стал неэффективным SCRUM, поменяли на KANBAN, все еще нет эффекта - сделали mix: SCRUM + KANBAN + Своя Методология. Простые проекты понятные каждому - легко. Сложные проекты стали управляться просто и гибко.
          </div>
          <br/>
          <br/>
        </div>
        <div *ngIf="translate.defaultLang === 'en'">
          <h1>
            Nettyos solution: <a [routerLink]="'/all-teams'">Teams (any)</a>
          </h1>
          <div>
            You can move from one team model to another in a "hot" mode. As well as customization. SCRUM became ineffective, changed to KANBAN, still no effect - made mix: SCRUM + KANBAN + Own Methodology. Simple projects that are clear to everyone - easy. Complex projects have become easy and flexible to manage.
          </div>
          <br/>
          <br/>
        </div>
      </td>
    </tr>
  </table>
  <hr/>


  <br/>
  <br/>
  <br/>
  <br/>
</div>
