<div [class]="'center'">
  <br/>
  <br/>
  <h1 *ngIf="translate.defaultLang === 'en'">
    Experience the Full Power of Nettyos
  </h1>
  <h1 *ngIf="translate.defaultLang === 'ru'">
    Испытайте всю мощь Nettyos
  </h1>
  <br/>
  <br/>
  <span *ngIf="translate.defaultLang === 'en'">payment@nettyos.com - all payment questions here</span>
  <span *ngIf="translate.defaultLang === 'ru'">payment@nettyos.com - все вопросы по оплате</span>
  <br/>
  <br/>
  <!-- For new users -->
  <div *ngIf="noAuth">
    <div *ngIf="translate.defaultLang === 'ru'">
      Много полезных функций у nettyos.com бесплатно:
      <ul>
        <li [@free] >Личные тикеты</li>
        <li [@free1] >Все личные категории: сегодня, завтра, потом, дата, мечта, идея, готово.</li>
        <li [@free2] >Чек-листы без ограничений</li>
        <li [@free3] >Описания для тикетов</li>
        <li [@free4] >Места</li>
        <li [@free5] >Друзья/Контакты</li>
        <li [@free6] >Комментарии для личных, персональных и командных тикетов без ограничений</li>
        <li [@free7] >Регулярный бэкап данных</li>
        <li [@free8] >Светлая/Темная темы</li>
        <li [@free9] >Напоминания - без органичений</li>
        <li [@free10] >3 персональных проекта</li>
        <li [@free11] >1 команда до 2х человек с двумя командными проектами</li>
        <li [@free12] >Система личностного роста (скоро будет перенесена из Nettyos 3)</li>
        <li [@free13] >Возможность заработать кастомизации и фичи из платных аккаунтов за активность</li>
        <li [@free14] >И многое другое...</li>
      </ul>
      <br/>
      <br/>
    </div>
    <div *ngIf = "translate.defaultLang === 'en'">
      Nettyos.com has many useful features for free:
      <ul>
        <li [@free] > Personal tickets </li>
        <li [@free1] > All personal categories: today, tomorrow, after, date, dream, idea, done. </li>
        <li [@free2] > Unlimited checklists </li>
        <li [@free3] > Descriptions for tickets </li>
        <li [@free4] > Places </li>
        <li [@free5] > Friends / Contacts </li>
        <li [@free6] > Unlimited comments for private, personal projects and team tickets </li>
        <li [@free7] > Regular data backup </li>
        <li [@free8] > Light / Dark themes </li>
        <li [@free9] > Reminders - no limit </li>
        <li [@free10] > 3 personal projects </li>
        <li [@free11] > 1 team up to 2 people with two team projects </li>
        <li [@free12] > Personal Growth System (coming soon from Nettyos 3) </li>
        <li [@free13] > Ability to earn customizations and features from paid accounts for activity </li>
        <li [@free14] > And much more ... </li>
      </ul>
      <br/>
      <br/>
    </div>
    <br/>
    <br/>
    <div style="width: 100%;" align="center">
      <button [routerLink]="'/register'" mat-raised-button color="warn">{{ 'LANDING.JOIN_CONTINUE' | translate}}</button>
    </div>
    <br/>
    <br/>
  </div>

<!--  <button [disabled]="noAuth" mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">-->
<!--    {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}-->
<!--  </button>-->
  <div [class]=" noAuth ? 'no-auth': ''">
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>{{ 'PAY.CHOOSE_PLAN' | translate}}</ng-template>
        <div class="show">
          <h1>{{ 'PAY.CHOOSE_PLAN' | translate}}</h1>
          <br/><br/>
        </div>
<!--        todo: decide-->
        <div *ngIf="!noAuth">
          {{ 'PAY.TY' | translate}}
          <button [disabled]="noAuth" mat-button color="primary" [routerLink]="'/payment'">{{ 'PAY.HERE' | translate}}</button>
        </div>
        <br/><br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.INDIVIDUAL_PLAN' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorchid">{{ 'PAY.PREMIUM' | translate}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              Premium account let you to create more then 3 personal projects. Usually people need this feature when success coming.
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Премиум-аккаунт позволяет создавать более 3-х личных проектов. Обычно эта функция нужна людям, когда приходит успех.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(10, 'en', 'Premium', 'month');" mat-raised-button color="primary" matStepperNext>$10/month</button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(10, 'ru', 'Premium', 'month');" mat-raised-button color="primary" matStepperNext>750 руб/месяц</button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.INDIVIDUAL_PLAN_ECO' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorchid">{{ 'PAY.PREMIUM' | translate}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              Premium account for 1 year. <span style="background-color: #a8ef80; color: white;">SAVE $12</span>
<!--              Happy New 2021. This is limited offer. 1 year of the Premium costs like 1 month of the Premium. Only till 31 January 2021. <br/><br/> <strong style="font-size: 150%; color: #a8ef80;">Benefit +1100% a year!</strong>-->
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
<!--              С Новым 2021 годом! Это ограниченное предложение. 1 год Премиум стоит как 1 месяц Премиум. Действует только до 31 января 2021 года. <br/><br/> <strong style="font-size: 150%; color: #a8ef80;">Выгода +1100% за год!</strong>-->
              Премиум аккаунт на 1 год. <span style="background-color: #a8ef80; color: white;">ЭКОНОМИЯ 900 руб</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(108, 'en', 'Premium', 'year');" mat-raised-button color="primary" matStepperNext>$108/year >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(108, 'ru', 'Premium', 'year');" mat-raised-button color="primary" matStepperNext>8,100 руб/год >></button>
<!--            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(10, 'en', 'Premium', 'year');" mat-raised-button color="primary" matStepperNext>$10/year >></button>-->
<!--            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(10, 'ru', 'Premium', 'year');" mat-raised-button color="primary" matStepperNext>750 руб/год >></button>-->
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Light</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              Create teams. Up to 5 members. Up to 2 teams. All bonuses of premium account. Team members can join with any account. You pay for your team members.
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Создавайте команды. До 5-х участников. До 2 команд. Все бонусы премиум аккаунта. Члены команды могут присоединиться с любой учетной записью. Вы платите за членов своей команды.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(50, 'en', 'Pro.Light', 'month');" mat-raised-button color="primary" matStepperNext>$50/month >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(50, 'ru', 'Pro.Light', 'month');" mat-raised-button color="primary" matStepperNext>3,750 руб/месяц >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN_ECO' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Light</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              This is the Pro.Light for a year. <span style="background-color: #a8ef80; color: white;">SAVE $60</span>
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
             Это Pro.Light на год. <span style="background-color: #a8ef80; color: white;">ЭКОНОМИЯ 4,500 руб</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(540, 'en', 'Pro.Light', 'year');" mat-raised-button color="primary" matStepperNext>$540/year >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(540, 'ru', 'Pro.Light', 'year');" mat-raised-button color="primary" matStepperNext>40,500 руб/год >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Middle</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              Create teams. Up to 10 members. Up to 3 teams. All bonuses of premium account. Team members can join with any account. You pay for your team members.
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Создавайте команды. До 10-и участников. До 3 команд. Все бонусы премиум аккаунта. Члены команды могут присоединиться с любой учетной записью. Вы платите за членов своей команды.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(100, 'en', 'Pro.Middle', 'month');" mat-raised-button color="primary" matStepperNext>$100/month >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(100, 'ru', 'Pro.Middle', 'month');" mat-raised-button color="primary" matStepperNext>7,500 руб/месяц >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN_ECO' | translate}} <span style="background-color: orange; color: white;">{{ 'PAY.THE_BEST' | translate}}</span></mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Middle</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              This is Pro.Middle for a year. <span style="background-color: #a8ef80; color: white;">SAVE $120</span>
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Это Pro.Middle на год. <span style="background-color: #a8ef80; color: white;">ЭКОНОМИЯ 9,000 руб</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(1080, 'en', 'Pro.Middle', 'year');" mat-raised-button color="primary" matStepperNext>$1080/year >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(1080, 'ru', 'Pro.Middle', 'year');" mat-raised-button color="primary" matStepperNext>81,000 руб/год >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Top</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              Create teams. Up to 25 members. Up to 10 teams. All bonuses of the premium account. Team members can join with any account. You pay for your team members.
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Создавайте команды. До 25-и участников. До 10 команд. Все бонусы премиум аккаунта. Члены команды могут присоединиться с любой учетной записью. Вы платите за членов своей команды.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(300, 'en', 'Pro.Top', 'month');" mat-raised-button color="primary" matStepperNext>$300/month >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(300, 'ru', 'Pro.Top', 'month');" mat-raised-button color="primary" matStepperNext>22,500 руб/месяц >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN_ECO' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Top</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              This is Pro.Top for a year. <span style="background-color: #a8ef80; color: white;">SAVE $600</span>
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Это Pro.Top на год. <span style="background-color: #a8ef80; color: white;">ЭКОНОМИЯ 45,000 руб</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(3000, 'en', 'Pro.Top', 'year');" mat-raised-button color="primary" matStepperNext>$3000/year >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(3000, 'ru', 'Pro.Top', 'year');" mat-raised-button color="primary" matStepperNext>225,000 руб/год >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Max</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              Create teams. Any number of members. Any number of teams. All bonuses of the premium account. Team members can join with any account. You pay for your team members.
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Создавайте команды. Любое количество участников. Любое количество команд. Все бонусы премиум аккаунта. Члены команды могут присоединиться с любой учетной записью. Вы платите за всех членов своей команды.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(500, 'en', 'Pro.Max', 'month');" mat-raised-button color="primary" matStepperNext>$500/month >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(500, 'ru', 'Pro.Max', 'month');" mat-raised-button color="primary" matStepperNext>37,500 руб/месяц >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title style="width: 100%; color: #03a9f4;">{{ 'PAY.TEAM_PLAN_ECO' | translate}}</mat-card-title>
            <mat-card-subtitle style="color: darkorange">Pro.Max</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="translate.defaultLang === 'en'">
              This is Pro.Max for a year. <span style="background-color: #a8ef80; color: white;">SAVE $1000</span>
            </p>
            <p *ngIf="translate.defaultLang === 'ru'">
              Это Pro.Max на год. <span style="background-color: #a8ef80; color: white;">ЭКОНОМИЯ 75,000 руб</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'en'" (click)="choosePaymentPlan(5000, 'en', 'Pro.Max', 'year');" mat-raised-button color="primary" matStepperNext>$5000/year >></button>
            <button [disabled]="noAuth" *ngIf="translate.defaultLang === 'ru'" (click)="choosePaymentPlan(5000, 'ru', 'Pro.Max', 'year');" mat-raised-button color="primary" matStepperNext>375,000/год >></button>
          </mat-card-actions>
        </mat-card>
        <br/>
        <br/>
        <br/>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>{{ 'PAY.PROCEED' | translate}}</ng-template>

        <div style="width: 100%;" align="center">
          <br/><br/>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'PAY.ELEMENTS.TOTAL' | translate}}</mat-label>
            <input matInput formControlName="totalAmountCtrl">
          </mat-form-field>
          <br/>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'PAY.ELEMENTS.PAYMENT_PLAN' | translate}}</mat-label>
            <input matInput formControlName="paymentPlanCtrl">
          </mat-form-field>
          <br/>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'PAY.ELEMENTS.PERIOD' | translate}}</mat-label>
            <input matInput formControlName="periodCtrl">
          </mat-form-field>

        </div>

        <!--        <mat-form-field>-->
<!--          Total, P. Plan, Period-->
<!--        </mat-form-field>-->
<!--        <div class="show">-->
<!--          <h1>{{ 'PAY.SYSTEM' | translate}}</h1>-->
<!--          <br/><br/>-->
<!--        </div>-->
        <br/><br/><br/>
<!--        <div style="width: 100%;" align="center">-->
<!--          <br/>-->
<!--          <br/>-->
<!--          <span style="margin: auto;">{{ 'PAY.ONLY_PAY_PAL' | translate}}</span>-->
<!--        </div>-->
<!--        <mat-form-field>-->
<!--          <mat-label>Address</mat-label>-->
<!--          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"-->
<!--                 required>-->
<!--        </mat-form-field>-->
        <div align="center">
          <br/><br/><br/>
          <button [disabled]="noAuth" mat-button color="primary" matStepperPrevious>{{ 'PAY.BACK' | translate}}</button>
          <button [disabled]="noAuth" mat-button color="primary" matStepperNext>{{ 'PAY.NEXT' | translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'PAY.SYSTEM' | translate}}</ng-template>
      <div class="show">
        <h1>{{ 'PAY.PROCEED' | translate}}</h1>
        <br/><br/>
      </div>

      <br/><br/><br/>

      <div style="width: 100%;" align="center">
        <img style="height: 20px;" src="./assets/images/logos/ukassa.svg"> UKassa
        <br/>
        <br/>
        <button mat-raised-button (click)="payUkassa()" *ngIf="paymentUKassaLink.length === 0">Okay</button>
        <a [href]="paymentUKassaLink"><button mat-raised-button *ngIf="paymentUKassaLink.length > 0">{{ 'PAY.HREF' | translate}}</button></a>
      </div>

      <br/><br/><br/>
      <div style="width: 100%;" align="center">
        Pay Pal (not for Russia)
        <br/>
        <a style="margin: auto; color:lightblue;" href="https://www.paypal.com/paypalme/nikitadanilov/{{choosenAccount}}USD">{{ 'PAY.FINISH' | translate}}: ${{choosenAccount}}</a>
      </div>

      <br/>
      <br/>
      <br/>

<!--      <script type="text/javascript" src="https://auth.robokassa.ru/Merchant/PaymentForm/FormV.js?MerchantLogin=nettyos&InvoiceID=0&Culture=ru&Encoding=utf-8&OutSum=0,00&SignatureValue=2cea6d79380c4aeec744ffd3be11f0aa"></script>-->

      <div style="width: 100%;" align="center">
        <iframe class="div-disabled" width="168" height="80"
                style="border:0;width:168px;height:80px;overflow:hidden;background-color:transparent;"
                allowTransparency="true"
                [src]="'https://auth.robokassa.ru/Merchant/PaymentForm/FormV.if?MerchantLogin=nettyos&InvoiceID=' + invId + '&Culture=' + translate.defaultLang + '&Encoding=utf-8&OutSum=' + roboRubAmount + '&SignatureValue=' + signatureValue + '&Description=' + description | safe"></iframe>
      </div>

      <div align="center">
        <br/><br/><br/>
        <button [disabled]="noAuth" mat-button color="primary" matStepperPrevious>{{ 'PAY.BACK' | translate}}</button>
        <button [disabled]="noAuth" mat-button color="primary" (click)="stepper.reset(); choosenAccount = 0; description = ''; totalAmount = 0; currency = 'USD'; roboRubAmount = 0;">{{ 'PAY.RESET' | translate}}</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  </div>

</div>
