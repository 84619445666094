import { Component, OnInit } from '@angular/core';
import {transition, trigger, useAnimation} from "@angular/animations";
import {tada} from "ng-animate";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-all-teams',
  templateUrl: './all-teams.component.html',
  styleUrls: ['./all-teams.component.scss'],
  animations: [
    trigger('nettyos', [
      transition('void => *', useAnimation(tada))
    ]),
  ]
})
export class AllTeamsComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
