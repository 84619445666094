
<br/>
<br/>

<div>
  <table class="about" *ngIf="personService.profile.auth === 'yes' else authwrong">
    <tr>
      <td valign="top">
        <table>
          <tr><td>{{ 'PROFILE.FIRST_NAME' | translate }}:</td><td>{{personService.profile.fname}}</td></tr>
          <tr><td>{{ 'PROFILE.LAST_NAME' | translate }}:</td><td>{{personService.profile.lname}}</td></tr>
          <tr><td>{{ 'PROFILE.EMAIL' | translate }}:</td><td>{{personService.profile.email}}</td></tr>
<!--          <tr><td>Language:</td><td><app-language [size]="'20px'"></app-language></td></tr>-->
        </table>
      </td>
    </tr>
  </table>
  <br/>
  <br/>
  <div class="about" align="center">
    <img class="ava" [src]="(this.gateway.localhost) ? this.gateway.NETTY_CLIENT_ANGULAR + personService.profile.ava : personService.profile.ava" *ngIf="personService.profile.ava.length > 0"/>
    <br/><br/>
    <input type="file" style="visibility: hidden" (change)="onFileSelected($event)" #fileInput>
    <br *ngIf="progress === 100"/>
    <mat-icon *ngIf="progress === 100" color="primary">done</mat-icon>
    <br *ngIf="progress > 0"/>
    <mat-progress-bar *ngIf="progress > 0" mode="determinate" style="width: 50%;" [value]="progress"></mat-progress-bar>
    <br/>
    <button mat-mini-fab style="width: fit-content;" color="primary" (click)="fileInput.click();">&nbsp;{{ 'PROFILE.CHANGE_IMAGE' | translate }}&nbsp;</button>
    &nbsp;
    <button *ngIf="selectedFile != null" mat-icon-button color="primary" (click)="onUpload()"><mat-icon>cloud_upload</mat-icon></button>
    <br/>
    <br/>
<!--    {{personService.profile.ava | json}}-->
<!--    Settings-->
    <table>
      <tr>
        <td>{{ 'PROFILE.LANGUAGE' | translate }}:</td><td><app-language [size]="'20px'"></app-language></td>
        <td *ngIf="personService.profile.accountExpirationDate !== ''"></td>
      </tr>
      <tr>
        <td>{{ 'PROFILE.ACCOUNT' | translate }}:</td>
        <td
          [ngStyle]="{color: personService.isPremiumAccount() ? 'darkorchid': personService.isProAccount() ? 'darkorange': 'lightgreen'}">{{personService.profile.account}}
          <button [routerLink]="'/payment-plan'" mat-icon-button *ngIf="!personService.isProAccount()" style="color: darkorange;">
            <mat-icon [matTooltip]="'PAY.UPGRADE' | translate">arrow_upward</mat-icon>
          </button>
        </td>
        <td *ngIf="personService.profile.accountExpirationDate !== '' && personService.profile.accountExpirationDate !== '∞'">[{{personService.profile.accountExpirationDate}}]</td>
        <td [ngStyle]="{color: personService.isPremiumAccount() ? 'darkorchid': personService.isProAccount() ? 'darkorange': 'lightgreen'}"
            *ngIf="personService.profile.accountExpirationDate !== '' && personService.profile.accountExpirationDate === '∞'" style="color: darkorange;">∞</td>
      </tr>
    </table>
    <br/><br/>
    <div>
      {{ 'PROFILE.CHANGE_PASSWORD' | translate }}
      <div *ngIf="answer.length > 0">{{answer}}</div>
      <br/><br/>
      <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordOnSubmit()">
        <mat-form-field class="form-fields" appearance="fill">
          <mat-label>{{ 'PROFILE.CURRENT_PASSWORD' | translate }}</mat-label>
          <input #pwd1 matInput
                 [type]="pwd1.value.length === 0 ? 'text': 'password'"
                 formControlName="currentPasswordCtrl" required>
        </mat-form-field>
        <br/>
        <mat-form-field class="form-fields" appearance="fill">
          <mat-label>{{ 'PROFILE.NEW_PASSWORD' | translate }}</mat-label>
          <input #pwd2 matInput
                 [type]="pwd2.value.length === 0 ? 'text': 'password'"
                 formControlName="newPasswordCtrl" required>
        </mat-form-field>
        <br/>
        <mat-form-field class="form-fields" appearance="fill">
          <mat-label>{{ 'PROFILE.CONFIRMATION' | translate }}</mat-label>
          <input #pwd3 matInput
                 [type]="pwd3.value.length === 0 ? 'text': 'password'"
                 formControlName="confirmationPasswordCtrl" required>
        </mat-form-field>
        <br/><br/>
        <button class="btn" [disabled]="changePasswordForm.get('newPasswordCtrl').value !== changePasswordForm.get('confirmationPasswordCtrl').value || changePasswordForm.get('confirmationPasswordCtrl').value.length < 3" mat-raised-button color="primary" type="submit">{{ 'PROFILE.CHANGE' | translate }}</button>

      </form>
    </div>
    <br/><br/>
  </div>
  <ng-template class="about" #authwrong>
    {{ 'PROFILE.RELOGIN' | translate }} <button mat-stroked-button [routerLink]="'/login'">{{ 'PROFILE.LOGIN' | translate }}</button>
  </ng-template>
</div>

