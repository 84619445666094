import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  // 50 - reset
  version = '4.13.26';

  constructor() { }
}
