<!--Check list here if exists-->
<div *ngIf="ticket.checkListDone.length + ticket.checkListActual.length > 0">
  <div *ngIf="ticket.checkListActual.length === 0" style="color: lightgreen;">✓
    ({{ticket.checkListDone.length}}/{{ticket.checkListDone.length + ticket.checkListActual.length}})
  </div>
  <div *ngIf="ticket.checkListActual.length !== 0">({{ticket.checkListDone.length}}
    /{{ticket.checkListDone.length + ticket.checkListActual.length}})
  </div>
</div>
<div [@openClose] *ngIf="ticket.checkListAddFlag && checkListAddingFlag">
  <br/>
  <form>
    <mat-form-field [style.width.%]=100>
      <mat-label>{{ 'TODAY.TICKET.CHECK_LIST.ADD_OPTION' | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="ticket.checkListOption"
        name="option"
        [placeholder]="'TODAY.TICKET.CHECK_LIST.NEW_OPTION' | translate"/>
    </mat-form-field>
    <br/>
    <button
      class="any-theme-button"
      mat-button
      color="primary"
      [disabled]="ticket.checkListOption == null || ticket.checkListOption.length === 0"
      (click)="newCheckListOption(ticket)">
      {{ 'TODAY.TICKET.CHECK_LIST.ADD' | translate }}
    </button>
    <button mat-button
            color="accent"
            (click)="closeCheckListEditing()">
      {{ 'TODAY.TICKET.CHECK_LIST.CLOSE' | translate }}
    </button>
  </form>
</div>

<div style="max-height: 140px; overflow: auto;">
  <div *ngIf="ticket.checkListDone.length > 0 ">
    <table style="width: 100%">
      <tr *ngFor="let optionDone of ticket.checkListDone">
        <td style="width: 8%">
          <mat-checkbox style="word-break: break-all;" class="check-list-option"
                        (click)="setOptionUnchecked(ticket, optionDone)" [checked]="true"></mat-checkbox>
          <!--              <span style="margin: auto"></span>-->
        </td>
        <td style="width: 84%">
          <span style="word-break: break-all;">{{optionDone}}</span>
        </td>
        <td style="width: 8%">
          <button mat-icon-button (click)="deleteOptionCheckList(ticket, optionDone)" color="warn">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="ticket.checkListActual.length > 0">
    <table style="width: 100%">
      <tr *ngFor="let optionActual of ticket.checkListActual">
        <td style="width: 8%">
          <mat-checkbox class="check-list-option" (click)="setOptionDone(ticket, optionActual)"
                        [checked]="false"></mat-checkbox>
        </td>
        <td style="width: 84%">
          <span style="word-break: break-all;">{{optionActual}}</span>
          <!--              <span style="margin: auto"></span>-->
        </td>
        <td style="width: 8%">
          <button mat-icon-button (click)="deleteOptionCheckList(ticket, optionActual)" color="warn">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>

