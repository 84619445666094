<div style="width: 96%; margin: auto;">
  <br/>
  <br/>
  <h1>About</h1>
  <br/>
  <br/>
  <hr/>
  <br/>
  <br/>
  <h2>This is nettyos (web application) version {{versionService.version}}</h2>
  <br/>
  <br/>
</div>
