<div class="opposite-sides">
  &nbsp;
  <button
    color="primary"
    (click)="personService.sideNavVisible = false;"
    style="position: relative; left: 5px;"
    mat-icon-button>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
</div>
<mat-accordion>
  <!-- Menu -->
<!--  <mat-expansion-panel hideToggle>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Menu-->
<!--      </mat-panel-title>-->
<!--      <mat-panel-description>-->
<!--        menu section-->
<!--      </mat-panel-description>-->
<!--    </mat-expansion-panel-header>-->
<!--    <pre>-->
<!--      show affairs:-->
<!--      Completed-->
<!--      Deferred-->
<!--      Thinking-->

<!--      Kill Dragon of Laziness-->
<!--      New Affair-->
<!--      <a [routerLink]="'/profile'"><button mat-stroked-button>Profile</button></a>-->
<!--      Today Affairs-->
<!--      Messages-->
<!--      Notes-->
<!--      Daily Affairs-->
<!--      Friends-->
<!--      Groups-->
<!--      Alerts-->
<!--      Interface-->
<!--      Change Password-->
<!--      Hall Of Glory-->
<!--      Support-->





<!--      NOS 2020. All rights reserved.-->
<!--    </pre>-->
<!--  </mat-expansion-panel>-->
  <!--  Profile-->
  <mat-expansion-panel [expanded]="true" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'MENU.PROFILE' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <pre *ngIf="personService.profile.auth === 'yes' else authwrong">
      {{personService.profile.fname}} {{personService.profile.lname}}

      {{personService.profile.email}}

      <!--   NETTY COINS   -->
      <!--      <span style="font-size: 33px;">1</span> <img style="height: 33px;" src="/assets/images/coins/nettysilver.png"> <span style="font-size: 33px;">1</span> <img style="height: 33px;" src="/assets/images/coins/nettygold.png">-->

      <img class="mini-ava"
           [src]="(this.gateway.localhost) ? this.gateway.NETTY_CLIENT_ANGULAR + personService.profile.ava : personService.profile.ava"
           *ngIf="personService.profile.ava.length > 0"/>


      <!--      <button mat-stroked-button disabled>Change Photo</button>-->
      <!--      language: <app-language [size]="'20px'"></app-language>-->
    </pre>
      <table>
      <tr>
        <td>{{ 'PROFILE.LANGUAGE' | translate}}:</td><td><app-language [size]="'20px'"></app-language></td>
        <td *ngIf="!personService.isProAccount()"></td>
        <td *ngIf="personService.profile.accountExpirationDate !== ''"></td>
      </tr>
      <tr>
        <td>{{ 'PROFILE.ACCOUNT' | translate}}:</td>
        <td
          [ngStyle]="{color: personService.isPremiumAccount() ? 'darkorchid': personService.isProAccount() ? 'darkorange': 'lightgreen'}">
          {{personService.profile.account}}
        </td>
        <td *ngIf="!personService.isProAccount()">
          <button [routerLink]="'/payment-plan'" mat-icon-button
                  style="color: darkorange;">
            <mat-icon [matTooltip]="'PAY.UPGRADE' | translate">arrow_upward</mat-icon>
          </button>
      </td>
        <td *ngIf="personService.profile.accountExpirationDate !== '' && personService.profile.accountExpirationDate !== '∞'">[{{personService.profile.accountExpirationDate}}]</td>
        <td [ngStyle]="{color: personService.isPremiumAccount() ? 'darkorchid': personService.isProAccount() ? 'darkorange': 'lightgreen'}"
            *ngIf="personService.profile.accountExpirationDate !== '' && personService.profile.accountExpirationDate === '∞'" style="color: darkorange;">∞</td>
      </tr>
    </table>
      <br/>
      <a [routerLink]="'/profile'"><button mat-stroked-button
                                           color="primary">{{ 'PROFILE.FULL_PROFILE' | translate}}</button></a>

    <ng-template #authwrong>
      {{ 'PROFILE.RELOGIN' | translate}} <button mat-stroked-button [routerLink]="'/login'">{{ 'PROFILE.LOGIN' | translate}}</button>
    </ng-template>
  </mat-expansion-panel>
  <!-- People -->
  <mat-expansion-panel hideToggle disabled="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'MENU.PEOPLE_BOOK' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-people></app-people>
  </mat-expansion-panel>
  <!-- Places -->
  <mat-expansion-panel hideToggle disabled="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'MENU.PLACES' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-places [tclass]="'ticket-content-sidenav'"></app-places>
  </mat-expansion-panel>
  <!-- Projects -->
  <mat-expansion-panel hideToggle disabled="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'MENU.PROJECTS' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-projects
      [containerClass]="'drag-n-drop-wrapper-sidebar'"
      [wrapperClass]="'tickets-container-sidebar'"
      [tclass]="'ticket-content-sidenav'"></app-projects>
  </mat-expansion-panel>
  <!-- Teams -->
<!--  <mat-expansion-panel hideToggle disabled="false">-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Teams-->
<!--      </mat-panel-title>-->
<!--      <mat-panel-description>-->
<!--      </mat-panel-description>-->
<!--    </mat-expansion-panel-header>-->
<!--    <app-teams [location]="'sidebar'"></app-teams>-->
<!--  </mat-expansion-panel>-->
  <!-- Guide -->
  <mat-expansion-panel hideToggle disabled="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'MENU.GUIDE' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-guide></app-guide>
  </mat-expansion-panel>
  <!-- Payment -->
<!--  <mat-expansion-panel hideToggle disabled="false">-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Payment-->
<!--      </mat-panel-title>-->
<!--      <mat-panel-description>-->
<!--      </mat-panel-description>-->
<!--    </mat-expansion-panel-header>-->
<!--    <app-payment [pclass]="'side-center'" [animationType]="'side'"></app-payment>-->
<!--  </mat-expansion-panel>-->
  <!-- Exit -->
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'MENU.EXIT' | translate }}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <pre>
      Goodbye!

      <a [routerLink]="'/'"><button mat-raised-button color="primary"  (click)="exit()">{{ 'MENU.EXIT' | translate}}</button></a>
    </pre>
  </mat-expansion-panel>
</mat-accordion>
