import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GatewayService, Ticket} from "../../../services/gateway.service";
import {afterMethod} from "kaop-ts";
import {AOPDialogTooltipAnswer} from "../../user/today/today.component";
import {FormControl, Validators} from "@angular/forms";
import {PersonService} from "../../../services/person.service";
import {MyErrorStateMatcher} from "../../today/projects/projects.component";
import {GuideDialogComponent} from "../../help/guide-dialog/guide-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-ticket-text',
  templateUrl: './ticket-text.component.html',
  styleUrls: ['./ticket-text.component.scss']
})
export class TicketTextComponent implements OnInit {

  @Input() ticket:Ticket;
  @Output() autoReload = new EventEmitter<boolean>();

  editTicketTextFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(200)
  ]);
  matcher = new MyErrorStateMatcher();

  constructor(public personService: PersonService, public gateway: GatewayService, public dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  startTicketEditing(ticket: Ticket) {
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    ticket.edit = 'yes';
    this.editTicketTextFormControl.setValue(ticket.ttext);
    this.autoReload.emit(false);
  }

  editTicketText(ticket: Ticket) {
    // this.autoReloadAvailableFlag = true;
    this.autoReload.emit(true);
    if (this.editTicketTextFormControl.valid) {
      this.personService.loading = true;
      ticket.ttext = this.editTicketTextFormControl.value;
      this.gateway.planningServiceEditTicketText(ticket).subscribe(data => {
        this.personService.loading = false;
        if (data.result === 'okay') {
          ticket.edit = 'no';
        }
      });
    }
  }

  // @afterMethod(AOPDialogTooltipAnswer.answer)
  // startTicketEditing(ticket: Ticket) {
  //   if (this.personService.giveDialogTooltipAnswer) return;
  //   // ****************************************************
  //   ticket.edit = 'yes';
  //   this.editTicketTextFormControl.setValue(ticket.ttext);
  //   this.autoReloadAvailableFlag = false;
  // }

  // in use by AOP functions
  public openDialog(data): void {

    //console.log(ticket);

    const dialogRef = this.dialog.open(GuideDialogComponent, {
      width: '250px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
    });
  }
}
