<mat-form-field class="language" style="width: 50px; color: #03a9f4;" color="primary">
  <mat-select (valueChange)="changeLanguage()" [(ngModel)]="personService.language" [ngClass]="personService.language" style="width: 38px;">
    <mat-option [value]="'en'" align="center">
      <img [ngStyle]="{width: size}" style="white-space: nowrap;" src="/assets/images/languages/en.png"/>
    </mat-option>
    <mat-option [value]="'ru'" align="center">
      <img [ngStyle]="{width: size}" style="white-space: nowrap;" src="/assets/images/languages/ru.png"/>
    </mat-option>
  </mat-select>
</mat-form-field>
