import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {CookieService} from "ngx-cookie-service";
import {PersonService} from "../../../services/person.service";
import {GatewayService} from "../../../services/gateway.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(public auth: AuthService,
              private cookieService: CookieService,
              public personService: PersonService,
              public gatewayService: GatewayService) { }

  ngOnInit(): void {
  }

  exit() {
      this.auth.token = 'no';
      // this.personService.profile.auth = 'no';
      this.cookieService.delete('auth');
      this.cookieService.deleteAll();
      this.auth.homeButtonLink = '/';
      // reset coins
      this.personService.silver = 0;
      this.personService.gold = 0;
  }
}
