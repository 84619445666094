<br/>
<br/>
<br/>
<div style="width: 90%; margin: auto;">

  <p>Welcome to nettyos control panel!</p>

  <table>
    <tr *ngFor="let user of users" >
      <td>{{user.fname}}</td>
      <td>{{user.lname}}</td>
      <td>{{user.email}}</td>
      <td>{{user.account}}</td>
      <td>{{user.accountExpirationDate}}</td>
      <td>{{user.tsCreated | date}}</td>
      <td>
        <select [(ngModel)]="user.account">
          <option value="free plan">free plan</option>
          <option value="Premium">Premium</option>
          <option value="Pro.Light">Pro.Light</option>
          <option value="Pro.Middle">Pro.Middle</option>
          <option value="Pro.Top">Pro.Top</option>
          <option value="Pro.Max">Pro.Max</option>
        </select>
      </td>
      <td>
        <select [(ngModel)]="user.expirationDateType">
          <option value="month">month</option>
          <option value="year">year</option>
          <option value="infinity">always</option>
        </select>
      </td>
      <td><button mat-stroked-button color="warn" (click)="changeAccount(user)">Change</button></td>
    </tr>
  </table>

</div>
