import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {Ticket} from "../../../../services/gateway.service";

@Component({
  selector: 'app-ticket-status-marker',
  templateUrl: './ticket-status-marker.component.html',
  styleUrls: ['./ticket-status-marker.component.scss']
})
export class TicketStatusMarkerComponent implements OnInit {

  @Input() ticket: Ticket;

  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  ifNotToday(ticket: any) {
    let localDateInTicket = new Date(ticket.tsOfPlannedDate);
    // console.log(ticket, this.datePipe.transform(localDateInTicket,"yyyy-MM-dd"));
    if (this.datePipe.transform(localDateInTicket,"yyyy-MM-dd") === this.datePipe.transform(new Date(),"yyyy-MM-dd")) {
      // today date
      return false;
    } else if (ticket.tsOfPlannedDate < new Date().getTime()) {
      // expired
      return false;
    }
    else {
      return true;
    }
  }

}
