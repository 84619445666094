import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

  @Input() plannedTs;

  counter = '';

  constructor() { }

  ngOnInit(): void {
    this.setCounter();
    setTimeout(() => {
      this.setCounter();
    }, 1000);
  }

  private setCounter() {
    if (new Date().getTime() < this.plannedTs) {
      const currentTs = new Date().getTime();
      // days
      let ts = this.plannedTs - currentTs;
      let days = Math.floor(ts / (24 * 60 * 60 * 1000));
      let daysNumber = 4 - String(days).length;
      let zeros = '';
      for (let i = 0; i < daysNumber; i++) {
        zeros += '0';
      }
      this.counter = zeros + days;
      // hours
      ts = ts - days * 24 * 60 * 60 * 1000;
      let hours = Math.floor(ts / (60 * 60 * 1000));
      let hoursNumber = 2 - String(hours).length;
      zeros = '';
      for (let i = 0; i < hoursNumber; i++) {
        zeros += '0';
      }
      this.counter += 'd-' + zeros + hours;
      // minutes
      ts = ts - hours * 60 * 60 * 1000;
      let minutes = Math.floor(ts / (60 * 1000));
      let minutesNumber = 2 - String(minutes).length;
      zeros = '';
      for (let i = 0; i < minutesNumber; i++) {
        zeros += '0';
      }
      this.counter += ':' + zeros + minutes;
      // seconds
      ts = ts - minutes * 60 * 1000;
      let seconds = Math.floor(ts / 1000);
      let secondsNumber = 2 - String(seconds).length;
      zeros = '';
      for (let i = 0; i < secondsNumber; i++) {
        zeros += '0';
      }
      this.counter += ':' + zeros + seconds;
      setTimeout(() => {
        this.setCounter();
      }, 1000);
    } else {
      if ((new Date().getTime() - this.plannedTs) < (24 * 60 * 60 * 1000)) {
        this.counter = 'Time!';
      } else {
        this.counter = 'expired';
      }

    }
  }
}
