<br/>
<br/>
<br/>

<!--  <mat-grid-list cols="2" rowHeight="2:1">-->
<!--    <mat-grid-tile>-->
<!--      <mat-grid-tile-header>For everyone</mat-grid-tile-header>-->
<!--      <p style="min-height: 200px;">-->
<!--        Use Nettyos instead of calendar, notes, reminder, plans, ideas, scheduler. All of that and more in apps or-->
<!--          paper versions.-->
<!--      </p>-->
<!--&lt;!&ndash;      <mat-grid-tile-footer>text</mat-grid-tile-footer>&ndash;&gt;-->
<!--    </mat-grid-tile>-->
<!--    <mat-grid-tile>2</mat-grid-tile>-->
<!--    &lt;!&ndash; separator &ndash;&gt;-->
<!--    <mat-grid-tile>3</mat-grid-tile>-->
<!--    <mat-grid-tile>4</mat-grid-tile>-->
<!--  </mat-grid-list>-->

<div class="wrapper">
  <div class="tile">
    <div align="center" class="inner-content">
      PIC1
    </div>
  </div>
  <div class="tile">
    <div align="left" class="inner-content">
      <h2>For everyone</h2>
      <p>
        Use Nettyos instead of calendar, notes, reminder, plans, ideas, scheduler. All of that and more in apps or
        paper versions.
      </p>
    </div>
  </div>
<!--  -->
  <div class="tile">
    <div align="center" class="inner-content">
      PIC2
    </div>
  </div>
  <div class="tile">
    <div align="left" class="inner-content">
      <h2>For family person</h2>
      <p>
        Find people to fix the roof in the countryside: plan, calculate, search, logistics... Chang wife's tires to winters one.
        Buy product cart in the shop. Take the floor from the country side to the apartment. Buy lego for kids the exact one in the side of the city.
        Pay student bills. Find an apartment with sea view. Test drive your family car. Step mother happy birthday. Pay taxes. This is only the beginning
        of the family man's list. Nettyos may help to do it easy and don't forget a thing.
      </p>
    </div>
  </div>
</div>

