import {Component, Injectable, OnInit} from '@angular/core';
import {GatewayService, Profile, Ticket} from "../../../services/gateway.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {HttpClient} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {DateAdapter} from "@angular/material/core";
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {DatePipe} from "@angular/common";
import {tick} from "@angular/core/testing";
import {PersonService} from "../../../services/person.service";
import {Place} from "../../today/places/places.component";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {delay} from "rxjs/operators";
import {afterMethod, beforeMethod} from "kaop-ts";
import {MatDialog} from "@angular/material/dialog";
import {TicketDialogComponent} from "../../today/team-projects/ticket-dialog/ticket-dialog.component";
import {GuideDialogComponent} from "../../help/guide-dialog/guide-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {MyErrorStateMatcher, Project} from "../../today/projects/projects.component";
import {TeamProject} from "../../today/teams/teams.component";
import {bounceInUp, bounceOutUp, hinge, shake, zoomOutRight} from "ng-animate";
import {Alert} from "../../../dto/Alerts";

export class AOPDialogTooltipAnswer {
  // all meta https://github.com/k1r0s/kaop-ts/blob/master/docs/api.md#as-an-alias
  static answer(meta) {
    // console.log('meta.key', meta.key);
    // console.log('Scope: ', meta.scope.personService.giveDialogTooltipAnswer);
    const flagShowAnswer = meta.scope.personService.giveDialogTooltipAnswer;
    if (flagShowAnswer) {
      switch (meta.key) {
        case 'mainTodayClicked' :
          meta.scope.translate.get('TOP_MENU.TODAY_TOOLTIP').subscribe(data => {
            // console.log(meta);
            meta.scope.openDialog(data);
          });
          break;
        case 'placeChoosen' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.PLACE.OPEN').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'openAddPlaceSelect' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.PLACE.ADD').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'descriptionAddStart' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.DESCRIPTION.ADD').subscribe(data => {
            meta.scope.openDialog(data);
          });
        case 'addDescriptionInDialogT' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.DESCRIPTION.ADD').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'descriptionEditStart' :
          // open edit are for description of the ticket
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.DESCRIPTION.EDIT').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'selectTicketAssignProject' :
          // select clicked (assign to person or project)
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.ASSIGN').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'selectTicketStatus' :
          // select clicked (ticket statuses)
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.STATUS').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'newTicketOnSubmit' :
          // add new ticket button
          // console.log('new ticket add button');
          meta.scope.translate.get('GUIDE.TOOLTIPS.CONTROLS.ADD').subscribe(data => {
            meta.scope.openDialog(data);
          });
          // meta.scope.openDialog('Click this button to add new ticket into the Today.');
          break;
        case 'removeTicketPre' :
          // remove ticket button
          // console.log('new ticket add button');
          meta.scope.translate.get('GUIDE.TOOLTIPS.TICKET.REMOVE').subscribe(data => {
            meta.scope.openDialog(data);
          });
          //meta.scope.openDialog('Remove ticket totally. Safety system - on. No way to recover.');
          break;
        case 'ideaTickets' :
          // idea tickets
          meta.scope.translate.get('GUIDE.TOOLTIPS.IDEA').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'endedTickets' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.ENDED').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'todayTickets' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.TODAY').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'futureTickets' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.FUTURE').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'searchTickets' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.SEARCH').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'changeTheme' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.THEME').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'aboutCoins' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.COINS').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'fastDone' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.FAST_DONE').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'fastTomorrow' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.FAST_TOMORROW').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'aboutInputTicket' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.WRITE_TICKET').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'startTicketEditing' :
          console.log(meta)
          meta.scope.translate.get('GUIDE.TOOLTIPS.EDIT_TICKET').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'checkListAddStart' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.CHECK_LIST').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        case 'openComments' :
          meta.scope.translate.get('GUIDE.TOOLTIPS.OPEN_COMMENTS').subscribe(data => {
            meta.scope.openDialog(data);
          });
          break;
        default:
      }
    }
    // any action should set flag off
    meta.scope.personService.giveDialogTooltipAnswer = false;
  }
}

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  animations: [
    trigger('movedCard', [
      state('exists', style({
        transform: 'translateX(0%)',
        opacity: '1'
      })),
      state('moved', style({
        transform: 'translateX(200%)'
      })),
      state('removed', style({
        opacity: '0'
      })),
      // transition('exists => moved', [
      //   //style({ transform: 'translateX(0%)' }),
      //   animate('500ms linear')
      // ]),
      transition('exists => moved', useAnimation(zoomOutRight)),
      transition('exists => removed', useAnimation(hinge)),
      // transition('exists => removed', [
      //   //style({ opacity: '1' }),
      //   animate('1500ms')
      // ])
      /*,
      transition('void => new', [
        style({ transform: 'translateX(+1000%)' }),
        animate(500)
      ])*/
    ]),
    trigger('comments', [
      transition('void => *', useAnimation(bounceInUp)),
      transition('* => void', useAnimation(bounceOutUp))
    ]),
    trigger('removeButtonShakeAnimation', [
      transition('accent => warn', useAnimation(shake, {
        params: { timing: 0.4 }
      }))
    ])
  ]
})
export class TodayComponent implements OnInit {

  date = new FormControl(moment());
  matcher = new MyErrorStateMatcher();

  tickets: Ticket [] =[];
  ticketsEnded: Ticket [] =[];
  ticketsToday: Ticket [] =[];
  ticketsFuture: Ticket[] = [];
  ticketsIdeaPersonalBacklog: Ticket[] = [];
  autoReloadAvailableFlag = true;
  checkListAddingFlag = false;
  commentsFlag =false;


  newTicketFormGroup: FormGroup;
  done = '';
  cancelled = '';
  tomorrow = '';
  later = '';
  idea = false;
  showCookies = false;
  ended = false;
  future = false;
  private loaded = 1;
  private tmpTickets: Ticket [] = [];
  search = '';
  // searchFlag = false;
  reloadAllTicketsAfterTs = 0;
  messageText = '';
  private messageMaxTs = 0;



  constructor(
    public gateway: GatewayService,
    public translate: TranslateService,
    private _formBuilder: FormBuilder,
    private auth: AuthService,
    private http: HttpClient,
    public cookieService: CookieService,
    private datePipe: DatePipe,
    public personService: PersonService,
    public dialog: MatDialog
  ) { }

  // loader
  ngOnInit(): void {
    // console.log('today');
    this.autoLoadAllTickets();
    this.reloadOnExternalComponentDemand();

    this.newTicketFormGroup = this._formBuilder.group({
      ticketTextCtrl: [''/*, Validators.required*/]
    });

    // todo: to make coins load everywhere
    this.personService.loading = true;
    this.gateway.userServiceGetUserProfile().subscribe(
      (profile: Profile) => {
        this.personService.addCoins(profile.silverCoin, profile.goldCoin);
        // this.personService.silver = profile.silverCoin;
        // this.personService.gold = profile.goldCoin;
        this.personService.loading = false;
      });

    // get all team projects for user
    ///api/pln/v1/teams/team/project/tickets/new
    this.personService.loading = true;
    this.gateway.getAllTeamProjectsService().subscribe(
      (teamProjects: TeamProject []) => {
        this.personService.allTeamProjects = teamProjects;
        // console.log(teamProjects + 'teamProject');
        // for(let i of teamProjects) {
        //   console.log(i)
        // }
        this.personService.loading = false;
      });
    this.loadAllTeams();

    this.personService.loading = true;
    this.gateway.userServiceGetUserAlerts().subscribe(
      (alerts: Alert []) => {
        this.personService.alerts = alerts;
        for (let alert of alerts) {
          if (!alert.readFlag) {
            this.personService.unread = true;
          }
        }
        // console.log('alerts', this.personService.alerts);
        this.personService.loading = false;
      });
  }


  private loadAllTeams() {
    this.personService.loading = true;
    this.gateway.planningServiceLoadAllTeams().subscribe(data => {
      this.personService.teams = data;
      this.personService.teamSelected = {projects: [], newMember: undefined, auth: "", id: "", members: [], name: ""};
      this.personService.loading = false;
    });
  }

  public openDialog(data): void {

    //console.log(ticket);

    const dialogRef = this.dialog.open(GuideDialogComponent, {
      width: '250px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
    });
  }

  public autoLoadAllTickets() {
    //console.log('autoLoadAllTickets', this.autoReloadTicketsOpenFlag);
    if (
      this.autoReloadAvailableFlag
      && new Date().getTime() > this.reloadAllTicketsAfterTs
    ) {
      this.loadAllTickets(0);
    }
    // one minute after reload
    this.reloadAllTicketsAfterTs = new Date().getTime() + 1 * 60 * 1000;
    setTimeout(() => {
      this.autoLoadAllTickets();
    }, 61 * 1000);
  }

  // loader - no request
  public loadAllTickets(delayTimeMs) {
    // reset flag not to block auto reload in some cases
    this.autoReloadAvailableFlag = true;
    // checkboxes
    this.checkListAddingFlag = false;
    if (!this.personService.searchFlag) {
      // this.tickets = [];
      this.loaded = 1;
      this.ticketsEnded = [];
      this.ticketsToday = [];
      this.ticketsFuture = [];
      this.ticketsIdeaPersonalBacklog = [];
      this.loadCategoryOfTickets('ended', this.ended, delayTimeMs);
      this.loadCategoryOfTickets('future', this.future, delayTimeMs);
      this.loadCategoryOfTickets('idea', this.idea, delayTimeMs);
      // today always shown
      this.loadCategoryOfTickets('today', true, delayTimeMs);
    } else {
      this.makeSearch();
    }
  }

  // loader - no request
  private resetAllTickets() {
    // this.tickets = [];
    this.tickets = [];
    // this.loaded = 1;
    this.ticketsEnded = [];
    this.ticketsToday = [];
    this.ticketsFuture = [];
    this.ticketsIdeaPersonalBacklog = [];
    // this.loadCategoryOfTickets('ended', this.ended);
    // this.loadCategoryOfTickets('future', this.future);
    // this.loadCategoryOfTickets('idea', this.idea);
    // // today always shown
    // this.loadCategoryOfTickets('today', true);
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  newTicketOnSubmit(value: any) {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;

    this.personService.loading = true;
    if ('show cookies' === value.ticketTextCtrl.toLowerCase()) {
      this.showCookies = true;
      return;
    }
    if ('alpha mode' === value.ticketTextCtrl.toLowerCase()) {
      this.personService.alpha = true;
      return;
    }
    //console.log(value.)
    if (value.ticketTextCtrl.length > 0) {
      this.gateway.planningServiceNewTicket(value).subscribe(
        (answer) => {
          //console.log('newTicketOnSubmit', answer);
          // this.tickets = ticket;
          this.loadAllTickets(0);
          //console.log('loadAllTickets', answer);
          this.newTicketFormGroup.get('ticketTextCtrl').setValue('');
          this.personService.loading = false;
        });
    } else {
      this.personService.loading = false;
    }
  }

  // loader
  removeTicket(uuid: string, ticket: Ticket) {
    //console.log(ticket);
    this.personService.loading = true;
    this.gateway.planningServiceDeleteTicket(uuid)
      .subscribe(
      (answer) => {
        this.personService.loading = false;
        this.tickets.find(p => p.uuid === uuid ).animationState = 'removed';
        this.leaveMessage('Ticket removed.');
        this.loadAllTickets(1000);
      });
  }


  @afterMethod(AOPDialogTooltipAnswer.answer)
  removeTicketPre(ticket: Ticket) {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;

    if (ticket.deleteButtonColor === 'warn') {
      this.removeTicket(ticket.uuid, ticket);
    }
    ticket.deleteButtonColor = 'warn';
    setTimeout(() => {
      if (ticket != null) {
        ticket.deleteButtonColor = 'accent';
      }
    }, 3000);
  }

  // loader
  changeStatus(ticket: Ticket) {
    //console.log('status', ticket);
    if ('date' !== ticket.status && 'newdate' !== ticket.status) {
      this.personService.loading = true;
      this.gateway.planningServiceNewTicketStatus(ticket).subscribe(
        (answer: ExtendedStatus) => {
          //console.log('changeStatus', answer);
          if ('GOLD' === answer.message) {
            // this.personService.gold += answer.measurement;
            this.personService.addCoins(this.personService.silver, this.personService.gold + answer.measurement)
          } else if ('SILVER' === answer.message) {
            this.personService.addCoins(this.personService.silver + answer.measurement, this.personService.gold)
          }
          this.personService.loading = false;
          let delay = 0;
          if (
            !this.future
            && !this.personService.searchFlag
            && (ticket.status === 'tomorrow' || ticket.status === 'later')
          ) {
            this.tickets.find(p => p.uuid === ticket.uuid).animationState = 'moved';
            this.leaveMessage('Press "future" to see ticket. Or "search".');
            delay = 1000;
          }
          if (
            !this.ended
            && !this.personService.searchFlag
            && (ticket.status === 'done' || ticket.status === 'cancelled')
          ) {
            this.tickets.find(p => p.uuid === ticket.uuid).animationState = 'moved';
            this.leaveMessage('Press "ended" to see ticket. Or "search".');
            delay = 1000;
          }
          if (
            !this.idea
            && !this.personService.searchFlag
            && (ticket.status === 'idea')
          ) {
            this.tickets.find(p => p.uuid === ticket.uuid).animationState = 'moved';
            this.leaveMessage('Press "idea" to see ticket. Or "search".');
            delay = 1000;
          }
          this.loadAllTickets(delay);
        });
    } else if ('date' === ticket.status || 'newdate' === ticket.status)  {
      setTimeout(() => {
        this.autoReloadAvailableFlag = false;
        //console.log('changeStatus', this.autoReloadTicketsOpenFlag);
      }, 1000);
    }
  }

  // todo: don't load twice if today list or other list was not changed
  // loader
  private loadCategoryOfTickets(category: string, concat: boolean, delayTimeMs) {
    //console.log('loadCategoryOfTickets', category, concat);
    this.personService.loading = true;
    if (concat) {
      this.gateway.planningServiceGetTicketsList(category)
        .pipe(
          delay(delayTimeMs)
        )
        .subscribe(
        (tickets: Ticket []) => {
          //console.log('ticket', tickets);
          // todo: constants
          if ('ended' === category) {
            this.ticketsEnded = tickets;
            // this.tickets = this.tickets.concat(this.ticketsEnded);
          } else if ('today' === category) {
            this.ticketsToday = tickets;
          } else if ('future' === category) {
            this.ticketsFuture = tickets;
          } else if ('idea' === category) {
            this.ticketsIdeaPersonalBacklog = tickets;
          }
          this.loaded ++;
          if (5 === this.loaded) {
            this.tickets = this.ticketsEnded.concat(this.ticketsToday.concat(this.ticketsFuture.concat(this.ticketsIdeaPersonalBacklog)));
          }
          //console.log('this.tickets', this.tickets);
          this.personService.loading = false;
        });
    } else {
      this.loaded ++;
      if ('ended' === category  && concat) {
        // this.tickets = this.tickets.concat(this.ticketsEnded);
      } else if ('today' === category && concat) {
        // always true
        // this.tickets = this.tickets.concat(this.ticketsToday);
      } else if ('future' === category  && concat) {
        // this.tickets = this.tickets.concat(this.ticketsFuture);
      } else if ('idea' === category  && concat) {
        // this.tickets = this.tickets.concat(this.ticketsIdeaPersonalBacklog);
      }
      if (5 === this.loaded) {
        this.tickets = this.ticketsEnded.concat(this.ticketsToday.concat(this.ticketsFuture.concat(this.ticketsIdeaPersonalBacklog)));
      }
    }
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  endedTickets() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************

    if (this.ended) {
      this.ended = false;
    } else {
      this.ended = true;
    }
    this.loadAllTickets(0);
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  futureTickets() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************

    if (this.future) {
      this.future = false;
    } else {
      this.future = true;
    }
    this.loadAllTickets(0);
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  ideaTickets() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************

    if (this.idea) {
      this.idea = false;
    } else {
      this.idea = true;
    }
    this.loadAllTickets(0);
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  todayTickets() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
  }

  // loader
  okayDateDone(ticket: Ticket) {
    this.autoReloadAvailableFlag = true;
    //console.log('okayDateDone', this.autoReloadTicketsOpenFlag);
    if ('newdate' === ticket.status) {
      ticket.status = 'date';
    }
    //console.log(ticket);
    //console.log(this.date);
    // const dateId = this.date.value._d.getFullYear() + '-' + this.date.value._d.getMonth() + '-' + this.date.value._d.getDate();
    //console.log(this.date.value._d.getFullYear());
    //console.log(dateId);
    //
    const dateStr = this.datePipe.transform(this.date.value._d,"yyyy-MM-dd");

    const dateAndTimeStr = dateStr + ':' + ticket.hh + ':' + ticket.mm;

    const dateAndTimeISOStr = dateStr + 'T' + ticket.hh + ':' + ticket.mm + ':00Z';

    let date = new Date(dateAndTimeISOStr);

    // date.setHours()

    //console.log(date.getMilliseconds());

    //console.log(dateStr, dateAndTimeStr, dateAndTimeISOStr, date, date.getTimezoneOffset(), new Date(date.getUTCMilliseconds() + date.getTimezoneOffset() * 60 * 1000));

    let localDateTimeWithZone = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    // console.log(date, date.getTime(), localDateTimeWithZone);

    const tsOfPlannedDate = localDateTimeWithZone.getTime();

    ticket.tsOfPlannedDate = tsOfPlannedDate;
    ticket.dateIdStr = dateStr;
    ticket.dateAndTimeIdStr = dateAndTimeStr;

    // console.log('ticket.tsOfPlannedDate', ticket.tsOfPlannedDate, new Date(ticket.tsOfPlannedDate));

    this.personService.loading = true;
    this.gateway.planningServiceNewTicketStatus(ticket).subscribe(
      (answer) => {
        this.personService.loading = false;
        let delay = 0;
        if (!this.future
          && !this.personService.searchFlag
          && (ticket.status === 'date')
          && (ticket.tsOfPlannedDate > new Date().getTime())
          && this.datePipe.transform(new Date(),"yyyy-MM-dd") !== ticket.dateIdStr
        ) {
          //console.log(ticket);
          this.tickets.find(p => p.uuid === ticket.uuid).animationState = 'moved';
          this.leaveMessage('Press "future" to see ticket. Or "search".');
          delay = 1000;
        }
        this.loadAllTickets(delay);
      });
  }

  // no loader
  newDate(tsOfPlannedDate: number) {
    return new Date(tsOfPlannedDate);
  }

  // no loader
  ifNotToday(ticket: any) {
    let localDateInTicket = new Date(ticket.tsOfPlannedDate);
    // console.log(ticket, this.datePipe.transform(localDateInTicket,"yyyy-MM-dd"));
    if (this.datePipe.transform(localDateInTicket,"yyyy-MM-dd") === this.datePipe.transform(new Date(),"yyyy-MM-dd")) {
      // today date
      return false;
    } else if (ticket.tsOfPlannedDate < new Date().getTime()) {
      // expired
      return false;
    }
    else {
      return true;
    }
  }

  // todo: or team
  assignToPersonOrProject(ticket: Ticket) {
    // console.log(ticket);
    if (ticket.personId.charAt(0) !== '*' && ticket.personId.charAt(0) !== '$') {
      //console.log('assignToPerson', ticket);
      this.gateway.planningServiceAssignTicket(ticket).subscribe(
          (answer) => {
            this.loadAllTickets(0);
          });
    } else if (ticket.personId.charAt(0) === '*')  {
      ticket.projectId = ticket.personId.substring(1, ticket.personId.length);
      // console.log('assignToPersonOrProject:: ', ticket.projectId, ticket.personId);
      let project = this.personService.projects.find(p => p.id === ticket.projectId);
      ticket.projectFullName = project.name;
      ticket.projectShortName = project.shortName;
      this.personService.loading = true;
      this.gateway.planningServiceSetTicketToProject(ticket).subscribe(
          (answer) => {
            this.personService.loading = false;
            this.loadAllTickets(0);
          });
      //console.log('assignToPersonOrProject', 'project');
    } else if (ticket.personId.charAt(0) === '$')  {

      ticket.teamProjectId = ticket.personId.substring(1, ticket.personId.length);
      // find team
      for (let team of this.personService.teams) {
        for (let teamProject of team.projects) {
          if (ticket.teamProjectId === teamProject.id) {
            ticket.teamId = team.id;
            break;
          }
        }
      }
      // set ticket to team
      this.personService.loading = true;
      this.gateway.planningServiceSetTicketToTeam(ticket).subscribe(
          (answer) => {
            this.personService.loading = false;
            this.loadAllTickets(0);
          });
    }

  }

  // loader
  mapThePlace(ticket: Ticket) {
    this.autoReloadAvailableFlag = true;
    //console.log('mapThePlace', this.autoReloadTicketsOpenFlag);
    //console.log('mapThePlace', ticket.addPlace)
    let place: Place = this.personService.places.find(pl => pl.id === ticket.addPlace);
    ticket.placeId = ticket.addPlace;
    ticket.placeName = place.name;
    this.personService.loading = true;
    this.gateway.planningServicePlaceToTicket(ticket).subscribe(
      (answer) => {
        this.personService.loading = false;
        this.loadAllTickets(0);
      });
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  searchTickets() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    if (this.personService.searchFlag) {
      this.personService.searchFlag = false;
      this.loadAllTickets(0);
    } else {
      this.personService.searchFlag = true;
      this.resetAllTickets();
    }
  }

  monitorSearchRequestAvailable = 0;
  // all actions in 500ms
  // no loader
  searchingPool() {
    // search request will be available in 500ms
    this.monitorSearchRequestAvailable = new Date().getTime() + 500;
    setTimeout(() => {
      if (new Date().getTime() > this.monitorSearchRequestAvailable) {
        this.makeSearch();
      }
    }, 550);
  }

  // loader
  makeSearch() {
    if (this.search.length >= 3) {
      this.personService.loading = true;
      this.gateway.planningServiceGetTicketListBySearch(this.search).subscribe(data => {
        this.tickets = data;
        this.personService.loading = false;
      });
    } else {
      this.tickets = [];
    }
  }

  // loader
  saveDescription(ticket: Ticket) {
    this.autoReloadAvailableFlag = true;
    //console.log('saveDescription', this.autoReloadTicketsOpenFlag);
    // if (ticket.description.length > 5) {
    this.personService.loading = true;
      this.gateway.planningServiceSaveTicketDescription(ticket).subscribe(data => {
        // this.tickets = data;
        this.personService.loading = false;
        this.loadAllTickets(0);
      });
    // } else {
    //   this.loadAllTickets();
    // }
  }

  private leaveMessage(text) {
    this.messageText = text;
    this.messageMaxTs = new Date().getTime() + 5 * 1000;
    setTimeout(() => {
      if (new Date().getTime() > this.messageMaxTs) {
        this.messageText = '';
      }
    }, 5100);
  }

  reloadingSemafor($event: any) {
    if ($event) {
      this.autoReloadAvailableFlag = false;
    } else {
      this.autoReloadAvailableFlag = true;
    }
    //console.log('reloadingSemafor', this.autoReloadTicketsOpenFlag);
    // console.log('opened, closed', $event, this.autoReloadTicketsOpenFlag);
  }

  getProjectName(teamProjectId: string) {
    let teamProject = this.personService.teamProjects.find(p => p.id === teamProjectId);
    return teamProject.shortName;
  }

  // private loadAllTeamProjects() {
  //   this.gateway.planningServicAllTeamProjects(this.teamId).subscribe(data => {
  //     //console.log('loadAllTeamProjects', data);
  //     this.personService.teamProjects = data;
  //     this.personService.loading = false;
  //   });
  // }


  @afterMethod(AOPDialogTooltipAnswer.answer)
  selectTicketStatus() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  selectTicketAssignProject() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  descriptionEditStart(ticket: Ticket) {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    ticket.descriptionAddFlag = true; this.autoReloadAvailableFlag = false;
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  descriptionAddStart(ticket: Ticket) {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    ticket.descriptionAddFlag = true; this.autoReloadAvailableFlag = false;
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  openAddPlaceSelect(ticket: Ticket) {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    ticket.addPlace='pyes'; this.autoReloadAvailableFlag = false;
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  placeChoosen() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  checkListAddStart(ticket: Ticket) {
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    this.checkListAddingFlag = true;
    this.autoReloadAvailableFlag = false;
    ticket.checkListAddFlag = true;
  }

  newCheckListOption(ticket: Ticket) {
    this.personService.loading = true;
    this.gateway.planningServiceAddCheckListOptionToTicket(ticket).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        ticket.checkListActual.push(ticket.checkListOption);
        ticket.checkListOption = '';
      }
      // console.log('newCheckListOption', data);
    });
  }

  setOptionDone(ticket: Ticket, optionActual: string) {
    this.personService.loading = true;
    this.gateway.planningServiceSetCheckListOptionCheckedToTicket(ticket, optionActual).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListActual.indexOf(optionActual, 0);
        if (index > -1) {
          ticket.checkListActual.splice(index, 1);
        }
        ticket.checkListDone.push(optionActual);
      }
      // console.log('newCheckListOption', data);
    });
  }

  setOptionUnchecked(ticket: Ticket, optionDone: string) {
    this.personService.loading = true;
    this.gateway.planningServiceSetCheckListOptionUncheckedToTicket(ticket, optionDone).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListDone.indexOf(optionDone, 0);
        if (index > -1) {
          ticket.checkListDone.splice(index, 1);
        }
        ticket.checkListActual.push(optionDone);
      }
      // console.log('newCheckListOption', data);
    });
  }

  deleteOptionCheckList(ticket: Ticket, optionToDelete: string) {
    this.personService.loading = true;
    this.gateway.planningServiceDeleteCheckListOptionInTicket(ticket, optionToDelete).subscribe(data => {
      this.personService.loading = false;
      if (data.result === 'okay') {
        const index = ticket.checkListDone.indexOf(optionToDelete, 0);
        if (index > -1) {
          ticket.checkListDone.splice(index, 1);
        }
        const index1 = ticket.checkListActual.indexOf(optionToDelete, 0);
        if (index1 > -1) {
          ticket.checkListActual.splice(index1, 1);
        }
      }
      // console.log('newCheckListOption', data);
    });
  }

  getTextWithLinks(actualDescription: string, fullDescription) {
    // the goal to add symbol charCode=10 with spaces
    let newDescription = '';
    let counter = 0;
    for (let i=0; i < fullDescription.length; i++) {
      if (fullDescription.charCodeAt(i) !== 10) {
        newDescription += fullDescription.charAt(i);
      } else {
        newDescription += ' ' + fullDescription.charAt(i) + ' ';
        counter-=2;
      }
    }
    let wordsArray = newDescription.split(' ');
    let finalWords = [];
    // only non cut words and links
    for (let word of wordsArray) {
      if (counter <= actualDescription.length) {
        finalWords.push(word);
        counter+= word.length + 1;
      }
    }
    return finalWords;
  }

  pureLink(wordOrLink: string) {
    while (wordOrLink.charAt(wordOrLink.length - 1) === ',' ||
      wordOrLink.charAt(wordOrLink.length - 1) === ';' ||
      wordOrLink.charAt(wordOrLink.length - 1) === ':' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '-' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '—' ||
      wordOrLink.charAt(wordOrLink.length - 1) === '.'
      ) {
      if (wordOrLink.length > 1) {
        wordOrLink = wordOrLink.substring(0, wordOrLink.length - 2)
      }
    }
    return wordOrLink;
  }

  private reloadOnExternalComponentDemand() {
   if (this.personService.reloadTicketsOnDemand) {
     this.personService.reloadTicketsOnDemand = false;
     this.loadAllTickets(0);
   }
    setTimeout(() => {
      this.reloadOnExternalComponentDemand();
    }, 100);
  }


  @afterMethod(AOPDialogTooltipAnswer.answer)
  fastDone(ticket: Ticket) {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    ticket.status = 'done';
    this.changeStatus(ticket);
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  fastTomorrow(ticket: Ticket) {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    ticket.status = 'tomorrow';
    this.changeStatus(ticket);
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  aboutInputTicket() {
    if (this.personService.giveDialogTooltipAnswer) return;
  }

  // isAssignedToProject(ticket: Ticket) {
  //   let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
  //   if (null != project && project.businessProcess != null && project.businessProcess.indexOf(',') !== -1)
  //     return true;
  //   else
  //     return false;
  //
  // }

  // assignFirstBusinessStep(ticket: Ticket) {
  //   let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
  //   if (null != project && project.businessProcess != null && project.businessProcess.indexOf(',') !== -1) {
  //     ticket.businessProcessStep = project.businessProcess.split(',')[0];
  //     this.personService.loading = true;
  //     this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
  //       (answer: ExtendedStatus) => {
  //         this.personService.loading = false;
  //       }
  //     );
  //   }
  // }

  // isPreviousStep(ticket: Ticket) {
  //   let isTheStepExist = false;
  //   let existsFlag = false;
  //   let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
  //   let stepsArray = project.businessProcess.split(',');
  //   for (let i=0; i<stepsArray.length; i++) {
  //     if (stepsArray[i] === ticket.businessProcessStep && i > 0) {
  //       existsFlag = true;
  //     }
  //     if (stepsArray[i] === ticket.businessProcessStep) {
  //       isTheStepExist = true;
  //     }
  //   }
  //   if (!isTheStepExist) {
  //     // если этого бизнес процесса больше нет - тогда вначало
  //     this.assignFirstBusinessStep(ticket);
  //   }
  //   return existsFlag;
  // }

  // isNextStep(ticket: Ticket) {
  //   let existsFlag = false;
  //   let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
  //   let stepsArray = project.businessProcess.split(',');
  //   for (let i=0; i<stepsArray.length; i++) {
  //     if (stepsArray[i] === ticket.businessProcessStep && i +1 < stepsArray.length) {
  //       existsFlag = true;
  //     }
  //   }
  //   return existsFlag;
  // }
  //
  // moveNextStep(ticket: Ticket) {
  //   let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
  //   if (null != project && project.businessProcess != null) {
  //     let stepsArray = project.businessProcess.split(',');
  //     for (let i=0; i<stepsArray.length; i++) {
  //       if (stepsArray[i] === ticket.businessProcessStep && (i +1) < stepsArray.length) {
  //         ticket.businessProcessStep = stepsArray[i+1];
  //         break;
  //       }
  //     }
  //     this.personService.loading = true;
  //     this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
  //       (answer: ExtendedStatus) => {
  //         this.personService.loading = false;
  //       }
  //     );
  //   }
  // }

  // movePreviousStep(ticket: Ticket) {
  //   let project: Project = this.personService.projects.find(p => p.id === ticket.projectId);
  //   if (null != project && project.businessProcess != null) {
  //     let stepsArray = project.businessProcess.split(',');
  //     for (let i=0; i<stepsArray.length; i++) {
  //       if (stepsArray[i] === ticket.businessProcessStep && (i - 1) >= 0) {
  //         ticket.businessProcessStep = stepsArray[i-1];
  //         break;
  //       }
  //     }
  //     this.personService.loading = true;
  //     this.gateway.planningServiceUpdateTicketBusinessStep(ticket).subscribe(
  //       (answer: ExtendedStatus) => {
  //         this.personService.loading = false;
  //       }
  //     );
  //   }
  // }

  projectReset(ticket: Ticket) {
    this.personService.loading = true;
    this.gateway.planningServiceResetProjectInTicket(ticket).subscribe(
      (answer: ExtendedStatus) => {
        this.personService.loading = false;
        this.loadAllTickets(0);
      }
    );
  }

  getShortNameByTeamProjectId(teamProjectId: string) {
    let project: TeamProject[] = this.personService.allTeamProjects.filter(tp => tp.id === teamProjectId)
    if (project.length > 0)
      return project[0].shortName;
    else
      return '';
  }

  getFullNameByTeamProjectId(teamProjectId: string) {
    let project: TeamProject[] = this.personService.allTeamProjects.filter(tp => tp.id === teamProjectId)
    if (project.length > 0)
      return project[0].name;
    else
      return '';
  }

  findShortNameByProjectId(projectId: string) {
    let answer = '';
    const projects: Project [] = this.personService.projects.filter(pr => pr.id === projectId);
    if (projects.length > 0) {
      const project: Project =  projects[0];
      answer = project.shortName;
    }
    return answer;
  }

  changeAutoReload(val: boolean) {
    this.autoReloadAvailableFlag = val;
  }

  getTeamName(teamId: string) {
    for (let team of this.personService.teams) {
      if (team.id === teamId) {
        return team.name;
      }
    }
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  openComments(ticket: Ticket) {
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
    ticket.commentsFlag = !ticket.commentsFlag;
    this.autoReloadAvailableFlag = !this.autoReloadAvailableFlag;
  }
}
