import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {PersonService} from "../../../services/person.service";
import {afterMethod} from "kaop-ts";
import {AOPDialogTooltipAnswer} from "../../user/today/today.component";
import {TranslateService} from "@ngx-translate/core";
import {GuideDialogComponent} from "../../help/guide-dialog/guide-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public router: Router,
              public auth: AuthService,
              public personService: PersonService,
              public translate: TranslateService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  @afterMethod(AOPDialogTooltipAnswer.answer)
  mainTodayClicked() {
    // guide button pressed
    if (this.personService.giveDialogTooltipAnswer) return;
    // ****************************************************
  }

  // in use by AOP functions
  public openDialog(data): void {

    //console.log(ticket);

    const dialogRef = this.dialog.open(GuideDialogComponent, {
      width: '250px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
    });
  }

}
