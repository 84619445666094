import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {GatewayService} from "../../../services/gateway.service";
import {AuthService} from "../../../services/auth.service";
import {CookieService} from "ngx-cookie-service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  dataFormGroup: FormGroup;
  passwordFormGroup: FormGroup;
  codeFormGroup: FormGroup;
  agreement = '';
  registerResult = "";
  registerColor = 'blue';
  suchUserCreated = '';
  private formValue: any;
  goAuth = false;
  systemInformation = '';

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    private api: GatewayService,
    private auth: AuthService,
    private cookieService: CookieService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.dataFormGroup = this._formBuilder.group({
      emailCtrl: ['', Validators.required],
      nameCtrl: ['', Validators.required],
      sNameCtrl: ['', Validators.required],
      agreementCtrl: ['', Validators.required],
      systemInformationCtrl: ['', Validators.required],
      pwdCtrl: ['', Validators.required],
      cPwdCtrl: ['', Validators.required]
    });
    this.codeFormGroup = this._formBuilder.group({
      codeCtrl: ['', Validators.required]
    });
  }

  register() {
    //console.log('agreement');
  }

  registerOnSubmitStep1(value: any) {
    this.formValue = value;
    //console.log('value', value);
    let url = '/api/lrs/v1/register/user/';
    if (this.api.localhost) {
      url = this.api.LOGIN_REGISTER_SERVICE + url;
    }
    try {
      //console.log('1');
      this.http.post<ExtendedStatus>(url, value)
        .subscribe((data) => {
          //console.log('data:: ', data);
          if (data.message === 'no created') {
            this.suchUserCreated = 'Such user exists. But not activated. Enter confirmation code from email.';
          }
        }, (error) => this.handleError(error));
    } catch (e) {
      //console.log('2');
      //console.log(e)
    }
  }

  handleError(error: any) {
    //console.log('error', error);
  }

  registerOnSubmitStep2(value: any) {

    const email = this.dataFormGroup.get('emailCtrl').value;
    const value2 = {
      codeCtrl: value.codeCtrl,
      emailCtrl: email
    };
    let url = '/api/lrs/v1/register/code/';
    if (this.api.localhost) {
      url = this.api.LOGIN_REGISTER_SERVICE + url;
    }

    try {
      this.http.put<ExtendedStatus>(url, value2)
        .subscribe((data) => {
          //console.log('data:: ', data);
          if (data.result === 'okay') {
            this.registerResult = 'Registration successful! Congratulations!';
            this.registerColor = 'lightgreen';
            this.soLogin();
          } else {
            this.registerResult = data.message;
            this.registerColor = 'orange';
          }
        }, (error) => this.handleError(error));
    } catch (e) {
    }
    //console.log('value', value);
  }

  private soLogin() {
    try {
      let url = '/api/lrs/v1/login/email/';
      if (this.api.localhost) {
        url = this.api.LOGIN_REGISTER_SERVICE + url;
      }
      this.auth.email = this.formValue.emailCtrl;
      this.http.post<ExtendedStatus>(url, this.formValue)
        .subscribe((data) => {
          // this.authToken = data.message;
          this.cookieService.deleteAll();
          this.auth.token = data.message;
          // set cookies
          this.cookieService.set('auth', data.message, 365);
          if (data.result === 'not') {
            // this.loginMessage = 'Password wrong.';
            // this.loginColor = 'orange';
          } else if (data.result === 'okay') {
            // this.loginMessage = 'Done.';
            // this.loginColor = 'green';
            this.auth.homeButtonLink = '/today';
            this.goAuth = true;
          }
        }, (error) => this.handleError(error));
    } catch (e) {
    }
  }
}
