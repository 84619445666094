<div style="width: 96%; margin: auto;" align="center">
  <app-solutions-header></app-solutions-header>
  <h1 *ngIf="translate.defaultLang === 'ru'">Исполнитель Мечт</h1>
  <h1 *ngIf="translate.defaultLang === 'en'">Dreams Maker</h1>
  <br/>
  <img
    [@nettyos]
    style="width: 300px;"
    src="./assets/images/landing/step2/top-devices.svg"
  />
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'ru'">
    <p class="text">
      Nettyos можно использовать для какой-то конкретной, ограниченной цели, а можно для всего сразу.
      Это зависит от настроек и тарифного плана.
      <br/>
      <br/>
      Если взрослый человек выпишет около 100 адекватных мечт на лист бумаги, то за год по статистике исполняется примерно 20 из 100. Если вместо листа бумаги использовать Nettyos то для 20 мечт из ста придется только один раз нажать кнопку "готово".
      <br/>
      <br/>
      Но это далеко не все. Nettyos умеет гораздо больше. Автор Nettyos еще и автор серии книг по психологии "Азбука Человеческой Психики", которая открыла новую эпоху психологии. То, что начинает становится популярным сегодня (ранняя весна 2021) уже много лет интегрировано в Nettyos чтобы пользователи и главный пользователь Nettyos, Никита Данилов, могли закончить проекты и реализовать идеи/мечты наиболее продуктивно и с минимальными затратами.
      <br/>
      <br/>
      Такой метод как "дофаминовое вознаграждение", например, используется Nettyos еще с 2009 года. Только в случае с Nettyos пользователь получает "честный нейрококтейль" в мозгу от успешной реализации части проекта. Мозгу, все-равно, закончили Вы с успехом полностью проект, модуль или разобрались с багом. В любом случае, будет вознаграждение. Именно это вознаграждение характерно для здоровой психики. Здоровый нейроколтейль, который получает мозг дает силы и воодушевление на последующие этапы. Разбиение задач, дел, проектов на этапы было важной задачей Nettyos c 2009 года.
      <br/>
      <br/>
      Этот и многие другие методы интегрированы в Nettyos флоу и помогают сделать мечты реальностью.
      <br/>
      <br/>
      <strong>Если использовать Nettyos для реализации мечты, вот краткий список того, что Nettyos умеет (базовые флоу, есть в  <a [routerLink]="'/pricing'">"Free Plan"</a>):</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>сохранять все мечты в профессиональном инструменте, а не в сообщениях "себе"</li>
        <li>специальная категория для мечт, идей, если проект используется для других целей, одновременно</li>
        <li>возможность обдумывать мечту и сохранять результат в комментариях, описании, чек-листе </li>
        <li>зафиксировать мысли, которые пришли в ночном вдохновении по реализации мечты</li>
        <li>превратить мечту в план или проект</li>
        <li>скинуть мечту другу/подруге для исполнения</li>
        <li>закинуть мечту в личный проект и реализовать</li>
        <li>закинуть мечту в командный проект и дать команде из 100 человек, например, исполнить мечту для Вас (у капитана команды должен быть тариф <a [routerLink]="'/pricing'">Pro.Max</a>)</li>
        <li>и другие фичи...</li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class="text">
      <strong>Жизненные ситуации, в которых это решение может пригодиться:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>Иногда, достаточно записать слова хейтера чтобы родился хит. Мечта солиста группы Dire Straits ответить хейтеру в песне привела к мировому успеху. Хит - "Money for Nothing". Хейтер упрекнул артистов в том, что у них так много денег - уже нечего больше покупать. Это переводится именно как "Money For Nothing"... Хейтеру ответили песней в топовых чартах.</li>
        <li>Обдумывал всю ночь крутую идею хита или стартапа, а утром на работу. Записал в Nettyos и важный шаг - сделан.</li>
        <li>Многие мечты, которые кажутся заоблачными, были записаны на куски бумаги, салфетки и т.п. И стали мировыми хитами, не только в музыке...</li>
        <li>В компании друзей решили для шутки придумать новый стартап. Так проснулись и забыли, а с Nettyos большой шанс реализовать и получить миллионы...</li>
        <li>И очень много других кейсов...</li>
      </ul>
    </div>

  </div>

  <div *ngIf = "translate.defaultLang === 'en'">
    <p class = "text">
      Nettyos can be used for a specific, limited purpose, or it can be used for everything at once.
      It depends on the settings and the tariff plan.
      <br/>
      <br/>
      If an adult writes about 100 adequate dreams on a sheet of paper, then according to statistics, about 20 out of 100 will be fulfilled in a year. If you use Nettyos instead of a sheet of paper, then for 20 dreams out of a hundred you will have to press the "done" button only once.
      <br/>
      <br/>
      But that's not all. Nettyos can do a lot more. Author of Nettyos is also the author of a series of books on psychology "The Alphabet of the Human Mind", which opened a new era of psychology. What is starting to become popular today (early spring 2021) has been integrated into Nettyos for many years so that users and the main user of Nettyos, Nikita Danilov, can complete projects and realize ideas/dreams in the most productive and cost-effective way.
      <br/>
      <br/>
      Such a method as "dopamine reward", for example, has been used by Nettyos since 2009. Only in the case of Nettyos does the user get an "honest neuro-cocktail" in the brain from the successful implementation of a part of the project. It doesn't matter to the brain whether you have successfully completed a project, a module or dealt with a bug. In any case, there will be a reward. It is this reward that is characteristic of a healthy psyche. A healthy neuro-cocktail that the brain receives gives strength and inspiration for the next stages. Breaking tasks, cases, projects into steps or stages has been an important task for Nettyos since 2009.
      <br/>
      <br/>
      This and many other methods are integrated into Nettyos flow and help make dreams come true.
      <br/>
      <br/>
      <strong> If you use Nettyos to make your dream come true, here is a short list of what Nettyos can do (basic flows, see <a [routerLink]="'/pricing'"> "Free Plan" </a>): </strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> save all dreams in a professional tool, not in messages to "myself" </li>
        <li> a special category for dreams, ideas, if Nettyos is used for other purposes, at the same time </li>
        <li> the ability to think over a dream and save the result in comments, description, checklist </li>
        <li> capture the thoughts that came in the night inspiration to realize the dream </li>
        <li> turn a dream into a plan or project </li>
        <li> throw a dream to a friend/girlfriend for fulfillment </li>
        <li> to throw a dream into a personal project and realize </li>
        <li> to throw a dream into a team project and let a team of 100 people, for example, make a dream come true for you (the team captain must have a tariff <a [routerLink]="'/pricing'"> Pro.Max </a>) </li>
        <li> and other features ... </li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class = "text">
      <strong>Life situations in which this solution may be useful:</strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> Sometimes, it is enough to write down the words of a hater to make a hit. The dream of the lead singer of Dire Straits to answer the hater in the song led to worldwide success. Hit - "Money for Nothing". Hater rebuked the artists for having so much money - there was nothing else to buy. This translates exactly as "Money For Nothing" ... Hater got the answer into the song in the top charts. </li>
        <li> I was thinking all night about a cool idea of ​​a hit or a startup, and in the morning have go to work. I wrote it down in Nettyos and an important step is done. </li>
        <li> Many dreams that seem transcendental have been written down on pieces of paper, napkins, etc. And they became world hits, not only in music... </li>
        <li> A group of friends decided to come up with a new startup as a joke. So they woke up and forgot, and with Nettyos there is a big chance to realize and get millions... </li>
        <li> And a lot of other cases... </li>
      </ul>
    </div>

  </div>

  <br/>
  <br/>
  <br/>
  <br/>
</div>
