<br/>
<div class="center" align="center">
  <i>{{ 'PLACES.CLICK' | translate }}</i>
  <br/>
  <br/>
  <form class="cost-form">
    <mat-form-field class="reason-width">
      <mat-label>{{ 'PLACES.ADD' | translate }}</mat-label>
      <input
        matInput
        placeholder="Place name"
        [(ngModel)]="placeName"
        [ngModelOptions]="{standalone: true}"
      >
    </mat-form-field>
    <br/>
    <button
      [disabled]="placeName.length === 0"
      (click)="addPlace()"
      mat-stroked-button
      color="primary"
    >{{ 'PLACES.OKAY' | translate }}</button>
  </form>
  <br/><br/>
  <p *ngIf="personService.places.length === 0">
    <b>{{ 'PLACES.NO_PLACES' | translate }}</b>
  </p>
<!--  <table  *ngIf="personService.places.length > 0">-->
<!--    <tr class="person-row" *ngFor="let place of personService.places" style="border: 1px solid lightblue;">-->
<!--      <td>{{place | json}}</td>-->
<!--    </tr>-->
<!--  </table>-->
  <div class="places-chips">
    <mat-chip-list aria-label="Places chips">
      <mat-chip *ngFor="let place of personService.places"
                (click)="choosePlace(place)"
                [selected]="place.selected"
                [removable]="true"
                (removed)="removePlace(place)"
                style="cursor: pointer"
      >{{place.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <br/>
    <br/>
<!--    <table>-->
<!--      <tr *ngFor="let ticket of personService.placeTickets">-->
<!--        <td><{{ticket.status}}></td>-->
<!--        <td style="background-color: lightblue;">{{ticket.ttext}}</td>-->
<!--        <td *ngIf="null != ticket.personFullName">{{ticket.personFullName}}</td>-->
<!--      </tr>-->
<!--    </table>-->
    <mat-card
      *ngFor="let ticket of personService.placeTickets"
      [class]="tclass"
      align="left"
    >
      <mat-card-header align="left">
        <ul style="list-style: none; padding-inline-start: 0px;">
          <li style="display: inline-block;">
            <{{ticket.status}}>&nbsp;&nbsp;
          </li>
          <li style="display: inline-block;">
<!--           todo:  Make today-->
            <button *ngIf="'today' !== ticket.status" mat-stroked-button (click)="moveTicketToToday(ticket)" color="primary">{{ 'PLACES.TODAY' | translate }}</button>
            <button *ngIf="'done' !== ticket.status" mat-button (click)="moveTicketToDone(ticket)" color="primary">{{ 'PLACES.DONE' | translate }}</button>
            <button *ngIf="'cancelled' !== ticket.status" mat-button (click)="moveTicketToCancelled(ticket)" color="accent">{{ 'PLACES.CANCELLED' | translate }}</button>
          </li>
        </ul>
      </mat-card-header>
      <span style="font-size: 150%;" *ngIf="null != ticket.personFullName">({{ticket.personFullName}})&nbsp;</span>
      <span style="font-size: 150%;">{{ticket.ttext}}</span>
    </mat-card>
    <span *ngIf="0 === personService.placeTickets.length && searching">{{ 'PLACES.NOTHING' | translate }}</span>
  </div>
</div>
