import {Component, Input, OnInit} from '@angular/core';
import {PersonService, Team, TeamMember} from "../../../services/person.service";
import {GatewayService} from "../../../services/gateway.service";
import {MyErrorStateMatcher, Project} from "../projects/projects.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";


export interface TeamProject {
  id: string,
  name: string,
  shortName: string,
  selected: boolean,
  auth: string,
  teamId: string,
  businessProcess: string,
  businessProcessStep: string,
  epicToAdd: string,
  epic: string,
  epicsList: string [],
  sprintsList: string [],
  sprintToAdd: string
}


@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  @Input() location = 'main';
  teamId = '';
  teamName = '';
  teamMembers: TeamMember [] = [];
  memberEmail = '';
  alert = '';
  projectFullName = '';
  projectShortName = '';
  // teamProjects: TeamProject[] = [];
  tabs = 0;

  teamNameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(50)
  ]);

  newTeamNameFormControl = new FormControl('', [
    Validators.minLength(3),
    Validators.maxLength(50)
  ]);

  matcher = new MyErrorStateMatcher();
  // newTeamNameFormGroup: FormGroup;
  newTeamNameCtrl = new FormControl('', [
    Validators.required,
  ]);

  constructor(public personService: PersonService,
              private gateway: GatewayService,
              private route: ActivatedRoute/*,
              private _formBuilder: FormBuilder*/
  ) { }

  ngOnInit(): void {
    this.loadAllTeams();
    this.personService.teamName = '';
    this.route.queryParams.subscribe(params => {
      const teamId = params['id'];
      if (null != teamId && teamId.length > 0) {
        this.teamId = teamId;
        this.chooseTeam();
      }
    });
  }

  chooseTeam() {
    if (this.teamId != '') {
      this.personService.loading = true;
      this.gateway.planningServiceLoadAllTeamMembers(this.teamId).subscribe(data => {
        //console.log('chooseTeam', data);
        this.personService.teamSelected = data;
        this.teamName = this.personService.teamSelected.name;
        // дублируется контрол элемент и ngModel todo: BUG
        this.newTeamNameFormControl.setValue(this.personService.teamSelected.name);
        this.personService.loading = false;
        this.loadAllTeamProjects();
      });
    } else {
      this.teamName = '';
      this.personService.teamSelected.name = null;
    }
  }

  createTeam() {
    // console.log('this.teamName', this.teamName);
    if (this.teamNameFormControl.value.length > 0) {
      let team: Team = {projects: [], newMember: undefined, auth: "", id: "", members: [], name: this.teamNameFormControl.value};
      this.personService.loading = true;
      this.gateway.planningServiceNewTeam(team).subscribe(data => {
        //console.log('planningServiceNewTeam', data);
        this.personService.loading = false;
        this.teamName = this.teamNameFormControl.value;
        this.newTeamNameFormControl.setValue(this.teamName);
        this.loadAllTeams();
        // reset form
        this.teamNameFormControl.reset();
        this.teamNameFormControl.markAsPristine();
        this.teamNameFormControl.markAllAsTouched();
        this.teamNameFormControl.updateValueAndValidity();
        this.teamNameFormControl.setErrors(null);
        // choose created team
        this.teamId = data.message;
        this.chooseTeam();
      });
    }
  }

  private loadAllTeams() {
    this.alert = '';
    //console.log('loadAllTeams')
    this.personService.loading = true;
    this.gateway.planningServiceLoadAllTeams().subscribe(data => {
      //console.log('loadAllTeams', data);
      this.personService.teams = data;
      //this.personService.teamSelected = {projects: [], newMember: undefined, auth: "", id: "", members: [], name: ""};
      this.personService.loading = false;
    });
  }

  addTeamMember() {
    this.alert = '';
    this.personService.loading = true;
    this.gateway.userServiceGetUserId(this.memberEmail).subscribe(data => {
      //console.log('addTeamMember', data);
      if (data.role === 'MEMBER') {
        this.gateway.planningServiceAddTeamMember(this.personService.teamSelected, data).subscribe(data => {
          //console.log('addTeamMember', data);
          this.personService.loading = false;
          if (data.result === 'okay') {
            this.chooseTeam();
          }
        });
        this.memberEmail = '';
      } else {
        // user not found or error
        this.personService.loading = false;
        this.alert = 'user not found';
        this.memberEmail = '';
      }
    });
  }

  removeMember(member: TeamMember, teamId) {
    this.personService.loading = true;
    this.gateway.planningServiceRemoveTeamMember(member, teamId).subscribe(data => {
      //console.log('removeMember', data);
      this.personService.loading = false;
      if (data.result === 'okay') {
        this.chooseTeam();
      }
    });
  }

  fix3Symbols() {
    if (this.projectShortName.length > 3) {
      this.projectShortName = this.projectShortName.charAt(0) + this.projectShortName.charAt(1) + this.projectShortName.charAt(2);
    }
  }

  generateShortName() {
    //
    let short = '';
    let name = this.projectFullName.split(' ');
    for (let word of name) {
      if (short.length < 3) {
        short += word.charAt(0).toUpperCase();
      }
    }
    if (short.length === 1) {
      short += short + short;
    } else if (short.length === 2) {
      short += short.charAt(0);
    }
    // console.log(name, short)
    this.projectShortName = short;
  }

  addProject() {
    let teamProject: TeamProject = {
      sprintToAdd: "",
      sprintsList: [],
      epic: "", epicToAdd: "", epicsList: [],
      businessProcess: "", businessProcessStep: "",
      teamId: this.teamId,
      id: '',
      name: this.projectFullName,
      shortName: this.projectShortName,
      selected: false,
      auth: ''
    };
    this.projectFullName = '';
    this.projectShortName = '';
    this.personService.loading = true;
    this.gateway.planningServiceNewTeamProject(teamProject).subscribe(data => {
      //console.log('addProject', data);
      this.personService.loading = false;
      this.loadAllTeamProjects();
    });
  }

  private loadAllTeamProjects() {
    this.gateway.planningServicAllTeamProjects(this.teamId).subscribe(data => {
      //console.log('loadAllTeamProjects', data);
      this.personService.teamProjects = data;
      this.personService.loading = false;
    });
  }

  changeTeamName() {

  }

  updateTeamName() {
    if (this.personService.teamSelected.name.length > 0) {
      let team = this.personService.teams.find(p => p.id === this.teamId);
      if (null != team) {
        team.name = this.personService.teamSelected.name;
      }
      this.personService.loading = true;
      this.gateway.planningServicUpdateTeamName(team).subscribe(data => {
        this.personService.loading = false;
        this.teamName = team.name;
      });
    }
  }
}
