<br/>
<br/>
<mat-tab-group>
  <mat-tab *ngFor="let skillsCategory of skillCategories" [label]="skillsCategory.name">
    <br/>
    <br/>
    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let skill of skillsCategory.skills" class="skills-accordion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{skill.name}}
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        Tickets with skill here, by Id

      </mat-expansion-panel>
    </mat-accordion>
  </mat-tab>
</mat-tab-group>
