<div style="width: 96%; margin: auto;" align="center">
  <app-solutions-header></app-solutions-header>
  <h1 *ngIf="translate.defaultLang === 'ru'">Команды (любые)</h1>
  <h1 *ngIf="translate.defaultLang === 'en'">Teams (any)</h1>
  <br/>
  <img
    [@nettyos]
    style="width: 300px;"
    src="./assets/images/landing/step2/planner-of-team.svg"
  />
  <br/>
  <br/>
  <div *ngIf="translate.defaultLang === 'ru'">
    <p class="text">
      Все виды и типы команд, которые могут существовать Nettyos поддерживает и делает это инновационно супер просто и очень эффективно.
      <br/>
      <br/>
      Переходить от одной модели команды к другой можно в "горячем" режиме. Также как и кастомизировать. Стал неэффективным SCRUM, поменяли на KANBAN, все еще нет эффекта - сделали mix: SCRUM + KANBAN + Своя Методология. Простые проекты понятные каждому - легко. Сложные проекты стали управляться просто и гибко.
      <br/>
      <br/>
      И все это в одной экосистеме. От личного напоминания сходить к врачу до доски команды - один клик. Бесплатно предоставляется только один командный проект и одна команда без спринтов и эпиков. До двух участников включительно. Но платит только Лидер команды, присоединиться к любой из команд пользователь может бесплатно и использовать все фичи согласно тарифу, который оплатил Лидер. Подробнее  <a [routerLink]="'/pricing'"> "Цены" </a>
      <br/>
      <br/>
      <strong>Краткий список того, что Nettyos умеет делать с командами:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>Создать команду, добавить участников, проекты (зависит от тарифа)</li>
        <li>Нет необходимости создавать проект сразу по методологии, можно будет поменять в несколько кликов "на ходу"</li>
        <li>Поддерживает любые методологии благодаря эпикам и спринтам и их конфигурации</li>
        <li>Plain, SCRUM, Kanban, XP, Agile, WF, Custom...</li>
        <li>Очень высокая эффективность от использования Nettyos для решений, которые строят новую реальность</li>
        <li>Тикеты можно перекидывать из личных проектов в командные</li>
        <li>Тикеты можно перекидывать из списка Сегодня и других в команды</li>
        <li>Конфигурировать и администрировать команды</li>
        <li>Командные проекты синхронизированы с личными делами</li>
        <li>и другие фичи...</li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class="text">
      <strong>Жизненные/бизнес ситуации, в которых это решение может пригодиться:</strong>
      <br/>
    </p>
    <div class="list" align="left">
      <ul>
        <li>Тимлид, Project Manager, Project Owner, Scrum Master в одной из топовых компаний IT сферы. Какая бы ни была методология, команда - просто добавили участника и работа идет.</li>
        <li>Фриланс. Позволит разделить проект не просто на этапы (и оплату), а на проработанные "живые" тикеты, которые будут альтернативой техническому заданию. Опытные поймут.</li>
        <li>Любая деятельность, которая приносит деньги. Именно от проработки зависит качество продукта и какое количество $ готов заплатить Клиент/Заказчик. А проект - это идеальное место для общения исполнителя/заказчика.</li>
        <li>Предоставление услуг, которые может выполнить только команда. Тогда Nettyos становится чуть ли не инвестицией. Добавляете Клиента, Клиент закидывает свой тикет, крутой результат - следующий.</li>
        <li>Супруга стокер и творческий человек. Хватит бесплатного тарифа чтобы заняться менеджментом: подключение к стокам, ключевание, стратегия, выкладка... Именно после этого этапа большинство стокеров выходят на доход.</li>
        <li>Хотите хорошую отделку/строительство - ищите те бригады, которые используют Nettyos. Иначе, останетесь с первым этапом за весь бюджет (хороший шанс). Полно способов поступить нечестно. Из-за высокой сложности работ, о которой Клиенты не хотят слушать вначале. Лучше потратить пару дней и обговорить все этапы стройки/отделки сразу в Nettyos. Эпики или спиринты будут идеальны чтобы разделить весь проект на части. К примеру, по комнатам или по стадиям строительства.</li>
        <li>Попробовать реализовать стартап самостоятельно, если начнет получаться. Тогда нанять и добавить Scrum Master, участников. Подходит не только для IT стартапов.</li>
        <li>И очень много других кейсов...</li>
      </ul>
    </div>
  </div>

  <div *ngIf = "translate.defaultLang === 'en'">
    <p class = "text">
      All kinds and types of teams that can exist Nettyos supports and makes it innovative, super simple and very effective.
      <br/>
      <br/>
      You can move from one team model to another in a "hot" mode. As well as customization. SCRUM became ineffective, changed to KANBAN, still no effect - made mix: SCRUM + KANBAN + Own Methodology. Simple projects that are clear to everyone - easy. Complex projects have become easy and flexible to manage.
      <br/>
      <br/>
      And all this in one ecosystem. From a personal reminder to see a doctor to a team board, it's one click away. Only one team project and one team without sprints and epics are provided for free. Up to two participants inclusive. But only the Leader of the team pays, the user can join any of the teams for free and use all the features according to the tariff paid by the Leader. More details <a [routerLink]="'/pricing'"> "Prices" </a>
      <br/>
      <br/>
      <strong> A short list of what Nettyos can do with teams: </strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> Create a team, add members, projects (depends on the tariff) </li>
        <li> There is no need to create a project immediately according to the methodology, it will be possible to change it in a few clicks "on the fly" </li>
        <li> Supports any methodology thanks to epics and sprints and their configuration </li>
        <li> Plain, SCRUM, Kanban, XP, Agile, WF, Custom ... </li>
        <li> Very high efficiency from using Nettyos for solutions that build a new reality </li>
        <li> Tickets can be transferred from personal projects to team projects </li>
        <li> Tickets can be transferred from the Today list and others to teams </li>
        <li> Configure and administer teams </li>
        <li> Team projects are synchronized with Today list </li>
        <li> and other features ... </li>
      </ul>
    </div>

    <br/>
    <br/>
    <p class = "text">
      <strong> Life/business cases in which this solution can be useful: </strong>
      <br/>
    </p>
    <div class = "list" align = "left">
      <ul>
        <li> Team Lead, Project Manager, Project Owner, Scrum Master in one of the top IT companies. Whatever the methodology, the team - just added a member and the work goes on. </li>
        <li> Freelance. It will allow you to divide the project not just into stages (and payment), but into well-developed "live" tickets, which will be an alternative to the terms of reference. Experienced people will understand. </li>
        <li> Any activity that makes money. It is the elaboration of the product that determines the quality of the product and how much $ the Client/Customer is willing to pay. And the project is an ideal place for communication between the contractor/customer. </li>
        <li> Providing services that only a team can perform. Then Nettyos becomes almost an investment. You add a Client, the Client submits his ticket, the cool result and the new one. </li>
        <li> The wife is a stocker and a creative person. The free plan will be enough to start managing: connecting to stocks, keying, strategy, layout ... It is after this stage that most stockers start earning income. </li>
        <li> If you want good finishing/construction - look for those brigades that use Nettyos. Otherwise, you will be left with the first stage for the entire budget (good chance). There are plenty of ways to be dishonest. Due to the high complexity of the work, which Clients do not want to hear about at the beginning. It is better to spend a couple of days and discuss all stages of construction/finishing at once in Nettyos. Epics or spirints are ideal for breaking up an entire project. For example, by room or by stage of construction. </li>
        <li> Try to implement a startup on your own if it starts to work out. Then hire and add Scrum Master, contributors. Suitable not only for IT startups. </li>
        <li> And a lot of other cases ... </li>
      </ul>
    </div>
  </div>


  <br/>
  <br/>
  <br/>
  <br/>
</div>
