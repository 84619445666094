import { Injectable } from '@angular/core';
import {Person} from "../components/today/people/people.component";
import {Place} from "../components/today/places/places.component";
import {Profile, Ticket} from "./gateway.service";
import {Project} from "../components/today/projects/projects.component";
import {TeamProject} from "../components/today/teams/teams.component";
import {Alert} from "../dto/Alerts";


export interface Team {
  auth: string;
  id: string;
  name: string;
  members: TeamMember [];
  projects: TeamProject[];
  newMember: TeamMember;
}

export interface TeamMember {
  id: string;
  firstName: string;
  secondName: string;
  // role in team: master or participant
  role: string;
  userId: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  public persons: Person [] = [];
  public places: Place [] = [];
  public placeTickets: Ticket [] = [];
  public silver = 1;
  public silverCoinFlag = true;
  public gold = 1;
  public goldCoinFlag = true;
  projects: Project[] = [];
  projectTicketsToday: Ticket [] = [];
  // done, completed
  projectTicketsCompleted: Ticket [] = [];
  // project backlog = 'project' status
  projectTicketsBacklog: Ticket [] = [];
  // later, tomorrow, date, idea
  projectTicketsTodo: Ticket [] = [];
  loading = false;
  teams: Team[] = [];
  teamName = '';
  teamProjects: TeamProject[] = [];
  teamSelected: Team = {newMember: undefined, auth: "", id: "", members: [], projects: [], name: ""};
  giveDialogTooltipAnswer = false;
  public language = 'en';
  profile: Profile = {
    expirationDateType: "",
    account: "",
    accountExpirationDate: "",
    goldCoin: 0, silverCoin: 0, auth: "no", ava: "", email: "", lname: "", fname: "", conf: "", userId: "", tsCreated: 1};

  // cost of interruptions
  public workingChart: any[];
  public view: any[] = [700, 400];
  public lastWorkingPoint = 0;
  public lastInterruptionPoint = 0;
  public lastBreakPoint = 0;
  public workCounter = 1;
  public interruptedCounter = 1;
  public breakCounter = 1;
  public searchFlag = false;
  public reloadTicketsOnDemand = false;
  public allTeamProjects: TeamProject [] = [];
  currentDialogTicket: Ticket;
  sideNavVisible = true;
  alpha = false;
  lastTeamProject: TeamProject;
  alerts: Alert[] = [];
  unread: boolean = false;
  invId: any;


  constructor() { }

  addCoins(silverCoin: number, goldCoin: number) {
    if (this.silver !== silverCoin) {
      this.silverCoinFlag = false;
      this.silver = silverCoin;
      setTimeout( () => {
        this.silverCoinFlag = true;
      }, 0);
    }
    if (this.gold !== goldCoin) {
      this.goldCoinFlag = false;
      this.gold = goldCoin;
      setTimeout( () => {
        this.goldCoinFlag = true;
      }, 0);
    }
  }

  public isProAccount() {
    if (this.profile.account != null && this.profile.account.startsWith('Pro'))
      return true;
    else return false;
  }

  public isPremiumAccount() {
    if (this.profile.account != null && this.profile.account.startsWith('Premium'))
      return true;
    else return false;
  }

}
