import { Component, OnInit } from '@angular/core';
import {SkillsCategory} from "../../../dto/Skills";

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  skillCategories: SkillsCategory [] = [
    {
      id: 'hjk',
      name: 'All IT',
      auth: '',
      skills: [
        {
          id: 'rtyurytu',
          auth: 'aa',
          name: 'Angular'
        }
      ]
    },
    {
      id: 'hjk',
      name: 'Srv IT',
      auth: '',
      skills: [
        {
          id: 'rtyurytu',
          auth: 'aa',
          name: 'Java'
        },
        {
          id: 'rtyurytu',
          auth: 'aa',
          name: 'Spring'
        },
        {
          id: 'rtyurytu',
          auth: 'aa',
          name: 'TeamCity'
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
