<br/>
<!--<p style="margin: auto" align="center">Today list</p>-->
<div style="width:fit-content; margin: auto;">
  <table style="font-size: 10%;">
    <tr>
      <td *ngIf="!personService.searchFlag">
        <mat-chip-list style="width: fit-content" aria-label="List selection">
          <mat-chip
            [matTooltip]="'GUIDE.TOOLTIPS.IDEA' | translate"
            class="chips"
            color="primary"
            [selected]="idea"
            [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            (click)="ideaTickets()"
          >{{ 'TODAY.CHIPS.IDEA.TITLE' | translate}}</mat-chip>
        </mat-chip-list>
      </td>
      <td *ngIf="!personService.searchFlag">
        <mat-chip-list style="width: fit-content" aria-label="List selection">
          <mat-chip
            [matTooltip]="'GUIDE.TOOLTIPS.ENDED' | translate"
            class="chips"
            color="primary"
            [selected]="ended"
            [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            (click)="endedTickets()"
          >{{ 'TODAY.CHIPS.ENDED.TITLE' | translate}}</mat-chip>
        </mat-chip-list>
      </td>
      <!--      <td>-->
      <!--        <mat-chip-list style="width: fit-content" aria-label="List selection">-->
      <!--          <mat-chip-->
      <!--            class="chips"-->
      <!--            color="primary"-->
      <!--            [selected]="done === 'done'"-->
      <!--            (click)="'' === done ? done = 'done' : done = ''"-->
      <!--          >done-->
      <!--          </mat-chip>-->
      <!--        </mat-chip-list>-->
      <!--      </td>-->
      <!--      <td> todo: add backlog -->
      <!--        <mat-chip-list style="width: fit-content" aria-label="List selection">-->
      <!--          <mat-chip class="chips" color="warn" selected>backlog</mat-chip>-->
      <!--        </mat-chip-list>-->
      <!--      </td>-->
      <td *ngIf="!personService.searchFlag">
        <mat-chip-list style="width: fit-content" aria-label="List selection">
          <mat-chip
            [matTooltip]="'GUIDE.TOOLTIPS.TODAY' | translate"
            class="chips"
            color="primary"
            style="cursor: not-allowed !important;"
            [selected]="true"
            [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            (click)="todayTickets()"
          >{{ 'TODAY.CHIPS.TODAY.TITLE' | translate}}</mat-chip>
        </mat-chip-list>
      </td>
      <td *ngIf="!personService.searchFlag">
        <mat-chip-list style="width: fit-content" aria-label="List selection">
          <mat-chip
            [matTooltip]="'GUIDE.TOOLTIPS.FUTURE' | translate"
            class="chips"
            color="primary"
            [selected]="future"
            [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            (click)="futureTickets()"
          >{{ 'TODAY.CHIPS.FUTURE.TITLE' | translate}}</mat-chip>
        </mat-chip-list>
      </td>
      <td>
        <mat-chip-list style="width: fit-content" aria-label="List selection">
          <mat-chip
            [matTooltip]="'GUIDE.TOOLTIPS.SEARCH' | translate"
            class="chips"
            color="primary"
            [selected]="personService.searchFlag"
            [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            (click)="searchTickets()"
          >{{ 'TODAY.CHIPS.SEARCH.TITLE' | translate}}</mat-chip>
        </mat-chip-list>
      </td>
      <!--      <td>-->
      <!--        <mat-chip-list style="width: fit-content" aria-label="List selection">-->
      <!--          <mat-chip-->
      <!--            class="chips"-->
      <!--            color="primary"-->
      <!--            [selected]="later === 'later'"-->
      <!--            (click)="'' === later ? later = 'later' : later = ''"-->
      <!--          >later</mat-chip>-->
      <!--        </mat-chip-list>-->
      <!--      </td>-->
      <!--      <td>-->
      <!--        <mat-chip-list style="width: fit-content" aria-label="List selection">-->
      <!--          <mat-chip-->
      <!--            class="chips"-->
      <!--            color="primary"-->
      <!--            [selected]="idea === 'idea'"-->
      <!--            (click)="'' === idea ? idea = 'idea' : idea = ''"-->
      <!--          >idea</mat-chip>-->
      <!--        </mat-chip-list>-->
      <!--      </td>-->
    </tr>
  </table>
  <div *ngIf="messageText.length > 0 else aligning" align="center">
    <br/>
    <p style="color: #03a9f4;">
      {{messageText}}
    </p>
  </div>
  <ng-template #aligning>
    <br/>
    <p>&nbsp;</p>
  </ng-template>
</div>

<!-- Working indicator -->
<!--<mat-progress-bar *ngIf="personService.lastWorkingPoint !== 0" mode="buffer"></mat-progress-bar>-->

<div *ngIf="personService.searchFlag" style="width: fit-content; margin: 5px auto;">
  <br/>
  <mat-form-field>
    <!--      Ticket      : task/affair/idea-->
    <mat-label>Search ticket</mat-label>
    <input matInput (keyup)="searchingPool()" placeholder="" [(ngModel)]="search">
  </mat-form-field>
</div>
<br/>
<br/>
<div *ngIf="!personService.searchFlag" style="margin: auto" align="center">
  <form class="example-form" [formGroup]="newTicketFormGroup" (ngSubmit)="newTicketOnSubmit(newTicketFormGroup.value)">
    <table>
      <tr>
        <td align="right">
          <button
            [matTooltip]="'GUIDE.TOOLTIPS.CONTROLS.ADD' | translate"
            [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            type="submit"
            mat-raised-button>{{ 'TODAY.CONTROLS.ADD' | translate}}</button>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <!--      Ticket      : task/affair/idea-->
            <mat-label>{{ 'TODAY.CONTROLS.FIELD' | translate}}</mat-label>
            <input
              matInput
              matTooltip="Ticket text here. Try to do it short. This is like ticket id will remain in your mind."
              placeholder="Read new book..."
              formControlName="ticketTextCtrl"
              (click)="aboutInputTicket()"
              [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
            >
          </mat-form-field>
        </td>
        <!--        <td>-->
        <!--          &nbsp;&nbsp;<button type="submit" mat-raised-button>Add</button>-->
        <!--        </td>-->
      </tr>
    </table>
  </form>
</div>
<br/>
<div *ngIf="tickets.length === 0 else ticketsList" style="margin: auto" align="center">
  <span *ngIf="!personService.searchFlag" class="get-rest">{{ 'TODAY.TICKET.REST_MESSAGE' | translate}}</span>
  <span *ngIf="personService.searchFlag" class="get-rest">{{ 'TODAY.TICKET.NO_FOUND' | translate}}"{{search}}".</span>
</div>
<div *ngIf="showCookies">
  {{cookieService.getAll()}}
  <hr/>
  {{cookieService.getAll() | json}}
</div>
<ng-template #ticketsList>

  <table style="margin: auto" align="center">
    <tr [@movedCard]="ticket.animationState" *ngFor="let ticket of tickets">
      <!--    <tr *ngFor="let ticket of tickets | status: done: cancelled: tomorrow: later: idea: 'today'">-->
      <td align="left" style="min-width: 310px;">
        <mat-card
          class="ticket-content"
          [ngStyle]="{border:
            ticket.status === 'done' ? '7px solid #a8ef80'  :
            ticket.status === 'cancelled' ? '7px solid #D26927' :
            ticket.status === 'idea' ? '7px solid rgb(188, 216, 244)' :
            (ticket.status === 'tomorrow' ||
            ticket.status === 'later' ||
            (ticket.status === 'date' && ifNotToday(ticket))
            ) ? '7px solid #d3d7d5' : '7px solid transparent'}"
        >
          <mat-card-header
            align="left"
          >
            <table style="position: absolute;
              left: 10px;">
              <tr>
                <td>
                  <button
                    class="done-button"
                    *ngIf="ticket.status !== 'done' && ticket.status !== 'team'"
                    [matTooltip]="'Done'"
                    style="color: #a8ef80; font-size: 200%;"
                    mat-stroked-button
                    color="primary"
                    [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
                    (click)="fastDone(ticket)"
                  >
                    ✓
                  </button>
                </td>
                <td>
                  <button
                    class="tomorrow-button"
                    *ngIf="ticket.status !== 'done' && ticket.status !== 'team'"
                    [matTooltip]="'Tomorrow'"
                    style="color: rgb(211, 215, 213); font-size: 200%;"
                    mat-stroked-button
                    color="primary"
                    [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
                    (click)="fastTomorrow(ticket)"
                  >
                    ➔
                  </button>
                </td>
              </tr>
            </table>
            <br/>
            <br/>
            <ul *ngIf="ticket.status !== 'team'" style="margin-top: 25px; list-style: none; padding-inline-start: 0px;">
              <li>
                <table>
                  <tr>
                    <td>
                      <mat-form-field class="status-person-group">
                        <mat-select
                          [matTooltip]="'GUIDE.TOOLTIPS.TICKET.STATUS' | translate"
                          (click)="selectTicketStatus()"
                          (openedChange)="reloadingSemafor($event)"
                          (selectionChange)="changeStatus(ticket)"
                          [(ngModel)]="ticket.status"
                          [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
                          style="color: #03a9f4;"
                          required>
                          <mat-option *ngIf="ticket.status === 'team'"
                                      [value]="'team'">{{ 'TODAY.TICKET.STATUS.TEAM' | translate}}</mat-option>
                          <mat-option [value]="'today'">{{ 'TODAY.TICKET.STATUS.TODAY' | translate}}</mat-option>
                          <mat-option [value]="'tomorrow'">{{ 'TODAY.TICKET.STATUS.TOMORROW' | translate}}</mat-option>
                          <mat-option [value]="'later'">{{ 'TODAY.TICKET.STATUS.LATER' | translate}}</mat-option>
                          <mat-option [value]="'date'">{{ 'TODAY.TICKET.STATUS.DATE' | translate}}</mat-option>
                          <mat-option *ngIf="ticket.status === 'date'"
                                      [value]="'newdate'">{{ 'TODAY.TICKET.STATUS.NEW_DATE' | translate}}</mat-option>
                          <mat-option [value]="'idea'">{{ 'TODAY.TICKET.STATUS.IDEA' | translate}}</mat-option>
                          <mat-option [value]="'done'">{{ 'TODAY.TICKET.STATUS.DONE' | translate}}</mat-option>
                          <mat-option
                            [value]="'cancelled'">{{ 'TODAY.TICKET.STATUS.CANCELLED' | translate}}</mat-option>
                          <mat-option [value]="'project'"
                                      *ngIf="personService.searchFlag && ticket.status === 'project'">{{ 'TODAY.TICKET.STATUS.PROJECT' | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!--                      <hr/>-->
                    </td>
                    <td *ngIf="ticket.direction !== 'to' && ticket.direction !== 'from' && ticket.projectId === 'no'">
                      <mat-form-field class="status-person-group">
                        <mat-select
                          [matTooltip]="'GUIDE.TOOLTIPS.TICKET.ASSIGN' | translate"
                          (click)="selectTicketAssignProject()"
                          (openedChange)="reloadingSemafor($event)"
                          style="color: #03a9f4;"
                          (selectionChange)="assignToPersonOrProject(ticket)"
                          [(ngModel)]="ticket.personId"
                          [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
                          required>
                          <mat-option [value]="'personal'">@{{ 'TODAY.TICKET.PERSONAL' | translate}}</mat-option>
                          <mat-option [value]="person.personId" *ngFor="let person of personService.persons"
                          >@{{person.firstName}} {{person.lastName}}
                          </mat-option>
                          <!--Teams projects-->
<!--                          todo: no epics and sprints here, better easy assign to epic/ sprint inthe project-->
                          <ng-container *ngFor="let team of personService.teams">
                            <mat-option [value]="'$' + teamProject.id" *ngFor="let teamProject of team.projects">
                              {{team.name}} -> {{teamProject.name}}
                            </mat-option>
                          </ng-container>
                          <!--Personal projects-->
                          <mat-option [value]="'*' + project.id" *ngFor="let project of personService.projects | projects"
                          >[{{project.name}}]
                          </mat-option>

                        </mat-select>
                      </mat-form-field>
                      <!--                      <hr/>-->
                    </td>
                    <td *ngIf="ticket.projectId !== 'no'"
                    >
                      &nbsp;&nbsp;
                      <a [routerLink]="'/projects'" [queryParams]="{id: ticket.projectId}"
                         style="color: #03a9f4;">[{{findShortNameByProjectId(ticket.projectId)}}]</a>
                      &nbsp;
                      <button mat-icon-button color="accent" (click)="projectReset(ticket)">
                        <mat-icon>autorenew</mat-icon>
                      </button>
                    </td>
                    <td *ngIf="ticket.direction === 'to' || ticket.direction === 'from'"
                    >
                      <a
                        [ngStyle]="{
                          backgroundColor:
                          ticket.relatedStatus === 'done' ? '#a8ef80'  :
                          ticket.relatedStatus === 'cancelled' ? '#D26927' :
                          ticket.relatedStatus === 'idea' ? 'rgb(188, 216, 244)' :
                          ticket.relatedStatus === 'removed' ? 'red' :
                          (ticket.relatedStatus === 'tomorrow' ||
                          ticket.relatedStatus === 'later' ||
                          (ticket.relatedStatus === 'date')
                          ) ? '#d3d7d5' : ''
                         }"
                        [routerLink]="'/people'"
                        style="color: #03a9f4;">{{ticket.direction}} @{{ticket.personFullName}}</a>
                      <!--                      <hr/>-->
                    </td>
                  </tr>
                </table>
              </li>
              <li class="grey"
                  *ngIf="('date' === ticket.status && 0 === ticket.tsOfPlannedDate) || 'newdate' === ticket.status"
              >
                <br/>
                <br/>
                <!--                <mat-form-field class="example-full-width" appearance="fill">-->
                <!--                  <mat-label>Choose a date</mat-label>-->
                <!--                  <input matInput [(ngModel)]="ticket.pickerDate" type="datetime-local" [matDatepicker]="picker" disabled>-->
                <!--                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
                <!--                  <mat-datepicker #picker color="primary" [disabled]="false"></mat-datepicker>-->
                <!--                </mat-form-field>-->
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'TODAY.TICKET.DATE.CHOOSE' | translate}}</mat-label>
                  <input matInput [matDatepicker]="dp" [formControl]="date">
                  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>
              </li>
              <li class="grey"
                  *ngIf="('date' === ticket.status && 0 === ticket.tsOfPlannedDate) || 'newdate' === ticket.status"
              >
                <select style="margin-left: 10px;" matNativeControl
                        [(ngModel)]="ticket.alertTsBefore"
                        required>
                  <option [value]="0">{{ 'TODAY.TICKET.DATE.NO_REMIND' | translate}}</option>
                  <option [value]="2*60*1000">{{ 'TODAY.TICKET.DATE.REMIND_1M_BEFORE_EMAIL' | translate}}</option>
                  <option [value]="6*60*1000">{{ 'TODAY.TICKET.DATE.REMIND_5M_BEFORE_EMAIL' | translate}}</option>
                  <option [value]="31*60*1000">{{ 'TODAY.TICKET.DATE.REMIND_30M_BEFORE_EMAIL' | translate}}</option>
                  <option [value]="61*60*1000">{{ 'TODAY.TICKET.DATE.REMIND_1H_BEFORE_EMAIL' | translate}}</option>
                  <option [value]="24*60*60*1000">{{ 'TODAY.TICKET.DATE.REMIND_1D_BEFORE_EMAIL' | translate}}</option>
                </select>
                <table>
                  <tr>
                    <!--                    <td>-->
                    <!--                      time:&nbsp;-->
                    <!--                    </td>-->
                    <td>&nbsp;</td>
                    <td>
                      <select matNativeControl
                              [(ngModel)]="ticket.hh"
                              required>
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                      </select>
                    </td>
                    <td>
                      :
                    </td>
                    <td>
                      <select matNativeControl
                              [(ngModel)]="ticket.mm"
                              required>
                        <option value="00">00</option>
                        <option value="05">05</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="30">30</option>
                        <option value="35">35</option>
                        <option value="40">40</option>
                        <option value="45">45</option>
                        <option value="50">50</option>
                        <option value="55">55</option>
                      </select>
                    </td>
                    <td>
                      <button mat-button color="accent"
                              (click)="okayDateDone(ticket)">{{ 'TODAY.TICKET.DATE.OKAY' | translate}}</button>
                    </td>
                  </tr>
                </table>
              </li>
              <li *ngIf="'date' === ticket.status && 0 < ticket.tsOfPlannedDate">
                <!--                <app-counter [plannedTs]="ticket.tsOfPlannedDate"></app-counter>-->
                <!--                <i style="color: lightgreen">{{newDate(ticket.tsOfPlannedDate) | date: '[yyyy-MM-dd, HH:mm]'}}</i>-->
                <table>
                  <tr>
                    <td>
                      <mat-chip-list aria-label="Counter date">
                        <mat-chip color="accent" selected>
                          <app-counter [plannedTs]="ticket.tsOfPlannedDate"></app-counter>
                        </mat-chip>
                        <!--                  <mat-chip color="primary" selected>-->
                        <!--                    {{newDate(ticket.tsOfPlannedDate) | date: '[yyyy-MM-dd, HH:mm]'}}-->
                        <!--                  </mat-chip>-->
                      </mat-chip-list>
                    </td>
                    <td>
                  <span [ngStyle]="{color:
                        (ticket.status === 'date' && ifNotToday(ticket)
                        ) ? '' : '#03a9f4'}"
                  >{{newDate(ticket.tsOfPlannedDate) | date: '[yyyy-MM-dd, HH:mm]'}}</span>
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
            <div *ngIf="ticket.status === 'team'" style="margin-top: 25px;">
              <br/>
<!--              <span-->
<!--                [routerLink]="'../teams'"-->
<!--                [queryParams]="{id: ticket.teamId}"-->
<!--                style="cursor: pointer; color: white; background-color: darkorange; padding: 8px; border-radius: 10%; border: 2px solid lightgrey;">-->
<!--                {{ (ticket.teamId != null && ticket.teamId.length > 0) ? getTeamName(ticket.teamId) : ('TODAY.TICKET.TEAM.TEAM' | translate)}}-->
<!--              </span>-->
              <button
                [matTooltip]="'Team'"
                [routerLink]="'../teams'"
                [queryParams]="{id: ticket.teamId}"
                mat-stroked-button
                style="color: darkorange;"
              >
                {{ (ticket.teamId != null && ticket.teamId.length > 0) ? getTeamName(ticket.teamId) : ('TODAY.TICKET.TEAM.TEAM' | translate)}}
              </button>
              <span style="color: darkorange;">&nbsp;&nbsp;->&nbsp;&nbsp;</span>
              <button
                [matTooltip]="'Team project: ' + getFullNameByTeamProjectId(ticket.teamProjectId)"
                mat-stroked-button
                style="color: darkorange;"
                [routerLink]="'../team-project'"
                [queryParams]="{id: ticket.teamProjectId, e: ticket.epic, s: ticket.sprint}">

                <span>{{getShortNameByTeamProjectId(ticket.teamProjectId)}}</span>
                <span *ngIf="ticket.epic !== '' && ticket.epic != null">
                  &nbsp;:&nbsp;{{ticket.epic}}
                </span>
                <span *ngIf="ticket.sprint !== '' && ticket.sprint != null">
                  &nbsp;:&nbsp;{{ticket.sprint}}
                </span>
              </button>
              <br/>
              <br/>
            </div>
            <button
              [matTooltip]="'GUIDE.TOOLTIPS.TICKET.REMOVE' | translate"
              style="position: absolute;
              right: 10px;"
              mat-mini-fab
              [@removeButtonShakeAnimation]="ticket.deleteButtonColor"
              [color]="ticket.deleteButtonColor"
              [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
              (click)="removeTicketPre(ticket)">X
            </button>
            <br/>
          </mat-card-header>

          <app-ticket-text
            [ticket]="ticket"
            (autoReload)="changeAutoReload($event)"
          ></app-ticket-text>

          <app-ticket-description
            [ticket]="ticket"
            [autoReloadAvailableFlag]="autoReloadAvailableFlag"
            (autoReload)="changeAutoReload($event)"
            (loadAllTicketsFromDescription)="loadAllTickets(0)"
          ></app-ticket-description>

          <app-ticket-check-list
            [ticket]="ticket"
            [checkListAddingFlag]="checkListAddingFlag"
            (autoReload)="changeAutoReload($event)"
            (loadAllTicketsFromCheckList)="loadAllTickets(0)"
          ></app-ticket-check-list>

          <div [@comments] *ngIf="ticket.commentsFlag">
            <app-comments [ticket]="ticket"></app-comments>
          </div>

          <mat-card-footer align="center">
            <hr style="width: 33%; margin: 3px auto;"/>

            <app-business-process [ticket]="ticket"></app-business-process>
<!--            <div *ngIf="isAssignedToProject(ticket) && (ticket.businessProcessStep == null || ticket.businessProcessStep.length === 0)" align="center">-->
<!--              <button mat-button-->
<!--                      (click)="assignFirstBusinessStep(ticket)"-->
<!--                      color="primary"-->
<!--              ><< {{'PERSONAL_PROJECTS.BP.ASSIGN' | translate}} >></button>-->
<!--            </div>-->

<!--            <div *ngIf="isAssignedToProject(ticket) && ticket.businessProcessStep != null && ticket.businessProcessStep.length > 0" align="center">-->
<!--              <span style="color: darkorange;">{{'PERSONAL_PROJECTS.BP.BP' | translate}}</span>-->
<!--              <br/>-->
<!--              <button mat-mini-fab color="accent" [disabled]="!isPreviousStep(ticket)" (click)="movePreviousStep(ticket)"><<</button>-->
<!--              &nbsp;&nbsp;-->
<!--              <a [routerLink]="'/projects'" [queryParams]="{id: ticket.projectId, tab: '1'}"-->
<!--                 class="business-process">{{ticket.businessProcessStep}}-->
<!--              </a>-->
<!--              &nbsp;&nbsp;-->
<!--              <button mat-mini-fab color="primary" [disabled]="!isNextStep(ticket)" (click)="moveNextStep(ticket)">>></button>-->
<!--            </div>-->

            <button
              mat-icon-button
              [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
              [color]="!ticket.commentsFlag ? 'primary': 'accent'"
              (click)="openComments(ticket)"
            ><mat-icon>comment</mat-icon>
              {{(ticket.commentsNumberThis + ticket.commentsNumberMirror) > 0 ? (ticket.commentsNumberThis + ticket.commentsNumberMirror): ''}}
            </button>

            <button
              mat-icon-button
              [matTooltip]="'GUIDE.TOOLTIPS.TICKET.DESCRIPTION.EDIT' | translate"
              color="primary"
              [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
              (click)="descriptionEditStart(ticket)"
              *ngIf="null != ticket.description && ticket.description.length > 0 && !ticket.descriptionAddFlag"
            >
<!--              {{ 'TODAY.TICKET.DESCRIPTION.EDIT' | translate}}-->
              <mat-icon>description</mat-icon>
            </button>

            <button
              mat-icon-button
              color="primary"
              *ngIf="!checkListAddingFlag"
              [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
              (click)="checkListAddStart(ticket)"
            >
<!--              {{ 'TODAY.TICKET.CHECK_LIST.CHECK_LIST' | translate }}-->
              <mat-icon>done_all</mat-icon>
            </button>

            <button
              mat-icon-button
              [matTooltip]="'GUIDE.TOOLTIPS.TICKET.DESCRIPTION.ADD' | translate"
              color="primary"
              (click)="descriptionAddStart(ticket)"
              [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
              *ngIf="null != ticket.description && ticket.description.length < 1 && !ticket.descriptionAddFlag"
            >
<!--              {{ 'TODAY.TICKET.DESCRIPTION.ADD' | translate}}-->
              <mat-icon>description</mat-icon>
            </button>
            <button
              mat-icon-button
              [matTooltip]="'GUIDE.TOOLTIPS.TICKET.PLACE.ADD' | translate"
              color="primary"
              *ngIf="'' === ticket.addPlace && '' === ticket.placeName"
              [ngStyle]="{border: personService.giveDialogTooltipAnswer ? '3px solid yellow' : ''}"
              (click)="openAddPlaceSelect(ticket)">
                <mat-icon>add_location</mat-icon>
<!--              {{ 'TODAY.TICKET.PLACE.ADD' | translate}}-->
            </button>
            <div>
              <select matNativeControl
                      (change)="mapThePlace(ticket)"
                      *ngIf="'' !== ticket.addPlace && '' === ticket.placeName"
                      [(ngModel)]="ticket.addPlace"
                      style="color: #03a9f4; margin: auto; width: fit-content;"
                      required>
                <option [value]="'pyes'">{{ 'TODAY.TICKET.PLACE.SELECT' | translate}}</option>
                <option *ngFor="let place of personService.places" [value]="place.id"
                >- {{place.name}} -
                </option>
              </select>
            </div>
            <div
              *ngIf="'' !== ticket.placeName"
              [matTooltip]="'GUIDE.TOOLTIPS.TICKET.PLACE.OPEN' | translate"
              style="
              color: #03a9f4;
              margin: 3px auto; width: fit-content;
              cursor: pointer;
              "
            >
              <div *ngIf="!personService.giveDialogTooltipAnswer else notLinkPlaceTooltip"
                   [routerLink]="'/places'"
                   [queryParams]="{id: ticket.placeId, name: ticket.placeName}">
                <span style="color: darkorange">-</span> {{ticket.placeName}} <span style="color: darkorange">-</span>
              </div>
              <ng-template #notLinkPlaceTooltip>
                <div (click)="placeChoosen()">
                  <span style="color: darkorange">-</span> {{ticket.placeName}} <span style="color: darkorange">-</span>
                </div>
              </ng-template>
            </div>
          </mat-card-footer>
        </mat-card>
      </td>
    </tr>
  </table>


</ng-template>
<br/>
<br/>
<br/>
