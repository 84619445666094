import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {ExtendedStatus} from "../../../dto/ExtendedStatus";
import {GatewayService} from "../../../services/gateway.service";
import {HttpClient} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  passwordsFormGroup: FormGroup;
  confirmationCodeFormGroup: FormGroup;
  resetResult = "";
  resetColor = 'green';
  codeConfirmed = '';
  private formValue: any;
  goAuth = false;

  constructor(private _formBuilder: FormBuilder,
              private auth: AuthService,
              private api: GatewayService,
              private http: HttpClient,
              private cookieService: CookieService) { }

  ngOnInit(): void {
    this.passwordsFormGroup = this._formBuilder.group({
      emailCtrl: [this.auth.email, Validators.required],
      pwdCtrl: ['', Validators.required],
      cPwdCtrl: ['', Validators.required]
    });
    this.confirmationCodeFormGroup = this._formBuilder.group({
      codeCtrl: ['', Validators.required]
    });
  }

  resetOnSubmitStep1(value: any) {
    this.formValue = value;
    //console.log(value);
    let url = '/api/lrs/v1/reset/password/';
    if (this.api.localhost) {
      url = this.api.LOGIN_REGISTER_SERVICE + url;
    }
    try {
      this.http.put<ExtendedStatus>(url, value)
        .subscribe((data) => {
          //console.log('code sent:: ', data);
          // if (data.result === 'no created') {
          //   this.codeConfirmed = 'Such user created. Login.';
          // }
        }, (error) => this.handleError(error));
    } catch (e) {
    }
  }

  resetOnSubmitStep2(value: any) {
    const email = this.passwordsFormGroup.get('emailCtrl').value;
    const value2 = {
      codeCtrl: value.codeCtrl,
      emailCtrl: email
    };
    let url = '/api/lrs/v1/reset/code/';
    if (this.api.localhost) {
      url = this.api.LOGIN_REGISTER_SERVICE + url;
    }

    try {
      this.http.put<ExtendedStatus>(url, value2)
        .subscribe((data) => {
          //console.log('data:: ', data);
          if (data.result === 'okay') {
            this.resetResult = 'Password Changed.';
            // this. = 'lightgreen';
            this.soLogin();
          } else {
            // this.resetResult = 'Code Wrong.';
            // this.resetColor = 'orange';
            // this.codeConfirmed = data.message;
            // this.registerColor = 'orange';
          }
        }, (error) => this.handleError(error));
    } catch (e) {
    }
  }

  private soLogin() {
    try {
      let url = '/api/lrs/v1/login/email/';
      if (this.api.localhost) {
        url = this.api.LOGIN_REGISTER_SERVICE + url;
      }
      this.auth.email = this.formValue.emailCtrl;
      this.http.post<ExtendedStatus>(url, this.formValue)
        .subscribe((data) => {
          // this.authToken = data.message;
          this.cookieService.deleteAll();
          this.auth.token = data.message;
          // set cookies
          this.cookieService.set('auth', data.message, 365);
          if (data.result === 'not') {
            // this.loginMessage = 'Password wrong.';
            // this.loginColor = 'orange';
          } else if (data.result === 'okay') {
            // this.loginMessage = 'Done.';
            // this.loginColor = 'green';
            this.auth.homeButtonLink = '/today';
            this.goAuth = true;
          }
        }, (error) => this.handleError(error));
    } catch (e) {
    }
  }

  private handleError(error: any) {
    this.resetResult = 'Code Wrong.';
    this.resetColor = 'orange';
  }
}
