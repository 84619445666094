import { Pipe, PipeTransform } from '@angular/core';
import {Project} from "../components/today/projects/projects.component";

@Pipe({
  name: 'projects'
})
export class ProjectsPipe implements PipeTransform {

  transform(projects: Project[]): Project[] {
    const filteredProjects: Project [] = projects.filter(p => !p.archive);
    return filteredProjects;
  }

}
