import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {DatePipe} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import { SidenavComponent } from './components/layout/sidenav/sidenav.component';
import { ContentComponent } from './components/layout/content/content.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import { NotFound404Component } from './components/system/not-found404/not-found404.component';
import { LoginComponent } from './components/user/login/login.component';
import { RegisterComponent } from './components/user/register/register.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatExpansionModule} from "@angular/material/expansion";
import { LandingComponent } from './components/layout/landing/landing.component';
import {MatButtonModule} from '@angular/material/button';
import {MatStepperModule} from "@angular/material/stepper";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import { TodayComponent } from './components/user/today/today.component';
import {MatMenuModule} from "@angular/material/menu";
import { MenuComponent } from './components/layout/menu/menu.component';
import { ProfileComponent } from './components/today/profile/profile.component';
import { DevelopmentComponent } from './components/alerts/development/development.component';
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {MatChipsModule} from "@angular/material/chips";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS, MatNativeDateModule, NativeDateModule} from '@angular/material/core';

import {CookieService} from "ngx-cookie-service";
import { ResetComponent } from './components/user/reset/reset.component';
import { StatusPipe } from './pipes/status.pipe';
import { PaymentComponent } from './components/today/payment/payment.component';
import { PrivacyPolicyComponent } from './components/law/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/law/terms-of-use/terms-of-use.component';
import { CounterComponent } from './components/utils/counter/counter.component';
import { PeopleComponent } from './components/today/people/people.component';
import {MatRadioModule} from "@angular/material/radio";
import { GuideComponent } from './components/help/guide/guide.component';
import { PlacesComponent } from './components/today/places/places.component';
import { ProjectsComponent } from './components/today/projects/projects.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DragDropModule} from "@angular/cdk/drag-drop";
import { AppearDirective } from './directives/appear.directive';
import { TeamsComponent } from './components/today/teams/teams.component';
import { TeamProjectComponent } from './components/today/team-projects/team-project.component';
import { TicketDialogComponent } from './components/today/team-projects/ticket-dialog/ticket-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import { AboutComponent } from './components/help/about/about.component';
import { PayComponent } from './components/today/pay/pay.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { GuideDialogComponent } from './components/help/guide-dialog/guide-dialog.component';
import { LanguageComponent } from './components/utils/language/language.component';
import { SafePipe } from './pipes/safe.pipe';
import { ControlComponent } from './components/administration/control/control.component';
import { CostComponent } from './components/today/cost/cost.component';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { CoachComponent } from './components/today/coach/coach.component';
import {MatTabsModule} from "@angular/material/tabs";
import { ProjectsPipe } from './pipes/projects.pipe';
import { TicketStatusMarkerComponent } from './components/common/projects/ticket-status-marker/ticket-status-marker.component';
import { TicketDescriptionComponent } from './components/common/ticket-description/ticket-description.component';
import { TicketCheckListComponent } from './components/common/ticket-check-list/ticket-check-list.component';
import { TicketTextComponent } from './components/common/ticket-text/ticket-text.component';
import { CommentsComponent } from './components/common/comments/comments.component';
import { UsersComponent } from './components/layout/pages/users/users.component';
import {MatGridListModule} from "@angular/material/grid-list";
import { BusinessProcessComponent } from './components/common/business-process/business-process.component';

import { InViewportModule } from 'ng-in-viewport';

import 'intersection-observer';
import {globalTooltipDefaults} from "./configuration/matTooltips";
import { SkillsComponent } from './components/today/skills/skills.component';
import { SelfOrganizerComponent } from './components/layout/pages/self-organizer/self-organizer.component';
import { SolutionsHeaderComponent } from './components/layout/pages/solutions-header/solutions-header.component';
import { FamilyHubComponent } from './components/layout/pages/family-hub/family-hub.component';
import { DreamsMakerComponent } from './components/layout/pages/dreams-maker/dreams-maker.component';
import { PersonalProjectsComponent } from './components/layout/pages/personal-projects/personal-projects.component';
import { AllTeamsComponent } from './components/layout/pages/all-teams/all-teams.component';
import { TourComponent } from './components/layout/pages/tour/tour.component';
import { ContactsComponent } from './components/layout/pages/contacts/contacts.component';
import { AlertsComponent } from './components/today/alerts/alerts.component';
import {MatListModule} from "@angular/material/list";
import {Router} from "@angular/router";


@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    ContentComponent,
    HeaderComponent,
    FooterComponent,
    NotFound404Component,
    LoginComponent,
    RegisterComponent,
    LandingComponent,
    TodayComponent,
    MenuComponent,
    ProfileComponent,
    DevelopmentComponent,
    ResetComponent,
    StatusPipe,
    PaymentComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    CounterComponent,
    PeopleComponent,
    GuideComponent,
    PlacesComponent,
    ProjectsComponent,
    AppearDirective,
    TeamsComponent,
    TeamProjectComponent,
    TicketDialogComponent,
    AboutComponent,
    PayComponent,
    GuideDialogComponent,
    LanguageComponent,
    SafePipe,
    ControlComponent,
    CostComponent,
    CoachComponent,
    ProjectsPipe,
    TicketStatusMarkerComponent,
    TicketDescriptionComponent,
    TicketCheckListComponent,
    TicketTextComponent,
    CommentsComponent,
    UsersComponent,
    BusinessProcessComponent,
    SkillsComponent,
    SelfOrganizerComponent,
    SolutionsHeaderComponent,
    FamilyHubComponent,
    DreamsMakerComponent,
    PersonalProjectsComponent,
    AllTeamsComponent,
    TourComponent,
    ContactsComponent,
    AlertsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    HttpClientModule,
    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatExpansionModule,
    MatButtonModule,
    FormsModule,
    MatStepperModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatSelectModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    DragDropModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxChartsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatGridListModule,
    InViewportModule,
    MatListModule
  ],
  providers: [
    CookieService,
    DatePipe,
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: globalTooltipDefaults}
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(
  //   private readonly router: Router,
  // ) {
  //   router.events
  //     .subscribe(console.log)
  // }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
