import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {GatewayService, Ticket} from "../../../services/gateway.service";
import {TeamProject} from "../teams/teams.component";
import {PersonService, Team, TeamMember} from "../../../services/person.service";
import {moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {MatDialog} from "@angular/material/dialog";
import {TicketDialogComponent} from "./ticket-dialog/ticket-dialog.component";
import {FormControl, Validators} from "@angular/forms";
import {MyErrorStateMatcher} from "../projects/projects.component";
import {MatDatepicker} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MY_FORMATS} from "../../user/today/today.component";

import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {bounce, bounceInUp, bounceOutUp, fadeOut, rotateInUpRight, rotateOutUpRight, zoomOut} from "ng-animate";

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-team-project',
  templateUrl: './team-project.component.html',
  styleUrls: ['./team-project.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  animations: [
    trigger('addNewIconAnimation', [
      state('*', style({ transform: 'rotate(0)' })),
      state('void', style({ transform: 'rotate(-45deg)' })),
      transition('* => void', [
      // style({ transform: 'rotate(-45deg)' }),

      animate('1000ms ease-in')
        ])
    ]),
    trigger('closeNewIconAnimation', [
      state('*', style({ transform: 'rotate(0)' })),
      state('void', style({ transform: 'rotate(45deg)' })),
      transition('* => void', [
      // style({ transform: 'rotate(-45deg)' }),

      animate('1000ms ease-in')
        ])
    ]),
    trigger('formToAddTicketAnimation', [
      transition('void => *', useAnimation(bounce)),
      transition('* => void', useAnimation(zoomOut))
    ]),
    trigger('settings', [
      transition('void => *', useAnimation(bounceInUp)),
      transition('* => void', useAnimation(bounceOutUp))
    ])
  ]
})
export class TeamProjectComponent implements OnInit {

  teamProjectId;
  teamProject: TeamProject = {
    sprintToAdd: "",
    sprintsList: [],
    epic: "", epicToAdd: "", epicsList: [],
    businessProcess: "",
    businessProcessStep: "",
    auth: "", id: "", name: "", selected: false, shortName: "", teamId: ""};
  projectTicketsBacklog: Ticket [] = [];
  projectTicketsTodo: Ticket [] = [];
  projectTicketsToday: Ticket [] = [];
  projectTicketsCompleted: Ticket [] = [];
  projectTicketsClosed: Ticket [] = [];
  backlogTextArea = '';
  todoTextArea = '';
  progressTextArea = '';
  completedTextArea = '';
  closedTextArea = '';

  epics = new FormControl();
  sprints = new FormControl();

  epicList: string[] = [];

  sprintList: string[] = [];

  addEpic= false;
  steps = [];

  epicNameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(33)
  ]);

  matcher = new MyErrorStateMatcher();
  addSprint = false;

  sprintDatePickerFormControl = new FormControl(moment());

  sprintWeeksNumberFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[1-9]')
  ]);
  settingsOpened = false;
  addTicketInput = true;
  closeTicketInput = false;
  newTicketInput = false;
  settingsOpenBtn = true;
  projectBusinessProcessTabIndex = 0;

  emailFormControl = new FormControl('', [
    Validators.required,
  ]);
  selectedEpic = null;
  selectedSprint = null;

  constructor(
    private route: ActivatedRoute,
    private gateway: GatewayService,
    public personService: PersonService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadAllTeamsInit();
  }

  private loadAllTeamsInit() {
    this.personService.loading = true;
    this.gateway.planningServiceLoadAllTeams().subscribe(data => {
      this.personService.teams = data;
      this.personService.loading = false;
      this.secondaryInit();
    });
  }

  private secondaryInit() {
    this.epics.setValue('111111100110101100101000000000');
    this.sprints.setValue('111111100110101100101000000000');
    this.route.queryParams.subscribe(params => {
      this.teamProjectId = params['id'];
      if (params['e'] != null && params['e'] !== '') {
        this.selectedEpic = params['e'];
        this.epics.setValue(this.selectedEpic);
      }
      if (params['s'] != null && params['s'] !== '') {
        this.selectedSprint = params['s'];
        this.sprints.setValue(this.selectedSprint);
      }
      //console.log('id', this.teamProjectId);
      this.getTeamProjectById();
      // load team selected by project id
      this.personService.loading = true;
      this.gateway.planningServiceLoadAllTeamMembersByProjectId(this.teamProjectId).subscribe(data => {
        //console.log('chooseTeam', data);
        this.personService.teamSelected = data;
        this.personService.loading = false;
      });

    });
    // this.openDialog();
  }


  openDialog(ticket: Ticket): void {
    const dialogRef = this.dialog.open(TicketDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      data: ticket
    });
    dialogRef.afterClosed().subscribe((finalTicket: Ticket) => {
      // console.log('The dialog was closed finalTicket', finalTicket);
      ticket.userHasGotId = this.personService.currentDialogTicket.userHasGotId;
      ticket.ttext = this.personService.currentDialogTicket.ttext;
      this.loadAllTickets();
    });
    dialogRef.backdropClick().subscribe((result) => {
      // console.log('The dialog was closed', result);
      // ticket.userHasGotId = finalTicket.userHasGotId;
    });
  }

  private getTeamProjectById() {
    let project = this.personService.teamProjects.find(p => p.id === this.teamProjectId);
    // reload the project hard to get business processes
    // todo: update bp version
    if (true || project == null) {
      // get team project
      this.personService.loading = true;
      this.gateway.planningServicLoadTeamProject(this.teamProjectId).subscribe(data => {
        // console.log('planningServicLoadTeamTickets', data);
        this.teamProject = data;
        this.personService.lastTeamProject = data;
        //console.log(this.personService.teams, this.teamProject, this.teamProjectId);
        this.team = this.personService.teams.find(p => (p.id === this.teamProject.teamId));
        this.personService.loading = false;
        if (null != this.teamProject.businessProcess && -1 !== this.teamProject.businessProcess.indexOf(',')) {
          this.steps = this.teamProject.businessProcess.split(',');
        } else {
          this.steps = [];
        }
      });
    } else {
      this.teamProject = project;
      this.personService.lastTeamProject = project;
      this.team = this.personService.teams.find(p => (p.id === this.teamProject.teamId));
    }

    // load tickets
    this.loadAllTickets();
  }

  private loadAllTickets() {
    this.personService.loading = true;
    this.gateway.planningServicLoadTeamTickets(this.teamProjectId, 'backlog', this.selectedEpic, this.selectedSprint).subscribe(data => {
      // console.log('planningServicLoadTeamTickets', data);
      this.projectTicketsBacklog = data;
      this.personService.loading = false;
    });
    this.personService.loading = true;
    this.gateway.planningServicLoadTeamTickets(this.teamProjectId, 'todo', this.selectedEpic, this.selectedSprint).subscribe(data => {
      // console.log('planningServicLoadTeamTickets', data);
      this.projectTicketsTodo = data;
      this.personService.loading = false;
    });
    this.personService.loading = true;
    this.gateway.planningServicLoadTeamTickets(this.teamProjectId, 'progress', this.selectedEpic, this.selectedSprint).subscribe(data => {
      // console.log('planningServicLoadTeamTickets', data);
      this.projectTicketsToday = data;
      this.personService.loading = false;
    });
    this.personService.loading = true;
    this.gateway.planningServicLoadTeamTickets(this.teamProjectId, 'completed', this.selectedEpic, this.selectedSprint).subscribe(data => {
      // console.log('planningServicLoadTeamTickets', data);
      this.projectTicketsCompleted = data;
      this.personService.loading = false;
    });
    this.personService.loading = true;
    this.gateway.planningServicLoadTeamTickets(this.teamProjectId, 'closed', this.selectedEpic, this.selectedSprint).subscribe(data => {
      // console.log('planningServicLoadTeamTickets', data);
      this.projectTicketsClosed = data;
      this.personService.loading = false;
    });
  }

  drop(event, column) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    //console.log('?item', event.container.data[event.currentIndex], column)
    //if ('backlog' === column) {

    this.changeProjectTeamTicketStatus(event.container.data[event.currentIndex], column);

    // } else if ('progress' === column) {
    //   // this.setTodayStatus(event.container.data[event.currentIndex]);
    // } else if ('completed' === column) {
    //   // this.setDoneStatus(event.container.data[event.currentIndex]);
    // } else if ('todo' === column) {
    //   // this.setDoneStatus(event.container.data[event.currentIndex]);
    // }
  }

  addToHere(textAreaContent: string, section: string) {
    let teamId = '';
    const localEpic = this.selectedEpic == null ? '' : this.selectedEpic;
    const localSprint = this.selectedSprint == null ? '' : this.selectedSprint;
    for (let team of this.personService.teams) {
      for (let project of team.projects) {
        if (project.id === this.teamProjectId) {
          teamId = team.id;
          break;
        }
      }
    }
    if (teamId === '') {
      teamId = this.teamProject.teamId;
    }
    let ticket: Ticket = {
      teamId: teamId,
      commentsNumberMirror: 0, commentsNumberThis: 0,
      commentsFlag: false, relatedTicketId: "",
      tmpOption: "",
      checkListActual: [], checkListDone: [],
      checkListAddFlag: false,
      checkListOption: "",
      edit: "",
      animationState: "",
      userAuthorId: "", userHasGotId: "",
      answer: "",
      addPlace: "",
      alertTsBefore: 0,
      auth: "",
      dateAndTimeIdStr: "",
      dateIdStr: "",
      deleteButtonColor: "",
      description: "",
      descriptionAddFlag: false,
      direction: "",
      fullDescription: false,
      hh: "",
      mm: "",
      personFullName: "",
      personId: "",
      pickerDate: undefined,
      placeId: "",
      placeName: "",
      projectFullName: "",
      projectId: "",
      projectShortName: "",
      businessProcessStep: "",
      relatedStatus: "",
      status: "team",
      teamStatus: section,
      teamProjectId: this.teamProjectId,
      tomorrowDateSending: "",
      tsOfPlannedDate: 0,
      ttext: textAreaContent,
      uuid: "",
      epic: localEpic,
      sprint: localSprint
    };
    //console.log('ticket', ticket);
    switch (section) {
      case 'backlog': {
        // this.projectTicketsBacklog.push(ticket);
        this.personService.loading = true;
        this.gateway.planningServicNewTeamProjectTicket(ticket).subscribe(data => {
          //console.log('planningServicNewTeamProjectTicket', data);
          this.projectTicketsBacklog.push(data);
          this.personService.loading = false;
        });
        break;
      }
      case 'todo': {
        this.gateway.planningServicNewTeamProjectTicket(ticket).subscribe(data => {
          //console.log('planningServicNewTeamProjectTicket', data);
          this.projectTicketsTodo.push(data);
          this.personService.loading = false;
        });
        break;
      }
      case 'progress': {
        this.gateway.planningServicNewTeamProjectTicket(ticket).subscribe(data => {
          //console.log('planningServicNewTeamProjectTicket', data);
          this.projectTicketsToday.push(data);
          this.personService.loading = false;
        });
        break;
      }
      case 'completed': {
        this.gateway.planningServicNewTeamProjectTicket(ticket).subscribe(data => {
          //console.log('planningServicNewTeamProjectTicket', data);
          this.projectTicketsCompleted.push(data);
          this.personService.loading = false;
        });
        break;
      }
      case 'closed': {
        this.gateway.planningServicNewTeamProjectTicket(ticket).subscribe(data => {
          //console.log('planningServicNewTeamProjectTicket', data);
          this.projectTicketsClosed.push(data);
          this.personService.loading = false;
        });
        break;
      }
      default: {
        break;
      }
    }
    this.backlogTextArea = '';
    //this.teamProjectId = '';
    this.completedTextArea = '';
    this.todoTextArea = '';
    this.progressTextArea = '';
  }

  private changeProjectTeamTicketStatus(ticket: Ticket, section) {
    ticket.teamStatus = section;
    this.personService.loading = true;
    this.gateway.planningServiceChangeTeamProjectTicketStatus(ticket).subscribe(data => {
      //console.log('planningServiceChangeTeamProjectTicketStatus', data);
      // this.projectTicketsClosed.push(data);
      this.personService.loading = false;
    });
    //console.log('changeProjectTeamTicketStatus', ticket);
  }

  addNewEpic() {
    this.personService.loading = true;
    this.teamProject.epicToAdd = this.epicNameFormControl.value;
    this.gateway.planningServiceTeamProjectAddEpic(this.teamProject).subscribe(data => {
      this.epicList.push(this.epicNameFormControl.value);
      //console.log('this.epicNameFormControl.value::', this.epicNameFormControl.value, this.epics);
      this.teamProject.epicsList.push(this.epicNameFormControl.value);
      this.epics.setValue(this.epicNameFormControl.value);
      this.selectedEpic = this.epicNameFormControl.value;
      this.loadAllTickets();
      // alert(1);
      this.epicNameFormControl.reset();
      this.addEpic = false;
      this.personService.loading = false;
    });
  }

  epicOptionSelected(event: any) {
    this.selectedSprint = null;
    this.sprints.setValue('111111100110101100101000000000');
    // alert(this.epics.value);
    // console.log(event);
    if ('111011100110101100101000000000' === event) {
      this.addEpic = true;
    } else if ('111111100110101100101000000000' === event) {
      this.addEpic = false;
      this.selectedEpic = null;
      this.loadAllTickets();
    } else {
      this.selectedEpic = event;
      this.loadAllTickets();
    }
  }

  sprintOptionSelected(event: any) {
    this.selectedEpic = null;
    this.epics.setValue('111111100110101100101000000000');
    // alert(this.epics.value);
    // console.log(event);
    if ('111011100110101100101000000000' === event) {
      this.addSprint = true;
    } else if ('111111100110101100101000000000' === event) {
      this.addSprint = false;
      this.selectedSprint = null;
      this.loadAllTickets();
    } else {
      this.selectedSprint = event;
      this.loadAllTickets();
    }
  }

  addNewSprint() {
    const optionValue = this.sprintDatePickerFormControl.value.format('YYYY-MM-DD');
    console.log('optionValue', optionValue);
    this.teamProject.sprintToAdd = optionValue;
    this.gateway.planningServiceTeamProjectAddSprint(this.teamProject).subscribe(data => {
      // this.epicList.push(this.epicNameFormControl.value);
      //console.log('this.epicNameFormControl.value::', this.epicNameFormControl.value, this.epics);
      this.teamProject.sprintsList.push(optionValue);
      this.sprints.setValue(optionValue);
      this.selectedSprint = optionValue;
      this.loadAllTickets();
      // alert(1);
      // this.sprintList.push(optionValue);
      // this.sprints.setValue(optionValue);
      this.sprintWeeksNumberFormControl.reset();
      this.sprintDatePickerFormControl.reset();
      this.addSprint = false;
      this.personService.loading = false;
    });
  }

  addTicketMode() {
    this.addTicketInput = false;
    setTimeout(() => {this.closeTicketInput = true; this.newTicketInput = true;}, 1010);
  }

  closeTicketMode() {
    this.closeTicketInput = false;
    setTimeout(() => {this.addTicketInput = true; this.newTicketInput = false;}, 1010);
  }

  closeSettings() {
    this.settingsOpened = false;
    setTimeout(() => {
      this.settingsOpenBtn = true;
    }, 1010);
  }

  getFullName(item: Ticket) {
    const member: TeamMember[] = this.personService.teamSelected.members.filter(t => t.userId === item.userHasGotId);
    if (member.length > 0) {
      return member[0].firstName + ' ' + member[0].secondName;
    } else
      return '';
  }

  // addStep() {
  //   if (this.emailFormControl.value === '') return;
  //
  //   this.steps.push(this.emailFormControl.value.toString().split(',').join('').toLowerCase());
  //   this.emailFormControl.setValue('');
  //   this.emailFormControl.setErrors(null);
  //
  //   //store data
  //   this.teamProject.businessProcess = '';
  //   for (let step of this.steps) {
  //     this.teamProject.businessProcess += step;
  //     if (step !== this.steps[this.steps.length - 1]) {
  //       this.teamProject.businessProcess += ',';
  //     }
  //   }
  //   // store to db
  //   // console.log('this.project.businessProcess', this.project.businessProcess);
  //   // this.personService.loading = true;
  //   // this.gateway.planningServiceUpdateProjectBusinessProcess(this.teamProject).subscribe(data => {
  //   //   this.personService.loading = false;
  //   // });
  // }
  //
  // removeStep() {
  //   this.steps.splice(this.steps.length - 1, 1);
  //   //store data
  //   this.teamProject.businessProcess = '';
  //   for (let step of this.steps) {
  //     this.teamProject.businessProcess += step;
  //     if (step !== this.steps[this.steps.length - 1]) {
  //       this.teamProject.businessProcess += ',';
  //     }
  //   }
  //   // this.personService.loading = true;
  //   // this.gateway.planningServiceUpdateProjectBusinessProcess(this.teamProject).subscribe(data => {
  //   //   this.personService.loading = false;
  //   // });
  // }
  updateShortNameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(5)
  ]);
  updateFullNameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(33)
  ]);
  team: Team = {auth: "", id: "", members: [], name: "", newMember: undefined, projects: []};

  addStep() {
    if (this.emailFormControl.value === '') return;

    this.steps.push(this.emailFormControl.value.toString().split(',').join('').toLowerCase());
    this.emailFormControl.setValue('');
    this.emailFormControl.setErrors(null);

    //store data
    this.teamProject.businessProcess = '';
    for (let step of this.steps) {
      this.teamProject.businessProcess += step;
      if (step !== this.steps[this.steps.length - 1]) {
        this.teamProject.businessProcess += ',';
      }
    }
    // store to db
    // console.log('this.project.businessProcess', this.project.businessProcess);
    this.personService.loading = true;
    this.gateway.planningServiceUpdateTeamProjectBusinessProcess(this.teamProject).subscribe(data => {
      this.personService.loading = false;
      let project = this.personService.teamProjects.find(p => p.id === this.teamProjectId);
      if (project != null) {
        project.businessProcess = this.teamProject.businessProcess;
      }
    });
  }


  removeStep() {
    this.steps.splice(this.steps.length - 1, 1);
    //store data
    this.teamProject.businessProcess = '';
    for (let step of this.steps) {
      this.teamProject.businessProcess += step;
      if (step !== this.steps[this.steps.length - 1]) {
        this.teamProject.businessProcess += ',';
      }
    }
    this.personService.loading = true;
    this.gateway.planningServiceUpdateTeamProjectBusinessProcess(this.teamProject).subscribe(data => {
      this.personService.loading = false;
      // update team project in the array
      let project = this.personService.teamProjects.find(p => p.id === this.teamProjectId);
      if (project != null) {
        project.businessProcess = this.teamProject.businessProcess;
      }
    });
  }


  updateName() {

    this.teamProject.name = this.updateFullNameFormControl.value;
    this.teamProject.shortName = this.updateShortNameFormControl.value;

    this.personService.loading = true;
    this.gateway.planningServiceUpdateTeamProjectName(this.teamProject).subscribe(data => {
      this.personService.loading = false;
    });
  }
}
